import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Axios from '../../../utils/axios';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import NewDecreeModal from '../NewDecreeModal/NewDecreeModal'
import { AnyARecord } from 'dns';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tools: {
        display: 'flex',
        flexDirection: 'row'
    },
    icon: {
        cursor: 'pointer',
        margin: '0px, 10px'
    }
});

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}

const formatDate = (date: string) => {
    const d = new Date(date);

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear() + ' г.';

    return result;
};

export default function DecreeTable({ isUpdate }: any) {
    const classes = useStyles();
    const [decrees, setDecrees] = useState([]);
    const [flag, setFlag] = useState(false);

    const [selectedId, setSelectedId] = useState(null);
    const [openNewModal, setOpenNewModal] = React.useState(false);

    const handleClickOpen = () => {
        setOpenNewModal(true);
    };
    const handleClose = () => {
        setOpenNewModal(false);
    };

    useEffect(() => {
        const axios = new Axios();
        axios.get(`worker/order/${localStorage.getItem('id')}/`)
            .then(res => {
                setDecrees(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
    }, [isUpdate, flag, openNewModal]);

    const editItem = (id: number) => {
        setSelectedId(id as any);
        handleClickOpen();
        /*const axios = new Axios();
        const data = {

        }

        axios.patch(`worker/order/${localStorage.getItem('id')}/${id}`, data)
            .then(res => {
                setFlag(!flag);
            })
            .catch(err => {
                console.log(err);
            })
            */
    }

    const deleteItem = (id: number) => {
        const axios = new Axios();
        axios.delete(`worker/order/${localStorage.getItem('id')}/${id}`, {})
            .then(res => {
                setFlag(!flag);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getNameDoc = (str: string) => {
        if (str === 'null') {
            return '';
        }
        const index = str.lastIndexOf('/');
        const name = str.substring(index + 1);
        return decodeURI(name);
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Тип документа</TableCell>
                            <TableCell align="left">Документ</TableCell>
                            <TableCell align="left">Дата</TableCell>
                            <TableCell align="left">ФИО</TableCell>
                            <TableCell align="left">Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {decrees.map((row: any) => (
                            <TableRow key={getRandomInt(876543)}>
                                <TableCell align="left">
                                    {row.type.name}
                                </TableCell>
                                <TableCell align="left">
                                    <a href={row.docs} download={row.type.id}>{getNameDoc(String(row.docs))}</a>
                                </TableCell>
                                <TableCell align="left">
                                    {formatDate(row.register_date)} {row.register_date_end ? ' - ' + formatDate(row.register_date_end) : ''}
                                </TableCell>
                                <TableCell align="left">
                                    {row.name}
                                </TableCell>
                                <TableCell align="left">
                                    <div className={classes.tools}>
                                        <div className={classes.icon}>
                                            <EditIcon onClick={() => { editItem(row.id) }} />
                                        </div>
                                        <div className={classes.icon}>
                                            <DeleteOutlineIcon onClick={() => { deleteItem(row.id) }} />
                                        </div>
                                    </div>

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            < NewDecreeModal
                open={openNewModal}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                id={selectedId}
            />
        </>
    );
}