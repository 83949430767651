import React from 'react';

class ErrorBoundary extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            error: null
        }
    }

    static getDerivedStateFromError(error: any) {
        // Update state so next render shows fallback UI.
       // debugger;
        return { error: error };
    }


    componentDidCatch(error: any, errorInfo: any) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
        //logErrorToMyService(error, errorInfo);
        // debugger;
    }

    render() {
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
           // debugger;
            return <h1>Что-то пошло не так.</h1>;
        }
        // debugger;
        return this.props.children;
    }
}

export default ErrorBoundary;