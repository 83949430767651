import React, { useState, useEffect } from 'react';
import DocumentsTable from '../components/Documents/DocumentsTable';
import Button from '@material-ui/core/Button';
import NewNoticeModal from '../components/Documents/NewNoticeModal'
import classes from '../components/Documents/Documents.module.css'
import DocumentsToolbar from '../components/Documents/DocumentsToolbar';
import Axios from '../utils/axios';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {DeletedDocuments} from "./DeletedDocuments";


export interface Item {
    id: string;
    name: string;
    register_date: string | number | Date;
    office: string;
    type: any;
}

type Order = 'asc' | 'desc';

export default function Documents(props: any) {

    const month = new Date().getMonth() + 1 <= 9 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1;
    const day = new Date().getDate() <= 9 ? `0${new Date().getDate()}` : new Date().getDate();
    const data = `${new Date().getFullYear()}-${month}-${day}`


    const [countItems, setCountItems] = React.useState(0);
    const [items, setItems] = React.useState<Item[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('register_date');

    const [updateTable, setUpdateTable] = React.useState(null as any);


    const [openModalSettings, setOpenModalSettings] = useState(false);

    const [columns, setColumns] = useState(null as any);
    const [defaultColumns, setDefaultColumns] = useState([] as any);
    const [isOpenDeleted, setOpenDeleted] = useState(false);
    const [deletedData, setDeletedData] = useState([]);

    const [openChangeFlagDate, setOpenChangeFlagDate] = useState(false);
    const [refuseFlagDate, setRefuseFlagDate] = useState(false);
    const [addedData, setAddedData] = useState(null as any);
    const [changeFlagData, setChangeFlagData] = useState(data);
    const [respPerson, setRespPerson] = useState('');

    const handleClickOpenSettings = () => {
        setOpenModalSettings(true);
    };


    const handleCloseSettings = () => {
        setOpenModalSettings(false);
    };

    useEffect(() => {
        const axios = new Axios();
        axios.get(`user/me/`)
            .then(res => {
                const result = Object.keys(res.data.setting['3']);
                setDefaultColumns(result)
            })

    }, []);

    useEffect(() => {
        const axios = new Axios();
        axios.get(`worker/field/3`)
            .then(res => {
                let newArrData = Object.assign(res.data, {delete: 'Удаление'})
                const result = [];
                for (let i = 0; i < defaultColumns.length; i += 1) {
                    result.push({});
                }

                for (const [key, value] of Object.entries(newArrData)) {
                    const index = defaultColumns.indexOf(key);
                    if (index !== -1) {
                        result[index] = {
                            id: key,
                            label: value,
                            isSelected: true
                        };
                    } else {
                        result.push({
                            id: key,
                            label: value,
                            isSelected: false
                        })
                    }
                }
let arr_=result.filter((i:any)=>{
    return Object.keys(i).length!==0 && i.label!=="Entering" && i.label !=="Flag" && i.id !=='name'
})
                setColumns(arr_);
            })
            .catch(err => {
                console.log(err);
            })
    }, [defaultColumns])

    const [openNoticeModal, setOpenNoticeModal] = React.useState(false);

    const handleClickOpenNoticeModal = () => {
        setOpenNoticeModal(true);
    };
    const handleCloseNoticeModal = () => {
        setOpenNoticeModal(false);
    };

    const [officeName, setOfficeName] = useState('');

    const handleChangeOfficeName = (event: any) => {
        setOfficeName(event.name as string);
    };


    const [valueDocumentType, setDocumentType] = useState(0);
    const handleChangeDocumentType = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDocumentType(event.target.value as number);
    };

    useEffect(() => {
        let data = {};

        let order_ = '';
        if (order === 'asc') {
            order_ = '-'
        }

        if (officeName.length > 0 && valueDocumentType > 0) {
            data = {
                "office__name__contains": officeName,
                'type': valueDocumentType,
                order: `${order_}${orderBy}`,
                'entering': true,
            }
        } else if (officeName.length > 0) {
            data = {
                "office__name__contains": officeName,
                order: `${order_}${orderBy}`,
                'entering': true,
            }
        } else if (valueDocumentType > 0) {
            data = {
                'type': valueDocumentType,
                order: `${order_}${orderBy}`,
                'entering': true,
            }
        } else data = {
            order: `${order_}${orderBy}`,
            'entering': true,
        }

        const axios = new Axios();
        axios.post(`worker/report/${localStorage.getItem('id')}/filter/?page=${page + 1}&page_size=${rowsPerPage}`, data)
            .then(res => {
                setCountItems(res.data.count);
                setItems(res.data.results);
                //setPage(0);
            })
            .catch(err => {
                console.log(err);
            })
        axios.get(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}/`)
            .then(res => {
                setRespPerson(res.data.full_name)
            })
    }, [page, rowsPerPage, officeName, valueDocumentType, order, orderBy, updateTable]);

    const handleClickOpenDeletedLetters=()=>{
        setOpenDeleted(true);
        getDeletedDate()
    };
    const getDeletedDate=()=>{
        const axios = new Axios();
        axios.post(`worker/report/archive/${localStorage.getItem('id')}/filter/`,{entering: true})
            .then(res => {
                setDeletedData(res.data.results)
            })
    };
    useEffect(()=>{
        getDeletedDate()
    },[updateTable])

  const sendAddedData=()=>{
      const axios = new Axios();
        if(addedData) {
            let body = new FormData();
            const blob = new Blob([addedData.docs], {type : 'text/plain'})

            body.append('docs', blob, addedData.docs)
            body.append("office_id", addedData.office_id);
            body.append("type_id", addedData.type.name);
            body.append("user", addedData.user);
            body.append("register_date", addedData.register_date);
            body.append("entering", addedData.entering);
            body.append("flag_date", addedData.flag_date);
            body.append("register_date_end", `${changeFlagData}T12:00:00.016954+03:00`);
            body.append("name", addedData.name);

            axios.post(`worker/report/${localStorage.getItem('id')}/`, body)
                .then(res=>{
                    setRefuseFlagDate(false);
                    setOpenChangeFlagDate(false);
                    setUpdateTable(new Date().getMilliseconds())
                })
        }

    }

    return (
        <div>
            <div className='documents-box1'>
                <span className={'title_'}>Входящие документы {localStorage.getItem('company')}</span>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={() => props.history.push('/home')} >
                        Назад
                </Button>
                </div>

                <div className={classes.btn} >
                    <div style={{flexDirection:'column', justifyContent:'flex-end', display:'flex'}}>
                    <Button variant="contained" onClick={handleClickOpenNoticeModal}
                            style={{width:'100px',marginBottom:'10px'}} >
                        Добавить
                </Button>
                    <Button variant="contained" onClick={handleClickOpenDeletedLetters} style={{width:'222px'}} >
                        Удалённые документы
                    </Button>
                </div>
                </div>
            </div>
            <div className='documents-box2'>
                <div style={{width:'98%'}}>
                    <DocumentsToolbar
                        valueDocumentType={valueDocumentType}
                        handleChangeDocumentType={handleChangeDocumentType}
                        handleChangeOfficeName={handleChangeOfficeName}
                        updateTable={updateTable}
                        setOfficeName={setOfficeName}
                        officeName={officeName}
                        handleClickOpen={handleClickOpenSettings}
                    />
                </div>


                <DocumentsTable
                    setOpenChangeFlagDate={setOpenChangeFlagDate}
                    setAddedData={setAddedData}
                    items={items}
                    countItems={countItems}
                    setItems={setItems}
                    officeName={officeName}
                    valueDocumentType={valueDocumentType}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    updateTable={updateTable}
                    order={order}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    setOrder={setOrder}
                    setUpdateTable={setUpdateTable}
                    columns={columns}
                    openModalSettings={openModalSettings}
                    handleCloseSettings={handleCloseSettings}
                    setColumns={setColumns}
                />

                <NewNoticeModal
                    type={'documents'}
                    handleClickOpen={handleClickOpenNoticeModal}
                    handleClose={handleCloseNoticeModal}
                    open={openNoticeModal}
                    updateTable={setUpdateTable}
                    respPerson={respPerson}
                    setRespPerson={setRespPerson}
                   />
                <DeletedDocuments setOpenDeleted={setOpenDeleted} isOpenDeleted={isOpenDeleted}
                                  deletedData={deletedData} setUpdateTable={setUpdateTable}/>
            </div>
            <Dialog style={{width:'300px !important'}}
                onClose={()=>{setOpenChangeFlagDate(false)}} aria-labelledby="customized-dialog-title"
                    open={openChangeFlagDate}>
                <DialogContent dividers style={{width:'300px !important'}}>
                    У документа задан Крайний срок ответа! Внести «Дату выполнения ответа» ?
                    {refuseFlagDate && <input type={'date'}
                    value={changeFlagData} onChange={(e)=>setChangeFlagData(e.target.value)}/>}
                </DialogContent>
                <DialogActions>
                    {refuseFlagDate ?   <Button autoFocus color="primary" onClick={sendAddedData}>
                        Сохранить
                    </Button> :
                    <Button autoFocus color="primary" onClick={()=>{setRefuseFlagDate(true)}}>
                        Да
                    </Button>}
                    <Button autoFocus onClick={()=>{
                        setOpenChangeFlagDate(false)
                        setAddedData(null);
                        setRefuseFlagDate(false)
                    }} color="primary">
                        Нет
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
