import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import RegulationsTable from '../components/RegulationsTable/RegulationsTable'
import Axios from '../utils/axios';
import NewRegulationModal from '../components/NewRegulationModal/NewRegulationModal';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsModalForDocuments from '../components/TableSettingsModal/SettingsModalForDocuments';

type Order = 'asc' | 'desc';

function Regulations(props: any) {
    const [countItems, setCountItems] = useState(0);
    const [items, setItems] = useState([] as any);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState('docs');

    const [defaultColumns, setDefaultColumns] = useState([] as any);

    const [value, setValue] = React.useState('');

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
    };

    const [openModalSettings, setOpenModalSettings] = useState(false);

    const [columns, setColumns] = useState(null as any);

    const [types, setTypes] = useState([{ id: 0, name: 'Все документы' }] as any);

    const [valueDocumentType, setDocumentType] = useState(0);
    const handleChangeDocumentType = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDocumentType(event.target.value as number);
    };

    const handleClickOpenSettings = () => {
        setOpenModalSettings(true);
    };

    const handleCloseSettings = () => {
        setOpenModalSettings(false);
    };

    useEffect(() => {
        const axios = new Axios();
        axios.get(`user/me/`)
            .then(res => {
                const result = Object.keys(res.data.setting['6']);
                setDefaultColumns(result)
            })

        axios.get(`library/subtype/`)
            .then(res => {
                const result = res.data.results as any;
                result.unshift({ id: 0, name: 'Все документы' });
                setTypes(result);
            })
    }, []);

    useEffect(() => {
        const axios = new Axios();
        axios.get(`worker/field/6`)
            .then(res => {
                const result = [];
                for (let i = 0; i < defaultColumns.length; i += 1) {
                    result.push({});
                }
                for (const [key, value] of Object.entries(res.data)) {
                    const index = defaultColumns.indexOf(key);
                    if (index !== -1) {
                        result[index] = {
                            id: key,
                            label: value,
                            isSelected: true
                        }
                    } else {
                        result.push({
                            id: key,
                            label: value,
                            isSelected: false
                        })
                    }
                }
let resultArr=result.map((i:any)=>{
    if(i.label==='Docs') {
        return {...i,label:'Документы'}
    }
    else return i
})
                setColumns(resultArr);
            })
            .catch(err => {
                console.log(err);
            })
    }, [defaultColumns]);



    const selectBy = () => {
        let data = {
            "type__name": "Нормативный документ",
            order: '-docs',
            "docs__contains": value,
            'delete': false,
            subtype_id: valueDocumentType === 0 ? undefined : valueDocumentType
        };
        const axios = new Axios();
        axios.post(`worker/report/${localStorage.getItem('id')}/filter/?page=${page + 1}&page_size=${rowsPerPage}`, data)
            .then(res => {
                setCountItems(res.data.count);
                setItems(res.data.results);

            })
            .catch(err => {
                console.log(err);
            })
    }

    const [open, setOpen] = useState(false);

    const handleClickOpenModal = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    const updateTable = () => {
        let order_ = '';
        if (order === 'desc') {
            order_ = '-'
        }
        let data = null as any;

        if (orderBy === 'name') {
            data = {
                "type__name": "Нормативный документ",
                order: `${order_}docs`,
                "docs__contains": value,
                subtype_id: valueDocumentType === 0 ? undefined : valueDocumentType,
                'delete': false
            }
        } else {
            data = {
                "type__name": "Нормативный документ",
                order: `${order_}${orderBy}`,
                "docs__contains": value,
                subtype_id: valueDocumentType === 0 ? undefined : valueDocumentType,
                'delete': false
            }
        }
        const axios = new Axios();
        axios.post(`worker/report/${localStorage.getItem('id')}/filter/?page=${page + 1}&page_size=${rowsPerPage}`, data)
            .then(res => {
                setCountItems(res.data.count);
                setItems(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        updateTable();
    }, [page, rowsPerPage, open, countItems, valueDocumentType, orderBy, order]);

    return (
        <div  style={{margin:'0px auto 10px auto' , width:'98%'}}>
            <span className={'title_'}>Нормативные документы Республики Беларусь по воинскому учёту и воинской службе</span>
            <br />
            <br />
            <div className='btn-wrapper'>
                <Button variant="contained" onClick={() => props.history.push('/home')} >
                    Назад
                </Button>
            </div>
            <div className='formControl'>
                <div className=''>
                    <Button variant="outlined" onClick={() => { handleClickOpenSettings() }} >
                        Вид
                    </Button>

                </div>

                <div className='formControl-right-regulation'>
                    Выбрать нормативный акт из предложенной группы документов:
                    <div className='search'>
                        <Select
                            id="document-type"
                            value={valueDocumentType}
                            onChange={handleChangeDocumentType}
                        //className={classes.select}
                        >
                            {types ? types.map((item: any) => {
                                return <MenuItem value={item.id} key={item.id + 'documentsToolbar'}>{item.name}</MenuItem>
                            }) : null}
                        </Select>
                    </div>
                    {
                    localStorage.getItem('userID')=="1" ?
                        <div className=''>
                            <Button variant="contained" onClick={() => { setOpen(true) }} >
                                Добавить
                            </Button>
                        </div>
                    :
                        null
                    }

                    

                    <div className="search">
                        <div className="search-box">
                            <TextField id="standard-basic" label="Поиск" onChange={handleChange} />
                        </div>
                        <div className="search-box">
                            <Button onClick={selectBy}>Поиск</Button>
                        </div>
                    </div>
                </div>
            </div>
            {
                localStorage.getItem('userID')=="1" ? <span className='regulations-star'>* Для удаления документа наведите на нужную строку и нажмите на кнопку удаления.</span>
                : null
            }
            
            < RegulationsTable
                items={items}
                countItems={countItems}
                setItems={setItems}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                containsValue={value}
                setCountItems={setCountItems}
                columns={columns}
                orderBy={orderBy}
                order={order}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
            />

            <NewRegulationModal
                open={open}
                handleClickOpen={handleClickOpenModal}
                handleClose={handleCloseModal}
                valueDocumentType={valueDocumentType}
            />

            <SettingsModalForDocuments
                open={openModalSettings}
                handleClose={handleCloseSettings}
                columns={columns}
                setColumns={setColumns}
                essence={6}
                page={page}
                rowsPerPage={rowsPerPage}
                setItems={setItems}
                body={'regulations'}
            />
        </div >
    )
}

export default Regulations;
