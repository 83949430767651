import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ArrearItem from './ArrearItem';
import {IHistory, IDataRepo} from "../../utils/type";


const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


interface IProps{
    open:boolean,
    handleClose:()=>void,
    notifications:{report?:Array<IDataRepo>,changes?:Array<IDataRepo>,dismissal?:Array<IDataRepo>},
    history:IHistory
}


const ArrearsOfLettersModal = ({ open, handleClose, notifications, history }: IProps) => {

    return (
        <>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth='md'>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Внимание! Вам необходимо подготовить и отправить письма.
                </DialogTitle>
                <DialogContent dividers>
                    {notifications.report && notifications.report !==undefined  ? notifications.report.map((item: IDataRepo,index:number) => {
                        return <ArrearItem
                            days={item.date}
                            office={item.office}
                            typeLetter={'hirring'}
                            history={history}
                            key={index + item.name}
                            name={item.name}
                        />
                    }) : null}
                    {notifications.dismissal && notifications.dismissal !==undefined  ? notifications.dismissal.map((item: IDataRepo,index:number) => {
                        return <ArrearItem
                            days={item.date}
                            office={item.office}
                            typeLetter={'dismissal'}
                            history={history}
                            key={index + item.name}
                            name={item.name}
                        />
                    }) : null}
                    {notifications.changes && notifications.changes !==undefined  ? notifications.changes.map((item: IDataRepo,index:number) => {
                        return <ArrearItem
                            days={item.date}
                            office={item.office}
                            typeLetter={'change'}
                            history={history}
                            key={index + item.name}
                            name={item.name}
                        />
                    }) : null}

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Ок
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ArrearsOfLettersModal;
