import Axios from "../utils/axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const DeletedDocuments = ({isOpenDeleted,setOpenDeleted,deletedData,
                                     setUpdateTable}:any) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const axios = new Axios()

    const restoreCard = (idCard: any) => {
        axios.delete(`worker/report/archive/${localStorage.getItem('id')}/${idCard}/`,{})
            .then(res => {
                setUpdateTable(new Date().getMilliseconds())
            })
    };

  //  deletedData.sort((a:any, b:any) => a.delete_date > b.delete_date ? 1 : -1);

    return ( <div className={'doc-wrap-dialog'}>
        <Dialog onClose={()=>{setOpenDeleted(false)}} aria-labelledby="customized-dialog-title"
                open={isOpenDeleted} fullScreen={fullScreen}>
            <DialogContent dividers >
                <div className={'wrapper-deleted-tables'}>
                <h3>Удалённые файлы раздела</h3>
                    <table>
                        <thead>
                        <tr>
                            <th style={{minWidth:'240px'}}>Документ</th>
                            <th style={{minWidth:'150px'}}>Тип</th>
                            <th style={{minWidth:'100px'}}>Военкомат</th>
                            <th style={{minWidth:'100px'}}>Дата удаления</th>
                            <th>Восстановление</th>
                        </tr>
                        </thead>
                        <tbody>

                        {deletedData.length !== 0 && deletedData.map((item: { docs: string, office: string, type:{name:string,id:any}
                                                                          delete_date: string, id: any }
                            , index: number) => {
                            return <tr key={index}>
                                <td> <a download={item.type.name + item.type.id} href={item.docs} style={{color:'black'}}>
                                    {decodeURI(item.docs.slice(item.docs.indexOf('docs/')+5))}</a></td>
                                <td>{item.type.name}</td>
                                <td>{item.office}</td>
                                <td>{item.delete_date ? item.delete_date.split('-').reverse().join('-') : ''}</td>
                                <td>
                                    <button className={'btn-table'} onClick={() => {
                                        restoreCard(item.id)
                                    }}>Восстановить
                                    </button>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" onClick={()=>{setOpenDeleted(false)}}>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    )
}
