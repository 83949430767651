import React, {useState, useEffect} from 'react';
import AutocompleteOffice from '../../components/AutocompleteOffice/AutocompleteOffice';
import RegistrationLetterPDF from '../../components/Letters/RegistrationLetter';
import Axios from '../../utils/axios';
import './NewRegistrationLetter.css';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const getDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;

    return today.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
}


const RegistrationLetter = ({
                                letter, isSave, num, numbersLetter, numbersLettersState, isAutoGenerate, dateLetter,
                                dismissPersons, idOffice, isSavePdf, setDisabled,updateFile
                            }: any) => {

    const month = new Date().getMonth() + 1 <= 9 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1;
    const day = new Date().getDate() <= 9 ? `0${new Date().getDate()}` : new Date().getDate();
    const date_ = `${new Date().getFullYear()}-${month}-${day}`

    const phone = localStorage.getItem('hr_department_phone');
    const company = localStorage.getItem('company');
    const name = `${(localStorage.getItem('responsible person') as string).split(' ')[2][0]}.${(localStorage.getItem('responsible person') as string).split(' ')[1][0]}. ${(localStorage.getItem('responsible person') as string).split(' ')[0]} `

    const [office, setOffice] = useState(letter.name);
    const [date, setDate] = useState(date_);
    const [address, setAddress] = useState(letter.full_add);
    const [openModal, setOpenModal] = useState(false);
    const [fullAddress, setFullAddress] = useState({
        area: '',
        city: '',
        district: '',
        index: '',
        address: ''
    });
    const [items, setItems] = useState(letter.worker || null);

    const [file, setFile] = useState<any>('');
    const [organizationName, setOrganizationName] = useState('');
    const [organizationRespPerson, setOrganizationRespPerson] = useState('');
    const [organizationPhone, setOrganizationPhone] = useState('');
    const [organizationPositionResp, setOrganizationPositionResp] = useState('');
    const [shortOffice, setShortOffice] = useState(letter.short_name);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        let resArr = items.map((i: any, index: number) => {
            return {
                ...i, ind: index + 1
            }
        });
        const arr = resArr.filter((e: any) => dismissPersons.findIndex((i: any) => i.id == e.id) === -1);
        setItems(arr);

        const axios = new Axios();
        axios.get(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}/`)
            .then(res => {
                setOrganizationName(res.data.short_organization_name);
                setOrganizationRespPerson(res.data.full_name);
                setOrganizationPhone(res.data.phone);
                setOrganizationPositionResp(res.data.position);
            })
    }, [office])

    useEffect(() => {

        if (file !== '') {
            let body = new FormData();
            body.append("register_date", `${date}T12:00:00.016954+03:00`);
            body.append("docs", file,'Письмо о постановке организации на воинский учёт');
            body.append("office_id", idOffice.toString());
            body.append("type_id", 'Письмо о постановке организации на воинский учёт');
            body.append("user", String(localStorage.getItem('id')));
            const axios = new Axios();
            axios.post(`worker/report/${localStorage.getItem('id')}/`, body)
                .then(res => {
                    handleOpenModal();
                    setTimeout(handleCloseModal, 5000);
                    setDisabled(true)
                })
                .catch(err => {
                    console.log(err);
                })
        }

    }, [isSavePdf,updateFile]);

    useEffect(() => {
        if (isSave) {

            const data = {
                fullAddress,
                office,
                // date: dateLettersState[num],
                date,
                number: numbersLettersState[num],
                address: address,
                items,
                name,
                phone,
                company,
                setFile,
                organizationPositionResp,
                organizationPhone,
                organizationRespPerson,
                organizationName,
                shortOffice
            }
            RegistrationLetterPDF(data);
        }
    }, [isSave])

    const handleChangeOffice = (event: any) => {
        setOffice(event.name as string);
        setShortOffice(event.short_name as string);
        setAddress(event.address as string);

        setFullAddress({
            area: event.area,
            city: event.city,
            district: event.district,
            address: event.address,
            index: event.index
        })
    };

    // @ts-ignore

    return <>
        <div className='letter'>

            <div className='header'>
                <div></div>
                <div>
                    <span>Военному комиссару <br/></span>
                    <AutocompleteOffice
                        handleChangeOfficeName={handleChangeOffice}
                        setOfficeName={setOffice}
                        office={letter.name}
                        isAutoGenerate={isAutoGenerate}
                    />
                    <span className='underHeader'>
                    <br/>
                        {`${address}`}
                </span>
                </div>
            </div>

            <div className='description'>
                <div>
                    <div className='description-child'>
                        Исх. №
                        <div>
                            <input type="text"
                                   value={numbersLetter ? numbersLetter[num] : numbersLettersState ? numbersLettersState[num] : ''}
                                   onChange={(e: any) => {
                                       numbersLetter[num] = e.target.value
                                   }} className='number-input'/>
                        </div>

                    </div>
                    <div className='description-child'>
                        от
                        <div>
                            <input type="date" id="start"
                                 value={date}
                                  // value={date}
                                   min="2017-01-01" max="2022-12-31"
                                   onChange={(e: any) => {
                                           setDate(e.target.value)
                                   }}
                                   className='number-input'
                            />
                        </div>

                    </div>
                </div>
                <div className="description-text">
                    <div>
                        <b>СВЕДЕНИЯ</b> <br/>
                        <b> о военнообязанных запаса и призывниках, работающих в {organizationName}
                            в связи с постановкой организации на воинский учет.</b><br/>

                    </div>
                    <div className={'org-name-let'}>

                        <div style={{borderBottom: '1px solid gray'}}><b
                            style={{fontSize: '14px'}}>{organizationName}</b></div>
                        <span>(наименование государственного органа, организации, учреждения образования,
                                местного исполнительного и распорядительного органа)</span>
                    </div>
                </div>
            </div>

            <div className='table'>
                <table className="tg">
                    <thead>
                    <tr className='table-header'>
                        <th className="tg-1">№ <br/> п/п</th>
                        <th className="tg-1">Основание</th>
                        <th className="tg-1">Воинское звание, номер военно-учетной специальности и код воинской
                            должности
                        </th>
                        <th className="tg-1">Фамилия, собственное имя, отчество(если
                            таковое имеется), идентификационный номер
                        </th>
                        <th className="tg-1">Дата рождения</th>
                        <th className="tg-1">Место жительства</th>
                        <th className="tg-1">Место пребывания</th>
                        <th className="tg-1">Образование</th>
                        <th className="tg-1">Семья</th>
                        <th className="tg-1">Работа(учеба)</th>
                        <th className="tg-1">Номера телефонов</th>
                        <th className="tg-1">Особые отметки</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items ? items.map((item: any, number: number) => {
                        return (<tr key={number + 1} className={'letter-columns-td'}>
                            <td className="tg-2">{number + 1}</td>
                            <td className="tg-2">Постановка организации на учёт
                            </td>
                            <td className="tg-2"> {item.accountg_id === 4 ? 'Призывник' :
                                <>{item.grade}<br/><br/>
                                    {item.wyc ? item.wyc : ''}
                                </>
                            }</td>
                            <td className="tg-2">{item.full_name}<br/><br/>
                                {item.pass_number}</td>
                            <td className="tg-2">{item.date_of_birth ? item.date_of_birth.split('-').reverse().join('-') : ''}</td>
                            <td className="tg-2">{item.registered_address}</td>
                            <td className="tg-2">{item.address}</td>
                            <td className="tg-2"> {item.education ? item.education : ''}
                                <br/> {item.education_full ? item.education_full : ''}
                                <br/> {item.edu_specialization ? item.edu_specialization : ''}
                                {/*<br/> {item.edu_qualification ? item.edu_qualification : ''}*/}
                                <br/> {item.edu_dop_full ? item.edu_dop_full : ''}
                                <br/> {item.edu_dop_specialization ? item.edu_dop_specialization : ''}

                            </td>
                            <td className="tg-2">{item.marital_status ? <span>{item.marital_status}
                                <br/>
                                {item.family_half && item.family ? `Состав семьи: \n супруга (супруг): \n ${item.family_half} \n ${item.family}` :
                                    !item.family_half && item.family ? `Состав семьи: \n ${item.family}` :
                                        item.family_half && !item.family ? `Состав семьи: \n супруга (супруг): \n ${item.family_half}` : ''}
                                    </span>:<span></span>}</td>
                            <td style={{whiteSpace: 'pre-line', width: '200px'}}>
                                {item.position ? `${item.position} \n Дата приёма: ${item.add_military ? item.add_military.split('-').reverse().join('-') : ''}
                                    ${item.order_military ? 'Приказ № ' + item.order_military : ''} 
                                         ${item.date_add_military ? 'от ' + item.date_add_military.split('-').reverse().join('-') : ''} ` :
                                    item.education}  </td>
                            <td className="tg-2">{item.home_phone ? 'дом. ' + item.home_phone : ''}<br/><br/>
                                {item.work_phone ? `рабоч. ${item.work_phone}` : ''}<br/><br/>
                                {item.phone ? `моб. ${item.phone}` : ''}</td>
                            <td className="tg-2">{item.status ? `Годность к военной службе: ${item.status}` : ''}<br/><br/>
                                {item.requirement === true ? <><span>Моб. предписание:</span>
                                        <div>Да</div>
                                        <div>{item.requirement_annotation}</div>
                                    </>
                                    : <><span>Моб. предписание:</span>
                                        <div>Нет</div>
                                    </>
                                }
                            </td>
                        </tr>)
                    }) : null}
                    </tbody>
                </table>
            </div>

            <div className='footers_'>
                <div className={'name_styles'}>
                    {organizationRespPerson}
                    <span style={{fontSize: '10px', width: '243px'}}> (подпись, инициалы, фамилия должностного лица, ответственного за ведение военно-учетной работы)</span>
                </div>
                <div style={{fontSize: '14px', marginTop: '10px'}}>
                    Тел. {organizationPhone}
                </div>
            </div>

            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Письма отправлены Адресатам</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </div>
        <div style={{marginBottom: '20px', fontSize: '14px', color: 'red'}}><sup>*</sup>Прежде, чем отправить данные в
            электронный архив -пожалуйста, сохраните документ в PDF формате.
        </div>
    </>
}

export default RegistrationLetter;
