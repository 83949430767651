import React from 'react';
import './Arrears.css';
import {RussianName} from '../../utils/Name';
import {IHistory} from "../../utils/type";

const getDays = (str: string) => {
    if (str === 'null') {
        return 0;
    } else {
        return str.split(' ')[0];
    }

};


const getGenitiveFullname = (fullname: string, type: string) => {
    if (fullname.length > 0) {
        //@ts-ignore
        const name = new RussianName(fullname);
        if (type === 'dative') {
            return name.fullName(name.gcaseDat);
        } else
            return name.fullName(name.gcaseRod);
    } else return ''

}

const getDeclinationDays = (days: string) => {
    const char = days[days.length - 1];


    switch (char) {
        case '1':
            return 'день';
            break;
        case '0':
            return 'дней';
            break;
        case '2':
            return 'дня';
            break;
        case '3':
            return 'дня';
            break;
        case '4':
            return 'дня';
            break;
        default:
            return 'дней';
            break;
    }
}

interface IProps {
    days: string,
    office: string,
    typeLetter: string,
    name: string,
    history: IHistory

}

const ArrearItem = ({days, office, typeLetter, name, history}: IProps) => {

    const goLetterPage = () => {
        switch (typeLetter) {
            case 'change':
                history.push('/newChangeLetter');
                break;
            case 'hirring':
                history.push('/newHiringLetter');
                break;
            case 'dismissal':
                history.push('/newDismissalLetter');
                break;
            case 'registration':
                history.push('/newRegistrationLetter');
                break;
            default:
                break;
        }
    }

    const getTypeLetter = () => {
        switch (typeLetter) {
            case 'change':
                return `об Изменении учётных данных`
                break;
            case 'hirring':
                return `о Приёме на работу`
                break;
            case 'dismissal':
                return `об Увольнении `
                break;
            case 'registration':
                return `о Постановке организации на воинский учёт`
                break;
            default:
                break;
        }
    }
    const days_ = Number(getDays(days));

    return (
        <>
            {days_ < 0 ? <div className={'arrearItem arrearRed'} onClick={goLetterPage}>
                    Тревожная ситуация!
                    Истёк КРАЙНИЙ СРОК отправки письма {getTypeLetter()} {getGenitiveFullname(name, `genitive`)} в адрес
                    "{office}". Просьба СРОЧНО устранить проблему!
                    {/*Cрок отправки письма  {getGenitiveFullname(name, `genitive`)} в {office} просрочен на <b>{days_ * -1}</b> {getDeclinationDays(String(days_))}*/}
                </div> :
                days_ >= 0 && days_ <= 2 ? <div className={'arrearItem arrearOrange'} onClick={goLetterPage}>
                        Внимание! Завтра истекает срок отправки
                        письма {getTypeLetter()} {getGenitiveFullname(name, `genitive`)} в адрес "{office}".
                    </div> :
                    days_ > 2 && days_ <= 5 ? <div className={'arrearItem arrearBlue'} onClick={goLetterPage}>
                            Необходимо СРОЧНО подготовить и направить письмо в адрес
                            "{office}" {getTypeLetter()} {getGenitiveFullname(name, `genitive`)}.
                            Осталось <b>{days_}</b> {getDeclinationDays(String(days_))} до отправки письма!
                        </div> :

                        <div className={'arrearItem arrearYellow'} onClick={goLetterPage}>
                            <b>{days_}</b> {getDeclinationDays(String(days_))} до отправки письма в
                            "{office}" {getTypeLetter()} {getGenitiveFullname(name, `genitive`)}
                            {/*<b>{days_}</b> {getDeclinationDays(String(days_))} до истечения срока отправки письма {getTypeLetter()} {getGenitiveFullname(name, `genitive`)} в {office}*/}
                        </div>}

        </>
    )
}

export default ArrearItem;
