
export const getStyleChat=(element)=>{
  element.position = {
    bottom: 40,
    right: 60,
  };
  element.color = 'warning'
}


