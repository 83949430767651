// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
import {Document, Page, StyleSheet, Image, View, Font, Text, PDFViewer} from "@react-pdf/renderer";
import React from "react";
import font from "../../fonts/TimesNewRoman/bold_Times New Roman.ttf";


Font.register({
    family: "Times New Roman",
    src: font,
    format: "truetype",
});


const EnvelopePDF = (props: any) => {

    const styles = StyleSheet.create({
        page: {
            fontFamily: "Times New Roman",
            marginRight: 'auto',
            marginLeft: 'auto',
            fontSize: 13,
        },
        wrapper: {
            marginTop:'10%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        text:{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width:290,
            fontSize:18
        },
        image: {
            width: 15,
            height:15,
            padding: 10
        },
        stamp: {
            borderRightWidth: 1,
            borderRightStyle: 'dashed',
            borderRightColor: 'black',
            borderBottomWidth: 1,
            borderBottomStyle: 'dashed',
            borderBottomColor: 'black',
            height: 49,
            width: 27,
            marginTop: '10%',
            marginRight:30
        },
        wrapStamps: {
            width: '90%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginRight:'auto',
            marginLeft:'auto',
            marginBottom:40

        },
        toWhom:{
            display: 'flex',
            flexDirection: 'column',
            textAlign:'left',
            width:310,
            // marginTop: props.logo? '5%' : '15%',
            marginLeft:460,
            fontSize:18,
            borderStyle:'solid',borderWidth:1,borderColor:'white',
            position:'absolute',
            bottom:50
        },
        insteadOfLogo:{
            height: 76,
            width: 190,
            marginBottom:'10px'
        },
        wrapPage:{
            borderStyle:'solid',borderWidth:1,borderColor:'white',
        }

    })

    function returnSrc() {
        // @ts-ignore
        let path=props.logo;
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        let url=proxyurl+path;
        const selectedMethod = 'GET' as const;
        return {uri: url, method: selectedMethod, body: '', headers: {'Access-Control-Allow-Origin': '*', withCredentials:true}};
    }

    return (
        <Document>
            <Page style={styles.page} orientation={'landscape'}>
                <View style={styles.wrapPage}>
                <View style={styles.wrapStamps}>
                    <View style={styles.wrapper}>
                        {props.logo !=='' && props.logo &&
                        <Image src={props.logo} style={styles.insteadOfLogo}/>
                     }
                        <View style={styles.text}>
                            <Text> {props.company}</Text>
                                <View>
                                    <Text>  {props.address_.address}</Text>
                                    <Text>  {props.address_.index}, {props.address_.settlement}</Text>
                                    <Text>  {props.address_.area}</Text>

                                </View>
                        </View>
                    </View>
                    <View style={styles.stamp}/>
                </View>
                </View>
                <View style={styles.toWhom}>
                    <Text>Военному комиссару</Text>
                    <Text>{props.address.name ? props.address.name.replace(/ый/, 'ого').replace(/комиссариат/, 'комиссариата') : ''}</Text>
                    <Text>{props.address.letter_street}</Text>
                    <Text>{props.address.index}, {props.address.letter_locality}</Text>
                    <Text>{props.address.letter_district ? props.address.letter_district  : ''}</Text>
                </View>
            </Page>
        </Document>
    )

};
export default EnvelopePDF;

