import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import style from '../Documents/Documents.module.css'
import createPDF from './CreatePDF'

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            maxWidth: '1000px'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        paper: {
            maxWidth: '1000px'
        },
        paperWidthMd: {
            maxWidth: '1000px'
        },
        paperWidthSm: {
            maxWidth: '1000px'
        }

    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),

    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        maxWidth: '1000px'
    },
}))(MuiDialogActions);


interface Item {
    id: string;
    office: string;
    officer: string;
    sergeant: string;
    recruit: string;
}

export default function ReportModal(props: any) {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    let date = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + today.getFullYear();


    return (
        <div>
            <Dialog
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth='xl'
                scroll='paper'
            >
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    Отчёт
                </DialogTitle>
                <DialogContent dividers>
                
                    <div className="tableForPDF">
                        <div >
                            <h4 className={style.h4}>По состоянию на {date}</h4>
                            <table className="tg">
                                <thead>
                                    <tr>
                                        <th className="tg-0pky" rowSpan={3}>Ведомственная принадлежность</th>
                                        <th className="tg-0pky" rowSpan={3}>Всего работающих</th>
                                        <th className="tg-lboi" colSpan={3}>из них</th>
                                    </tr>
                                    <tr>
                                        <td className="tg-0pky" colSpan={2}>военнообязанных</td>
                                        <td className="tg-zd5i" rowSpan={2}>Призывников</td>
                                    </tr>
                                    <tr>
                                        <td className="tg-0pky">всего</td>
                                        <td className="tg-0pky">в т.ч. офицеров</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="tg-0pky">{props.stateTable1.affiliations}</td>
                                        <td className="tg-0pky">{props.stateTable1.all_worker}</td>
                                        <td className="tg-0pky">{props.stateTable1.all_war}</td>
                                        <td className="tg-0pky">{props.stateTable1.officer}</td>
                                        <td className="tg-0pky">{props.stateTable1.recruit}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={style.box2}>
                            <p className={style.p}>Распределение по военным комиссариатам</p>
                            <table className="tg">
                                <thead>
                                    <tr>
                                        <th className="tg-0lax" rowSpan={2}>№ <br />п/п</th>
                                        <th className="tg-0lax" rowSpan={2}>Военный комиссариат</th>
                                        <th className="tg-0lax" colSpan={3}>Количество</th>
                                    </tr>
                                    <tr>
                                        <td className="tg-0lax">Офицеры</td>
                                        <td className="tg-0lax">Прапорщики, сержанты, солдаты</td>
                                        <td className="tg-0lax">Призывники</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.stateTable2.map((item: any) => {
                                            //console.log(item)
                                            return <tr key={item.id + 9}>
                                                <td className="tg-0lax">{item.id}.</td>
                                                <td className="tg-0lax">{item.office}</td>
                                                <td className="tg-0lax">{item.officer}</td>
                                                <td className="tg-0lax">{item.sergeant}</td>
                                                <td className="tg-0lax">{item.recruit}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={createPDF} variant="outlined" color="primary">
                        Сохранить для печати
                    </Button>
                    <Button autoFocus onClick={props.handleClose} variant="contained" color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}