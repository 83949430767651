import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import './FunctionalResponsibilities.css';
import FunctionalResponsibilitiesPDF from '../../components/PDFOther/FunctionalResponsibilities';
import FunctionalResponsibilitiesDOCX from '../../components/DOCXOther/FunctionalResponsibilitiesDOCX';
import Axios from '../../utils/axios';

const getDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;

    return today.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
}

const FunctionalResponsibilities = (props: any) => {
    const [name1, setName1] = useState('');
    const [position1, setPosition1] = useState('');
    const [date, setDate] = useState(getDate());

    const [name2, setName2] = useState('');
    const [name2_, setName2_] = useState('');
    const [position2, setPosition2] = useState('');
    const [position2_, setPosition2_] = useState('');

    const [workerName, setWorkerName] = useState('');

    const company = localStorage.getItem('company');

    const [dateInText, setDateInText] = useState('01 декабря');
    const [limitation, setLimitation] = useState('месячный срок');
    const [workerPos, setWorkerPos] = useState('');

    const axios=new Axios();

    useEffect(()=>{
        axios.get(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}/`)
            .then(res=>{
                setName1(res.data.full_name_head);
                setName2_(res.data.full_name);
                setPosition1(res.data.position_of_head)
                setPosition2_(res.data.position)
            })

    })

    const save = (type: string) => {

        const axios = new Axios();
        axios.post(`worker/report/${localStorage.getItem('id')}/`, {
            type_id : "Функциональные обязанности ответственного за ВУ",
            user: localStorage.getItem('id'),
            docs: null
        })
            .then(res => {
            })

        const data = {
            name1,
            position1,
            name2,
            position2,
            name2_,
            position2_,
            date,
            company,
            dateInText,
            limitation,
            workerName,
            workerPos
        }

        if (type === 'pdf') {
            FunctionalResponsibilitiesPDF(data);
        } else {
            FunctionalResponsibilitiesDOCX(data);
        }

    };

    return (
        <div style={{margin:'216px auto 10px auto', width:'98%'}}>
            <span className='title_letters'>Функциональные обязанности ответственного за ведение воинского учёта</span>
            <div className='toolbar'>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={() => props.history.push('/start_military')} >
                        Назад
                    </Button>
                </div>

            </div>

            <span className='plan-functional'>Заполните пустые поля: </span>

            <div className='wrapperResp'>
                <div className='header'>
                    <div></div>
                    <div className='wrapp-header'>
                        Утверждаю
                        <br /> <br />
                        <input type="text" value={name1}  className='name-input' placeholder='ФИО' style={{width:'200px'}} />
                        <input type="text" value={position1} className='name-input' placeholder='Должность' style={{width:'200px'}}  />
                        {company}
                        <input type="date" id="start"
                            value={date}
                            min="2017-01-01" max="2022-12-31"
                            onChange={(e: any) => setDate(e.target.value)}
                            className='number-input'
                        />
                    </div>
                </div>
                <div className='body-functionalResp'>
                    <span><b>
                        Функциональные обязанности <br />
                        ответственного за ведение воинского учёта <br />
                        военнообязанных и призывников.</b>
                    </span>
                    <div className='body-title-functionalResp'>
                        Ответственный за ведение воинского учёта обязан:
                    </div>
                    <div className='body-text'>
                        <p>проверять при приеме на работу (учебу) у призывников удостоверения призывников, а у военнообязанных - военные билеты;</p>
                        <p>установить, состоят ли граждане, принимаемые на работу (учебу), на воинском учете по месту регистрации;</p>
                        <p>направлять граждан, подлежащих приему на воинский учет, в соответствующий орган, ведущий воинский учет по месту  регистрации;</p>
                        <p>обеспечить полноту и качество воинского учета призывников и военнообязанных из числа работающих (обучающихся) в организации;</p>
                        <p>осуществлять учет изменений, касающихся должности (специальности), образования, состава семьи, семейного положения и места прописки граждан, и сообщать о них в военный комиссариат;</p>
                        <p>ежегодно сверять личные карточки призывников и военнообязанных с записями в удостоверениях призывников и военных билетах, представлять эти карточки для сверки с учетными данными военного комиссариата;</p>
                        <p>направлять в месячный срок в местные исполнительные и распорядительные органы, военные комиссариаты и организации сведения о гражданах, становящихся на воинский учет, состоящих на воинском учете, а также не состоящих, но обязанных состоять на воинском учете;</p>
                        <p>ежегодно до <input type="text" value={dateInText} onChange={(e: any) => setDateInText(e.target.value)} className='name-input-footer' /> представлять в военные комиссариаты списки граждан мужского пола, подлежащих приписке к призывным участкам в следующем году;</p>
                        <p>по требованию военного комиссариата оповещать граждан о вызовах в военный комиссариат;</p>
                        <p>обеспечивать гражданам возможность своевременной явки по вызовам (повесткам) в военный комиссариат;</p>
                        <p>сообщать в <input type="text" value={limitation} onChange={(e: any) => setLimitation(e.target.value)} className='name-input-footer' /> в соответствующий военный комиссариат о всех призывниках и военнообязанных, принятых на работу (учебу) или уволенных с работы (отчисленных из учреждений образования);</p>
                        <p>направлять ежегодно в военный комиссариат сведения о численности работников организации (призывников и военнообязанных).</p>
                    </div>
                    <div className='footerResp'>
                        <div className='footer-child'>
                            <div>
                                <input type="text" value={workerPos} onChange={(e: any) => setWorkerPos(e.target.value)} className='name-input-footer' placeholder='Должность' />
                            </div>
                            <div>

                            </div>
                            <div>
                                <input type="text" value={workerName} onChange={(e: any) => setWorkerName(e.target.value)} className='name-input' placeholder='ФИО' />
                            </div>
                        </div>
                        <div className='footer-child'>
                            <div>
                                Ознакомлен: <br />
                                <input type="text"  defaultValue={position2_}
                                       onChange={(e:any)=>{setPosition2(e.currentTarget.value)}}
                                       className='name-input-footer' placeholder='Должность' />
                            </div>
                            <div>

                            </div>
                            <div>
                                <input type="text"  defaultValue={name2_}
                                       onChange={(e:any)=>{setName2(e.currentTarget.value)}}  className='name-input' placeholder='ФИО' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='btn-footer'>
                <div className='btn-pdf'>
                    <Button variant="contained" onClick={e => save('pdf')} color="primary">
                        Сохранить PDF
                    </Button>
                </div>

                <Button variant="contained" onClick={e => save('docx')} color="primary">
                    Сохранить DOCX
                </Button>

            </div>
        </div>
    )

}
export default FunctionalResponsibilities;
