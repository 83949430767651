import React from 'react';
import Button from '@material-ui/core/Button';
import DismissalLetter from '../../components/Letters/DismissalLetter';
import HiringlLetter from '../../components/Letters/HiringLetter';
import './Letters.css'

const Letters = (props: any) => {
    return (
        <div style={{margin:'330px auto 10px auto'}}>
            <span className='title_letters'>Переписка с Военными комиссариатами</span>
            <div className='btn-wrapper'>
                <Button variant="contained" onClick={() => props.history.push('/home')} >
                    Назад
                </Button>
            </div>

            <div className='columns'>
                <div className='btn-wrapp'>

                    <div className='btn-letter'>
                        <Button variant="contained" className={'btn'} onClick={() => props.history.push('/newHiringLetter')}>Письмо о приёме на работу</Button>
                    </div>
                    <div className='btn-letter'>
                        <Button variant="contained" className={'btn'} onClick={() => props.history.push('/newDismissalLetter')}>Письмо об уволеннии с работы</Button>
                    </div>
                    <div className='btn-letter'>
                        <Button variant="contained" className={'btn'} onClick={() => props.history.push('/newChangeLetter')}>Письмо об изменении учётных данных</Button>
                    </div>
                    <div className='btn-letter'>
                        <Button variant="contained" className={'btn'} onClick={() => props.history.push('/forms')}>Стандартные формы</Button>
                    </div>
                </div>

                <div className='btn-wrapp'>

                    <div className='btn-letter'>
                        <Button variant="contained" className={'btn'} onClick={() => props.history.push('/listForCorrect')}>Список для сверки</Button>
                    </div>
                    <div className='btn-letter'>
                        <Button variant="contained" className={'btn'} onClick={() => props.history.push('/workPlan')}>"План работ"</Button>
                    </div>
                    {/*
                    <div className='btn-letter'>
                        <Button variant="contained" className={'btn'} onClick={() => props.history.push('/characteristic')}>"Характеристика"</Button>
                    </div>
                    <div className='btn-letter'>
                        <Button variant="contained" className={'btn'} onClick={() => props.history.push('/petition')}>"Ходатайство"</Button>
                    </div>
                    */}
                    <div className='btn-letter'>
                        <Button variant="contained" className={'btn'} onClick={() => props.history.push('/envelopes')}>"Конверты"</Button>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Letters;
