import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Axios from '../../utils/axios';


export default function ConfirmDialog(props: any) {


  const handleClose = () => {
    props.setOpen(false);
  };

  const confirm = () => {
    const data = {
      'flag': true,
      'flag_date': new Date()
    }
    const axios = new Axios();
    axios.patch(`worker/report/${localStorage.getItem('id')}/${props.id}/`, data)
      .then(res => {
        props.setUpdateTable(new Date().getMilliseconds());
      })
      .catch(err => {
        console.log(err)
      })

    handleClose();
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Подтверждение'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Пометить как "выполнено"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={confirm} color="primary" autoFocus>
            Да
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}