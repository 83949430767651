import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import MilitaryPeopleModal from '../MilitaryPeopleModal/MilitaryPeopleModal';
import LettersModal from '../LettersModal/LettersModal';

interface Column {
    id: 'address' | 'name' | 'id' | 'letter' | 'people';
    label: string;
    minWidth?: number;
    align?: 'center';
    isSelected?: boolean,
    width?: number;
}

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        width: '100%',
       // maxHeight: '90vh',
       height: 'calc(100vh - 270px)'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    linkTypeReport: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    btnUpdate: {
        marginLeft: '10px'
    },
    table: {
        minWidth: 750,
    },
    paper: {
        width: '100%',
    },
    narrowCell: {
		'width': '150px',
    },
    tableCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '40px',
    },
});


export default function OfficesTable({ order, items, countItems, setPage, setRowsPerPage, setOrder, setOrderId, page, rowsPerPage, columns }: any) {

    const classes = useStyles();

    const [orderBy, setOrderBy] = React.useState('full_name');

    const [openPeopleModal, setOpenPeopleModal] = React.useState(false);
    const [openLettersModal, setOpenLettersModal] = React.useState(false);

    const [nameOffice, setNameOffice] = useState('');

    

    const handleClickOpenPeopleModal = () => {
        setOpenPeopleModal(true);
    };

    const handleClosePeopleModal = () => {
        setOpenPeopleModal(false);
    };

    const handleClickOpenLettersModal = () => {
        setOpenLettersModal(true);
    };

    const handleCloseLettersModal = () => {
        setOpenLettersModal(false);
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = orderBy === property && order === 'desc';
        setOrder(isAsc ? 'asc' : 'desc');
        setOrderBy(property);
    };


    const _orderBy = (id: any) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, id);
        setOrderId(id)
    };

    const openMilitaryPeopleModal = (id: number, name: string) => {
        handleClickOpenPeopleModal();
        setNameOffice(name);
    }

    const openMilitaryLettersModal = (id: number, name: string) => {
        handleClickOpenLettersModal();
        setNameOffice(name);
    }

    return (
        <div style={{width:'100%'}}>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns ? columns.map((column: Column) => {
                                    if (column.isSelected) {
                                        if (column.id !== 'people' && column.id !== 'letter') {
                                            return (
                                                <TableCell
                                                    key={getRandomInt(36984)}
                                                    align={column.align}
                                                    style={{minWidth:10, maxWidth:50}}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === column.id}
                                                        direction={orderBy === column.id ? order : 'asc'}
                                                        onClick={_orderBy(column.id)}
                                                    >
                                                        {column.label}
                                                        {
                                                            orderBy === column.id ? (
                                                                <span className={classes.visuallyHidden}>
                                                                    {order === 'desc' ? 'По убыванию' : 'По возрастанию'}
                                                                </span>
                                                            ) : null
                                                        }
                                                    </TableSortLabel>
                                                </TableCell>
                                            )
                                        }
                                        return (
                                            <TableCell
                                                key={column.id}
                                            >
                                                {column.label}
                                            </TableCell>
                                        )
                                    }
                                }) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row: any, number: number) => {
                                const koff2 = (page + 1) * rowsPerPage - rowsPerPage + 1;
                                const koff = order === 'desc' ? koff2 : countItems - koff2;
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns ? columns.map((column: any) => {
                                            const columnID = column.id;
                                            const value = row[column.id];
                                            if (column.isSelected) {
                                                if (columnID === 'people') {
                                                    return (
                                                        <TableCell key={getRandomInt(74136)} align={'center'}>
                                                            <span className='field-letters' onClick={(e: any) => { openMilitaryPeopleModal(row.id, row.name) }}>
                                                                {row.worker.user}
                                                            </span>
                                                        </TableCell>
                                                    );
                                                }
                                                if (columnID === 'letter') {
                                                    return (
                                                        <TableCell key={getRandomInt(74126)} align={'center'} onClick={(e: any) => { openMilitaryLettersModal(row.id, row.name) }}>
                                                            <span className='field-letters'>
                                                                {row.worker.letter}
                                                            </span>
                                                        </TableCell>
                                                    );
                                                }
                                                if (columnID === 'city') {
                                                    return (
                                                        <TableCell key={getRandomInt(74126)} align={'left'}>
                                                            <span className='field-letters'>
                                                                {row.letter_locality}
                                                            </span>
                                                        </TableCell>
                                                    );
                                                }
                                                if (columnID === 'area') {
                                                    return (
                                                        <TableCell key={getRandomInt(74126)} align={'left'}>
                                                            <span className='field-letters'>
                                                                {row.area}
                                                            </span>
                                                        </TableCell>
                                                    );
                                                }
                                                if (columnID === 'district') {
                                                    return (
                                                        <TableCell key={getRandomInt(74126)} align={'left'}>
                                                            <span className='field-letters'>
                                                                {row.district}
                                                            </span>
                                                        </TableCell>
                                                    );
                                                }
                                                
                                                if (columnID === 'id') {
                                                    return <TableCell key={column.id + getRandomInt(1111)} align={column.align}>
                                                        {order === 'asc' ? koff - number + 1 : koff + number}
                                                    </TableCell>
                                                }

                                                return (
                                                    <TableCell key={getRandomInt(1478965)} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            }
                                        }) : null}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    labelRowsPerPage='Показывать по:'
                    backIconButtonText='Предыдущие'
                    nextIconButtonText='Следующие'
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                    count={countItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>

            <MilitaryPeopleModal
                open={openPeopleModal}
                handleClose={handleClosePeopleModal}
                nameOffice={nameOffice} />

            <LettersModal
                open={openLettersModal}
                handleClose={handleCloseLettersModal}
                nameOffice={nameOffice} />
        </div>
    );
}


// <Checkbox id={String(row.id)} disabled icon={<DoneIcon />} />
