import { reducer as formReducer } from 'redux-form';
const initialState = {
    formPersonalCard: formReducer
};

const LOAD = 'LOAD';
// export default (state=initialState)=>{
//    return state
// }

export default function (state = {}, action: any) {
    switch (action.type) {
        case LOAD:
            return {
                data: action.data,
            };
        default:
            return state;
    }
}

export const load = (data : any) => ({ type: LOAD, data });