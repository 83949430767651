import React, { useState, useEffect } from 'react';
import Input from '@material-ui/core/Input';
import classes from './PersonalCard.module.css';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Axios from '../../../utils/axios';
import AutocompleteOffice from '../../AutocompleteOffice/AutocompleteOffice';


export default function MilitaryAccounting({ state, updateField, setState, updateMilitary, isEmpty, selectState, setSelectState, setOffice, handleChangeOffice, office, otherStatus, setOtherStatus, otherAccountС, setOtherAccountС, otherStructure, setOtherStructure }: any) {
    const [valueSpecialAccounting, setValueSpecialAccounting] = React.useState(state.special);

    const [valueStructure, setValueStructure] = React.useState(state.structure_id);
    const [valueAccountG, setValueAccountG] = React.useState(state.accountg_id);
    const [valueAccountС, setValueAccountС] = React.useState(state.accountc_id);
    const [valueGrade, setValueGrade] = React.useState(state.grade_id);
    const [valueStatus, setValueStatus] = React.useState(state.status_id);
    const [valueOffice, setValueOffice] = React.useState(state.office_id);

    const [listStructure, setListStructure] = useState([]);
    const [listAccountG, setListAccountG] = useState([]);
    const [listAccountC, setListAccountC] = useState([]);
    const [listGrade, setListGrade] = useState([]);
    const [listStatus, setListStatus] = useState([]);

    //const [valueStatus, setValueStatus] = React.useState(state.generalInfState1.nationality_id); 


    useEffect(() => {
       // debugger;
        setValueStructure(state.structure_id);
        setValueAccountG(state.accountg_id);
        setValueAccountС(state.accountc_id);
        setValueGrade(state.grade_id);
        setValueStatus(state.status_id);
        setValueOffice(state.office_id);
        setValueSpecialAccounting(state.special);
    }, [state])

    const handleChangeStructure = (event: any) => {
        setValueStructure(event.target.value as string);
        setState({
            ...state,
            'structure_id': event.target.value
        });

        setSelectState({
            ...selectState,
            'structure': event.currentTarget.textContent
        });
    };

    const handleChangeAccountG = (event: any) => {
        setValueAccountG(event.target.value as string);
        setState({
            ...state,
            'accountg_id': event.target.value
        });

        setSelectState({
            ...selectState,
            'accountg': event.currentTarget.textContent
        });
    };

    const handleChangeAccountC = (event: any) => {
        // debugger
        setValueAccountС(event.target.value as string);
        setState({
            ...state,
            'accountc_id': event.target.value //event.currentTarget.textContent 
        });

        setSelectState({
            ...selectState,
            'accountc': event.currentTarget.textContent
        });
    };

    const handleChangeGrade = (event: any) => {
        setValueGrade(event.target.value as string);
        setState({
            ...state,
            'grade_id': event.target.value
        });

        setSelectState({
            ...selectState,
            'grade': event.currentTarget.textContent
        });
    };

    const handleChangeStatus = (event: any) => {
        setValueStatus(event.target.value as string);
        setState({
            ...state,
            'status_id': event.target.value
        });

        setSelectState({
            ...selectState,
            'status': event.currentTarget.textContent
        });
    };

    /*const handleChangeOffice = (event: any) => {
        setValueOffice(event.target.value as string);
        setState({
            ...state,
            'office_id': event.target.value
        });

        setSelectState({
            ...selectState,
            'office': event.currentTarget.textContent
        });
    };*/


    const handleChangeSpecialAccounting = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValueSpecialAccounting(event.target.value as string);
        if (event.target.value === 'false') {
            setState({
                ...state,
                'special': false,
                'specialN': '',
            });
        } else {
            setState({
                ...state,
                'special': true
            });
        }

    };

    useEffect(() => {
        const fetchData = () => {
            const axios = new Axios();

            axios.get(`library/structure/`)
                .then(res => {
                    setListStructure(res.data.results);
                })
                .catch(err => {
                    console.log(err);
                })
            axios.get(`library/accountc/`)
                .then(res => {
                    setListAccountC(res.data.results);
                })
                .catch(err => {
                    console.log(err);
                })
            axios.get(`library/accountg/`)
                .then(res => {
                    setListAccountG(res.data.results);
                })
                .catch(err => {
                    console.log(err);
                })
            axios.get(`library/rank/`)
                .then(res => {
                    setListGrade(res.data.results);
                })
                .catch(err => {
                    console.log(err);
                })

            axios.get(`library/structure/`)
                .then(res => {
                    setListStructure(res.data.results);
                })
                .catch(err => {
                    console.log(err);
                })

            axios.get(`library/validity/`)
                .then(res => {
                    setListStatus(res.data.results);
                })
                .catch(err => {
                    console.log(err);
                })
            /*axios.get(`worker/office/`)
                .then(res => {
                    setListOffice(res.data.results);
                })
                .catch(err => {
                    console.log(err);
                })*/
        };
        fetchData();
    }, []);

    return (
        <div>
            <h3 className={classes.headers}>II. Сведения о воинском учёте</h3>

            <div className={classes.grid}>
                <div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Военный билет: </p>
                        <div className={classes.boxChildInput}>
                            <Input
                                id="series"
                                className={classes.passportChild}
                                classes={{ root: classes.inputSmall }}
                                onChange={updateMilitary} value={state.military.split(' ')[0] || ''}
                            // error={isEmpty ? (state.military.split(' ')[0] === '' ? true : false) : false}
                            />
                            <p className={classes.passportChild}>№ </p>
                            <Input
                                id="number"
                                className={classes.boxChildInput}
                                onChange={updateMilitary}
                                value={state.military.split(' ')[1] || ''}
                            // error={isEmpty ? (state.military.split(' ')[0] === '' ? true : false) : false}
                            />
                        </div>

                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Группа учёта: </p>
                        <div className={classes.militaryInput} >
                            <Select
                                id="accountG"
                                value={valueAccountG}
                                onChange={handleChangeAccountG}
                                className={classes.select}
                            //error={isEmpty ? (state.accountg_id === '' ? true : false) : false}
                            >
                                {listAccountG.map((item: any) => {
                                    return <MenuItem value={item.id} key={item.id + 'accountG'}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </div>
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Категория учёта: </p>
                        <div className={classes.militaryInput} >
                            <Select
                                id="structure"
                                value={valueStructure}
                                onChange={handleChangeStructure}
                                className={classes.select}
                                //displayEmpty={valueAccountG !== 'Призывник' ? true : false}
                                disabled={false}
                            //error={isEmpty ? (state.structure_id === '' ? true : false) : false}
                            >
                                {listStructure.map((item: any) => {
                                    return <MenuItem value={item.name} key={item.id + 'structure'}>{item.name}</MenuItem>
                                })}
                                <MenuItem id={'structure' + 7} value={'пусто'} key={180 + 'structure'}>пусто</MenuItem>
                            </Select>
                        </div>
                    </div>
                    {
                        valueStructure === 'пусто' ?
                            <div className={classes.box}>
                                <p className={classes.boxChild}></p>
                                <Input
                                    id="otherStructure"
                                    //error={isEmpty ? (getName(state.generalInfState1.full_name, 2) === '' ? true : false) : false}
                                    className={classes.boxChildInput}
                                    onChange={(e: any) => { setOtherStructure(e.target.value) }}
                                    value={otherStructure}
                                />
                            </div> : null
                    }
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Состав: </p>
                        <div className={classes.militaryInput} >
                            <Select
                                id="accountС"
                                value={valueAccountС}
                                onChange={handleChangeAccountC}
                                className={classes.select}
                            //error={isEmpty ? (state.accountc_id === '' ? true : false) : false}
                            >
                                {listAccountC.map((item: any) => {
                                    return <MenuItem value={item.name} key={item.id + 'accountС'}>{item.name}</MenuItem>
                                })}
                                <MenuItem id={'account_C_id' + 7} value={'пусто'} key={100 + 'accountC'}>пусто</MenuItem>
                            </Select>
                        </div>
                    </div>
                    {
                        valueAccountС === 'пусто' ?
                            <div className={classes.box}>
                                <p className={classes.boxChild}></p>
                                <Input
                                    id="otherAccountС"
                                    //error={isEmpty ? (getName(state.generalInfState1.full_name, 2) === '' ? true : false) : false}
                                    className={classes.boxChildInput}
                                    onChange={(e: any) => { setOtherAccountС(e.target.value) }}
                                    value={otherAccountС}
                                />
                            </div> : null
                    }
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Воинское звание: </p>
                        <div className={classes.militaryInput} >
                            <Select
                                id="grade"
                                value={valueGrade}
                                onChange={handleChangeGrade}
                                className={classes.select}
                            //error={isEmpty ? (state.grade_id === '' ? true : false) : false}
                            >
                                {listGrade.map((item: any) => {
                                    return <MenuItem value={item.id} key={item.id + 'grade'}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </div>
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}></p>
                        <Input
                            id="grade_full"
                            className={classes.militaryInput}
                            onChange={(e: any) => { updateField(e, setState, state) }}
                            value={state.grade_full}
                        />
                    </div>
                </div>
                <div >
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Военно-учётная специальность №: </p>
                        <Input
                            id="wyc"
                            className={classes.militaryInput}
                            value={state.wyc}
                            onChange={(e: any) => { updateField(e, setState, state) }}
                        // error={isEmpty ? (state.wyc === '' ? true : false) : false}
                        />
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Годность к военной службе: </p>
                        <div className={classes.militaryInput} >
                            <Select
                                id="status"
                                value={valueStatus}
                                onChange={handleChangeStatus}
                                className={classes.select}
                            //error={isEmpty ? (state.status_id === '' ? true : false) : false}
                            >
                                {listStatus.map((item: any) => {
                                    return <MenuItem value={item.name} key={item.id + 'status'}>{item.name}</MenuItem>
                                })}
                                <MenuItem id={'status_id' + 7} value={'пусто'} key={100 + 'status'}>пусто</MenuItem>
                            </Select>
                        </div>
                    </div>
                    {
                        valueStatus === 'пусто' ?
                            <div className={classes.box}>
                                <p className={classes.boxChild}></p>
                                <Input
                                    id="otherStatus"
                                    //error={isEmpty ? (getName(state.generalInfState1.full_name, 2) === '' ? true : false) : false}
                                    className={classes.boxChildInput}
                                    onChange={(e: any) => { setOtherStatus(e.target.value) }}
                                    value={otherStatus}
                                />
                            </div> : null
                    }
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Наименование военного комиссариата по месту жительства: </p>
                        <div className={classes.militaryInput} >
                            <AutocompleteOffice
                                handleChangeOfficeName={handleChangeOffice}
                                setOfficeName={setOffice}
                                office={office}
                            />
                            {
                                /*
                                <Select
                                id="office"
                                value={valueOffice}
                                onChange={handleChangeOffice}
                                className={classes.select}
                            //error={isEmpty ? (state.office_id === '' ? true : false) : false}
                            >
                                {listOffice.map((item: any) => {
                                    return <MenuItem value={item.id} key={item.id + 'office'}>{item.name}</MenuItem>
                                })}
                            </Select>
                            */
                            }

                        </div>
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Состоит ли на специальном учёте N: </p>
                        <div className={classes.boxSelectInput}>
                            <Select
                                id="special-accounting"
                                value={state.special}
                                onChange={handleChangeSpecialAccounting}
                            //error={isEmpty ? (state.special.phone === '' ? true : false) : false}
                            >
                                <MenuItem value={'false'}>Нет</MenuItem>
                                <MenuItem value={'true'}>Да</MenuItem>

                            </Select>
                            {valueSpecialAccounting === true ? <div className={classes.boxSelectInput}>
                                <p className={classes.pSelectInput}> № </p>
                                <Input
                                    id="specialN"
                                    className={classes.selectInput}
                                    onChange={(e: any) => { updateField(e, setState, state) }}
                                    value={state.specialN}
                                />
                            </div> : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
