import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PeopleTable from '../PersonalCardsTable/TableAllUsers';

const MilitaryPeopleModal = ({ open, handleClose, nameOffice}: any) => {


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='xl'
                scroll={'body'}
            >
                <DialogTitle id="alert-dialog-title">{`Военнообязанные, состоящие на учёте в военном комиссариате ${nameOffice.slice(20)}`}</DialogTitle>
                <DialogContent>
                    <PeopleTable nameOfficeDefault={nameOffice}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Назад
                    </Button>          
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default MilitaryPeopleModal;