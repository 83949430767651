import React, {useEffect} from 'react';
import './App.css';
import Login from './pages/Login';
import Home from './pages/Home';
import {Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './redux/store/store';
import PrivateRoute from './utils/PrivateRoute';
import {CheckAuthentication} from './utils/CheckAuthentication'
import PersonalCard from './components/PersonalCard/PersonalCard_v.1/PersonalCard';
import PersonalCard_v2 from './components/PersonalCard/PersonalCard_v.2/PersonalCard_v.2'
import Documents from './pages/Documents';
import Reports from './pages/Reports';
import Offices from './pages/Offices';
import Contacts from './pages/Contacts';
import Regulations from './pages/Regulations';
import PersonalCards from './pages/PersonalCards/PersonalCards';
import MilitaryBase from './pages/MilitaryBase';
import Letters from './pages/Letters/Letters'
import NewHiringLetter from './pages/NewHiringLetter/NewHiringLetter';
import ListForCorrect from './pages/ListForCorrect/ListForCorrect';
import NewDismissalLetter from './pages/NewDismissalLetter/NewDismissalLetter';
import NewRegistrationLetter from './pages/NewRegistrationLetter/NewRegistrationLetter';
import FunctionalResponsibilities from './pages/FunctionalResponsibilities/FunctionalResponsibilities';
import WorkPlan from './pages/WorkPlan/WorkPlan';
import ReportForm6 from './pages/ReportForm6/ReportForm6';
import NewChangeLetter from './pages/NewChangeLetter/NewChangeLetter';
import Petition from './pages/Petition/Petition';
import Characteristic from './pages/Characteristic/Characteristic';
import Company from './pages/Company/Company';
import RegistrationCompany from './pages/RegistrationCompany/RegistrationCompany'
import LandingPage from './pages/LandingPage/LandingPage';
import Legislation from './pages/LandingPage/Legislation/Legislation'
import BasicProvisions from './pages/LandingPage/BasicProvisions/BasicProvisions';
import ResponsibilityPage from './pages/LandingPage/ResponsibilityPage/ResponsibilityPage'
import HowWeWorkPage from './pages/LandingPage/HowWeWorkPage/HowWeWorkPage';
import FAQ from './pages/LandingPage/FAQ/FAQ';
import PublicOffer from './pages/LandingPage/PublicOfferPage/PublicOfferPage';
import ContactsLandingPage from './pages/LandingPage/ContactsLandingPage/Contacts';
import Envelopes from './pages/Envelopes/Envelopes';
import Voucher from "./components/Voucher/Voucher";
import Acts from "./components/Acts/Acts";
import Forms from "./pages/Forms/Forms";
import Journal from "./pages/Forms/Journal";
import RegistrationForm from "./pages/Forms/RegistrationForm";
import StartingMilitary from "./pages/StartingMilitary/StartingMilitary";
import OrganizationOrder from "./pages/OrganizationOrder/OrganizationOrder";
import JobDescription from "./pages/JobDescription/JobDescription";
import Parther from './pages/LandingPage/parther/FAQ';

const App: React.FC = () => {
    useEffect(() => {
        CheckAuthentication();
    }, []);


    return (
        <>
                <Provider store={store}>
            <Route
                exact
                path="/"
                component={LandingPage}
            />
            <PrivateRoute
                exact
                path="/home"
                component={Home}
            />
            <PrivateRoute
                exact
                path="/acts"
                component={Acts}
            />
            <Route
                exact
                path="/login"
                component={Login}
            />
            <PrivateRoute
                exact
                path="/newPersonalCard"
                component={PersonalCard}
            />
            <PrivateRoute
                exact
                path="/newPersonalCard2"
                component={PersonalCard_v2}
            />
            <PrivateRoute
                exact
                path="/voucher"
                component={Voucher}
            />
            <PrivateRoute
                exact
                path="/user/:id"
                component={PersonalCard}
            />
            <PrivateRoute
                exact
                path="/user_new/:id"
                component={PersonalCard_v2}
            />
            <PrivateRoute
                exact
                path="/documents"
                component={Documents}
            />
            <PrivateRoute
                exact
                path="/reports"
                component={Reports}
            />
            <PrivateRoute
                exact
                path="/contacts"
                component={Contacts}
            />
            <PrivateRoute
                exact
                path="/offices"
                component={Offices}
            />
            <PrivateRoute
                exact
                path="/regulations"
                component={Regulations}
            />
            <PrivateRoute
                exact
                path="/personalCards"
                component={PersonalCards}
            />
            <PrivateRoute
                exact
                path="/militaryBase"
                // render={(props) => <MilitaryBase isUpdateCard={isUpdateCard}/>}
                component={MilitaryBase}
            />
            <PrivateRoute
                exact
                path="/start_military"
                component={StartingMilitary}
            />
            <PrivateRoute
                exact
                path="/organization_wy_order"
                component={OrganizationOrder}
            />
            <PrivateRoute
                exact
                path="/job_description"
                component={JobDescription}
            />
            <PrivateRoute
                exact
                path="/letters"
                component={Letters}
            />
            <PrivateRoute
                exact
                path="/forms"
                component={Forms}
            />
            <PrivateRoute
                exact
                path="/journal"
                component={Journal}
            />
            <PrivateRoute
                exact
                path="/registration_forms"
                component={RegistrationForm}
            />
            <PrivateRoute
                exact
                path="/newHiringLetter"
                component={NewHiringLetter}
            />
             <PrivateRoute
                exact
                path="/listForCorrect"
                component={ListForCorrect}
            />
            <PrivateRoute
                exact
                path="/newDismissalLetter"
                component={NewDismissalLetter}
            />
            <PrivateRoute
                exact
                path="/newRegistrationLetter"
                component={NewRegistrationLetter}
            />
            <PrivateRoute
                exact
                path="/newChangeLetter"
                component={NewChangeLetter}
            />
            <PrivateRoute
                exact
                path="/functionalResponsibilities"
                component={FunctionalResponsibilities}
            />
            <PrivateRoute
                exact
                path="/workPlan"
                component={WorkPlan}
            />
            {/*<PrivateRoute
                exact
                path="/reportForm6"
                component={ReportForm6}
            />
            <PrivateRoute
                exact
                path="/petition"
                component={Petition}
            />
            <PrivateRoute
                exact
                path="/characteristic"
                component={Characteristic}
            />*/}
            <PrivateRoute
                exact
                path="/company"
                component={Company}
            />
            <PrivateRoute
                exact
                path="/registrationCompany"
                component={RegistrationCompany}
            />
            <PrivateRoute
                exact
                path="/aboutCompany"
                component={RegistrationCompany}
            />

            <Route
                exct path='/legislation'
                component={Legislation}
            />
            <Route
                exct path='/basicProvisions'
                component={BasicProvisions}
            />
            <Route
                exct path='/responsibility'
                component={ResponsibilityPage}
            />
            <Route
                exct path='/howWeWork'
                component={HowWeWorkPage}
            />
            <Route
                exct path='/entity'
                component={Parther}
            />
            <Route
                exct path='/ip'
                component={Parther}
            />
            <Route
                exct path='/faq'
                component={FAQ}
            />
            <Route
                exct path='/publicOffer'
                component={PublicOffer}
            />
            <Route
                exct path='/our_contacts'
                component={ContactsLandingPage}
            />
            <PrivateRoute
                exact
                path="/envelopes"
                component={Envelopes}
            />
                        </Provider>
        </>
    )
}

export default App;
