import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatDate = (date: string) => {
    const d = new Date(date);

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear() + ' г.';

    return result;
}

const FunctionalResponsibilitiesPDF = ({ date, name1, position1, name2, position2,name2_,position2_,
                                           company, dateInText, limitation,workerName,workerPos }: any) => {
    const card = {
        pageSize: 'A4',
        content: [
            {
                text: `Утверждаю `,
                margin: [300, 5, 0, 5],
                fontSize: 12,
                bold: true,
                lineHeight: 1,
            },
            {
                text: `___________________`,
                margin: [300, 5, 0, 5],
                fontSize: 12,
                bold: true,
                lineHeight: 1,
            },
            {
                text: `${name1} \n ${position1} \n ${company}`,
                margin: [300, 0, 0, 0],
                fontSize: 12,
                bold: true,
            },
            {
                text: `${formatDate(date)}`,
                margin: [300, 0, 0, 10],
                fontSize: 12,
            },

            {
                fontSize: 12,
                alignment: 'left',
                text: `Функциональные обязанности\nответственного за ведение воинского учёта\nвоеннообязанных и призывников.`,
                bold: true,
                margin: [10, 30, 0, 10],
            },
            {
                fontSize: 13,
                alignment: 'center',
                bold: true,
                text: `Ответственный за ведение воинского учёта обязан:`,
                margin: [0, 10, 0, 10],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t   проверять при приеме на работу (учебу) у призывников удостоверения призывников, а у военнообязанных - военные билеты;`,
                margin: [10, 0, 0, 0],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t\t установить, состоят ли граждане, принимаемые на работу (учебу), на воинском учете по месту регистрации;`,
                margin: [10, 0, 0, 0],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t\t направлять граждан, подлежащих приему на воинский учет, в соответствующий орган, ведущий воинский учет по месту  регистрации;`,
                margin: [12, 0, 0, 0],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t\t обеспечить полноту и качество воинского учета призывников и военнообязанных из числа работающих (обучающихся) в организации;`,
                margin: [10, 0, 0, 0],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t\t осуществлять учет изменений, касающихся должности (специальности), образования, состава семьи, семейного положения и места прописки  граждан, и сообщать о них в военный комиссариат;`,
                margin: [10, 0, 0, 0],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t\t ежегодно сверять личные карточки призывников и военнообязанных с записями в удостоверениях призывников и военных билетах, представлять эти карточки для сверки с учетными данными военного комиссариата;`,
                margin: [10, 0, 0, 0],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t\t направлять в месячный срок в местные исполнительные и распорядительные органы, военные комиссариаты и организации сведения о гражданах, становящихся на воинский учет, состоящих на воинском учете, а также не состоящих, но обязанных состоять на воинском учете;`,
                margin: [10, 0, 0, 0],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t\t ежегодно до ${dateInText} представлять в военные комиссариаты списки граждан мужского пола, подлежащих приписке к призывным участкам в следующем году;`,
                margin: [10, 0, 0, 0],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t\t по требованию военного комиссариата оповещать граждан о вызовах в военный комиссариат;`,
                margin: [10, 0, 0, 0],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t  обеспечивать гражданам возможность своевременной явки по вызовам (повесткам) в военный комиссариат;`,
                margin: [10, 0, 0, 0],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t    сообщать в ${limitation} в соответствующий военный комиссариат о всех призывниках и военнообязанных, принятых на работу (учебу) или уволенных с работы (отчисленных из учреждений образования);`,
                margin: [10, 0, 0, 0],
            },
            {
                fontSize: 11,
                alignment: 'justify',
                text: `\u200B\t\t\t направлять ежегодно в военный комиссариат сведения о численности работников организации (призывников и военнообязанных).`,
                margin: [10, 0, 0, 0],
            },
            {
                margin: [10, 40, 0, 40],
                columns: [
                    {
                        width: 150,
                        text: `${workerPos}`,
                        fontSize: 10,
                        lineHeight: 1,
                        bold: true,
                    },
                    {
                        width: '*',
                        fontSize: 10,
                        lineHeight: 1.1,
                        text: `___________________   ${workerName}`,
                        margin: [0, 0, 30, 0],
                        bold: true,
                    },
                ]
            },
            {
                margin: [10, 0, 0, 0],
                columns:[
                    {
                        width: 150,
                        text: `Ознакомлен:`,
                        fontSize: 14,
                        lineHeight: 1,
                        bold: true,
                    },
                ],
            },
            {
                margin: [10, 10, 0, 0],
                columns: [
                    {
                        width: 150,
                        text: `${position2==='' ? position2_ : position2}`,
                        fontSize: 10,
                        lineHeight: 1,
                        bold: true,
                    },
                    {
                        width: '*',
                        fontSize: 10,
                        lineHeight: 1.1,
                        text: `___________________   ${name2==='' ? name2_ : name2}`,
                        margin: [0, 0, 30, 25],
                        bold: true,
                    },
                ]
            },
        ]
    }
    pdfMake.createPdf(card as any).download('FunctionalResponsibilities.pdf');

};
export default FunctionalResponsibilitiesPDF;
