import { SET_USER, SET_UNAUTHENTICATED, SET_ERRORS, CLEAR_ERRORS, LOADED_COMPANY, SET_TABLE_SETTINGS } from '../types'
import axios from 'axios';
import {AGAIN_SEND_LETTER, USER_ID_AGAIN_LETTER} from "../reducers/userReducer";


export const loginUser = (userData: any, history: any) => (dispatch: any) => {
    axios.post('https://voenkomat.by/war/api/auth/', userData)
        .then((res) => {
            //console.log('res action', res.data)
            const token = `Bearer ${res.data.token}`;
            localStorage.setItem('token', `Bearer ${res.data.token}`);
            localStorage.setItem('userID', res.data.user.id);
            localStorage.setItem('username', res.data.user.username);
            localStorage.setItem('responsible person', res.data.user.last_name + ' ' + res.data.user.first_name + ' ' + res.data.user.middle_name);
            // localStorage.setItem('company', res.data.user.company);
            localStorage.setItem('hr_department_phone', res.data.user.hr_department_phone);

            axios.defaults.headers.common['Authorization'] = token;
            dispatch({
                type: SET_USER,
                payload: res.data.user
            });

            dispatch({
                type: LOADED_COMPANY,
                payload: res.data.user.master
            });
/*
            dispatch({
                type: SET_TABLE_SETTINGS,
                payload: res.data.user.setting
            })
*/
            dispatch({ type: CLEAR_ERRORS });


            history.push('/company');
        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data['non_field_errors'] !==undefined ? err.response.data['non_field_errors'][0] :
                    "Неверно введены логин или пароль."
            });
        });
}


export const logoutUser = () => (dispatch: any) => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization']
    dispatch({
        type: SET_UNAUTHENTICATED
    });
    window.location.href = '/login';
};

export const onAgainSendLetterAC=(againSendLetter:string)=>{
    return {
        type:AGAIN_SEND_LETTER,againSendLetter
    }
}
export const getUserIdAgainLetterAC=(userIdAgainLetter:number)=>{
    return {
        type:USER_ID_AGAIN_LETTER,userIdAgainLetter
    }
}
