import React, {useState, useEffect} from 'react';
import Autocomplete from 'react-autocomplete';
import Axios from '../../utils/axios';
import './AutocompleteOffice.css'
import {useDispatch, useSelector} from "react-redux";
import lettersReducer, {getOfficeId} from "../../redux/reducers/lettersReducer";
import {getUserIdAgainLetterAC, onAgainSendLetterAC} from "../../redux/actions/userActions";

const AutocompleteOffice = (props: any) => {
    const [value, setValue] = useState('');
    const [fullValue, setFullValue] = useState('');
    const [autocompleteData, setAutocompleteData] = useState<any>([{}]);

    const againSendLetter=useSelector((state:any)=>state.user.againSendLetter)
    const user_id_again_letter=useSelector((state:any)=>state.user.userIdAgainLetter)

    const office_value = useSelector((state: any) => state.lettersReducer.office_id_value)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getOfficeId(props.office));
    }, [props.office])

    const renderItem = (item: any, isHighlighted: any) => {
        return (
            <div style={{background: isHighlighted ? 'rgba(145, 144, 144, 0.9)' : 'rgba(214, 214, 214, 0.9)'}}
                 key={item.id + new Date().getMilliseconds()}>
                {item.name}
            </div>
        );
    }

    const getItemValue = (item: any) => {
        return `${item.name}`;
    }

    const onSelect = (value: any, item: any) => {
        dispatch(getOfficeId(value))
        setFullValue(value);
        props.handleChangeOfficeName(item);

    }

    const onChange = (e: any) => {
        dispatch(getOfficeId(e.target.value))
        retrieveDataAsynchronously(e.target.value);
        dispatch(onAgainSendLetterAC(''))
        dispatch(getUserIdAgainLetterAC(0))
    }

    const retrieveDataAsynchronously = (searchText: any) => {

        const axios = new Axios();
        if (office_value && office_value.length > 0) {
            const data = {
                "name__contains": office_value
            }

            axios.post('worker/office/filter/', data)
                .then(res => {
                    const arr = [{id: 0, name: ''}, ...res.data.results];
                    setAutocompleteData(arr);
                })
                .catch(err => {
                    console.log(err);
                })
        }

    };


    return (<>

        <Autocomplete
            getItemValue={getItemValue}
            items={autocompleteData}
            renderItem={renderItem}
            value={againSendLetter !=="" && user_id_again_letter !==0 ?
                props.againOffice : props.isAutoGenerate ? props.office : office_value}
            onChange={onChange}
            onSelect={onSelect}
            menuStyle={{
                borderRadius: '1px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                background: 'rgba(255, 255, 255, 0.9)',
                padding: '5px',
                fontSize: '16px',
                position: 'fixed',
                overflow: 'auto',
                maxHeight: 'auto',
                cursor: 'pointer',
                width: '100%',
                left: '100px',
                maxBlockSize: '300px',
                zIndex: 80
            }}
            renderInput={props => {
                //return <Input  id="first_name" />;
                return <input {...props} className={'inputStyle'} type="search"
                              placeholder="Введите начало названия комиссариата"/>;
            }}
        />
    </>)

}

export default AutocompleteOffice;
