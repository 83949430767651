import React from 'react';

function Contacts(props: any) {
    return (
        <>
            <span className={'title'}>Контакты</span>
        </>
    )
}

export default Contacts;