import jsPDF from 'jspdf'
import html2canvas from 'html2canvas';


const createPDF = async (setFile : any) => {

    const input = document.getElementsByClassName('tableForPDF')[0];

    html2canvas(input as any)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm');
            pdf.addImage(imgData, 'PNG', 20, 20);
            pdf.save(`report.pdf`);

            setFile(pdf.output('blob'));
        });

};
export default createPDF;