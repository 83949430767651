import React, { useEffect } from 'react';
import MaterialTable, { Column } from 'material-table';
import './PersonalCard_v2.css'

export interface Row {
    time: Date | string;
    checking: string;
    signature: string;
    worker?: string;
    tableData?: any
}

export interface TableState {
    columns: Array<Column<Row>>;
    data: Row[] | any;
}

const getData = (verifications: any) => {
    const result = [] as any;
    verifications.map((item: any) => {
        const data = {
            time: item.time,
            checking: item.checking,
            signature: item.signature,
            tableData: { id: item.id }
        }

        result.push(data)
    });
    return result;
}

export default function TableVerification({ verifications, setStateTable }: any) {

    const [state, setState] = React.useState<TableState>({
        columns: [
            {
                title: 'Дата сверки',
                field: 'time',
                cellStyle: {
                    width: 150,
                    minWidth: 150
                },
                headerStyle: {
                    width: 150,
                    minWidth: 150
                },
            },
            {
                title: 'С кем или чем проводилась сверка (наименование \nоргана, уполномоченного проводить сверки, или документа)',
                field: 'checking',
                cellStyle: {
                    width: 500,
                    minWidth: 500,
                },
                headerStyle: {
                    width: 500,
                    minWidth: 500,
                },
            },
            {
                title: 'Подпись, инициалы, фамилия лица, проводившего сверку',
                field: 'signature',
                cellStyle: {
                    width: 200,
                    minWidth: 200
                },
                headerStyle: {
                    width: 200,
                    minWidth: 200
                },
            },
        ],
        data: getData(verifications),
    });

    useEffect(() => {
        setState((prevState) => {
            let data = [...prevState.data];
            data = getData(verifications);
            return { ...prevState, data };
        });
    }, [verifications]);

    return (
        <div className='tableVerification-wrp'>
            <MaterialTable
                title=""
                columns={state.columns}
                data={state.data}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                setState((prevState) => {
                                    const data = [...prevState.data];
                                    data.push(newData);
                                    return { ...prevState, data };
                                });
                                setStateTable((prevState: any) => {
                                    const obj = {
                                        time: newData.time,
                                        checking: newData.checking,
                                        signature: newData.signature,
                                        "worker": null,
                                    };
                                    const data = prevState;
                                    data.push(obj);
                                    return data;
                                })
                            }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                if (oldData) {
                                    setState((prevState) => {
                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;
                                        return { ...prevState, data };
                                    });
                                    setStateTable((prevState: any) => {

                                        const obj = {
                                            time: newData.time,
                                            checking: newData.checking,
                                            signature: newData.signature,
                                            "worker": null,
                                        };

                                        const data = prevState;
                                        data[oldData.tableData.id] = obj;
                                        return data;
                                    })
                                }
                            }, 600);

                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                setState((prevState) => {
                                    const data = [...prevState.data];
                                    data.splice(data.indexOf(oldData), 1);
                                    return { ...prevState, data };
                                });

                                setStateTable((prevState: any) => {
                                    const data = prevState;
                                    data.splice(oldData.tableData.id, 1);
                                    return data;
                                })
                            }, 600);
                        }),

                }}
                options={{
                    search: false,
                    paging: false,
                }}
                localization={{
                    toolbar: {
                        nRowsSelected: '{0} выбрано строк'
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Нет записей',
                        filterRow: {
                            filterTooltip: 'Фильтр'
                        },
                        editRow: {
                            deleteText: 'Вы уверены, что хотите удалить запись?',
                            cancelTooltip: 'Отмена',
                            saveTooltip: 'Сохранить'
                        },
                        addTooltip: 'Добавить',
                        deleteTooltip: 'Удалить',
                        editTooltip: 'Изменить',
                    }
                }}
            />
        </div>
    );
}
