import React, {useState, useEffect} from 'react';
import Axios from '.../../../src/utils/axios';
import './PersonalCard_v2.css';
import PersonalCardReduxForm from './PersonalCardReduxForm';
import Button from '@material-ui/core/Button';
import SuccessfulSaveModal from '../SuccessfulSaveModal';
import PersonalCard_v2PDF from './PersonalCard_v2PDF'
import {Row} from "../PersonalCard_v.1/Table";
import {withRouter} from "react-router";
import {useHistory} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const PersonalCard = ({...props}: any) => {

    const url = props.location.pathname;
    const id = props.match.params.id;
    const user_id = localStorage.getItem('id') || '';

    const [openUpdatedModal, setOpenUpdatedModal] = React.useState(false);
    const [isUploaded, setIsUploaded] = React.useState(false);

    const [isSaveInfo, setSaveInfo] = React.useState(false);

    React.useEffect(() => {
        if (isUploaded) {
            props.history.push('/personalCards');
            setIsUploaded(false);
        }
    }, [isUploaded])

    const handleOpenUpdatedModal = () => {
        setOpenUpdatedModal(true);
    };
    const handleCloseUpdatedModal = () => {
        setOpenUpdatedModal(false);
    };

    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const [office, setOffice] = useState('');
    const [office_id, setOfficeId] = useState(0);

    const [otherStatus, setOtherStatus] = useState('');
    const [otherStructure, setOtherStructure] = useState('');
    const [otherReserve, setOtherReserve] = useState('');

    const [valueStructure, setValueStructure] = React.useState('');
    const [valueStatus, setValueStatus] = React.useState('');
    const [valueReserve, setValueReserve] = React.useState('');
    const [valueWyc, setValueWyc] = React.useState('');
    const [valueSpecial, setValueSpecial] = React.useState('');
    const [requirementVal, setRequirementVal] = React.useState('');

    const [fullname, setFullname] = useState({
        firstname: '',
        lastname: '',
        middlename: ''
    })

    const handleFullname = (event: any) => {
        setFullname({
            ...fullname,
            [event.target.id]: event.target.value
        })
    }

    const [isSavePDF, setSavePDF] = useState(false);

    const handleChangeOffice = (event: any) => {
        setOffice(event.name as string);
        setOfficeId(event.id);
    };

    const handleChangeStatus = (event: any) => {
        setValueStatus(event.target.value as string);
    };
    const handleChangeSpecial = (event: any) => {
        setValueSpecial(event.target.value as string);
    };
    const handleChangeRequirementVal = (event: any) => {
        setRequirementVal(event.target.value);
    };

    const clearChangeStatus = (event: any) => {
        setValueStatus('');
    };


    const handleChangeStructure = (event: any) => {
        setValueStructure(event.target.value as string);
    };

    const handleChangeReserve = (event: any) => {
        setValueReserve(event.target.value as string);
    };
    const handleChangeWyc = (event: any) => {
        setValueWyc(event.target.value as string);
    };

    const [stateTableVerification, setStateTableVerification] = React.useState([] as any);

    const [verification, setVerification] = useState([] as any);

    const [stateTableMarks, setStateTableMarks] = React.useState([] as any);

    const [marks, setMarks] = useState([] as any);

    const [message, setMessage] = useState('');

    const [flag, setFlag] = useState(false);
    const [stateTable, setStateTable] = React.useState<Array<Row>>([]);

    const [openWarningModal, setOpenWarningModal] = useState(false);

    const [initialState, setInitialState] = useState({

        firstName: '',
        lastName: '',
        middleName: '',
        date_of_birth: '',
        birthplace: '',
        nationality_id: null,
        dsivision: '',
        position: '',
        address: '',
        registered_address: '',
        work_phone: '',
        home_phone: '',
        phone: '',

        education_id: null,
        education_full: '',
        edu_specialization: '',
        edu_qualification: '',
        edu_dop_full: '',
        edu_dop_specialization: '',
        edu_dop_qualification: '',
        marital_status_id: null,
        marital_status: '',
        passport: '',
        pass_office: '',
        pass_date: '',
        pass_number: '',
        family: '',

        military_1: '',
        military_2: '',
        military: '',
        accountc_id: null,
        accountg_id: null,
        structure_id: null,
        grade_id: null,
        wyc: '',
        status_id: null,
        office_id: null,
        special: false,
        specialN: '',
        grade_full: '',
        profile_id: '',
        date_add_military: '',
      //  number_add_military: '',
        // date2_add_military: '',
        add_military:'',

        date_remove_military: '',
        // number_remove_military: '',
        // date2_remove_military: '',
        order_remove_military:'',
        time_end: '',
        special_marks: '',
        requirement: false,
        requirement_annotation:'',
        worker: [],
        verification: [],
        marks: [],
        status_empty: '',
        structure_empty: '',
        accountc_empty: '',
        reserve_level_empty: '',
        order_military:'',
        driving:'',
        disability:'',

        basic_military_id: null,
        form6_id: null,

        "user": 0,

    });

    useEffect(() => {
        setInitialState({
            ...initialState,
            'worker': stateTable as any
        });
    }, [stateTable]);

    const onChangeGrade = (e: any) => {
        console.log(e.target.value)
        //setGradeVal(e.target.value)
    };
    const history = useHistory();
    const closePage = () => {
        history.goBack();
    }

    const onSubmit = (formData: any) => {

        const buf = Object.assign(formData, {

            full_name: fullname ? `${fullname.lastname} ${fullname.firstname} ${fullname.middlename}` : null,
            office_id: office_id === 0 || formData.office_id === '0' ? null : office_id,
            structure_id: formData.accountg_id === '4' ? null : formData.accountg_id === '5' ? null :
                formData.accountg_id === '6' ? null : formData.accountg_id === 4 ? null : formData.accountg_id === 5 ? null :
                    formData.accountg_id === 6 ? null : valueStructure, // otherStructure === '' ? '' : otherStructure : valueStructure,
            structure_empty: valueStructure === '' ? formData.structure_empty : null,
            status_id: valueStatus === '' ? null : formData.accountg_id === 4 || formData.accountg_id === 5  || formData.accountg_id === 6 ? null : formData.accountg_id === '4' || formData.accountg_id === '5' || formData.accountg_id === '6' ? null : valueStatus,
            status_empty: valueStatus === '' ? formData.status_empty : null,
            add_military: formData.add_military === '' ? null : formData.add_military,
            remove_military: formData.remove_military==='' ? null : formData.remove_military,
            order_remove_military: formData.order_remove_military==='' ? null : formData.order_remove_military,
            //@ts-ignore
            special:valueSpecial==='true'  ? true : valueSpecial===true ? true : false,
            order_military:formData.order_military ==='' ? null : formData.order_military,
            verification: stateTableVerification,
            marks: stateTableMarks,
            nationality_id: null,
            accountc_id: null,
            wyc: formData.accountg_id === 4 ? null : formData.accountg_id === '4' ? null : formData.wyc,
            profile_id: formData.accountg_id === '4' || formData.profile_id === '0' || formData.accountg_id === '0' ? null : formData.accountg_id === 4 ? null : formData.profile_id,
            grade_id: formData.accountg_id === '4' || formData.grade_id === '0' ? null : formData.accountg_id === 4 ? null : formData.grade_id,
            grade: formData.accountg_id === '4' ? null : formData.accountg_id === 4 ? null : formData.grade,
            reserve_level_id: valueReserve === '' ? null : formData.accountg_id === '4' ? null : formData.accountg_id === 4 ? null : valueReserve, //otherReserve === '' ? null : otherReserve : valueReserve,
            reserve_level_empty: valueReserve === '' ? formData.reserve_level_empty : null,
           time_end: formData.basic_military==='Уволен(а)' ? formData.remove_military : null,
                //reserve_level : valueReserve === '' ? null : valueReserve,
            date_remove_military: formData.date_remove_military === '' ? null : formData.date_remove_military,
            date_add_military: formData.date_add_military === '' ? null : formData.date_add_military,
            //@ts-ignore
           requirement: requirementVal==='true'  ? true : requirementVal===true ? true : false,
            marital_status_id: formData.marital_status_id === '0' ? null : formData.marital_status_id,
            education_id: formData.education_id === '0' ? null : formData.education_id,
            accountg_id: formData.accountg_id === '0' ? null : formData.accountg_id,
            basic_military_id: formData.basic_military_id === '0' ? null : formData.basic_military_id,
            date_of_birth: formData.date_of_birth === '' ? null : formData.date_of_birth,
            pass_number: formData.pass_number === '' ? null : formData.pass_number,
            registered_address: formData.registered_address === '' ? null : formData.registered_address,
            address: formData.address === '' ? null : formData.address,
            birthplace: formData.birthplace === '' ? null : formData.birthplace,
            passport: formData.passport === '' ? null : formData.passport,
            driving: formData.driving === '' ? null : formData.driving,
            disability: formData.disability === '' ? null : formData.disability,
        });

        // if (buf.date_remove_military === '') {
        //     delete buf['date_remove_military'];
        // }
        //
        // if (buf.form6_id === '0') {
        //     buf['form6_id'] = null
        // }
        //
        // delete buf['lastName'];
        // delete buf['firstName'];
        // delete buf['middleName'];
        //
        // delete buf['number_add_military'];
        // delete buf['date2_add_military'];
        // delete buf['number_remove_military'];
        // delete buf['date2_remove_military'];
        //
        // delete buf['accountc'];
        // //delete buf['accountg'];
        // delete buf['nationality'];
        // delete buf['edu_diploma'];


        const data = Object.assign(buf, {user: localStorage.getItem('id')});


        for (let key in data) {
            if (key.substring(key.length - 3) === '_id' || key === 'time_end') {
                //@ts-ignore
                if (data[key] === '') {
                    //@ts-ignore
                    data[key] = null;
                }
            } else {
                //@ts-ignore
                if (data[key] === '') {
                    //@ts-ignore
                    data[key] = null;
                }
            }
        }

        if (isSavePDF) {
            PersonalCard_v2PDF(data);
            setFlag(!flag);
            setSavePDF(false);
            //window.location.reload();
        } else {
            const axios = new Axios();

            delete data['accountg'];
            data['military']=data['military_1']+" № "+data['military_2']
            delete data['military_1']
            delete data['military_2']
            const sendData = () => {
                if (url !== '/newPersonalCard2') {
                    axios.put(`worker/${user_id}/${id}/`, data)
                        .then(res => {
                            setMessage('Личная карточка сохранена успешно');
                            handleOpenModal();
                            setTimeout(handleCloseModal, 3000);
                            setTimeout(()=>{
                                setSaveInfo(false)
                            }, 2000);
                            // setTimeout(()=>{
                            //      props.history.push('/personalCards')
                            // },4500)
                          //  window.location.reload();
                            // props.history.goBack();
                        })
                        .catch(err => {
                            console.log(err);
                            setMessage('Личная карточка не сохранена');
                            handleOpenModal();
                            setTimeout(handleCloseModal, 3000);
                            //window.location.reload();
                        })
                } else {
                    axios.post(`worker/${user_id}/`, data)
                        .then(res => {
                            setMessage('Личная карточка сохранена успешно');
                            handleOpenModal();
                            setTimeout(handleCloseModal, 4000);
                            setTimeout(()=>{
                                 props.history.push('/personalCards')
                            },4500)

                          //  window.location.reload();
                            // props.history.goBack();
                        })
                        .catch(err => {
                            console.log(err);
                            setMessage('Личная карточка не сохранена')
                            handleOpenModal();
                            setTimeout(handleCloseModal, 4000);
                            //window.location.reload();
                        })
                }

            }
            if (formData.date_remove_military && formData.basic_military_id === null) {
                setMessage('Пожалуйста, заполните основание снятия (исключения) гражданина с воинского учета');
                handleOpenModal();
                setTimeout(handleCloseModal, 4000);
            } else sendData()
        }

    }




    useEffect(() => {

        if (url !== '/newPersonalCard2') {
            const axios = new Axios();
            axios.get(`worker/${user_id}/${id}`)
                .then(res => {
                    //debugger;
                    let dd=res.data
                    if (dd && dd['military'])
                    {
                         let tt=dd['military'].split(" № ");
                        dd['military_1']=tt[0];
                        dd['military_2']=tt[1];
                    }
                       
                    setInitialState(dd as any);
                    setValueStatus(res.data.status || '');
                    setValueSpecial(res.data.special)
                    setRequirementVal(res.data.requirement)
                    setValueStructure(res.data.structure || '');
                    setOfficeId(res.data.office_id || null);
                    setOffice(res.data.office || '');
                    setValueReserve(res.data.reserve_level || '');

                    const arrVerification = res.data.verification.map((item: any) => {
                        return Object.assign({worker: null}, item);
                    });
                    const arrWorker = res.data.worker.map((item: any) => {
                        return Object.assign({worker: null}, item);
                    })
                    setStateTable(arrWorker);
                    setVerification(arrVerification);
                    setStateTableVerification(arrVerification);

                    setMarks(res.data.marks);
                    setStateTableMarks(res.data.marks);

                    const arrName = res.data.full_name ? res.data.full_name.split(' ') : res.data.full_name.indexOf('undefined') !==-1 ? []:[];
                    // const arrRemove_military = res.data.remove_military   ? res.data.remove_military.split(' ') :
                    //     res.data.remove_military ==='undefined' || res.data.remove_military ==='undefined undefined' ? [] :  [];
                    // const arrAdd_military = res.data.add_military  ? res.data.add_military.split(' ') :
                    //     res.data.add_military ==='undefined' ||  res.data.add_military ==='undefined undefined' ? [] : [];

                    setFullname({
                        firstname: arrName[1],
                        lastname: arrName[0],
                        middlename: arrName[2]
                    })

                    setInitialState((prevState: any) => {
                        return {
                            ...prevState,
                            //firstName: arrName[1],
                            //lastName: arrName[0],
                            //middleName: arrName[2],
                            // number_add_military: arrAdd_military.length!==0 ?  arrAdd_military[0] : '',
                            // date2_add_military: arrAdd_military.length!==0 ?  arrAdd_military[1] : '',
                            //
                            // number_remove_military: arrRemove_military.length!==0 ?  arrRemove_military[0] : '',
                            // date2_remove_military: arrRemove_military.length!==0 ? arrRemove_military[1] : '',
                        };
                    })
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [flag,isSaveInfo])


    const deleteWorker = () => {
        setOpenWarningModal(false)
        const axios = new Axios();
        axios.delete(`worker/${localStorage.getItem('id')}/${id}/`, {}) //${id} 210
            .then(res => {
                 props.history.push('/personalCards');
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div className='container_1'>
            <div className={'wrap-notice'}>
                <Button variant="contained" style={{width:'310px'}}>
               <a href={'http://voenkomat.by/war/media/Памятка_заполнения_учетных_данных_Личной_Карточки.pdf'}
               target='_blank'>
                   Памятка заполнения учетных данных Личной Карточки </a>
                </Button>
            </div>
            <span className='title_'
            >{url !== '/newPersonalCard2' ? 'Редактирование личной карточки' : 'Создание личной карточки'}</span>

            <div className={'toolbar'}>
                <div className='btn-wrapper'>
                    { /*<Button variant="contained" onClick={() => props.history.goBack()} >
                        Назад
                    </Button>*/
                    }
                </div>
                {(url === '/newPersonalCard2') ? <div className={'buttonLoaded'}>
                    <Button variant="contained" onClick={handleOpenUpdatedModal}>Загрузить</Button>
                </div> : <div>
                    <Button onClick={() => {
                    setOpenWarningModal(true)
                }} color="primary" variant="contained">
                    Удалить карточку
                </Button></div>}

            </div>
            <div style={{color:'red',marginTop:'20px'}}><sup>*</sup> Что бы сохранить изменения,внесенные в личную карточку, и(или) вывести Отчёт - прежде нажмите кнопку
                Сохранить
            </div>
            <PersonalCardReduxForm
                requirementVal={requirementVal}
                handleChangeRequirementVal={handleChangeRequirementVal}
                handleChangeSpecial={handleChangeSpecial}
                valueSpecial={valueSpecial}
                setSaveInfo={setSaveInfo}
                handleChangeWyc={handleChangeWyc}
                valueWyc={valueWyc}
                onChangeGrade={onChangeGrade}
                onSubmit={onSubmit}
                initialValues={initialState}
                setInitialState={setInitialState}
                url={url}
                handleChangeOffice={handleChangeOffice}
                office={office}
                setOffice={setOffice}
                fullname={fullname}
                handleFullname={handleFullname}
                valueStructure={valueStructure}
                handleChangeStructure={handleChangeStructure}
                otherStructure={otherStructure}
                setOtherStructure={setOtherStructure}
                valueReserve={valueReserve}
                handleChangeReserve={handleChangeReserve}
                otherReserve={otherReserve}
                setOtherReserve={setOtherReserve}
                valueStatus={valueStatus}
                handleChangeStatus={handleChangeStatus}
                otherStatus={otherStatus}
                setOtherStatus={setOtherStatus}
                setStateTableVerification={setStateTableVerification}
                verifications={verification}
                setSavePDF={setSavePDF}
                clearChangeStatus={clearChangeStatus}
                setStateTableMarks={setStateTableMarks}
                marks={marks}
                id={id}
            />

            <SuccessfulSaveModal
                open={openModal}
                handleClose={handleCloseModal}
                message={message}
            />

            <Dialog onClose={()=>{setOpenWarningModal(false)}} aria-labelledby="customized-dialog-title"
                    open={openWarningModal}>
                <DialogContent dividers>
                    Просим подтвердить удаление личной карточки указанного сотрудника!
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>{deleteWorker()}} color="primary">
                        Продтверждаю
                    </Button>
                    <Button autoFocus onClick={()=>{setOpenWarningModal(false)}} color="primary">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default withRouter (PersonalCard)
