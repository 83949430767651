import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import GeneralInformation from './GeneralInformation';
import MilitaryAccounting from './MilitaryAccounting';
import DestinationAndMovement from './DestinationAndMovement'
import classes from './PersonalCard.module.css';
import Axios from '.../../../src/utils/axios';
import store from '../../../redux/store/store';


import createPDFPersonalCard from './CreatePDFPersonalCard';
import createPersonalCardPDF2Page from './PersonalCardPDF2Page';

import { Row } from './Table';
import UploaderModal from '../UploaderModal/UploaderModal';
import HistoryModal from '../../HistoryPersonalCardModal/HistoryModal'
import SuccessfulSaveModal from '../SuccessfulSaveModal';

export default function PersonalCard(props: any) {

    const url = props.location.pathname;
    const id = props.match.params.id;

    const [generalInfState1, setGeneralInfState1] = useState({
        full_name: '',
        date_of_birth: '',
        birthplace: '',
        nationality_id: '',
        position: '',
        address: '',
        registered_address: '',
        phone: '',
        email: ''
    });

    const [generalInfEducationState, setGeneralInfEducationState] = useState({
        education_id: '',
        edu_specialization: '',
        edu_qualification: '',
        edu_diploma: '',
        edu_certificate: '',
    });

    const [generalInfState3, setGeneralInfState3] = useState({
        marital_status_id: '',
        passport: '',
        pass_office: '',
        pass_date: '',
        pass_number: '',
        family: ''
    });

    const [militaryState, setMilitaryState] = useState({
        military: '',
        accountc_id: '',
        accountg_id: '',
        structure_id: '',
        grade_id: '',
        wyc: '',
        status_id: '',
        office_id: '',
        special: false,
        specialN: '',
        grade_full: ''
    });

    const [destinationState, setDestinationState] = useState({
        worker: [],
        special_marks: '',
        requirement: '',
        time_end: '',
        form6_id: ''
    });

    const [otherNationality, setOtherNationality] = useState('');
    const [otherStatus, setOtherStatus] = useState('');
    const [otherAccountС, setOtherAccountС] = useState('');
    const [otherStructure, setOtherStructure] = useState('');
    // const [passportSeries, setPassportSeries] = useState('');
    // const [passportNumber, setPassportNumber] = useState('');

    const [passport, setPassport] = useState({
        pass_series: '',
        pass_num: ''
    })

    const [full_name, setFullName] = useState({
        firstName: '',
        lastName: '',
        middleName: ''
    });

    const [military, setMilitary] = useState({
        series: '',
        number: ''
    });

    const [diploma, setDiploma] = useState({
        series_diploma: '',
        number_diploma: '',
        date_diploma: ''
    });

    const [certificate, setCertificate] = useState({
        series_certificate: '',
        number_certificate: '',
        date_certificate: ''
    });

    const [isEmpty, setEmpty] = useState(false);

    const [selectState, setSelectState] = useState({
        nationality: '',
        marital_status: '',
        accountc: '',
        accountg: '',
        structure: '',
        grade: '',
        status: '',
        //office: '',
        education: '',
        form6: ''
    });

    const [office, setOffice] = useState('');
    const [office_id, setOfficeId] = useState(0);
    const [officeShort, setOfficeShort] = useState('');

    const handleChangeOffice = (event: any) => {
        setOffice(event.name as string);
        setOfficeId(event.id);
        setOfficeShort(event.short_name);
    };

    const [openHistoryModal, setOpenHistoryModal] = React.useState(false);
    const [itemsHistory, setItemsHistory] = useState([] as any);

    const [openUpdatedModal, setOpenUpdatedModal] = React.useState(false);
    const [isUploaded, setIsUploaded] = React.useState(false);

    React.useEffect(() => {
        if (isUploaded) {
            props.history.push('/personalCards');
            setIsUploaded(false);
        }
    }, [isUploaded])

    const handleOpenUpdatedModal = () => {
        setOpenUpdatedModal(true);
    };
    const handleCloseUpdatedModal = () => {
        setOpenUpdatedModal(false);
    };

    const handleOpenHisoryModal = () => {
        setOpenHistoryModal(true);
    };
    const handleCloseHistoryModal = () => {
        setOpenHistoryModal(false);
    };

    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage] = useState('');

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const getHistory = () => {
        const axios = new Axios();
        axios.get(`worker/history/${id}/`) //${id} 210
            .then(res => {
                // debugger;
                setItemsHistory(res.data.results);
                handleOpenHisoryModal();
            })
    }

    const deleteWorker = () => {
        const axios = new Axios();
        axios.delete(`worker/${localStorage.getItem('id')}/${id}/`,{}) //${id} 210
            .then(res => {
                props.history.push('/personalCards');
            })
            .catch(err => {
                console.log(err);
            })
    }


    const updateMilitary = (e: any) => {
        setMilitary({
            ...military,
            [e.target.id]: e.target.value
        });
    };

    const updateFullName = (e: any) => {
        setFullName({
            ...full_name,
            [e.target.id]: e.target.value
        });
    };

    const updateDiploma = (e: any) => {
        setDiploma({
            ...diploma,
            [e.target.id]: e.target.value
        });
    };

    const updateCertificate = (e: any) => {
        setCertificate({
            ...certificate,
            [e.target.id]: e.target.value
        });
    };

    const updatePassport = (e: any) => {
        setPassport({
            ...passport,
            [e.target.id]: e.target.value
        });
    };


    useEffect(() => {
        const text = military.series + ' ' + military.number;
        setMilitaryState({
            ...militaryState,
            'military': text as string
        });
    }, [military]);

    useEffect(() => {
        const text = full_name.lastName + ' ' + full_name.firstName + ' ' + full_name.middleName;
        setGeneralInfState1({
            ...generalInfState1,
            'full_name': text as string
        });
    }, [full_name]);

    useEffect(() => {
        const text = (diploma.series_diploma === '' ? '' : diploma.series_diploma) + ' ' + (diploma.number_diploma === '' ? ' ' : diploma.number_diploma) + ' ' + (diploma.date_diploma === '' ? ' ' : diploma.date_diploma);
        setGeneralInfEducationState({
            ...generalInfEducationState,
            'edu_diploma': text as string
        });
    }, [diploma]);

    useEffect(() => {
        const text = (certificate.series_certificate === '' ? '' : certificate.series_certificate) + ' ' + (certificate.number_certificate === '' ? ' ' : certificate.number_certificate) + ' ' + (certificate.date_certificate === '' ? ' ' : certificate.date_certificate);
        setGeneralInfEducationState({
            ...generalInfEducationState,
            'edu_certificate': text as string
        });
    }, [certificate]);



    useEffect(() => {
        const text = passport.pass_series + ' ' + passport.pass_num;
        setGeneralInfState3({
            ...generalInfState3,
            'passport': text as string
        });
    }, [passport]);


    const [stateTable, setStateTable] = React.useState<Array<Row>>([]);

    useEffect(() => {
        setDestinationState({
            ...destinationState,
            'worker': stateTable as any
        });
    }, [stateTable]);

    useEffect(() => {
        if (url !== '/newPersonalCard') {
            const fetchData = () => {
                const axios = new Axios();
                const user_id = localStorage.getItem('id') || '';
                axios.get(`worker/${user_id}/${id}`)
                    .then(res => {

                        setGeneralInfState1({
                            full_name: res.data.full_name || '',
                            date_of_birth: res.data.date_of_birth || '',
                            birthplace: res.data.birthplace || '',
                            nationality_id: res.data.nationality || '',
                            position: res.data.position || '',
                            address: res.data.address || '',
                            registered_address: res.data.registered_address || '',
                            phone: res.data.phone || '',
                            email: res.data.email || ''
                        });


                        setGeneralInfEducationState({
                            education_id: res.data.education_id || '',
                            edu_specialization: res.data.edu_specialization || '',
                            edu_qualification: res.data.edu_qualification || '',
                            edu_diploma: res.data.edu_diploma || '',
                            edu_certificate: res.data.edu_certificate || '',
                        });

                        setGeneralInfState3({
                            marital_status_id: res.data.marital_status_id || '',
                            passport: res.data.passport || '',
                            pass_office: res.data.pass_office || '',
                            pass_date: res.data.pass_date || '',
                            pass_number: res.data.pass_number || '',
                            family: res.data.family || ''
                        })
                        //debugger;
                        setMilitaryState({
                            military: res.data.military || '',
                            accountc_id: res.data.accountc || '',
                            accountg_id: res.data.accountg_id || '',
                            structure_id: res.data.structure || '',
                            grade_id: res.data.grade_id || '',
                            wyc: res.data.wyc || '',
                            status_id: res.data.status || '',
                            office_id: res.data.office_id || '',
                            special: res.data.special || '',
                            specialN: res.data.specialN || '',
                            grade_full: res.data.grade_full || ''
                        });

                        setDestinationState({
                            worker: res.data.worker || [],
                            special_marks: res.data.special_marks || '',
                            requirement: res.data.requirement || '',
                            time_end: res.data.time_end || '',
                            form6_id: res.data.form6_id || ''
                        })


                        setFullName({
                            lastName: res.data.full_name ? res.data.full_name.split(' ').filter((item: any) => item !== '')[0] : '',
                            firstName: res.data.full_name ? res.data.full_name.split(' ').filter((item: any) => item !== '')[1] : '',
                            middleName: res.data.full_name ? res.data.full_name.split(' ').filter((item: any) => item !== '')[2] : '',
                        })

                        setMilitary({
                            series: res.data.military ? res.data.military.trim().split(' ')[0] : '',
                            number: res.data.military ? res.data.military.trim().split(' ')[1] : '',
                        })

                        setDiploma({
                            series_diploma: res.data.edu_diploma ? res.data.edu_diploma.split(' ')[0] : '',
                            number_diploma: res.data.edu_diploma ? res.data.edu_diploma.split(' ')[1] : '',
                            date_diploma: res.data.edu_diploma ? res.data.edu_diploma.split(' ')[2] : '',
                        })

                        setCertificate({
                            series_certificate: res.data.edu_certificate ? res.data.edu_certificate.split(' ')[0] : '',
                            number_certificate: res.data.edu_certificate ? res.data.edu_certificate.split(' ')[1] : '',
                            date_certificate: res.data.edu_certificate ? res.data.edu_certificate.split(' ')[2] : '',
                        });

                        setOfficeShort(res.data.office)

                        const arrWorker = res.data.worker.map((item: any) => {
                            return Object.assign({ worker: '1' }, item);
                        })
                        setStateTable(arrWorker);

                        setSelectState({
                            nationality: res.data.nationality || '',
                            marital_status: res.data.marital_status || '',
                            accountc: res.data.accountc || '',
                            accountg: res.data.accountg || '',
                            structure: res.data.structure || '',
                            grade: res.data.grade || '',
                            status: res.data.status || '',
                            //office: res.data.office || '',
                            education: res.data.education || '',
                            form6: res.data.form6 || ''
                        })

                        setOffice(res.data.office || '');
                        setOfficeId(res.data.office_id || 0);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            };
            fetchData();
        }
    }, []);


    const updateField = (e: any, setState: any, form: any) => {
        if (e.target.id === 'pass_number') {
            if ((/^[a-zA-Z/@.-/[0-9]+$/.test(e.target.value[e.target.value.length - 1]))) {
                setState({
                    ...form,
                    [e.target.id]: e.target.value
                });
            }
        }
        else {
            setState({
                ...form,
                [e.target.id]: e.target.value
            });
        }
    };


    const savePDF = (countList: number) => {

        const form = {
            full_name: generalInfState1.full_name,
            date_of_birth: generalInfState1.date_of_birth,
            birthplace: generalInfState1.birthplace,
            nationality: Number(generalInfState1.nationality_id) === 0 ? otherNationality : selectState.nationality,
            //nationality_id: 
            position: generalInfState1.position,
            address: generalInfState1.address,
            registered_address: generalInfState1.registered_address,
            phone: generalInfState1.phone,
            email: generalInfState1.email,

            education: selectState.education,
            edu_specialization: generalInfEducationState.edu_specialization,
            edu_qualification: generalInfEducationState.edu_qualification,
            edu_diploma: generalInfEducationState.edu_diploma,
            edu_certificate: generalInfEducationState.edu_certificate,

            marital_status: selectState.marital_status,
            passport: generalInfState3.passport,
            pass_office: generalInfState3.pass_office,
            pass_date: generalInfState3.pass_date,
            pass_number: generalInfState3.pass_number,
            family: generalInfState3.family,

            military: militaryState.military || '',
            accountc: militaryState.accountc_id === 'пусто' ? otherAccountС === '' ? '' : otherAccountС : selectState.accountc,
            accountg: selectState.accountg || '',
            structure: militaryState.structure_id === 'пусто' ? otherStructure === '' ? '' : otherStructure : selectState.structure,
            grade: selectState.grade,
            wyc: militaryState.wyc,
            status: militaryState.status_id === 'пусто' ? otherStatus === '' ? '' : otherStatus : selectState.status,
            office: officeShort,
            special: militaryState.special,
            specialN: militaryState.specialN,

            time_end: destinationState.time_end,
            form6_id: destinationState.form6_id,
            special_marks: destinationState.special_marks,
            requirement: destinationState.requirement,
            worker: destinationState.worker,

            user: Number(localStorage.getItem('id')),
        }

        if (countList === 1) {
            createPDFPersonalCard(form);
        } else {
            createPersonalCardPDF2Page(form);
        }

    }

    const validateEmail = (email: string) => {
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (!email) return true;
        if (email === ' ') return true;

        return reg.test(email);
    }

    const save = () => {
        const axios = new Axios();
        const user_id = localStorage.getItem('id') || '';

        // @ts-ignore
        let form = {
            full_name: generalInfState1.full_name,
            date_of_birth: generalInfState1.date_of_birth,
            birthplace: generalInfState1.birthplace,
            nationality_id: Number(generalInfState1.nationality_id) === 0 ? otherNationality : generalInfState1.nationality_id,
            position: generalInfState1.position,
            address: generalInfState1.address,
            registered_address: generalInfState1.registered_address,
            phone: generalInfState1.phone,
            email: generalInfState1.email,

            education_id: generalInfEducationState.education_id,
            edu_specialization: generalInfEducationState.edu_specialization,
            edu_qualification: generalInfEducationState.edu_qualification,
            edu_diploma: generalInfEducationState.edu_diploma,
            edu_certificate: generalInfEducationState.edu_certificate,

            marital_status_id: generalInfState3.marital_status_id,
            passport: generalInfState3.passport,
            pass_office: generalInfState3.pass_office,
            pass_date: generalInfState3.pass_date,
            pass_number: generalInfState3.pass_number,
            family: generalInfState3.family,

            military: militaryState.military || '',
            //accountc_id: 
            accountc_id: militaryState.accountc_id === 'пусто' ? otherAccountС === '' ? '' : otherAccountС : selectState.accountc,
            accountg_id: militaryState.accountg_id || '',
            structure_id: militaryState.structure_id === 'пусто' ? otherStructure === '' ? '' : otherStructure : selectState.structure,
            grade_id: militaryState.grade_id || '',
            wyc: militaryState.wyc,
            status_id: militaryState.status_id === 'пусто' ? otherStatus === '' ? '' : otherStatus : selectState.status,
            //office: office,
            office_id: office_id === 0 ? null : office_id,
            special: militaryState.special,
            specialN: militaryState.specialN,
            grade_full: militaryState.grade_full,

            time_end: destinationState.time_end,
            special_marks: destinationState.special_marks,
            requirement: destinationState.requirement,
            worker: destinationState.worker,

            verification: [],
            marks: [],

            user: Number(localStorage.getItem('id')),
            reserve_level_id: null,
            basic_military_id: null,
            form6_id: destinationState.form6_id || '',
        };


        for (let key in form) {
            if (key.substring(key.length - 3) === '_id' || key === 'time_end') {
                //@ts-ignore
                if (form[key] === '') {
                    //@ts-ignore
                    form[key] = null;
                }
            } else {
                //@ts-ignore
                if (form[key] === '') {
                    //@ts-ignore
                    form[key] = undefined;
                }
            }
        }

        if (form.full_name.split(' ')[0] === '' || form.full_name.split(' ')[1] === '' || form.full_name.split(' ')[2] === '') {
            setEmpty(true);
        }
        else if (validateEmail(form.email)) {
            if (url !== '/newPersonalCard') {
                axios.put(`worker/${user_id}/${id}/`, form)
                    .then(res => {
                        //props.history.goBack();
                        setMessage('Личная карточка сохранена')
                        handleOpenModal();
                        setTimeout(handleCloseModal, 4000);
                        // window.location.reload();
                    })
                    .catch(err => {
                        console.log(err);
                        setMessage('Личная карточка не сохранена')
                        handleOpenModal();
                        setTimeout(handleCloseModal, 4000);
                    })
            } else {
                axios.post(`worker/${user_id}/`, form)
                    .then(res => {
                        // props.history.goBack();
                        setMessage('Личная карточка сохранена')
                        handleOpenModal();
                        setTimeout(handleCloseModal, 4000);
                        // window.location.reload();
                    })
                    .catch(err => {
                        console.log(err);
                        setMessage('Личная карточка не сохранена')
                        handleOpenModal();
                        setTimeout(handleCloseModal, 4000);
                    })
            }
        }
    }

    const closePage = () => {
        props.history.goBack();
    }

    return (
        <>
            <span className='title_'
            >{url !== '/newPersonalCard' ? 'Редактирование личной карточки' : 'Создание личной карточки'}</span>
            <div className={'toolbar'}>               
                {/* <div className={classes.buttonBack}>
                    <Button variant="contained" onClick={closePage}>Назад</Button>
    </div>*/}
                {(url === '/newPersonalCard') ? <div className={'buttonLoaded'}>
                    <Button variant="contained" onClick={handleOpenUpdatedModal}>Загрузить</Button>
                </div> : <div><Button onClick={() => { deleteWorker() }} color="primary" variant="contained">
                            Удалить карточку
                    </Button></div>}

            </div>
            <div className={classes.container}>
                <div className={classes.paper}>
                    <h2 className={classes.headers}>Личная карточка</h2>
                    <GeneralInformation
                        updateField={updateField}
                        state={{ generalInfState1, generalInfEducationState, generalInfState3, selectState }}
                        setState={{ setGeneralInfState1, setGeneralInfEducationState, setGeneralInfState3, setSelectState }}
                        updatePassport={updatePassport}
                        updateFullName={updateFullName}
                        updateDiploma={updateDiploma}
                        updateCertificate={updateCertificate}
                        isEmpty={isEmpty}
                        otherNationality={otherNationality}
                        setOtherNationality={setOtherNationality}

                    />

                    <MilitaryAccounting
                        updateField={updateField}
                        state={militaryState}
                        setState={setMilitaryState}
                        updateMilitary={updateMilitary}
                        isEmpty={isEmpty}
                        selectState={selectState}
                        setSelectState={setSelectState}
                        handleChangeOffice={handleChangeOffice}
                        setOffice={setOffice}
                        office={office}
                        otherStatus={otherStatus}
                        setOtherStatus={setOtherStatus}
                        otherAccountС={otherAccountС}
                        setOtherAccountС={setOtherAccountС}
                        otherStructure={otherStructure}
                        setOtherStructure={setOtherStructure}
                    />

                    <DestinationAndMovement
                        updateField={updateField}
                        state={destinationState}
                        setState={setDestinationState}
                        stateTable={stateTable}
                        setStateTable={setStateTable}
                        isEmpty={isEmpty}
                        selectState={selectState}
                        setSelectState={setSelectState}
                    />


                    <div className={classes.boxButton}>
                        <div className={classes.button}>
                            <Button variant="contained" onClick={() => savePDF(1)}>Отчёт (1 лист)</Button>
                        </div>
                        <div className={classes.button}>
                            <Button variant="contained" onClick={() => savePDF(2)}>Отчёт (2 листа)</Button>
                        </div>
                        <div className={classes.button}>
                            <Button variant="contained" onClick={getHistory}>История</Button>
                        </div>
                        <div className={classes.button}>
                            <Button variant="contained" color="primary" onClick={save}>Сохранить</Button>
                        </div>
                        <div className={classes.button}>
                            <Button variant="contained" onClick={closePage}>Отмена</Button>
                        </div>
                    </div>
                </div>
            </div>

            <UploaderModal
                handleClickOpen={handleOpenUpdatedModal}
                handleClose={handleCloseUpdatedModal}
                open={openUpdatedModal}
                setIsUploaded={setIsUploaded}
            />

            <HistoryModal
                open={openHistoryModal}
                handleClose={handleCloseHistoryModal}
                items={itemsHistory}
            />

            <SuccessfulSaveModal
                open={openModal}
                handleClose={handleCloseModal}
                message={message}
            />
        </>
    );

}
