import axios from 'axios';
//const api = process.env.REACT_APP_API_URL
const api = 'https://voenkomat.by/war/api'
// const api = 'http://192.168.0.160:8000/api'

const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsInVzZXJuYW1lIjoiYWRtaW4iLCJleHAiOjE1OTE3MTE4NjF9.TiAdS2LdKvsGtSuR3Py23X42XOthXL6ZrcVXv4-Wlg4'
const token2 = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsInVzZXJuYW1lIjoiYWRtaW4iLCJleHAiOjE1OTIzMDc4NzZ9.ZAlwzZecwAxl3NmbT8RlnI8AnjUWSKOH4PT1ncApe6g'

export default class WrapperAxios {

    post(url: string, data: any) {
        return axios.post(`${api}/${url}`, data, { headers: { Authorization: localStorage.getItem('token') } });
    
        //return axios.post(`${api}/${url}`, data, { headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsInVzZXJuYW1lIjoiYWRtaW4iLCJleHAiOjE1OTE3MTE4NjF9.TiAdS2LdKvsGtSuR3Py23X42XOthXL6ZrcVXv4-Wlg4' } });
    }

    get(url: string) {
        return axios.get(`${api}/${url}`, { headers: { Authorization: localStorage.getItem('token') } })
    }
    //,  { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }

    put(url: string, data: any) {
        return axios.put(`${api}/${url}`, data, { headers: { Authorization: localStorage.getItem('token') } })

    }

    patch(url: string, data: any) {
        return axios.patch(`${api}/${url}`, data, { headers: { Authorization: localStorage.getItem('token') } })
    }

    delete(url: string, data: any) {
        return axios.delete(`${api}/${url}`, { data: data, headers: { Authorization: localStorage.getItem('token') } })
    }

}
