import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import HistoryOnePropsModal from './HistoryOnePropsModal';

const formatDate = (date: string) => {
    const d = new Date(date);

    const day = d.getDate();
    const month = d.getMonth() + 1;
   const hour=d.getHours();
   const minute=d.getMinutes();

    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear() + ' г. '
    + (hour < 10 ? '0' + hour : hour) + ' : ' + (minute < 10 ? '0' + minute : minute)
    ;

    return result;
};

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    row: {
        cursor: 'pointer'
    }
});

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}

//const open

export default function HistoryAllPropsTable({ items, ...props }: any) {
    const classes = useStyles();

    const [openHistoryOneModal, setOpenHistoryOneModal] = React.useState(false);
    const [itemsHistoryOne, setItemsHistoryOne] = useState([] as any);
    const [nameField, setNameField] = useState('');

    const handleOpenHisoryOneModal = () => {
        setOpenHistoryOneModal(true);
    };
    const handleCloseHistoryOneModal = () => {
        setOpenHistoryOneModal(false);
    };

    const openModal = (items: any, name : string) => {
        setItemsHistoryOne(items);
        setNameField(name)
        handleOpenHisoryOneModal();
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Название поля</TableCell>
                            <TableCell align="right">Дата изменения</TableCell>
                            <TableCell align="right">Текущее значение</TableCell>
                            <TableCell align="right">Предыдущее значение</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item: any) => (
                            <TableRow key={getRandomInt(64532)} className={classes.row} onClick={() => { openModal(item.about, item.name) }}>
                                <TableCell component="th" scope="row">
                                    {item.name}
                                </TableCell>
                                <TableCell align="center">{formatDate(item.data)}
                                {/*<br/>*/}
                                {/*    {new Date(item.data).getHours()+ ":" + new Date(item.data).getMinutes()}*/}
                                </TableCell>
                                <TableCell align="center">{item.сurrent}</TableCell>
                                <TableCell align="center">{item.previous}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <HistoryOnePropsModal
                open={openHistoryOneModal}
                handleClose={handleCloseHistoryOneModal}
                items={itemsHistoryOne}
                nameField={nameField}
            />
        </>
    );
}