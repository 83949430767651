import React, {useEffect, useState} from 'react';
import PeopleTable from '../components/MilitaryBaseTable/TableAllUsers'
import Button from '@material-ui/core/Button';
import Axios from '../utils/axios';
import { Link } from 'react-router-dom';


function MilitaryBase(props: any) {
    const [countTotal, setCountTotal] = React.useState(0);
    const [countFired, setCountFired] = React.useState(0);
    const [deregistered, setCountDeregistered] = React.useState(0);

    useEffect(() => {
        const axios = new Axios();
        axios.get(`worker/fired/${localStorage.getItem('id')}`)
            .then(res => {
                setCountTotal(res.data.total);
                setCountFired(res.data.fired);
setCountDeregistered(res.data.deregistered)
            })
    }, []);

    // @ts-ignore
    return (<>
        <div className="container_militaryBase">
            <div className='documents-box1'>
                <div className='militaryBase-toolbar'>
                    <div>

                    </div>
                    <div>
                        <p>Всего на текущем учёте: <b>{countTotal-deregistered}</b></p>
                        <p>Снято с учёта: <b>{deregistered}</b>, из них уволено: <b>{countFired}</b> </p>
                        <p style={{marginTop: '-22px',fontSize:'14px'}}>(выделены фоном в конце списка)</p>
                    </div>
                </div>

                <div className='btn-wrapper'>
                    <div className='btn-wrapper'>
                    <Link to ='/home'>   <Button variant="contained">
                            Назад
                        </Button>
                    </Link>

                    </div>

                </div>
            </div>

            <div className='documents-box2'>
                <span>Вход в личную карточку - нажатием на поле ФИО</span>
                <span className={'title_military_base_list'}>База военнообязанных и призывников организации</span>
                <PeopleTable countFired={countFired} />
            </div>
        </div>
        </>
    )
}

export default MilitaryBase;
