import React from 'react';
import Button from "@material-ui/core/Button";

const Forms = (props: any) => {
    return (
        <div style={{marginTop:'225px'}}>
            <div className='btn-wrapp'>
                <span className='title_letters'>Стандартные формы</span>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={() => props.history.push('/letters')} >
                        Назад
                    </Button>
                </div>
                <div className='btn-letter'>
                    <Button variant="contained" className={'btn'} onClick={() => props.history.push('/voucher')}>Расписка</Button>
                </div>
                <div className='btn-letter'>
                    <Button variant="contained" className={'btn'} onClick={() => props.history.push('/journal')}>Журнал сверок</Button>
                </div>
                <div className='btn-letter'>
                    <Button variant="contained" className={'btn'} onClick={() => props.history.push('/registration_forms')}>Приходно-расходная книга по учёту бланков</Button>
                </div>
            </div>
        </div>
    )
}
export default Forms;
