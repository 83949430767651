export const ADDRESS_DATA = "ADDRESS-DATA";


const initialState = {
    addressData:[]
}

const addressesReducer = (state = initialState, action: ReducerActionsTypes) => {
    switch (action.type) {

        case ADDRESS_DATA:
            return {
                ...state,
                addressData: action.addressData
            };
    }
    return state;
}

type ReducerActionsTypes =
    IGetAddressData

export interface IGetAddressData {
    type: typeof ADDRESS_DATA,
    addressData: any[],
}
export const getDataAddressAC = (addressData: any[]) => {
    return {type: ADDRESS_DATA, addressData}
};



export default addressesReducer;
