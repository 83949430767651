import React, { useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { FileUploader } from '../../FileUploader/FileUploader'
import classes from '../../Documents/Documents.module.css';
import Axios from '../../../utils/axios'

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },

    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);



export default function UploaderModal(props: any) {

    const [file, setFile] = useState<any>('');

    const submit = () => {

        let body = new FormData();
        body.append("file", file);

        const axios = new Axios();
        axios.post(`worker/file/${String(localStorage.getItem('id'))}`, body)
            .then(res => {
                props.setIsUploaded(true);
                props.handleClose();
            })
            .catch(err => {
                console.log(err);
            })
    }


    return (
        <>
            <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open} maxWidth='xl'>
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    Добавить личную карточку
                </DialogTitle>
                <DialogContent dividers>
                    <div className={classes.body}>
                        <div className={classes.fileUpload}>
                            <FileUploader setFile={setFile} />
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={submit} variant="contained" color="primary">
                        Добавить
                    </Button>
                    <Button autoFocus onClick={props.handleClose} color="primary">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}