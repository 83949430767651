import { LOADED_COMPANY } from '../types'

const initialState = {
    company: <any>[]
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case LOADED_COMPANY:
            return {
                ...state,
                company: action.company
            };
        default:
            return state;
    }
}