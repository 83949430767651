import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DecreeTable from './DecreeTable';
import NewDecreeModal from '../NewDecreeModal/NewDecreeModal'



const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function DecreeModal(props: any) {
    const [openNewModal, setOpenNewModal] = React.useState(false);

    const handleClickOpen = () => {
        setOpenNewModal(true);
    };
    const handleClose = () => {
        setOpenNewModal(false);
    };


    return (
        <div>
            <Dialog
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth='xl'
            >
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    Приказ
                </DialogTitle>
                <DialogContent dividers>
                    <DecreeTable
                        isUpdate={openNewModal} />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClickOpen} color="primary">
                        Добавить ответственного
                    </Button>
                    <Button autoFocus onClick={props.handleClose} color="primary" variant="contained">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            < NewDecreeModal
                open={openNewModal}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                id={null}
            />
        </div>
    );
}