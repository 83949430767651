import React from 'react';
import './LandingPage.css';
// import star from '../LandingPage/assets/images/star.svg';
// import Link from "@material-ui/core/Link";
import note from '../assets/images/note.png';
import { NavLink } from 'react-router-dom';




const BodyDescription = () => {

    const selfAccount=[
        'Воинский учет ведет штатный сотрудник вашей организации, назначенный приказом или директор',
        'Оказываем консультационную поддержку',
        'Гарантируем соответствие текущего законодательства для всех форм',
        'Ответственность за данные на Вас'
    ]
    const profAccount=[
        'Воинский учет ведет рекомендованный нами профессионал',
        'Ответственность за замечания и нарушения несем мы',
        'Взаимодействия с военкоматами берем на себя',
        'Проведение проверок - силами нашего профессионала'
    ];



    // @ts-ignore
    return (<>

            <div className='wrapper_description'>
                <span> Сопровождение воинского учета организаций Республики Беларусь</span>
            </div>
            <div className='wrapper_description_mobile'>
                Сопровождение воинского учета организаций РБ
            </div>
            <div className='wrapper_description_1'>
                <span> Сопровождение воинского учета организаций РБ</span>
            </div>
            <div className='wrapper_description_note'>
                <span>Доверьте сопровождение воинского учета нашему облачному сервису и значительно сократите расходы на его ведение</span>
            </div>
            <div>
                <div className={'wrapLicenceBtns'}>
                    <div style={{ width:'669px'}} className={'wrapLicenceBtns-child'}>
                        <span style={{marginLeft: '47px'}}>Вы сами ведете воинский учет<br/> с помощью нашего сервиса</span>
                    <div className={'wrap-description-licence'} >
                        {selfAccount.map((i:string,index:number)=>{
                            return <p key={index} className={'licence-items'}>
                                <img src={note} alt={''}/> {i}
                            </p>
                        })}
                    </div>
                    <a href="/#our_work" className={'know-match_'}
                                >Узнать больше</a>
                    </div>

                    <div style={{width:'507px'}} className={'wrapLicenceBtns-child_two'}>
                        <span className='ff' style={{marginRight: '37px'}}>Мы ведем ваш воинский учет<br/> с помощью нашего сервиса</span>
                        <div className={'wrap-description-licence'} >
                            {profAccount.map((i:string,index:number)=>{
                                return <p key={index} className={'licence-items-two'}>
                                    <img src={note} alt={''}/>{i}</p>
                            })}
                        </div>
                        <a href="/#our_work" className={'know-match_'}
                           >Узнать больше</a>
                    </div>
                    <NavLink className={'go-to-registration-organization_btnc'} to={'/our_contacts'}
                    onClick={()=>{window.scrollTo(0,0)}}>
                        Перейти к регистрации в сервисе
                    </NavLink>
                </div>
            </div>


            {/*<div className='list_description'>*/}
            {/*    <div className='possibility_list'>*/}
            {/*        <img alt='' src={star}/> <span*/}
            {/*        className='list_point'>Многократное снижение затрат на ведение ВУ</span>*/}
            {/*    </div>*/}
            {/*    <div className='possibility_list'>*/}
            {/*        <img alt='' src={star}/> <span className='list_point_1'>Полное избавление от штрафов за ошибки в ведении ВУ</span>*/}
            {/*    </div>*/}
            {/*    <div className='possibility_list'>*/}
            {/*        <img alt='' src={star}/> <span className='list_point_2'>Подробная актуальная инструкция по работе с сервисом</span>*/}
            {/*    </div>*/}
            {/*    <div className='possibility_list'>*/}
            {/*        <img alt='' src={star}/> <span className='list_point_3'>Возможность полного ведения ВУ нашим специалистом</span>*/}
            {/*    </div>*/}

            {/*</div>*/}
        </>
    )
}
export default BodyDescription;
