import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Axios from '../../utils/axios';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ClearIcon from '@material-ui/icons/Clear';


import './RegulationsTable.css';

type Order = 'asc' | 'desc';

interface Column {
    id: 'address' | 'name' | 'id';
    label: string;
    minWidth?: number;
    align?: 'center';
}
/*
const columns: Column[] = [
    {
        id: 'id',
        label: '#',
    },
    {
        id: 'name',
        label: 'Наименование документа',
    },
      {
          id: 'address',
          label: 'Адрес'
      },

];
*/



const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        //maxHeight: '90vh',
        height: 'calc(100vh - 260px)'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    linkTypeReport: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    btnUpdate: {
        marginLeft: '10px'
    }
});

const getNameDoc = (str: string) => {
    if (str === 'null') {
        return '';
    }
    const index = str.lastIndexOf('/');
    const name = str.substring(index + 1);
    return decodeURI(name);
};

export default function RegulationsTable({ items, setPage, setRowsPerPage, page, setCountItems, rowsPerPage, countItems, columns, orderBy, order, setOrder, setOrderBy }: any) {
    const classes = useStyles();

    /*const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState('full_name');
    */



    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const _orderBy = (id: any) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, id);
        const isAsc = orderBy === id && order === 'asc';
        setOrderBy(id);
        setOrder(isAsc ? 'desc' : 'asc');
       /* let order_ = '';
        if (order === 'desc') {
            order_ = '-'
        }
        let data = null as any;

        if (id === 'name') {
            data = {
                "type__name": "Нормативный документ",
                order: `${order_}docs`,
                "docs__contains": containsValue,
                subtype_id: valueDocumentType
            }
        } else {
            data = {
                "type__name": "Нормативный документ",
                order: `${order_}${id}`,
                "docs__contains": containsValue,
                subtype_id: valueDocumentType
            }
        }

        const axios = new Axios();
        axios.post(`worker/report/${localStorage.getItem('id')}/filter/?page=${page + 1}&page_size=${rowsPerPage}`, data)
            .then(res => {
                setItems(res.data.results);
                setCountItems(res.data.count);
                //props.setPage(0);
            })
            .catch(err => {
                console.log(err);
            })*/
    };

    const handleDelete = (id: number) => {
        const axios = new Axios();
        axios.delete(`worker/report/${localStorage.getItem('id')}/${id}/`, {})
            .then(res => {
                //  console.log(res.data);

            })
            .catch(err => {
                console.log(err);
            })
        setCountItems(countItems - 1);
    }

    return (
        <div style={{width:'100%'}}>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns ? columns.map((column: any) => {
                                    if (column.isSelected)
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.id === 'id' ?
                                                    "№" :
                                                    <TableSortLabel
                                                        active={orderBy === column.id}
                                                        direction={orderBy === column.id ? order : 'asc'}
                                                        onClick={_orderBy(column.id)}
                                                    >
                                                        {column.label === 'docs' ? 'Документ' : column.label}
                                                        {
                                                            orderBy === column.id ? (
                                                                <span className={classes.visuallyHidden}>
                                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </span>
                                                            ) : null
                                                        }
                                                    </TableSortLabel>
                                                }
                                            </TableCell>
                                        )
                                }) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row: any, number: number) => {
                                //console.log('roww', row);

                                return (
                                    <TableRow hover className='icon' role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns ? columns.map((column: any) => {
                                            const columnID = column.id;
                                            const value = row[column.id];
                                            if (column.isSelected) {
                                                if (columnID === 'id') {
                                                    return  <TableCell key={column.id} align={column.align} className='icon'>
                                                        {
                                                            number + page * 10 + 1
                                                        }

                                                        {
                                                            localStorage.getItem('userID')=="1" ? <div className='delete_icon' onClick={() => { handleDelete(row.id) }}>
                                                                <ClearIcon fontSize="small" />
                                                            </div>  : null}

                                                    </TableCell>
                                                }
                                                if (columnID === 'docs') {
                                                    return <TableCell key={column.id} align={column.align}>
                                                        <a className={classes.linkTypeReport} href={row.docs} download={row.type.name + row.type.id}>
                                                            {getNameDoc(String(row.docs.replace(/_/gi, ' ')))}
                                                        </a>
                                                    </TableCell>
                                                }
                                                if (columnID === 'subtype') {
                                                    return <TableCell key={column.id} align={column.align}>
                                                        {row.subtype}
                                                    </TableCell>
                                                }

                                            }
                                        }) : null}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    labelRowsPerPage='Показывать по:'
                    backIconButtonText='Предыдущие'
                    nextIconButtonText='Следующие'
                    labelDisplayedRows={({ from, to, count }: any) => `${from}-${to} из ${count}`}
                    count={countItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>

        </div>
    );
}


// <Checkbox id={String(row.id)} disabled icon={<DoneIcon />} />
