import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatDate = (date: string) => {
    const d = new Date(date)

    const arr = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + ' ' + (arr[month - 1]) + ' ' + d.getFullYear() + ' г.';

    return result;
}

const getData = (items: any) => {

    let result = [
        [{ text: '№ п/п', bold: true ,fontSize: 10, margin: [0, 5, 0, 5] },
            { text: 'Воинское звание, ВУС и код должности, номер военного билета', bold: true ,fontSize: 10, margin: [0, 5, 0, 5] },
            { text: 'Фамилия, имя, отчество, идентификационный номер, номер паспорта, дата выдачи', bold: true, fontSize: 10, margin: [0, 5, 0, 5] },
            { text: 'Дата рождения', bold: true, margin: [0, 5, 0, 5] },
            { text: 'Категория запаса (для ПСС)', bold: true, margin: [0, 5, 0, 5] },
            { text: 'Образование (наименование учреждения образования, год окончания, специальность по диплому)', bold: true, margin: [0, 15, 0, 5] },
            { text: 'Дата приема на работу Должность', bold: true, margin: [0, 15, 0, 5] },
            { text: 'Регистрация по месту жительства, по месту пребывания, телефон', bold: true, margin: [0, 15, 0, 5] },
            { text: 'Семеное положение', bold: true, margin: [0, 15, 0, 5] },
            { text: `Предназначен в команду`, bold: true, margin: [0, 5, 0, 5] },
        ]
    ]
    if (items) {
        items.map((item: any, number: number) => {
            result.push([
                {text: `${number + 1}`, alignment: 'center', margin: [0, 5, 0, 0],verticalAlign:'middle'},
                {text: `${item.accountg_id == 4 ? 'Призывник' : item.grade} \n  ${item.wyc ? item.wyc : ''} `, alignment: 'center'},
                {text: `${item.full_name ? item.full_name :''} \n  ${item.pass_number ? item.pass_number  : ''}\n  ${item.passport ? item.passport  : ''}\n  ${item.pass_date ? item.pass_date  : ''} `, alignment: 'center'},
                {text: `${item.date_of_birth ? item.date_of_birth.split('-').reverse().join('.') : ''}`, alignment: 'center'},
                {text: `${item.accountg}`, alignment: 'center'},
                {
                    text: `
                    ${item.education ? item.education : ''}
                    ${item.education_full ? item.education_full + ',' : ''}
                    ${item.edu_specialization ? item.edu_specialization + ',' : ''}
                    ${item.edu_qualification ? item.edu_qualification : ''}
                    ${item.edu_dop_full ? item.edu_dop_full : ''}
                    ${item.edu_dop_specialization ? item.edu_dop_specialization : ''}`
                    ,
                    alignment: 'center'
                },
                                {
                    text: `${item.position ? item.position : item.education}
                     ${item.add_military ? `Дата приёма: ${item.add_military.split('-').reverse().join('.')}` : ''}
                      ${item.order_military ? 'Приказ № ' + item.order_military : ''} 
                      ${item.date_add_military ? 'от ' + item.date_add_military.split('-').reverse().join('.') : ''}
                     `, alignment: 'center', wordWrap: 'break-word'
                },
                {text: `
                ${item.registered_address ? item.registered_address+'\n(Регистрация по месту жительства )' :''}
                ${item.address ? item.address+'\n(Регистрация по месту пребывания)' : ''}
                ${item.home_phone ? 'дом. ' + item.home_phone + ',' : ''} 
                 ${item.work_phone ? 'рабоч. ' + item.work_phone : ''}
                 ${item.phone ? 'моб. ' + item.phone : ''}
                `, alignment: 'center'},
                {text: ` ${item.marital_status ?item.marital_status : ''}
                ${item.family_half && item.family ? `Состав семьи: \n супруга (супруг): \n ${ item.family_half},\n ${item.family}` :
                               !item.family_half && item.family ? `Состав семьи: \n${item.family}` :
                                   item.family_half && !item.family ? `Состав семьи: \n супруга (супруг): \n ${item.family_half}` : ''}  `, alignment: 'center'},
                {text: `${!item.special ? "-" : item.specialN }`, alignment: 'center',verticalAlign:'middle'},
                {text: `${item.address ? item.address : ''} \n ${item.phone ? 'моб. ' + item.phone : ''},`, alignment: 'center'},
            ] as any)
        })
    }
    return result;
};

const ListCorrectLetter = ({ 
    office, 
    date, 
    number, 
    address,
    special, 
    items, 
    typeCity, 
    dataYear, 
    name, 
    phone, 
    company, 
    setFile, 
    fullAddress, 
    organizationName, 
    addressCompany,
    organizationRespPerson,
    organizationPositionResp,
    shortOffice,
    organizationPhone }: any) => {

    let nameResponsible=  organizationRespPerson ? organizationRespPerson.split(' ')[1] && organizationRespPerson.split(' ')[2] && organizationRespPerson.split(' ')[0] ?
        organizationRespPerson.split(' ')[1][0] + '.' + organizationRespPerson.split(' ')[2][0] + " " + organizationRespPerson.split(' ')[0]
        : '' : organizationRespPerson

    const card = {
        pageOrientation: 'landscape',
        pageSize: 'A3',
        footer: function (currentPage: any, pageCount: any) {
            if (pageCount > 1) {
                return {
                    margin: [10, 0, 40, 15],
                    table: {
                        widths: [160, '*', 80],
                        body: [
                            [{}, {},
                                {
                                    alignment: 'right',
                                    fontSize: 10,
                                    lineHeight: 1.1,
                                    text: `лист ${currentPage.toString()} из  ${pageCount}`,
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                }
            }
        },
        content:  [
            {
                text: `Военному комиссару \n ${shortOffice.indexOf('ий') !==-1 ? shortOffice.replace(/ий/gi, 'ого') :
                    shortOffice.indexOf('ый') !==-1 ? shortOffice.replace(/ый/gi, 'ого') : shortOffice.replace(/ой/gi, 'ого')} `,
                margin: [830, 5, 0, 0],
                fontSize: 11,
                bold: true,
                lineHeight: 1,
            },
            {
                text: ` ${fullAddress.address}`,
                margin: [830, 0, 0, 0],
                fontSize: 10
            },
            {
                margin: [0, 0, 0, 0],
                columns: [
                    {
                        width: '*',
                        alignment: 'left',
                        fontSize: 10,
                        lineHeight: 1.1,
                        text: [
                            {text: 'СПИСОК\n', fontSize: 13, bold: true},
                            {
                                text:[
                                    {text: `военнообязанных и призывников, работающих в  ${organizationName} \n `, fontSize: 12, bold: true },
                                    {text: `для сверки учетных сведений о воинском учете, содержащихся в личных карточках, \n`, fontSize: 12, bold: true}
                                ],
                                bold: true,
                            },
                            { text: `Адрес организации: ${addressCompany} \n`, fontSize: 12, bold: true,},
                            { text: `Ответственный за ведение воинского учета: ${organizationRespPerson} \n`,fontSize: 12,  bold: true,},
                            { text: `(тел. ${organizationPhone})`, fontSize: 12, bold: true,},
                        ],
                        margin: [0, 0, 90, 25]
                    },
                ]
            },
            {
                fontSize: 10,
                alignment: 'left',
                table: {
                    widths: [20, 70, 100, 110, 60, 80, 80, 80, 110, 105, 100, 90],
                    headerRows: 1,
                    body: getData(items),
                    dontBreakRows: true,
                }
            },
            {
                width: '*',
                text: `Примечание. Дата проведения сверки согласно выписки из плана сверки личных карточек призывников и военнообязанных с учетными
                данными военных комиссариатов районов города Минска ${!typeCity ? `________` : typeCity}202${!dataYear ? `__`: dataYear} г.` ,
                fontSize: 10,
                lineHeight: 1,
                margin: [0, 10, 0, 0]
            },
            {
                margin: [0, 10, 0, 0],
                text: [
                    {
                        width: '*',
                        alignment: 'left',
                        fontSize: 10,
                        lineHeight: 1.1,
                        text:`Ответственный за ведение воинского учета                                                        ${organizationRespPerson}`,
                        margin: [0, 0, 0, 0]
                    },
                ]
            },
        ]
    }
    

    const file = pdfMake.createPdf(card as any);
    file.download('ListCorrectLetter.pdf');
    file.getBuffer((buffer) => {
        const blob = new Blob([buffer]);
        setFile(blob);
    });

};
export default ListCorrectLetter;
