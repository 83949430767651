import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import './WorkPlan.css';
import WorkPlanPDF from '../../components/PDFOther/WorkPlanPDF';
import AutocompleteOffice from '../../components/AutocompleteOffice/AutocompleteOffice';
import WorkPlanDOXC from '../../components/DOCXOther/WorkPlanDOCX'


const getDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;

    return today.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
}

const WorkPlan = (props: any) => {
    const nameResponsible = `${(localStorage.getItem('resp-for-documents') as string).split(' ')[2][0]}.${(localStorage.getItem('resp-for-documents') as string).split(' ')[1][0]}. ${(localStorage.getItem('resp-for-documents') as string).split(' ')[0]} `;

    const [name1, setName1] = useState('');
    const [date1, setDate1] = useState(getDate());
    const [date2, setDate2] = useState(getDate());

    const [name2, setName2] = useState('');
    const [position2, setPosition2] = useState('');

    const [name3, setName3] = useState(nameResponsible);
    const [position3, setPosition3] = useState('');

    const company = localStorage.getItem('company');
    const [office, setOffice] = useState('');
    const [year, setYear] = useState('');

    const [city, setCity] = useState('');

    const handleChangeOffice = (event: any) => {
        setOffice(event.name as string);
    };

    const save = (type: string) => {
        const data = {
            name1,
            name2,
            position2,
            position3,
            name3,
            date1,
            company,
            office,
            year,
            date2,
            nameResponsible,
            city
        }

        if (type === 'pdf') {
            WorkPlanPDF(data);
        } else {
            WorkPlanDOXC(data);
        }


    };



    return (
        <div style={{margin:'216px auto 10px auto', width:'98%'}}>
            <span className='title_letters'>План работы по ведению воинского учёта призывников и военнообязанных</span>
            <div className='toolbar'>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={() => props.history.push('/letters')} >
                        Назад
                    </Button>
                </div>
            </div>

            <span className='plan-work'>Заполните пустые поля: </span>
            <div className='wrapperWorkPlan'>
                <div className='header'>
                    <div className='wrapp-header'>
                        СОГЛАСОВАНО
                        <br />
                        Военный комиссар
                        <br />
                        <AutocompleteOffice
                            handleChangeOfficeName={handleChangeOffice}
                            setOfficeName={setOffice}
                        />
                        <input type="text" value={name1} onChange={(e: any) => setName1(e.target.value)} className='name-input' placeholder='Должность, ФИО' />
                        <input type="date" id="start"
                            value={date1}
                            min="2017-01-01" max="2022-12-31"
                            onChange={(e: any) => setDate1(e.target.value)}
                            className='number-input'
                        ></input>
                    </div>
                    <div className='wrapp-header'>
                        УТВЕРЖДАЮ
                        <br />
                        <input type="text" value={position2} onChange={(e: any) => setPosition2(e.target.value)} className='name-input' placeholder='Должность' />
                        {company}
                        <input type="text" value={name2} onChange={(e: any) => setName2(e.target.value)} className='name-input' placeholder='ФИО' />
                        <input type="date" id="start"
                            value={date2}
                            min="2017-01-01" max="2022-12-31"
                            onChange={(e: any) => setDate2(e.target.value)}
                            className='number-input'
                        ></input>
                    </div>
                </div>
                <div className='body'>
                    <div className='workPlan-body-title'>
                        <b>ПЛАН
                        <br />
                        работы по ведению воинского учёта  военнообязанных и призывников в<br />  {company}<br />
                        на <input type="text" value={year} onChange={(e: any) => setYear(e.target.value)} className='input-small' /> году<br />
                        </b>
                    </div>

                    <div className='table'>
                        <table className="tg">
                            <thead>
                                <tr className='table-header'>
                                    <th className="tg-1">№ <br />п/п</th>
                                    <th className="tg-1">Наименование мероприятий</th>
                                    <th className="tg-1">Ответственный за исполнение</th>
                                    <th className="tg-1">Срок исполнения</th>
                                    <th className="tg-1">Отметка о выполнении</th>
                                </tr>
                                <tr >
                                    <th className="tg-1">1</th>
                                    <th className="tg-1">2</th>
                                    <th className="tg-1">3</th>
                                    <th className="tg-1">4</th>
                                    <th className="tg-1">5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td className="tg-2">1</td>
                                    <td className="tg-2">Ведение персонально-первичного воинского учёта по личным карточкам учёта</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">Постоянно</td>
                                    <td className="tg-2"></td>
                                </tr>
                                <tr >
                                    <td className="tg-2">2</td>
                                    <td className="tg-2">                                 
                                    Комплектование и ведение картотеки из личных карточек граждан по следующим разделам:<br/>
                                    1. Личные карточки на офицеров;<br/>
                                    2. Личные карточки на прапорщиков, сержантов, старшин и солдат запаса;<br/>
                                    3. Личные карточки на военнообязанных женского пола;<br/>
                                    4. Личные карточки на призывников.</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">Постоянно</td>
                                    <td className="tg-2"></td>
                                </tr>
                                <tr >
                                    <td className="tg-2">3</td>
                                    <td className="tg-2">Формирование папки «Документы по воинскому учёту».</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">Постоянно</td>
                                    <td className="tg-2"></td>
                                </tr>
                                <tr >
                                    <td className="tg-2">4</td>
                                    <td className="tg-2">Ведение служебного делопроизводства (отдельного дела) по вопросам ведения воинского учёта военнообязанных в организации.</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">Постоянно</td>
                                    <td className="tg-2"></td>
                                </tr>
                                <tr >
                                    <td className="tg-2">5</td>
                                    <td className="tg-2">Проверка наличия учётно-воинских документов у военнообязанных, принимаемых на работу. Оформление личных карточек и заполнение раздела «Сведения о воинском учёте».</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">Постоянно</td>
                                    <td className="tg-2"></td>
                                </tr>
                                <tr >
                                    <td className="tg-2">6</td>
                                    <td className="tg-2">Учёт изменений, касающихся семейного положения, состава семьи, образования, должности (специальности), места жительства (регистрации) граждан и письменное сообщение о них в РВК. Внесение изменений в личные карточки учёта.</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">Постоянно</td>
                                    <td className="tg-2"></td>
                                </tr>
                                <tr >
                                    <td className="tg-2">7</td>
                                    <td className="tg-2">По требованию военного комиссариата оповещать военнообязанных о вызовах в военный комиссариат.</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">Постоянно</td>
                                    <td className="tg-2"></td>
                                </tr>
                                <tr >
                                    <td className="tg-2">8</td>
                                    <td className="tg-2">Обеспечивать военнообязанным возможность своевременной явки по повесткам в военный комиссариат.</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">Постоянно</td>
                                    <td className="tg-2"></td>
                                </tr>
                                <tr >
                                    <td className="tg-2">9</td>
                                    <td className="tg-2">Своевременное (согласно графика военного комиссариата района) представление в райвоенкоматы г. Минска личных карточек для сверки с учётными документами райвоенкоматов.</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">1 раз в 2 года</td>
                                    <td className="tg-2"></td>
                                </tr>
                                <tr >
                                    <td className="tg-2">10</td>
                                    <td className="tg-2">Направление сведений (отчёта) о численности призывников и военнообязанных.</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">к 1 октября</td>
                                    <td className="tg-2"></td>
                                </tr>
                                <tr >
                                    <td className="tg-2">11</td>
                                    <td className="tg-2">Сообщение в РВК о всех призывниках и военнообязанных, принятых на работу или уволенных с работы.</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">В месячный срок</td>
                                    <td className="tg-2"></td>
                                </tr>
                                <tr >
                                    <td className="tg-2">12</td>
                                    <td className="tg-2">Проведение другой текущей работы, изучение руководящих документов.</td>
                                    <td className="tg-2">Отв. за ведение ВУ</td>
                                    <td className="tg-2">Постоянно</td>
                                    <td className="tg-2"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='footerWorkPlan'>
                        <div>
                            Ответственный за ведение воинского учета                 
                        </div>
                        <div>

                        </div>
                        <div>
                            <input type="text" value={name3} onChange={(e: any) => setName3(e.target.value)} className='name-input' placeholder='ФИО' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='btn-footer'>
                <div className='btn-pdf'>
                    <Button variant="contained" onClick={e => save('pdf')} color="primary">
                        Сохранить PDF
                    </Button>
                </div>

                <Button variant="contained" onClick={e => save('docx')} color="primary">
                    Сохранить DOCX
                </Button>
            </div>

        </div>
    )

}
export default WorkPlan;
