import React from 'react';
import Button from "@material-ui/core/Button";
import style from "./Forms.module.css";

class RegistrationTitle extends React.Component<any> {
    render() {
        return (
            <div style={{marginTop: '225px'}}>
                <span className='title_letters'>Приходно-расходная книга по учету бланков строгой отчетности</span>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={() => this.props.history.push('/forms')}>
                        Назад
                    </Button>
                </div>
            </div>
        )
    }
};

class FormForPrint extends React.Component<any> {

    render() {

        return (
            <div style={{marginTop: '76px',height: '1906px'}}>
                <div className={style.wrapper_}>
                    <div className={style.card}>
                        <div className={style.cardTitle}>
                            <span>ПРИХОДНО-РАСХОДНАЯ КНИГА</span><span> по учету бланков строгой отчетности </span>
                        </div>
                        <div className={style.cardTitleInput}>
                            <input/>
                            <span>( организации )</span>
                            <input/>
                            <span>( адрес )</span>
                            <input/>
                            <span>( фамилия, собственное имя, отчество руководителя ) </span>
                            <input/>
                            <span>( работник, отвечающий за воинский учет )</span>
                        </div>
                    </div>
<div className={style.wrapTablePrint}>
                    <div className={style.wrapperTitleInput}>
                        <input/>
                        <span>(наименование бланков)</span>
                    </div>
                    <table className={style.Card_2} cellSpacing="0">
                        <tr>

                            <td rowSpan={3}>№
                                п\п
                            </td>
                            <td rowSpan={3} style={{width:'98px'}}>Дата
                                записи
                                операции
                            </td>
                            <td rowSpan={3} style={{width:'130px'}}>От кого
                                получено и кому
                                выдано,
                                основание
                                (наименование
                                документа,
                                номер и дата)
                            </td>
                            <td colSpan={6}>Движение</td>
                            <td rowSpan={3} style={{width:'119px'}}>Подпись
                                лица,
                                получившего
                                бланки
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>приход</td>
                            <td colSpan={2}>расход</td>
                            <td colSpan={2}>остаток</td>
                        </tr>
                        <tr>
                            <td>коли-
                                чество
                            </td>
                            <td>серия и номер</td>
                            <td>коли-
                                чество
                            </td>
                            <td>серия и номер</td>
                            <td>коли-
                                чество
                            </td>
                            <td>серия и номер</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                            <td>10</td>
                        </tr>
                        <tr style={{height:'1000px'}}>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                        </tr>
                    </table>
</div>
                </div>
            </div>
        )
    }
}

class RegistrationForm extends React.Component<any> {
    state={
        isPrint:false
    }
    printDocument=()=>{
        this.setState({isPrint: true})
        setTimeout(()=>{window.print()},2000)
        setTimeout(()=>{this.setState({isPrint: false})},5000)
    }

    render() {

        return (<>
                {!this.state.isPrint && <RegistrationTitle {...this.props} />}
                <FormForPrint/>
                <div style={{textAlign:'center'}}>
                    {!this.state.isPrint && <Button variant="contained" onClick={this.printDocument} style={{marginBottom:'40px'}} >Распечатать</Button>}
                </div>
            </>
        )
    }
}

export default RegistrationForm;
