
import React from "react";
import {Document, Page, StyleSheet, Image, View, Font, Text} from "@react-pdf/renderer";
import font_1 from "../../fonts/Rubik/static/Rubik-Light.ttf";
import font_2 from "../../fonts/Rubik/static/Rubik-Bold.ttf";

Font.register({
    family: "Rubik",
    src: font_1,
    format: "truetype",
});
Font.register({
    family: "Rubik",
    src: font_2,
    format: "truetype",
});

const styles = StyleSheet.create({
    page: {
        fontFamily: "Rubik",
        fontSize: 12,
        paddingBottom: 10,
        marginTop: 30
    },
    textTitle: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 10,
    },
    section_1: {
        padding: 20
    },
    section_2: {
        padding: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    addresses: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%'
    }
})
const CompanyPDF = (form: any) => {

    function returnSrc() {
        // @ts-ignore
        let path = form.dataObj.logotip;
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        let url = proxyurl + path;
        const selectedMethod = 'GET' as const;
        return {
            uri: url,
            method: selectedMethod,
            body: '',
            headers: {'Access-Control-Allow-Origin': '*', withCredentials: true}
        };
    }

    return (
        <Document>
            <Page style={styles.page}>
                <Text style={styles.textTitle}>Основные данные {form.dataObj.short_organization_name}</Text>
                <View style={styles.section_1}>
                    <Text>УНП: {form.dataObj.unp_inn}</Text>
                    <Text>ОКПО: {form.dataObj.rcbo_psrn}</Text>
                    <Text>Полное юридическое название: {form.dataObj.full_legal_name}</Text>
                    <Text>Краткое название организации: {form.dataObj.short_organization_name}</Text>
                    <Text>Форма собственности: {form.dataObj.form_of_ownership}</Text>
                    <Text>Численность штатных сотрудников на январь отчётного года: {form.dataObj.quantity}</Text>
                    <Text>Число военнообязанных и призывников: {form.dataObj.quantity_war}</Text>
                </View>
                <Text style={styles.textTitle}>Контактные данные организации</Text>
                <View style={styles.section_1}>
                    <Text>Телефон: {form.dataObj.organization_phone}</Text>
                    {form.dataObj.organization_fax !=='null' && form.dataObj.organization_fax !=='' && <Text>Факс: {form.dataObj.organization_fax}</Text> }
                    {form.dataObj.organization_website !=='null' && form.dataObj.organization_website !=='' &&
                    <Text>Веб-сайт: {form.dataObj.organization_website}</Text>}
                    <Text>E-mail: {form.dataObj.organization_mail}</Text>
                </View>
                <View style={styles.addresses}>
                    <View style={{width: '50%'}}>
                        <Text style={styles.textTitle}>Юридический адрес</Text>
                        <View style={styles.section_1}>
                            <Text>Страна: {form.dataObj.country}</Text>
                            {form.dataObj.settlement.toLowerCase().indexOf('минск') === -1 &&
                            <Text>Область: {form.dataObj.area}</Text>}
                            <Text>Населенный пункт: {form.dataObj.settlement}</Text>
                            <Text>Индекс: {form.dataObj.index}</Text>
                            <Text>Адрес: {form.dataObj.address}</Text>
                        </View>
                    </View>
                    <View style={{width: '50%'}}>
                        <Text style={styles.textTitle}>Почтовый адрес</Text>

                        <View style={styles.section_1}>
                            <Text>Страна: {form.dataObj.country}</Text>
                            {form.dataObj.postal_settlement.toLowerCase().indexOf('минск') === -1 &&
                            <Text>Область: {form.dataObj.postal_area}</Text>}
                            <Text>Населенный пункт: {form.dataObj.postal_settlement}</Text>
                            <Text>Индекс: {form.dataObj.postal_index}</Text>
                            <Text>Адрес: {form.dataObj.postal_address}</Text>
                        </View>

                    </View>
                </View>


                <Text style={styles.textTitle}>Руководитель предприятия</Text>
                <View style={styles.section_1}>
                    <Text>Должность руководителя: {form.dataObj.position_of_head}</Text>
                    <Text>Ф.И.O (в именительном падеже): {form.dataObj.full_name_head}</Text>
                    <Text>Действует на основании (в родительном падеже): {form.dataObj.basis_head_r}</Text>
                </View>

                <Text style={styles.textTitle}>Лицо, ответственное за воинский учёт</Text>
                <View style={styles.section_1}>
                    <Text>ФИО: {form.dataObj.full_name}</Text>
                    <Text>Должность: {form.dataObj.position}</Text>
                    <Text>E-mail: {form.dataObj.mail}</Text>
                    <Text>Телефон: {form.dataObj.phone}</Text>
                    {form.dataObj.mobile_phone !=='null' && form.dataObj.mobile_phone !=='' && <Text>Мобильный телефон: {form.dataObj.mobile_phone}</Text> }
                    {form.dataObj.fax !=='null' && form.dataObj.fax !=='' && <Text>Факс: {form.dataObj.fax}</Text>}
                </View>

                <Text
                    style={styles.textTitle}>{form.dataObj.logotip !== null && form.dataObj.logotip !== '' && 'Логотип организации'}</Text>

                <View style={styles.section_2}>
                    <Image src={returnSrc()} style={{maxWidth: '95px', maxHeight: '37px'}}/>
                </View>
            </Page>
        </Document>
    )


};
export default CompanyPDF;


