//@ts-nocheck
import React, { useState} from 'react';
import './LandingPage.css';
import footer_line from './assets/images/footer_line.png'
import {NavLink} from "react-router-dom";
import {NavHashLink} from 'react-router-hash-link';
import pay_logos from './assets/images/pay_logos.png';

const FooterLandingPage = () => {

    const [classForLegislation, setClassForLegislation] = useState(false);

    const addedMainLink = () => {
        if (!classForLegislation) {
            setClassForLegislation(true)
        } else setClassForLegislation(false)
    }

    return (<>

            <div className={window.location.pathname === '/faq' ? 'landing_footer_faq ' :
                window.location.pathname === '/login' ? 'landing_footer_login ' :
                    window.location.pathname === '/publicOffer' ? 'landing_footer_offer' :
                        window.location.pathname === '/legislation' ? 'landing_footer_legislation' :
                            window.location.pathname === '/basicProvisions' ? 'landing_footer_basicProvisions ' :
                                window.location.pathname === '/responsibility' ? 'landing_footer_responsibility ' :
                                    window.location.pathname === '/our_contacts' ? 'landing_footer_our_contacts ' :
                                        "landing_footer"}>
                <NavHashLink to={'/'} className={'footer_title'}>Воинский учет</NavHashLink>
                <div className="landing_links">
                    <NavHashLink to="/#our_work" className='landing_links_links' activeClassName='activeLink'
                                 onClick={() => {
                                     setClassForLegislation(false)
                                 }}>Как мы работаем</NavHashLink>
                    <NavHashLink to="/#cost" className='landing_links_links' activeClassName='activeLink'
                                 onClick={() => {
                                     setClassForLegislation(false)
                                 }}>Стоимость</NavHashLink>
                    <NavHashLink to="/faq" className='landing_links_links' activeClassName='activeLink'
                                 onClick={() => {
                                     setClassForLegislation(false)
                                 }}>Частые вопросы</NavHashLink>
                    <div
                        onClick={addedMainLink}
                        className={window.location.pathname === '/basicProvisions' ||
                        window.location.pathname === '/legislation' ||
                        window.location.pathname === '/responsibility' ? 'activeLink_low' : 'landing_links_links'}>Законодательство
                    </div>
                    {classForLegislation && <div className='legislation_list_1'>
                        <div className='list_legislation_links'>
                            <NavLink to='/legislation'
                                     style={window.location.pathname === '/legislation' ? {color: 'rgba(240, 186, 20, 1)'} : {}}>Законодательство
                                по ведению ВУ в РБ</NavLink>
                            <NavLink to='/basicProvisions'
                                     style={window.location.pathname === '/basicProvisions' ? {color: 'rgba(240, 186, 20, 1)'} : {}}>Основные
                                положения ВУ в РБ</NavLink>
                            <NavLink to='/responsibility'
                                     style={window.location.pathname === '/responsibility' ? {color: 'rgba(240, 186, 20, 1)'} : {}}>Ответственность
                                за нарушения ВУ</NavLink>
                        </div>
                    </div>}
                    <NavHashLink to="/#contacts" className='landing_links_links' activeClassName='activeLink'
                                 onClick={() => {
                                     setClassForLegislation(false)
                                 }}>Контакты</NavHashLink>
                    <NavLink to='/publicOffer' className='landing_links_links' activeClassName='activeLink'
                             onClick={() => {
                                 setClassForLegislation(false)
                             }}>Публичная оферта</NavLink>
                    <NavHashLink style={{position: 'relative', bottom: '16px'}} to='/#call_me'
                                 onClick={() => {
                                     setClassForLegislation(false)
                                 }}>
                        <button className='footer_btn'>Заказать звонок</button>
                    </NavHashLink>
                </div>
                <img src={footer_line} alt='' className='footer_line'/>
                <img className={'paid_logos_mobile'} src={pay_logos} alt={''}/>
                <div className='footer_license_mobile'>© Группа компаний «БелХард» 1994-2021
                </div>
                <div className='footer_license'>
                    <div>Все права защищены | © Группа компаний «БелХард» 1994-2021</div>
                    <img className={'paid_logos'} src={pay_logos} alt={''}/>
                    <div>info@belhard.com</div>
                </div>
            </div>
        </>
    )
}

export default FooterLandingPage;
