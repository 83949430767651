import {useEffect, useState} from "react";
import Axios from '../axios';

const axios=new Axios();

export const useFetchLanding=(url:string)=>{
    const [data,setData]=useState({});

    useEffect(()=>{
        axios.get(url)
            .then((res: { data: import("react").SetStateAction<never[]>; })=>{
            setData(res.data);
        })
    },[]);

    return {
        data
    }
};

export const useFetchLandingResData=(url:string)=>{
    const [data,setData]=useState([]);

    useEffect(()=>{
        axios.get(url)
            .then((res)=>{
                setData(res.data.results);
            })
    },[]);

    return {
        data
    }
}