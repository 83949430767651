import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';


const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}


const ConfirmDialogDeleteCompany = ({ open, handleClose, deleteCompany }: any) => {

    const deleteCompanyLocal = () => {
        deleteCompany();
        handleClose();
    }

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle>
                    Предупреждение
                </DialogTitle>
                <DialogContent dividers>
                    Вы уверены, что хотите удалить организацию ? Все данные будут утеряны.
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button autoFocus onClick={deleteCompanyLocal} color="primary">
                       Да
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default ConfirmDialogDeleteCompany;
