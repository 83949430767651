import React, {useEffect, useState} from "react";
import Axios from "../../utils/axios";
import {useWindowDimensions} from "../../utils/customHooks/useWindowDimensions";
import Carousel from "react-elastic-carousel";
import ReactModal from 'react-modal';

export const DocsExample = () => {

    ReactModal.setAppElement('#root');

    const [docsList, setDoclist] = useState(null)

    useEffect(() => {
        const axios = new Axios()
        axios.get('control/page/10/')
            .then(res => {
                setDoclist(res.data)
            })
    }, [])

    const {width} = useWindowDimensions();


    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index: number) => {
        setCurrentImage(index);
        setViewerIsOpen(true)
    }

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };


    // @ts-ignore
    // @ts-ignore
    var settings = {
        pagination: false,
        enableAutoPlay: true,
        showArrows: false
      };
    return (<>
            {docsList !== null &&
            <div className={'wrapper-example-docs'}>
                <div className={'title-example'}>Примеры форм и документов сервиса «Воинский учет»</div>
                <div className={'pre-title-example'}>Нажмите на фото для детального просмотра</div>
                <div className={'docs-carousel'} style={{height: '300px'}}>

                    { // @ts-ignore
                        docsList?.page !== undefined ?
                            <>
                                <Carousel {...settings} itemsToScroll={1}
                                          itemsToShow={width < 530 ? 1 :
                                              width > 531 && width < 700 ? 2 :
                                                  width > 701 && width < 850 ? 3 :
                                                      width > 851 && width < 1025 ? 4 :
                                                          width > 1026 && width < 1251 ? 5 :
                                                              6
                                          } isRTL>
                                    {// @ts-ignore
                                        docsList.page.map((item: object, index: number) => {
                                            return <div key={index} className={'parent'}>
                                                <img src={
                                                    // @ts-ignore
                                                    item.doc} alt={''} className={'child'} name={item.name}
                                                     onClick={() => {
                                                         openLightbox(index)
                                                     }}
                                                />
                                            </div>
                                        })}
                                </Carousel>

                                <div>
                                    <ReactModal
                                        isOpen={viewerIsOpen}
                                        contentLabel="onRequestClose Example"
                                        onRequestClose={closeLightbox}
                                        className="Modal"
                                        overlayClassName="Overlay"
                                    >
                                        <Carousel itemsToScroll={1}
                                                  initialActiveIndex={currentImage}
                                                  itemsToShow={1} isRTL>
                                            {// @ts-ignore
                                                docsList.page.map((item: object, index: number) => {
                                                    return  <img key={index} src={
                                                            // @ts-ignore
                                                            item.doc} alt={''} name={item.name}
                                                                 className={'image-viewer'}
                                                        />
                                                })}
                                        </Carousel>
                                        <button onClick={closeLightbox} className={'viewer-close-btn'}>X</button>
                                    </ReactModal>
                                </div>

                            </>
                            : <></>
                    }

                </div>
            </div>

            }
        </>
    )
}