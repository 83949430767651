import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatDate = (date: string) => {
  const d = new Date(date)

  const arr = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  const day = d.getDate();
  const month = d.getMonth() + 1;
  let result = (day < 10 ? '0' + day : day) + ' ' + (arr[month - 1]) + ' ' + d.getFullYear() + ' г.';

  return result;
};

const getData = (items: any) => {
  let result = [
    [{ text: '№', bold: true ,fontSize: 9, margin: [0, 30, 0, 0]},
      { text: 'Основание', bold: true ,fontSize: 9, margin: [0, 30, 0, 0],verticalAlign:'middle'},
      {
        text: 'Воинское звание, номер военно-учетной специальности и код воинской должности',
        bold: true,
        fontSize: 9, margin: [0, 10, 0, 10]
      },
      {
        text: 'Фамилия, собственное имя, отчество(если\n' +
            '                         таковое имеется), идентификационный номер', bold: true, margin: [0, 10, 0, 10]
      },
      {text: 'Дата рождения', bold: true, margin: [0, 24, 0, 0]},
      {text: 'Место жительства', bold: true, margin: [0, 24, 0, 0]},
      {text: 'Место пребывания', bold: true, margin: [0, 24, 0, 0]},
      {text: 'Образование', bold: true, margin: [0, 30, 0, 0]},
      {text: 'Семья', bold: true, margin: [0, 30, 0, 0]},
      {text: `Работа \n (учеба)`, bold: true, margin: [0, 24, 0, 0]},
    {text: 'Номера телефонов', bold: true, margin: [0, 24, 0, 0]},
      {text: 'Особые отметки', bold: true, margin: [0, 30, 0, 0]}]
  ]
  // if (items) {
  //   items.map((item: any, number: number) => {
  //     result.push([
  //       { text: `${number + 1}`, alignment: 'center', margin: [0, 5, 0, 0] },
  //       { text: `${item.grade ? item.grade : ''}`, alignment: 'center' },
  //       { text: `${item.full_name ? item.full_name + ',' : ''} \n ${item.pass_number ? item.pass_number : ''}`, alignment: 'left' },
  //       `${item.date_of_birth ? item.date_of_birth.slice(0, 4) : ''}`,
  //       `${item.wyc ? item.wyc : ''}`,
  //       { text: `${item.education ? item.education + ',' : ''} ${item.edu_qualification ? item.edu_qualification + ',' : ''} ${item.edu_specialization ? item.edu_specialization : ''}`, alignment: 'left' },
  //       { text: `${item.worker[item.worker.length - 1] ? item.worker[item.worker.length - 1].position + ',' : ''} ${item.worker[item.worker.length - 1] ? item.worker[item.worker.length - 1].basis : ''}`, alignment: 'left' },
  //       { text: `${item.registered_address ? 'Регистрация: ' + item.registered_address : ''} \n \n  ${item.address ? 'Домашний адрес: ' + item.address : ''}`, alignment: 'left' },
  //       { text: `${item.marital_status ? item.marital_status : ''}`, alignment: 'left' },
  //       { text: `${item.office ? item.office : ''}`, alignment: 'left' },
  //     ] as any)
  //   })
  // }

  if (items) {
    items.map((item: any, number: number) => {
      result.push([
        {text: `${number+1}`, alignment: 'center', margin: [0, 5, 0, 0],verticalAlign:'middle'},
        {text: `Постановка организации на учёт`, alignment: 'center',verticalAlign:'middle'},
        {text: `${item.accountg_id===4 ? 'Призывник' :
              `${item.grade}
               \n${item.wyc?  item.wyc : '' } `
          }`, alignment: 'center'},
        {text: `${item.full_name ? item.full_name :''}
         \n  ${item.pass_number ? item.pass_number  : ''} `, alignment: 'center'},
        {text: `${item.date_of_birth ? item.date_of_birth.split('-').reverse().join('-') : ''}`, alignment: 'center'},
        {text: `${item.registered_address ? item.registered_address : ''}`, alignment: 'center'},
        {text: `${item.address ? item.address : ''}`, alignment: 'center'},
        {
          text: `
                    ${item.education ? item.education : ''}
         ${item.education_full ? item.education_full + ',' : ''}
            ${item.edu_specialization ? item.edu_specialization + ',' : ''}
         // ${item.edu_qualification ? item.edu_qualification : ''}
         ${item.edu_dop_full ? item.edu_dop_full : ''}
         ${item.edu_dop_specialization ? item.edu_dop_specialization : ''}`
          ,
          alignment: 'center'
        },
        {text: ` ${item.marital_status ?item.marital_status : ''}
         ${item.family_half && item.family ? `Состав семьи: \n супруга (супруг): \n ${ item.family_half}, ${item.family}` :
              !item.family_half && item.family ? `Состав семьи: \n${item.family}` :
                  item.family_half && !item.family ? `Состав семьи: \n супруга (супруг): \n ${item.family_half}` : ''}  `, alignment: 'center'},
        {
          text: `${item.position ? item.position  : item.education}
                     ${item.add_military ? `Дата приёма: ${item.add_military.split('-').reverse().join('.')}` : ''}
                      ${item.order_military ? 'Приказ № ' + item.order_military : ''} 
                      ${item.date_add_military ? 'от ' + item.date_add_military.split('-').reverse().join('.') : ''}
                     ` , alignment: 'center',wordWrap: 'break-word'
        },
        {
          text: `${item.home_phone ? 'дом. '+ item.home_phone + ',' : ''} 
          \n ${item.work_phone ? 'рабоч. ' + item.work_phone : ''}
          \n ${item.phone ? 'моб. ' + item.phone : ''}`,
          alignment: 'center'
        },
        {text: `${item.status ? 'Годность к военной службе: ' + item.status : ''}\n \n ${item.requirement===true ? 'Моб. предписание: \n ' + 'Да \n'+ item.requirement_annotation  :
              'Моб. предписание: \n ' + 'Нет'}`, alignment: 'center'},

      ] as any)
    })
  }

  return result;
};


const RegistrationLetter = ({ office, date, number, address, items, name, phone, company, setFile, fullAddress,
                              organizationName,
                              organizationRespPerson,organizationPositionResp,shortOffice,
                              organizationPhone }: any) => {
  let ar=getData(items);

  let nameResponsible=  organizationRespPerson ? organizationRespPerson.split(' ')[1] && organizationRespPerson.split(' ')[2] && organizationRespPerson.split(' ')[0] ?
      organizationRespPerson.split(' ')[1][0] + '.' + organizationRespPerson.split(' ')[2][0] + " " + organizationRespPerson.split(' ')[0]
      : '' : organizationRespPerson

  const card = {
    pageOrientation: 'landscape',
    pageSize: 'A3',

    footer: function (currentPage: any, pageCount: any) {
      if (pageCount > 1) {
        return {
          margin: [10, 0, 40, 15],
          table: {
            widths: [160, '*', 80],
            body: [
              [{}, {},
                {
                  alignment: 'right',
                  fontSize: 9,
                  lineHeight: 1.1,
                  text: `лист ${currentPage.toString()} из  ${pageCount}`,
                }
              ]
            ]
          },
          layout: 'noBorders'
        }
      }
    },

    content: [
      {
        text: `Военному комиссару \n ${shortOffice.indexOf('ий') !==-1 ? shortOffice.replace(/ий/gi, 'ого') :
            shortOffice.indexOf('ый') !==-1 ? shortOffice.replace(/ый/gi, 'ого') : shortOffice.replace(/ой/gi, 'ого')} `,
        margin: [830, 5, 0, 0],
        fontSize: 11,
        bold: true,
        lineHeight: 1,
      },
      {
        text: ` ${address}`,
        margin: [830, 0, 0, 0],
        fontSize: 9
      },
      {
        margin: [0, 40, 0, 0],
        columns: [
          {
            width: 130,
            text: `Исх. ${number ? number : ''}  \n от ${formatDate(date)}`,
            italics: true,
            bold: true,
            fontSize: 10,
            lineHeight: 1,
          },
          {
            width: '*',
            alignment: 'center',
            fontSize: 10,
            lineHeight: 1.1,
            text: [
              {text: 'СВЕДЕНИЯ \n', fontSize: 12, bold: true},
              {
                text: `о военнообязанных запаса и призывниках, работающих в ${organizationName} в связи с постановкой организации на воинский учет.`,
                bold: true,
              },
            ],
            margin: [-125, 0, 90, 25]
          }
        ]
      },
      {
        width: '*',
        alignment: 'center',
        fontSize: 9,
        lineHeight: 1.1,
        text: [

          {text: ` ${organizationName}\n` , fontSize: 13, bold: true, paddingBottom:-4,lineHeight: 0.5},
          {
            text: '____________________________________________________________________________________________________________________________________\n',
          },
          {
            text: '(наименование государственного органа, организации, учреждения образования местного исполнительного и распорядительного органа)',
            fontSize: 8
          },
        ],
        margin: [0, 0, 90, 25]
      },
      {
        fontSize: 9,
        alignment: 'center',
        table: {
          widths: [20, 70, 100, 110, 60, 80, 80, 80, 110, 105, 100, 90],
          headerRows: 1,
          // dontBreakRows: true,
          body: ar,
          dontBreakRows: true,
        }
      },
      {
        width: '*',
        text: `\n ${organizationPositionResp ? organizationPositionResp : ''} ` ,
        fontSize: 9,
        lineHeight: 1,
        margin: [0, 40, 0, 0]
      },
      {
        margin: [0, 0, 0, 0],
        text: [
          {
            width: '*',
            text: `\n\n ________________________________  ${nameResponsible ? nameResponsible : ''} 
                        Тел. ${organizationPhone ? organizationPhone : ''}` ,
            fontSize: 9,
            lineHeight: 1,
          },

          // {
          //     width: '*',
          //     alignment: 'center',
          //     fontSize: 10,
          //     lineHeight: 1.1,
          //     text:`${organizationPhone}`,
          //     margin: [0, 0, 0, 0]
          // }
        ]
      },
    ],
    pageBreakBefore: function(currentNode: { id: string; pageNumbers: string | any[]; pages: any; }, followingNodesOnPage: any, nodesOnNextPage: any, previousNodesOnPage: any) {

      //check if signature part is completely on the last page, add pagebreak if not
      if (currentNode.id === 'signature' && (currentNode.pageNumbers.length != 1 || currentNode.pageNumbers[0] != currentNode.pages)) {
        return true;
      }
      //check if last paragraph is entirely on a single page, add pagebreak if not
      else if (currentNode.id === 'closingParagraph' && currentNode.pageNumbers.length != 1) {
        return true;
      }
      return false;
    }
  }

  // card.pageBreakBefore = function(cur: { headlineLevel: number; }, next: any[], next_page: any[], prev: any) {
  //   let pb = false;
  //   if ((cur.headlineLevel === 1) || (cur.headlineLevel === 0))	{		// Each table row has headlineLevel 1, last row has headlineLevel 2
  //     if (!next.find(node => node.headlineLevel) && next_page.length) {	// If last row, test further
  //       let next_row = next_page.find(node => node.headlineLevel);
  //       pb = (next_row && (next_row.headlineLevel === 2));	// Page break if 1st row on next page is last row of table
  //     }
  //   }
  //   return pb;
  // }

  const file = pdfMake.createPdf(card as any);
  file.download(`Письмо_о_постановке_организации_на_учёт_для_${office.slice(20)}.pdf`);
  file.getBuffer((buffer) => {
    const blob = new Blob([buffer]);
    setFile(blob);
  });

};
export default RegistrationLetter;