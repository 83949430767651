import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Axios from '../../utils/axios';
import {FormControlSelect} from './FormControlSelect';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {Link} from 'react-router-dom';
import SettingsModal from '../TableSettingsModal/SettingsModal'
import './TableAllUsers.css';
import {getUserIdAgainLetterAC, onAgainSendLetterAC} from "../../redux/actions/userActions";
import {useDispatch} from "react-redux";
import { NavLink } from 'react-router-dom';


type Order = 'asc' | 'desc';


interface Column {
    id: 'id' | 'grade' | 'full_name' | 'date_of_birth' | 'status' | 'office' | 'requirement' | 'marital_status' |
        'education' | 'address' | 'registered_address' | 'military' | 'wyc' | 'time_end' | 'register_date' | 'family' |
        'position' | 'phone';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center';
    format?: (value: number) => string;
    width?: number,
    isSelected?: boolean
}

export interface Item {
    id: string | number;
    grade: string;
    full_name: string;
    date_of_birth: string | number | Date;
    office: string;
    status: string;
    prescription: string;
    marital_status: string;
    education: string;
    address: string;
    report: boolean;
    time: string | number | Date;
    wyc: string;
    registered_address: string;
    military: string;
    worker: boolean;
}

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        height: 'calc(100vh - 300px)'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    colorRed: {
        background: 'rgba(199, 0, 0, 0.2)',
    },
    colorOrange: {
        background: 'rgba(255, 168, 18, 0.2)',
    },
    colorBlack: {
        background: 'rgba(2, 2, 2, 0.2)',
    },
    colorGray: {
        color: 'white',
        background: 'rgba(2, 2, 2, 0.6)',
    },

});

const formatDate = (date: string) => {
    const d = new Date(date);

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear();

    return result;
}

export default function PeopleTable(props: any) {

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [countItems, setCountItems] = React.useState(0);
    const [items, setItems] = React.useState<Item[]>([]);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState('full_name');
    const [searchSet, setSearchSet] = useState(false);


    const [filter, setFilter] = React.useState({
        name: '',
        value: ''
    } as any);

    const [filterDateOfBirdth, setFilterDateOfBirdth] = React.useState({
        date_of_birth__gte: null as any,
        date_of_birth__lte: null as any
    });


    const [openModal, setOpenModal] = useState(false);

    const [columns, setColumns] = useState(null as any);
    const [defaultColumns, setDefaultColumns] = useState([] as any);


    const handleClickOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        const axios = new Axios();
        axios.get(`user/me/`)
            .then(res => {
                //   const result = Object.keys(res.data.setting['2']);

                const data = res.data.setting['4']
                let result = [data.id.name, data.grade.name, data.full_name.name,
                    data.date_of_birth.name, data.wyc.name, data.office.name, data.registered_address.name, data.address.name,
                    data.education.name, data.marital_status.name, data.family.name,
                    data.position.name, data.phone.name]
                setDefaultColumns(result)
            })
    }, []);

    useEffect(() => {

        const axios = new Axios();
        axios.get(`worker/field/4`)
            .then(res => {
                let arr = Object.values(res.data);

                let defArr = ["№", "Воинское звание", "ФИО", "Дата рождения", "ВУС", "Военкомат", "Место пребывания", "Место жительства (Регистрации)", "Образование", "Семейное положение", "Состав семьи", "Должность. Дата (Принятия/Снятия) с учета", "Телефон"]

                let diff = function (a1: any, a2: any) {
                    return a1.filter((i: any) => !a2.includes(i))
                        .concat(a2.filter((i: any) => !a1.includes(i)))
                }
                defArr = [...defArr, ...diff(arr, defArr)];
                let val = Object.entries(res.data)
                let allArr = defArr.map((i: any) => {
                    return {
                        id: val.filter((v: any) => {
                            return v[1] === i ? v[0] : null
                        }),
                        label: i,
                        isSelected: defaultColumns.filter((d: any) => {
                            return i === d ? true : false
                        })
                    }
                })
                let addedArr = allArr.map((i) => {
                    return {
                        ...i, id: i.id.flat()[0], isSelected: i.isSelected.length === 0 ? false : true
                    }
                })
                setColumns(addedArr);

            })
            .catch(err => {
                console.log(err);
            })
    }, [defaultColumns])


    useEffect(() => {
        let orderByBuf = null as any;

        if (orderBy === 'office' || orderBy === 'grade') {
            orderByBuf = `${orderBy}__name`
        } else {
            orderByBuf = orderBy;
        }

        let data = null as any;
        if (filter.name !== '') {

            data = {
                order: `${order === `desc` ? `` : `-`}${orderByBuf}`,
                [filter.name]: filter.value,
                date_of_birth__gte: filterDateOfBirdth.date_of_birth__gte ? filterDateOfBirdth.date_of_birth__gte : undefined,
                date_of_birth__lte: filterDateOfBirdth.date_of_birth__lte ? filterDateOfBirdth.date_of_birth__lte : undefined,
                type: 3
            };
        } else {
            data = {
                order: `${order === `desc` ? `` : `-`}${orderByBuf}`,
                date_of_birth__gte: filterDateOfBirdth.date_of_birth__gte ? filterDateOfBirdth.date_of_birth__gte : undefined,
                date_of_birth__lte: filterDateOfBirdth.date_of_birth__lte ? filterDateOfBirdth.date_of_birth__lte : undefined,
                type: 3
            };
        }

        const axios = new Axios();
        const user_id = localStorage.getItem('id') || '';
        axios.post(`worker/${user_id}/filter/?page=${page + 1}&page_size=${rowsPerPage}`, data)
            .then(res => {
                setCountItems(res.data.count);
                setItems(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })

    }, [page, rowsPerPage, orderBy, filterDateOfBirdth, filter, order]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const _orderBy = (id: any) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, id)
    };

    const selectBy = (parametr: string, value: string, age?: any) => {
        let firstDate = '';
        let secondDate = '';


        if (age.firstAge || age.secondAge) {
            const today = new Date();
            const todayYear = today.getFullYear();
            const todayMonth = today.getMonth();
            const todayDay = today.getDate();

            const gte = todayYear - Number(age.firstAge);
            const lte = todayYear - Number(age.secondAge);

            firstDate = gte + '-' + (todayMonth < 10 ? '0' + todayMonth : todayMonth) + '-' + (todayDay < 10 ? '0' + todayDay : todayDay);
            secondDate = lte + '-' + (todayMonth < 10 ? '0' + todayMonth : todayMonth) + '-' + (todayDay < 10 ? '0' + todayDay : todayDay);

            if (age.firstAge === '') {
                setFilter({
                    name: `${parametr}__contains`,
                    value: value === '' ? '' : value[0].toUpperCase() + value.slice(1)
                });
                setFilterDateOfBirdth({
                    date_of_birth__lte: null,
                    date_of_birth__gte: secondDate
                });
            } else if (age.secondAge === '') {
                setFilter({
                    name: `${parametr}__contains`,
                    value: value === '' ? '' : value[0].toUpperCase() + value.slice(1)
                });
                setFilterDateOfBirdth({
                    date_of_birth__gte: null,
                    date_of_birth__lte: firstDate
                });
            } else {
                setFilter({
                    name: `${parametr}__contains`,
                    value: value[0].toUpperCase() + value.slice(1)
                });
                setFilterDateOfBirdth({
                    date_of_birth__gte: secondDate,
                    date_of_birth__lte: firstDate
                });
            }
        } else if (parametr === 'special' && (value === 'нет' || value === 'Нет')) {
            setFilter({
                name: `${parametr}`,
                value: false,

            });
        } else if (parametr === 'special' && (value.indexOf('да') !== 1 || value.indexOf('Да') !== -1)) {
            setFilter({
                name: `${parametr}`,
                value: true
            });
        } else if (parametr === 'grade__name' && (value.indexOf('приз') !== -1 || value.indexOf('Приз') !== -1)) {
            setFilter({
                name: `accountg__name`,
                value: 'Призывник'
            });
        } else {
            setFilter({
                name: `${parametr}__contains`,
                value: value === '' ? '' : value[0].toUpperCase() + value.slice(1)
            });

            setFilterDateOfBirdth({
                date_of_birth__gte: null,
                date_of_birth__lte: null
            });
        }
        ;
    }

    const getLink = (id: string | number) => {
        return `/user_new/${id}`
    }
const dispatch=useDispatch();

    const sendInfoForAgainLetter=(type:string,user_id:number)=>{
        dispatch(onAgainSendLetterAC(type))
        dispatch(getUserIdAgainLetterAC(user_id))
    }

    return (
        <div style={{width: '100%', minHeight: '100vh'}}>
            {searchSet && <div className={'search-workers-cl'}>Найдено {items.length} сотрудник(ов) </div>}

            <FormControlSelect selectBy={selectBy} setSearchSet={setSearchSet} setItems={setItems}
                               handleClickOpen={handleClickOpen} filter={filter} setFilter={setFilter}/>

            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns ? columns.map((column: any) => {
                                    if (column.isSelected)
                                        return (
                                            <TableCell
                                                key={column.id + getRandomInt(4898)}
                                                align={column.align}
                                                style={column.id === 'position' ? {minWidth: '191px'} : column.id === 'family' ? {minWidth: '220px'} :
                                                    {minWidth: column.minWidth}}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id ? order : 'asc'}
                                                    onClick={_orderBy(column.id)}
                                                >
                                                    {column.label}
                                                    {
                                                        orderBy === column.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null
                                                    }
                                                </TableSortLabel>
                                            </TableCell>
                                        )
                                }) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row: any, number: number) => {

                                const koff2 = (page + 1) * rowsPerPage - rowsPerPage + 1;
                                const koff = order === 'desc' ? koff2 : countItems - koff2;
                                return (
                                    <TableRow role="checkbox" tabIndex={-1} key={row.id + getRandomInt(7588)}
                                              style={(row.remove_military !== null || row.basic_military !== null) ? {background: 'gray'} : {background: 'white'}}>
                                        {columns ? columns.map((column: any) => {
                                            const columnID = column.id;
                                            const value = row[column.id] as any;
                                            if (column.isSelected) {
                                                if (columnID === 'full_name') {
                                                    return <TableCell key={column.id + getRandomInt(758)}
                                                                      align={column.align}>
                                                        <Link to={getLink(row.id)} target="_blank"
                                                              style={{color: 'black'}}>
                                                            {`${value} ${row.pass_number ? `\n${row.pass_number}` : ''}`}
                                                        </Link>
                                                    </TableCell>
                                                }

                                                if (columnID === 'id') {
                                                    return <TableCell key={column.id + getRandomInt(1111)}
                                                                      align={column.align}>
                                                        {order === 'asc' ? koff - number + 1 : koff + number}
                                                    </TableCell>
                                                }
                                                if (columnID === 'letter_time') {
                                                    return <TableCell key={column.id + getRandomInt(1111)}
                                                                      align={column.align}>
                                                        {value ? <div className={'wrap-again-send'}>
                                                                <span>{formatDate(value)}</span>
                                                                <NavLink to={'/newHiringLetter'}><button
                                                                    onClick={()=>{sendInfoForAgainLetter('hiring_letter_again',row.id)}}>
                                                                    Отправить еще раз</button></NavLink>
                                                            </div>
                                                            : <></>}
                                                    </TableCell>
                                                }

                                                if (columnID === 'dismissal_letter_time') {
                                                    return <TableCell key={column.id + getRandomInt(1111)}
                                                                      align={column.align}>
                                                        {value ? <div className={'wrap-again-send'}>
                                                            <span>{formatDate(value)}</span>
                                                            <NavLink to={'/newDismissalLetter'}><button
                                                                onClick={()=>{sendInfoForAgainLetter('dismiss_letter_again',row.id)}}>
                                                                Отправить еще раз</button></NavLink>
                                                        </div> : <></>}
                                                    </TableCell>
                                                }
                                                if (columnID === 'requirement') {
                                                    return <TableCell key={column.id + getRandomInt(15004)}
                                                                      align={column.align}>
                                                        {row.requirement === true ? <><span>Да</span>
                                                                <div>{row.requirement_annotation}</div>
                                                            </>
                                                            : <span>Нет</span>
                                                        }
                                                    </TableCell>
                                                }

                                                if (columnID === 'letter_time') {
                                                    return <TableCell key={column.id + getRandomInt(99504)}
                                                                      align={column.align}>
                                                        {value ? formatDate(value as string) : ''}
                                                    </TableCell>
                                                }

                                                if (columnID === 'position') {
                                                    return <TableCell key={column.id + getRandomInt(22227665)}
                                                                      align={column.align}>
                                                        <p style={{marginBottom: '0px'}}>
                                                            {row.add_military ? `Принят(а) на учет: ${formatDate(row.add_military)} ` : ''}
                                                            {row.position ? `, ${row.position} ` : ''}
                                                        </p>
                                                        <p>
                                                            {row.order_military ? `Приказ № ${row.order_military} от ${formatDate(row.date_add_military as string)}` : ''}
                                                        </p>
                                                        <p style={{marginBottom: '0px'}}>
                                                            {row.remove_military ? `Снят(а) с учета: \n ${formatDate(row.remove_military)}` : ''}
                                                        </p>
                                                        <p style={{marginBottom: '0px'}}>
                                                            {row.order_remove_military ? `Приказ № ${row.order_remove_military} от ${formatDate(row.date_remove_military as string)}` : ''}
                                                        </p>
                                                        <p>
                                                            {row.basic_military && !row.order_remove_military && !row.date_remove_military && !row.remove_military ? `Снят(а) с учёта: ${row.basic_military}` :
                                                                !row.basic_military && !row.order_remove_military && !row.date_remove_military ? '' :
                                                                    `Основание: ${row.basic_military}`}
                                                        </p>

                                                    </TableCell>
                                                }
                                                if (columnID === 'date_of_birth') {
                                                    return <TableCell key={column.id + getRandomInt(27888)}
                                                                      align={column.align}>
                                                        {value ? formatDate(value as string) : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'education') {
                                                    return <TableCell key={column.id + 'key-col'}
                                                                      align={column.align}>
                                                        {value ? <>
                                                            {value}
                                                            <br/> {row.education_full ? row.education_full + ',' : ''}
                                                            <br/>{row.edu_specialization ? row.edu_specialization : ''}

                                                            <br/> {row.edu_dop_full ? row.edu_dop_full + ',' : ''}
                                                            <br/> {row.edu_dop_specialization ? row.edu_dop_specialization : ''}
                                                        </> : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'special') {
                                                    return <TableCell key={column.id + getRandomInt(4843)}
                                                                      align={column.align}>
                                                        {row.special ? 'Да' : 'Нет'}
                                                        <br/>
                                                        <br/>
                                                        {row.specialN ? row.specialN : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'grade') {
                                                    return <TableCell key={column.id + getRandomInt(7327)}
                                                                      align={column.align}>
                                                        {value ? `${row.grade}\n ${row.grade_full || ''}` :
                                                            row.accountg_id === 4 ? 'Призывник' : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'marital_status') {
                                                    return <TableCell key={column.id + getRandomInt(58942)}
                                                                      align={column.align}>
                                                        {value ? value : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'register_date') {
                                                    return <TableCell key={column.id + getRandomInt(64748) + 2}
                                                                      align={column.align}>
                                                        {value ? formatDate(value as string) : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'wyc') {
                                                    return <TableCell key={column.id + getRandomInt(64748) + 2}
                                                                      align={column.align}>
                                                        {value ? `${row.wyc}` : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'office') {
                                                    return <TableCell key={column.id + getRandomInt(64748) + 2}
                                                                      align={column.align}>
                                                        {value ? `${row.office}` : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'registered_address') {
                                                    return <TableCell key={column.id + getRandomInt(64748) + 2}
                                                                      align={column.align}>
                                                        {value ? `${row.registered_address}` : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'address') {
                                                    return <TableCell key={column.id + getRandomInt(64748) + 2}
                                                                      align={column.align}>
                                                        {value ? `${row.address}` : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'family') {
                                                    return <TableCell key={column.id + getRandomInt(6802)}
                                                                      align={column.align}
                                                                      className={'family-column'}>
                                                        <span>
                                                            {row.family_half && row.family ? `жена (муж): ${row.family_half}, ${row.family}` :
                                                                !row.family_half && row.family ? `${row.family}` :
                                                                    row.family_half && !row.family ? `жена (муж): ${row.family_half}` : ``}
                                    </span>
                                                    </TableCell>
                                                }
                                                if (columnID === 'phone') {
                                                    return <TableCell key={column.id + getRandomInt(64748) + 2}
                                                                      align={column.align}>
                                                        {value ? <>{row.phone ? `моб. ${row.phone}` : ''}
                                                            <br/><br/>
                                                            {row.work_phone ? `раб. ${row.work_phone}` : ''}
                                                            <br/><br/>
                                                            {row.home_phone ? `дом. ${row.home_phone} ` : ''}</> : ''}
                                                    </TableCell>
                                                }
                                                return (
                                                    <TableCell key={column.id + getRandomInt(6372)}
                                                               align={column.align}>

                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            }
                                        }) : null}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    labelRowsPerPage='Показывать по:'
                    backIconButtonText='Предыдущие'
                    nextIconButtonText='Следующие'
                    labelDisplayedRows={({from, to, count}) => `${from}-${to} из ${count}`}
                    count={countItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

                <SettingsModal
                    page={page}
                    rowsPerPage={rowsPerPage}
                    open={openModal}
                    handleClose={handleClose}
                    columns={columns}
                    setColumns={setColumns}
                    essence={2}
                    setItems={setItems}
                />
            </Paper>

        </div>
    );

}
