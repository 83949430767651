//@ts-nocheck
import React, {useState, useEffect} from 'react';
import Header from '../HeaderLandingPage'
import Footer from '../FooterLandingPage';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Axios from '../../../utils/axios';
import {Addresses} from "../BodyLandingPage";
import Input from 'react-phone-number-input/input';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store/store";
import {getDataAddressAC} from "../../../redux/reducers/addressesReducer";
import SuccessfulSaveModal from "../../../components/PersonalCard/SuccessfulSaveModal";
import {NavLink} from "react-router-dom";

import flag from '../assets/images/flag.svg';
import right_vector from '../assets/images/right_vector.svg';
import left_vector from '../assets/images/left_vector.svg';
import work from '../assets/images/work.svg';
import our_work_mr from '../assets/images/our_work_mr.svg';
import why_us from '../assets/images/why_us.png';
import loupe from '../assets/images/why_us_list/loupe.png';
import photo from '../assets/images/why_us_list/photo.png';
import hands from '../assets/images/why_us_list/hands.png';
import archive from '../assets/images/why_us_list/archive.png';
import money from '../assets/images/why_us_list/money.png';
import card from '../assets/images/why_us_list/card.png';
import settings from '../assets/images/why_us_list/settings.png';
import question_ from '../assets/images/why_us_list/question.png';
import star from '../assets/images/star.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, .03)',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,

        },
    }),
);

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}

const FAQPage = () => {

const listMilitaryRegistration = [
        {img: loupe, title: 'Контролируемое управление воинским учетом'},
        {img: photo, title: 'Своевременную реакцию на изменения нормативных документов'},
        {img: hands, title: 'Перенос ответственности на профессионального специалиста'},
        {img: archive, title: 'Информационная связь с используемыми программными средствами'},
    ]
const listMilitaryRegistration_ = [
        {img: money, title: 'Минимизацию финансовых издержек'},
        {img: card, title: 'Прозрачные взаимоотношения с контрольными органами'},
        {img: settings, title: 'Помощь специалистов по вопросам учета'},
        {img: question_, title: 'Справочник ответов по повторяющимся вопросам'},
    ]

const MilitaryRegistration = (props) => {

    return (
        <div className='wrapper_military_registration'>
            <img alt='' src={why_us} className='what_is_mr'/>
            <div className='what_is_mr_mobile'>
                <span>ПОЧЕМУ МЫ? </span>
            </div>
            <div style={{border: '1px solid white'}}>
                <div className='title_law_military_registration'>
                    Сервис «Воинский учет» обеспечит:
                </div>
                <div className={'wrapper-why-us-comp'}>
                    <div className={'wrapper-why-us-comp-item'} style={{marginRight: '75px'}}>
                        {listMilitaryRegistration.map((item: any, index: number) => {
                            return <div key={index}>
                                <img src={item.img} alt={''} style={{marginRight: '20px'}}/>
                                <span>{item.title}</span>
                            </div>
                        })}
                    </div>
                    <div className={'wrapper-why-us-comp-item'}>
                        {listMilitaryRegistration_.map((item: any, index: number) => {
                            return <div key={index}>
                                <img src={item.img} alt={''} style={{marginRight: '20px'}}/>
                                <span>{item.title}</span>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
};



    const classes = useStyles();

    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const divStyle = {
        "font-size": "20px"
    };


    return (<>
            <div className='wrapper_header_'>
                <Header/>
            </div>
            <div className='wrapper_faq'>
                <div className='provisions_pre_title_4'>
                    <NavLink to='/' style={{textDecoration: 'none', color: 'black'}}><span>Главная</span></NavLink>
                    <span>/</span>
                    { window.location.href.includes("/ip") && <span style={{color: 'gray'}}>Индивидуальный предприниматель</span>}
                    { window.location.href.includes("/entity") && <span style={{color: 'gray'}}>Юридическое лицо</span>}
                </div>
                <MilitaryRegistration/>

                
            <div className={'wrapper_our_work'} id={'our_work'}>
            <div className='wrap_work'><img alt='' src={our_work_mr} className='our_work_mr_img'/></div>
            <div className='wrap_work_mobile'><img alt='' src={work} className='our_work_mr_img'/></div>
            <div className='title_our_work_mr'>Варианты использования сервиса “Воинский учет”:</div>
            <div className='vectors'>
                <img alt='' src={left_vector} className='left_vector'/>
                <img alt='' src={left_vector} className='left_vector'/>
                <img alt='' src={right_vector} className='right_vector'/>
            </div>
            <div className='wrapper_work_description'>
                <div className='title_mode_par '>
                    <div className='title_mode_point_1'>
                        <img alt='' src={flag}/>
                        <div>Основной режим</div>
                    </div>
                    <div className={'title_mode_point_2'}>
                        <img alt='' src={flag}/>
                        <div>Расширенный режим</div>
                    </div>
                    <div className={'title_mode_point_2'}>
                        <img alt='' src={flag}/>
                        <div>Режим Наставник </div>
                    </div>
                </div>
                <div className='mode_list_par '>
                    <ul className='mode_list_left'>
                        <li><strong>Вы</strong> - определяете специалиста Вашей организации, который будет вести
                            воинский
                            учёт,
                            и приобретаете лицензию
                        </li>
                        <li><strong>Мы</strong> - предоставляем доступ, обучаем и консультируем по использованию нашего
                            сервиса
                        </li>
                    </ul>
                    <ul className='mode_list_right'>
                        <li><strong>Вы</strong> - приобретаете расширенную лицензию</li>
                        <li><strong>Мы</strong> - оказываем услуги по полному сопровождению программного продукта</li>
                    </ul>
                    <ul className='mode_list_right'>
                        <li><strong>Вы</strong> - определяете специалиста Вашей организации, который будет вести воинский учёт, и приобретаете лицензию</li>
                        <li><strong>Мы</strong> - предоставляем доступ, обучаем и консультируем по использованию нашего сервиса, обучению вашего работника в течение 3 -х месяцев</li>
                    </ul>
                </div>
            </div>

            <div className='wrapper_work_description_mobile'>
                <div style={{width: '99%'}}>
                    <div className='title_mode_point_1'>
                        <div className='title_mode_point_1_title'><img alt='' src={flag}/>
                            <div>Основной режим</div>
                        </div>
                        <ul className='mode_list_left'>
                            <li><strong>Вы</strong> - определяете специалиста Вашей организации, который будет вести
                                воинский
                                учёт,
                                и приобретаете лицензию
                            </li>
                            <li><strong>Мы</strong> - предоставляем доступ, обучаем и консультируем по использованию
                                нашего
                                сервиса
                            </li>
                        </ul>
                    </div>
                    <div className={'title_mode_point_2'}>
                        <div className='title_mode_point_2_title'><img alt='' src={flag} style={{marginRight: '10px'}}/>
                            <div>Расширенный режим</div>
                        </div>
                        <ul className='mode_list_right'>
                            <li><strong>Вы</strong> - приобретаете расширенную лицензию</li>
                            <li><strong>Мы</strong> - оказываем услуги по полному сопровождению программного продукта
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> 
        {console.log(window.location.href)}           
            { window.location.href.includes("/ip") &&  <div className={'wrapper_cost'} id={'cost'}>
                <div className='wrapper_title_cost'>
                    <img alt='' src={star} className='cost_img'/>
                    <span className='title_cost'>{"Стоимость"}</span>
                    <img alt='' src={star} className='cost_img'/>
                </div>
                <div className='cost_footer'>
                    <div><b>{"1.Производите разовый платеж за подключение.---25 BYN"}</b></div>
                    <div><b>{"2.Сопровождение лицензии (Основной режим), в месяц---10 BYN"}</b></div>
                    <div><b>{"3.Консультирование организации ведения воинского учета сроком на 3 месяца, мес---25 BYN"}</b></div>
                    <div style={divStyle}><br/><br/>
                Все организации независимо от формы собственности и индивидуальные предприниматели,
                имеющие в своем составе военнообязанных сотрудников или призывников,
                обязаны организовать военно-учётную работу и поставить организацию на учёт
                в районном военном комиссариате согласно
                Постановления Совета Министров Республики Беларусь от 18.12.2003 № 1662
                «Положение о воинском учёте».
                Невыполнение данного постановления влечет  штрафные санкции.    
                Организации обязаны представлять периодическую отчётность,
                более 10 видов отчетных форм для контролирующих органов.
                </div>
                </div>
            </div>}
            { window.location.href.includes("/entity") &&  <div className={'wrapper_cost'} id={'cost'}>
                <div className='wrapper_title_cost'>
                    <img alt='' src={star} className='cost_img'/>
                    <span className='title_cost'>{"Стоимость"}</span>
                    <img alt='' src={star} className='cost_img'/>
                </div>
                <div className='cost_footer'>
                    <div><b>{"1.Производите разовый платеж за подключение.---50 BYN"}</b></div>
                    <div><b>{"2.Сопровождение лицензии (Основной режим), в месяц---25 BYN"}</b></div>
                    <div><b>{"3.Расширенный режим для юридических лиц – сопровождение лицензии, в месяц---20 BYN + 2.5 BYN за каждого военнообязанного или призывника свыше 10 человек"}</b></div>
                    <div><b>{"4.Консультирование организации ведения воинского учета сроком на 3 месяца, мес---50 BYN"}</b></div>
                    <div style={divStyle}><br/><br/>
                Все организации независимо от формы собственности и индивидуальные предприниматели,
                имеющие в своем составе военнообязанных сотрудников или призывников,
                обязаны организовать военно-учётную работу и поставить организацию на учёт
                в районном военном комиссариате согласно
                Постановления Совета Министров Республики Беларусь от 18.12.2003 № 1662
                «Положение о воинском учёте».
                Невыполнение данного постановления влечет  штрафные санкции.    
                Организации обязаны представлять периодическую отчётность,
                более 10 видов отчетных форм для контролирующих органов.
                </div>
                </div>
            </div>}
            </div>
            <Footer/>
        </>
    )
}
export default FAQPage;
