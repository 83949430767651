import React, { useEffect, useState } from 'react';
import classes from './Documents.module.css';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AutocompleteOffice from '../AutocompleteOffice/AutocompleteOffice';
import Axios from '../../utils/axios';
import Button from '@material-ui/core/Button';

export default function DocumentsToolbar(props: any) {

    const [types, setTypes] = useState<any>([{}]);

    useEffect(() => {
        const axios = new Axios();
        axios.get('worker/type/')
            .then(res => {
                const arr = [{ id: 0, name: 'Все' }, ...res.data.results];
                setTypes(arr);
            })
            .catch(err => {
                console.log(err)
            })
    }, []);

    return (
        <div className={'formControl'}>
            <div>
            {props.type !== 'reports' &&
                <Button variant="outlined" onClick={() => {
                    props.handleClickOpen()
                }}>
                    Вид
                </Button>
            }
            </div>
            <div className='formControl-right'>
                <div className={classes.autocomplete}>
                    <p className={classes.p_autocomplete}>Военкомат: </p>
                    <AutocompleteOffice
                        handleChangeOfficeName={props.handleChangeOfficeName}
                        updateTable={props.updateTable}
                        setOfficeName={props.setOfficeName}
                    />
                </div>

                <div className={classes.box}>
                    <p className={classes.boxChild}>Тип документа: </p>

                    <Select
                        id="document-type"
                        value={props.valueDocumentType}
                        onChange={props.handleChangeDocumentType}
                        className={classes.select}
                    >
                        {types.map((item: any) => {
                            return <MenuItem value={item.id} key={item.id + 'documentsToolbar'}>{item.name}</MenuItem>
                        })}
                    </Select>

                </div>
            </div>
        </div>
    );

}