import React, {useEffect, useState} from 'react';
import ReportsTable from '../components/Reports/ReportsTable';
import Axios from '../utils/axios';
import Button from '@material-ui/core/Button';
import NewReportModal from '../components/Reports/NewReportModal';
import {Link} from "react-router-dom";
import NewNoticeModal from "../components/Documents/NewNoticeModal";
import DocumentsToolbar from "../components/Documents/DocumentsToolbar";


interface Item {
    id: string;
    name: string;
    register_date: string | number | Date;
    office: string;
    type: any;
}

interface Item2 {
    id: string;
    office: string;
    officer: string;
    sergeant: string;
    recruit: string;
}

export default function Documents(props: any) {


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [headName, setHeadName] = React.useState('');
    const [headPosition, setHeadPosition] = React.useState('');
    const [phoneResp, setPhoneResp] = React.useState('');
    const [phoneHead, setPhoneHead] = React.useState('');

    const [stateTable2, setStateTable2] = React.useState<Item2[]>([]);
    const [stateTable1, setStateTable1] = React.useState({
        affiliations: 'нет',
        all_worker: '',
        all_war: '',
        officer: '',
        recruit: '',
        war_mp: '',
        officer_mp: '',
    });

    const [openModal, setOpenModal] = React.useState(false);
    const [city, setCity] = React.useState('');
    const [openNoticeModal, setOpenNoticeModal] = React.useState(false);
    const [isOpenDeleted, setOpenDeleted] = useState(false);
    const [deletedData, setDeletedData] = useState([]);
    const [respPerson, setRespPerson] = useState('');

    const handleClickOpenNoticeModal = () => {
        setOpenNoticeModal(true);
    };
    const handleCloseNoticeModal = () => {
        setOpenNoticeModal(false);
    };
    const [updateTable, setUpdateTable] = React.useState(null as any);

    const handleClickOpenModal = () => {
        setOpenModal(true);

        const axios = new Axios();
        // @ts-ignore
        axios.post(`worker/recruit/${localStorage.getItem('id')}`, {})
            .then(res => {
                setStateTable1({
                    affiliations: 'нет',
                    all_worker: res.data.all_worker,
                    all_war: res.data.all_war,
                    officer: res.data.officer,
                    recruit: res.data.recruit,
                    war_mp: res.data.war_mp,
                    officer_mp: res.data.officer_mp,
                })

                setStateTable2(res.data.table)

            })
            .catch(err => {
                console.log(err)
            })
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        const data = {
            'order': '-register_date',
            'entering': false
        }
        const axios = new Axios();
        axios.post(`worker/report/${localStorage.getItem('id')}/filter/?page=${page + 1}&page_size=${rowsPerPage}`, data)
            .then(res => {
            })
            .catch(err => {
                console.log(err);
            })
        axios.get(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}/`)
            .then(res => {
                setHeadName(res.data.full_name_head)
                setHeadPosition(res.data.position_of_head)
                setPhoneResp(res.data.phone)
                setCity(res.data.settlement)
                setRespPerson(res.data.full_name)
                setPhoneHead(res.data.organization_phone)
            })
    }, [page, rowsPerPage, openModal, updateTable]);

    const handleClickOpenDeletedLetters = () => {
        setOpenDeleted(true);
        getDeletedDate()
    };
    const getDeletedDate = () => {
        const axios = new Axios();
        axios.post(`worker/report/archive/${localStorage.getItem('id')}/filter/`, {entering: false})
            .then(res => {
                setDeletedData(res.data.results)
            })
    };

    return (
        <div>
            <span className={'title_'}>Исходящие документы, Акты и Отчеты   <span
                style={{color: 'black', paddingLeft: '10px'}}> {localStorage.getItem('company')} </span></span>
            <div className='wrapp-btn-createReport'>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={() => props.history.push('/home')}>
                        Назад
                    </Button>
                </div>

              <div className='btn-createReport-container'>

              <div>  <div className='btn-createReport'>
                        <Button variant="contained">
                            <Link to="/acts" className={'link'} style={{color: 'black'}} target="_blank"> Акт приёма и
                                передачи документов по воинскому учёту </Link>
                        </Button>
                    </div>
                    <div className='btn-createReport'>
                        <Button variant="contained" onClick={handleClickOpenModal}>
                            Отчёт о численности работников
                        </Button>
                    </div>
                    {/*
                    <div className='btn-createReport'>
                        <Button variant="contained" onClick={() => props.history.push('/reportForm6')}>
                            отчёт по Форме 6
                        </Button>
                    </div>
                    */}
              </div>
                  <div> <div className='btn-createReport'>
                      <Button variant="contained" onClick={handleClickOpenNoticeModal}>
                          Добавить
                      </Button>
                  </div>
                      <div className='btn-createReport'>
                          <Button variant="contained" onClick={handleClickOpenDeletedLetters} style={{width: '459px'}}>
                              Удалённые файлы раздела "Исходящие документы"
                          </Button>
                      </div> </div>
                    </div>


            </div>

            <NewNoticeModal
                type={'reports'}
                handleClickOpen={handleClickOpenNoticeModal}
                handleClose={handleCloseNoticeModal}
                open={openNoticeModal}
                updateTable={setUpdateTable}
                respPerson={respPerson}
                setRespPerson={setRespPerson}
            />

            <ReportsTable
                // items={items}
                //   countItems={countItems}
                // setItems={setItems}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                updateTable={updateTable}
                getDeletedDate={getDeletedDate}
                setOpenDeleted={setOpenDeleted}
                isOpenDeleted={isOpenDeleted}
                setDeletedData={setDeletedData}
                deletedData={deletedData}
            />

            <NewReportModal
                city={city}
                handleClickOpen={handleClickOpenModal}
                handleClose={handleCloseModal}
                open={openModal}
                stateTable2={stateTable2}
                stateTable1={stateTable1}
                headName={headName}
                headPosition={headPosition}
                phoneHead={phoneHead}
                setphoneHead={setPhoneHead}
                setHeadPosition={setHeadPosition}
                setHeadName={setHeadName}
                phoneResp={phoneResp}

            />


        </div>
    )
}
