import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Axios from '../../utils/axios';
import DocumentsTable from '../Documents/DocumentsTable';
import DocumentsToolbar from '../Documents/DocumentsToolbar';


export interface Item {
    id: string;
    name: string;
    register_date: string | number | Date;
    office: string;
    type: any;
}

type Order = 'asc' | 'desc';

const LettersModal = ({open, handleClose, nameOffice}: any) => {
    const [countItems, setCountItems] = React.useState(0);
    const [items, setItems] = React.useState<Item[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('register_date');

    const [updateTable, setUpdateTable] = React.useState(null as any);

    const [officeName, setOfficeName] = useState('');

    const [columns, setColumns] = useState(null as any);
    const [defaultColumns, setDefaultColumns] = useState([] as any);
    const [openModalSettings, setOpenModalSettings] = useState(false);

    const handleClickOpenSettings = () => {
        setOpenModalSettings(true);
    };

    const handleCloseSettings = () => {
        setOpenModalSettings(false);
    };

    useEffect(() => {
        const axios = new Axios();
        axios.get(`user/me/`)
            .then(res => {
                const result = Object.keys(res.data.setting['3']);
                setDefaultColumns(result)
            })

    }, []);

    useEffect(() => {
        const axios = new Axios();
        axios.get(`worker/field/3`)
            .then(res => {
                const result = [];
                for (let i = 0; i < defaultColumns.length; i += 1) {
                    result.push({});
                }

                for (const [key, value] of Object.entries(res.data)) {
                    const index = defaultColumns.indexOf(key);
                    if (index !== -1) {
                        result[index] = {
                            id: key,
                            label: value,
                            isSelected: true
                        };
                    } else {
                        result.push({
                            id: key,
                            label: value,
                            isSelected: false
                        })
                    }
                }
                ;
                let newArr = result.filter((i: any) => i.id !== "flag" && i.id !== "entering")
                newArr.map((i: any) => {
                    if (i.label === 'Docs') {
                        i.label = "Документ"
                    } else return i
                })
                setColumns(newArr);
            })
            .catch(err => {
                console.log(err);
            })
    }, [defaultColumns])


    const handleChangeOfficeName = (event: any) => {
        setOfficeName(event.name as string);
    };


    const [valueDocumentType, setDocumentType] = useState(0);
    const handleChangeDocumentType = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDocumentType(event.target.value as number);
    };

    useEffect(() => {
        let data = {};

        let order_ = '';
        if (order === 'asc') {
            order_ = '-'
        }

        if (officeName.length > 0 && valueDocumentType > 0) {
            data = {
                "office__name__contains": officeName,
                'type': valueDocumentType,
                order: `${order_}${orderBy}`,

                //'entering' : true
            }
        } else if (officeName.length > 0) {
            data = {
                "office__name__contains": officeName,
                order: `${order_}${orderBy}`,
                //'entering' : true
            }
        } else if (valueDocumentType > 0) {
            data = {
                'type': valueDocumentType,
                order: `${order_}${orderBy}`,

                //'entering' : true
            }
        } else data = {
            order: `${order_}${orderBy}`,
            office__name: nameOffice
            //'entering' : true
        }

        const axios = new Axios();
        axios.post(`worker/report/${localStorage.getItem('id')}/filter/?page=${page + 1}&page_size=${rowsPerPage}`, data)
            .then(res => {
                setCountItems(res.data.count);
                setItems(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
    }, [page, rowsPerPage, officeName, valueDocumentType, order, orderBy, updateTable, nameOffice]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='xl'
                scroll={'body'}
            >
                <DialogTitle
                    id="alert-dialog-title">{`Письма для военного комиссариата ${nameOffice.slice(20)}`}</DialogTitle>
                <DialogContent>
                    <div>
                        <DocumentsToolbar
                            valueDocumentType={valueDocumentType}
                            handleChangeDocumentType={handleChangeDocumentType}
                            handleChangeOfficeName={handleChangeOfficeName}
                            updateTable={updateTable}
                            setOfficeName={setOfficeName}
                            officeName={officeName}
                            handleClickOpen={handleClickOpenSettings}
                        />

                        <DocumentsTable
                            items={items}
                            countItems={countItems}
                            setItems={setItems}
                            officeName={officeName}
                            valueDocumentType={valueDocumentType}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            updateTable={updateTable}
                            order={order}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            setOrder={setOrder}
                            setUpdateTable={setUpdateTable}
                            columns={columns}
                            openModalSettings={openModalSettings}
                            handleCloseSettings={handleCloseSettings}
                            setColumns={setColumns}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Назад
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default LettersModal;