//@ts-nocheck
import React, {useEffect, useState} from 'react';
import '../LandingPage/LandingPage.css';
import Axios from "../../utils/axios";

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}

const ExtendedModeText = () => {

    const [data, setData] = useState([])

    useEffect(() => {
        const axios = new Axios();
        axios.get(`control/page/1/`)
            .then(res => {
                setData(res.data.page);
            })
    }, []);

    return (
        <div className='details_block_text_more'>
            <div><strong>Последовательность шагов сотрудничества наших организаций для варианта "Расширенный режим"
                :</strong></div>
            {data[2] ? data[2].text.split('\r\nПоследовательность шагов сотрудничества наших организаций для варианта \"Расширенный режим\" :')[1].split('\r\n').map((item: any) => {
                const index = item.indexOf('https')
                const index_ = item.includes('https://voenkomat.by/responsibility');
                let str='https://voenkomat.by/responsibility'

                if (index > 0 && index_) {
                    const buf = item.slice(index).split(' ') as any;
                    const link = buf.shift();
                    return <p key={getRandomInt(876543)}>
                        {item.substr(0, index)} <a href={link}>{link}</a> <span> и </span>  <a href={str}>{str} ;</a>
                    </p>
                } else if (index > 0 && !index_) {
                    const buf = item.slice(index).split(' ') as any;
                    const link = buf.shift();
                    return <p key={getRandomInt(876543)}>
                        {item.substr(0, index)} <a href={link}>{link}</a> {buf.join(' ')}
                    </p>
                } else
                    return <p className='details_block_text_p' key={getRandomInt(876543)}>{item}</p>
            }) : null}
        </div>
    )
}
export default ExtendedModeText;
