import {OFFICE_ID_VALUE} from '../types'

const initialState = {
   office_id_value:''
}

const lettersReducer= (state = initialState, action: any)=> {
    switch (action.type) {
        case OFFICE_ID_VALUE:
            return {
                ...state,
                office_id_value: action.office_id_value
            };
        default:
            return state;
    }
};

export const getOfficeId = (office_id_value: string) => {
    return {type: OFFICE_ID_VALUE, office_id_value}
};
export default lettersReducer;
