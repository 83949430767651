import React from 'react';
import style from '../StartingMilitary/StartingMilitary.module.css';
import Button from "@material-ui/core/Button";



const JobDescription=(props: any)=> {

    return (
        <div style={{margin: '330px auto 10px auto'}}>
            <span className='title_letters'>Должностная инструкция ответственного за воинский учёт</span>
            <div className='btn-wrapper'>
                <Button variant="contained" onClick={() => props.history.push('/start_military')}>
                    Назад
                </Button>
            </div>

            <div className={style.wrapLetter}>
             <span>1. Выведите проект должностной инструкции и внесите необходимые данные</span>
             <span>2. Согласованный оригинал Инструкции необходимо сохранить в архиве воинского учета организации</span>
                <Button variant="contained"  color="primary" >
                  <a href={'https://voenkomat.by/war/media/Должностная_инструкция.docx'} style={{color:'white',fontSize:'14px'}}>
                      Вывести проект ИНСТРУКЦИИ
                  </a>
                </Button>
            </div>

        </div>
    )
}

export default JobDescription;
