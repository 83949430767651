import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import {Link, NavLink} from 'react-router-dom';
import '../components/Home/Home.css';
import DecreeModal from '../components/Home/DecreeModal/DecreeModal';
import ArrearsOfLettersModal from '../components/ArrearsOfLettersModal/ArrearsOfLettersModal';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import Axios from '../utils/axios';
import outPutFunction from "./OutPut";
import Alert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core/styles';
import {IHistory, ILocation, IMatch} from "../utils/type";

interface IProps {
    history: IHistory,
    location: ILocation,
    match: IMatch,
}

function Home(props: IProps) {

    const [isNotification, setNotification] = useState(false);
    const [critical, setCritical] = useState(0);
    const [notifications, setNotifications] = useState({} as object);
    const [openArrearsModal, setOpenArrearsModal] = React.useState(false);
    const [openDecreeModal, setOpenDecreeModal] = React.useState(false);
    const [responsiblePerson, setResponsiblePerson] = React.useState('');

    const handleClickOpenDecree = () => {
        setOpenDecreeModal(true);
    };
    const handleCloseDecree = () => {
        setOpenDecreeModal(false);
    };

    const handleClickOpenArrears = () => {
        setOpenArrearsModal(true);
    };
    const handleCloseArrears = () => {
        setOpenArrearsModal(false);
    };

    useEffect(() => {
        const axios = new Axios();
        axios.get(`worker/time/${localStorage.getItem('id')}`)
            .then(res => {
                const dismissal = Object.values(res.data.dismissal);
                const changes = Object.values(res.data.changes);
                const report = Object.values(res.data.report);
                setCritical(report.length + dismissal.length + changes.length)
                if (dismissal.length > 1 || changes.length > 1 || report.length > 1) {
                    setNotifications(res.data);
                    setNotification(true);
                    //handleClickOpenArrears();
                }

            })
            .catch(err => {
                console.log(err);
            })

        axios.get(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}/`)
            .then(res => {
                setResponsiblePerson(res.data.full_name);
                localStorage.setItem('resp-for-documents', res.data.full_name)
                localStorage.setItem('resp-for-documents-position', res.data.position)
            })
    }, []);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
    }));
    const classes = useStyles();

    return (
        <div className="container">
            <div>
                <NavLink to={'/'}>
                    <button className={'wrap_resp_btn'} onClick={outPutFunction}>Выход</button>
                </NavLink>
            </div>
            <div className='home-header'>
                {
                    isNotification ? <div className='iconNotification-wrapp'>
                            <NotificationImportantIcon
                                color='error'
                                fontSize='large'
                                className='iconNotification'
                                onClick={() => {
                                    handleClickOpenArrears()
                                }}
                            />
                            <Alert severity="error" style={{cursor:'auto'}}>В системе обнаружено <b>{critical}</b> критичных событий!
                                Нажмите ссылку, чтобы открыть перечень этих событий и определить Ваши действия!
                            </Alert>
                        </div>
                        : null
                }
                <div className={'wrap_resp'}>
                    <div className='responsible'>
                        <p className={'style.btn'}>Ответственный: </p>
                        <p className='responsibleLink'
                           onClick={handleClickOpenDecree}>{responsiblePerson}</p>
                        <DecreeModal handleClickOpen={handleClickOpenDecree} handleClose={handleCloseDecree}
                                     open={openDecreeModal}/>
                    </div>
                </div>
            </div>
            <Link to="/company" className='respBtn'>
                <Button variant="contained" style={{background: '#F0BA14'}}>Выбрать другую организацию</Button>
            </Link>
            <div className={'title-cabinet'}>
                <span>Личный кабинет организации</span> <span
                style={{paddingLeft: '10x', fontSize: '28px'}}><b>{localStorage.getItem('company')}</b></span>
            </div>
            <div className='home-categories'>
                <section className={'first-section'}>
                    <div className={'btn_'}>
                        <Link to="/start_military" className={'link'}>
                            <Button style={{width: '100%', height: '100%'}} variant="contained" className={'btn'}>Начиная
                                Воинский Учёт организации</Button>
                        </Link>
                    </div>
                    <div className={'btn_'}>
                        <Link to="/militaryBase" className={'link'}>
                            <Button style={{width: '100%', height: '100%'}} variant="contained" className={'btn'}>База
                                военнообязанных и призывников
                                организации</Button>
                        </Link>
                    </div>
                    <div className={'btn_'}>
                        <Link to="/personalCards" className={'link'}> {/* target="_blank"*/}
                            <Button style={{width: '100%', height: '100%'}} variant="contained" className={'btn'}>Личные
                                карточки военнообязанных и призывников</Button>
                        </Link>
                    </div>
                </section>

                <section className={'second-section'}>
                    <div className={'btn_'}>
                        <Link to="/reports" className={'link'}>
                            <Button style={{width: '100%', height: '100%'}} variant="contained" className={'btn'}>Отчёты и исходящие документы</Button>
                        </Link>
                    </div>
                    <div className={'btn_'}>
                        <Link to="/documents" className={'link'}>
                            <Button style={{width: '100%', height: '100%'}} variant="contained" className={'btn'}>Входящие
                                документы</Button>
                        </Link>
                    </div>
                    <div className={'btn_'}>
                        <Link to="/letters" className={'link'}>
                            <Button style={{width: '100%', height: '100%'}} variant="contained" className={'btn'}>Переписка
                                с Военными комиссариатами</Button>
                        </Link>
                    </div>
                </section>

                <section className={'third-section'}>
                    <div className={'btn_'}>
                        <Link to="/offices" className={'link'}>
                            <Button style={{width: '100%', height: '100%'}} variant="contained" className={'btn'}>Военные
                                комиссариаты Республики Беларусь</Button>
                        </Link>
                    </div>
                    <div className={'btn_'}>
                        <Link to="/regulations" className={'link'}>
                            <Button style={{width: '100%', height: '100%'}} variant="contained" className={'btn'}>Нормативные
                                документы Республики Беларусь по
                                воинскому учёту</Button>
                        </Link>
                    </div>
                    <div className={'btn_'}>
                        <Link to="/aboutCompany" className={'link'}>
                            <Button variant="contained" className={'btn'}
                                    style={{width: '100%', height: '100%'}}>Контакты</Button>
                        </Link>
                    </div>
                </section>
            </div>

            <ArrearsOfLettersModal
                open={openArrearsModal}
                handleClose={handleCloseArrears}
                history={props.history}
                notifications={notifications}
            />
        </div>
    )
}

export default Home;
