import React, { useEffect } from 'react';
import MaterialTable, { Column } from 'material-table';

export interface Row {
    time: Date | string;
    basis: string;
    position: string;
    worker?: string;
    tableData?: any
}

export interface TableState {
    columns: Array<Column<Row>>;
    data: Row[] | any;
}

const getData = (worker: any) => {
    const result = [] as any;
    worker.map((item: any) => {
        const data = {
            time: item.time,
            basis: item.basis,
            position: item.position,
            tableData: { id: item.id }
        }

        result.push(data)
    });
    return result;
}

export default function Table({ worker, stateTable, setStateTable }: any) {
    const [state, setState] = React.useState<TableState>({
        columns: [
            {
                title: 'Дата',
                field: 'time',
                cellStyle: {
                    width: 150,
                    minWidth: 150
                },
                headerStyle: {
                    width: 150,
                    minWidth: 150
                },
            },
            {
                title: 'Профессия, должность',
                field: 'position',
                cellStyle: {
                    width: 250,
                    minWidth: 250,
                },
                headerStyle: {
                    width: 250,
                    minWidth: 250,  
                            
                },
            },
            {
                title: 'Основание',
                field: 'basis',
                cellStyle: {
                    width: 150,
                    minWidth: 150
                },
                headerStyle: {
                    width: 150,
                    minWidth: 150
                },
            },
        ],
        data: getData(worker),
    });

    useEffect(() => {
        setState((prevState) => {
            let data = [...prevState.data];
            data = getData(worker);
            return { ...prevState, data };
        });
    }, [worker]);

    return (
        <MaterialTable
            title=""
            columns={state.columns}
            data={state.data}
            editable={{
                onRowAdd: (newData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                            setStateTable((prevState: any) => {
                                const obj = {
                                    time: newData.time,
                                    basis: newData.basis,
                                    position: newData.position,
                                    worker: '1',
                                };
                                const data = prevState;
                                data.push(obj);
                                return data;
                            })
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            if (oldData) {
                                setState((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                                setStateTable((prevState: any) => {

                                    const obj = {
                                        time: newData.time,
                                        basis: newData.basis,
                                        position: newData.position,
                                        worker: '1',
                                    };

                                    const data = prevState;
                                    data[oldData.tableData.id] = obj;
                                    return data;
                                })
                            }
                        }, 600);

                    }),
                onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            setState((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });

                            setStateTable((prevState: any) => {
                                const data = prevState;
                                data.splice(oldData.tableData.id, 1);
                                return data;
                            })
                        }, 600);
                    }),

            }}
            options={{
                search: false,
                paging: false,
            }}
            localization={{
                toolbar: {
                    nRowsSelected: '{0} выбрано строк'
                },
                header: {
                    actions: 'Действия',
                },
                body: {
                    emptyDataSourceMessage: 'Нет записей',
                    filterRow: {
                        filterTooltip: 'Фильтр'
                    },
                    editRow: {
                        deleteText: 'Вы уверены, что хотите удалить запись?',
                        cancelTooltip: 'Отмена',
                        saveTooltip: 'Сохранить'
                    },
                    addTooltip: 'Добавить',
                    deleteTooltip: 'Удалить',
                    editTooltip: 'Изменить',
                }
            }}
        />
    );
}