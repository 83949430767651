import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const SuccessfulSaveModal = ({ office, open,againOffice,
                                 handleClose,message }: any) => {

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{message} {againOffice==='' || !againOffice ? office : againOffice }</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SuccessfulSaveModal;