import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import React from "react";



export const WarningModal = ({ message, open, handleClose }: any) => {

    return (
        <>
            <Dialog
                open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
    <DialogContent>
    <DialogContentText id="alert-dialog-description">

        </DialogContentText>
        </DialogContent>
        </Dialog>
        </>
)
}