import React, {Suspense, useEffect, useState} from 'react';
import PeopleTable from '../../components/PersonalCardsTable/TableAllUsers'
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import './PersonalCards.css';
import ErrorBoundary from '../../utils/ErrorBoundary';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Axios from "../../utils/axios";
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";


function PersonalCards(props: any) {

    const [showDeletedCards, setShowDeletedCards] = useState(false);
    const [deletedData, setDeletedData] = useState([]);
    const [isUpdate, setUpdate] = useState(0);

    const handleCloseDeletedCards = () => {
        setShowDeletedCards(false)
    };

    const handleOpenDeletedCardsList = () => {
        setShowDeletedCards(true);
        deletedCards()
    };
const deletedCards=()=>{
    const axios = new Axios()
    axios.get(`worker/archive/${localStorage.getItem('id')}/`)
        .then(res => {
            setDeletedData(res.data.results)
        })
}
    useEffect(() => {
        deletedCards()
    }, [isUpdate])

    return (
        <div className="container_militaryBase">
            <span className='title_military_base'>Личные карточки военнообязанных и призывников</span>
            <div className='toolbar'>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={() => props.history.push('/home')}>
                        Назад
                    </Button>
                </div>

                <Link to="/newPersonalCard2" target="_blank" className='link'>
                    <Button variant="contained">Добавить</Button>
                </Link>
                <Button variant="contained" onClick={handleOpenDeletedCardsList}>Удалённые карточки</Button>
                <DeletedCards handleCloseDeletedCards={handleCloseDeletedCards}
                              showDeletedCards={showDeletedCards}
                              deletedData={deletedData} setUpdate={setUpdate}/>
                {/*<Link to="/newPersonalCard" target="_blank" className='link'>*/}
                {/*    <Button variant="contained">Добавить (старый вид)</Button>*/}
                {/*</Link>*/}
            </div>
            <ErrorBoundary>
                <Suspense fallback={<div>Загрузка...</div>}>
                    <PeopleTable props={props} isUpdate={isUpdate}/>
                </Suspense>
            </ErrorBoundary>

        </div>
    )
}

export default PersonalCards;


const DeletedCards = ({handleCloseDeletedCards, showDeletedCards, deletedData, setUpdate}: any) => {

    const axios = new Axios()

    const restoreCard = (idCard: any) => {
        axios.delete(`worker/archive/${localStorage.getItem('id')}/${idCard}/`,{})
            .then(res => {
                setUpdate(new Date().getMilliseconds())
            })
    };

    deletedData.sort((a:any, b:any) => a.delete_date > b.delete_date ? -1 : 1);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog onClose={handleCloseDeletedCards} aria-labelledby="customized-dialog-title"
                open={showDeletedCards} fullScreen={fullScreen}>
            <DialogContent dividers >
                <div className={'wrapper-deleted-tables'}>
                    <table>
                        <thead>
                        <tr>
                            <th>ФИО</th>
                            <th>Военкомат</th>
                            <th>Дата удаления</th>
                            <th>Восстановление</th>
                        </tr>
                        </thead>
                        <tbody>

                        {deletedData.length !== 0 && deletedData.map((item: { full_name: string, office: string, delete_date: string, id: any }
                            , index: number) => {
                            return <tr key={index}>
                                <td>{item.full_name}</td>
                                <td>{item.office}</td>
                                <td>{item.delete_date ? item.delete_date.split('-').reverse().join('-') : ''}</td>
                                <td>
                                    <button className={'btn-table'} onClick={() => {
                                        restoreCard(item.id)
                                    }}>Восстановить
                                    </button>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" onClick={handleCloseDeletedCards}>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    )
}
