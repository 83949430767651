import React, {useEffect} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import clear from '../../pages/assets/images/clear.svg';
import './TableAllUsers.css'

interface FormControlSelectProps {
    selectBy: any,
    handleClickOpen: any,
    filter:{name:string,value:string},
    setFilter:({name,value}:any)=>void,
    setSearchSet:(search:boolean)=>any;
    setItems:any
}

export const FormControlSelect: React.FC<FormControlSelectProps> = props => {

    const [selected, setSelect] = React.useState('full_name');
    const [value, setValue] = React.useState('');
    const [firstAge, setFirstAge] = React.useState('');
    const [secondAge, setSecondAge] = React.useState('');

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelect(event.target.value as string);
    };

    const handleChangeTextField = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
        if(event.target.value !=='') {
            props.setItems([])
        } else if(event.target.value ==='') {
            props.setFilter({name:'',value:''})
        }

    };
    const handleClearText = () => {
props.setFilter({name:'',value:''})
        setValue('')
    }

    const selectBy = () => {
        props.selectBy(selected, value, { firstAge, secondAge });
    }
    useEffect(()=>{
        return firstAge!=='' || secondAge !=='' || value !=='' ? props.setSearchSet(true) : props.setSearchSet(false)
    },[firstAge,secondAge,value])
    return (
        <div className='formControl'>
            <div>
                <Button variant="outlined" onClick={() => { props.handleClickOpen() }} >
                    Вид
                </Button>
            </div>
            <div className='formControl-right'>
                <div className='wrapp-age'>
                    Возраст:
                <div className='age'>
                        <TextField id="age_first" className="filterAge" label="От" onChange={(e: any) => setFirstAge(e.target.value)} />
                    </div>
                    —
                <div className='age'>
                        <TextField id="age_second" className="filterAge" label="До" onChange={(e: any) => setSecondAge(e.target.value)} />
                    </div>
                </div>

                <div className="search">
                    <div className="search-box">
                        <InputLabel id="demo-simple-select-label"/>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selected}
                            onChange={handleChange}
                        >
                            <MenuItem value={'grade__name'}>Звание</MenuItem>
                            <MenuItem value={'full_name'}>ФИО</MenuItem>
                            <MenuItem value={'date_of_birth'}>Дата рождения</MenuItem>
                            <MenuItem value={'office__short_name'}>Военкомат</MenuItem>
                            <MenuItem value={'special'}>Бронь</MenuItem>
                            <MenuItem value={'family_half'}>Состав семьи</MenuItem>
                            <MenuItem value={'requirement'}>Мобилизационное предписание</MenuItem>
                            <MenuItem value={'marital_status__name'}>Семейное положение</MenuItem>
                            <MenuItem value={'education__name'}>Образование</MenuItem>
                            <MenuItem value={'address'}>Адрес проживания</MenuItem>
                            <MenuItem value={'registered_address'}>Адрес регистрации</MenuItem>
                            <MenuItem value={'military'}>Номер военного билета</MenuItem>
                            <MenuItem value={'wyc'}>ВУС</MenuItem>
                        </Select>
                    </div>
                    <div className="search-box">
                        <TextField id="standard-basic" label="Поиск" onChange={handleChangeTextField} value={value} />
                    <img alt={'clear-image'} src={clear} className={'clear-search-box'}
                    onClick={handleClearText}/>
                    </div>
                    <div className="search-box">
                        <Button onClick={selectBy}>Поиск</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}