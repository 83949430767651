import React from 'react';
import style from './Forms.module.css';
import Button from "@material-ui/core/Button";


class JournalTitle extends React.Component<any> {
    render() {
        return (
            <div style={{marginTop: '225px'}}>
                <span className='title_letters'>Журнал сверок</span>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={() => this.props.history.push('/forms')}>
                        Назад
                    </Button>
                </div>
            </div>
        )
    }
}

class JournalForPrint extends React.Component<any> {


    render() {
        return (
            <div style={{marginTop: '76px'}}>
                <div className={style.wrapper}>
                    <div className={style.title}>ЖУРНАЛ</div>
                    <div className={style.title_}>проверок состояния воинского учёта и бронирования военнообязанных
                    </div>
                    <div className={style.form}>
                        <div className={style.formInput}>
                            <input/>
                            <span>( организации )</span>
                        </div>
                        <div className={style.formInput}>
                            <input/>
                            <span>( адрес )</span>
                        </div>
                        <div className={style.formInput}>
                            <input/>
                            <span>( фамилия, собственное имя, отчество руководителя )</span>
                        </div>
                        <div className={style.formInput}>
                            <input/>
                            <span>( работник, отвечающий за воинский учет )</span>
                        </div>
                    </div>
                </div>

                <div className={style.wrapperTable}>
                    <table>
                        <tr>
                            <td>Дата проверки</td>
                            <td>Должность, Фамилия, Имя, Отчество, проверяющего</td>
                            <td>Результат проверки, основные недостатки и выводы</td>
                            <td>Отметка об устранении выявленных недостатков</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                        </tr>
                    </table>
                </div>
            </div>
        )
    }
}

class Journal extends React.Component<any> {

    state={
        isPrint:false
    }
    printDocument=()=>{
        this.setState({isPrint: true})
        setTimeout(()=>{window.print()},2000)
        setTimeout(()=>{this.setState({isPrint: false})},5000)
    }

    render() {

        return (<>
                {!this.state.isPrint && <JournalTitle {...this.props} />}
                <JournalForPrint/>
                <div style={{ position: 'relative', top: '220px'}}>
                    {!this.state.isPrint && <Button variant="contained" onClick={this.printDocument} className={style.btn}>Распечатать</Button>}
                </div>
            </>
        )
    }
}

export default Journal;

