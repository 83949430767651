import React, {useState, useEffect} from 'react';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Axios from '../../utils/axios';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import CompanyPDF from './CompanyPDF'
import './RegistrationCompany.css'
import {pdf} from "@react-pdf/renderer";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";


const maxLength = (max: number, min: number) => (value: string | any[]) => {
    const errormessage = (message: string) => {
        return message
    };
    return value && value.length > max || value && value.length < min ? errormessage(`Количество символов должно быть от 9 до 12`) : ''
}


const maxLengthVal = maxLength(12, 9)

const renderField = ({type, input, id, meta: {touched, error, warning}}: any, props: any) => {

    return (
        <div>
            <div>
                <input {...input} type={type} id={id} className={'registr-input'}
                       style={{
                           borderTop: 'none',
                           borderLeft: 'none',
                           borderRight: 'none',
                           borderBottom: '1px solid #968f8f'
                       }}/>
            </div>
            {touched && ((error && <span style={{color: 'red'}}>{error}</span>))}
        </div>
    )
}

const MyInput = (props: any) => {
    if (props.input.value === 'null') {
        props.input.value = ''
    }

    return <>
        <Input
            id={props.id}
            className={'registr-input'}
            inputProps={props.input}
            disabled={props.disabled}
        />
    </>
}

const MyInputWithLength = (props: any) => {
    if (props.input.value === 'null') {
        props.input.value = ''
    }
    return <>
        <Input
            id={props.id}
            className={'registr-input'}
            inputProps={props.input}
            disabled={props.disabled}
            style={{width: '100%'}}
        />
    </>
}

const FileInput = (props: any) => {

    const [logo, setLogo] = useState('')

    const onChange = (e: any) => {
        const {input: {onChange}} = props
        onChange(e.target.files[0]);
        setLogo(URL.createObjectURL(e.target.files[0]))
    }

    return (<>
            <Input
                onChange={onChange}
                type="file"
                className={'registr-input'}
            />
            <img className={'logo'} src={logo}/>
        </>
    );
};

const RegistrationCompanyForm = (props: any) => {

    const [areaEmpty, setAreaEmpty] = useState(false);
    const [postalAreaEmpty, setPostalAreaEmpty] = useState(false);


    const url = props.url;
    let flag_pdf=true;
    Object.keys(props.initialValues).map((fieldName, i) => {
                if(!props.initialValues[fieldName]
                    && fieldName!="logotip" 
                    && fieldName!="name"
                    && fieldName!="area"
                    && fieldName!="postal_area"
                    && fieldName!="branch"
                    && fieldName!="delete_date"
                    && fieldName!="delete"
                    && fieldName!="deleter"){
                    flag_pdf=false
                    return      
                }})
    // @ts-ignore
    return (
        <form onSubmit={props.handleSubmit} style={{margin: '0px', maxWidth: '100%'}}>
            <div className='registration-container'>
                <h3>Основные данные организации</h3>
                <div>Поля, помеченные * обязательны к заполению Пользователем!</div>
                <div>Поля, помеченные <span style={{color: 'red'}}>**</span> обязательны к заполению Администратором!
                </div>
                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>УНП<sup>*</sup></div>

                    <Field name="unp_inn" type="text"
                           id="unp_inn"
                           className={'registr-input'}
                           component={renderField}
                           validate={maxLengthVal}
                    />
                    <FormHelperText id="unp_inn">от 9 до 12 символов</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={''} style={{fontWeight: 'bold'}}>ОКПО<sup>*</sup></div>
                    <Field name="rcbo_psrn" type="text"
                           id="rcbo_psrn"
                           className={'registr-input'}
                           component={renderField}
                           validate={maxLengthVal}
                    />
                    <FormHelperText id="rcbo_psrn">от 9 до 12 цифр</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={''} style={{fontWeight: 'bold'}}>Полное юридическое название<sup>*</sup></div>
                    <Field
                        id="full_legal_name"
                        className={'registr-input'}
                        component={'textarea'}
                        name="full_legal_name"
                        style={{width: '100%'}}
                    />
                </div>

                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Краткое название
                        организации<sup>* <span style={{color: 'red'}}>**</span> </sup></div>
                    <Field
                        id="short_organization_name"
                        name="short_organization_name"
                        className={'registr-input'}
                        component={MyInputWithLength}
                    />
                </div>
                <>
                    <div className={'registr-box'}>
                        <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Форма собственности<sup>*</sup>
                        </div>
                        <Field
                            id="form_of_ownership"
                            name="form_of_ownership"
                            className={'registr-input'}
                            component={MyInput}
                        />
                    </div>
                </>

                <div className={'registr-box'}>
                    <div className={''} style={{fontWeight: 'bold'}}>Численность штатных сотрудников на январь отчётного
                        года<sup>* <span style={{color: 'red'}}>**</span> </sup></div>
                    <Field
                        id="quantity"
                        className={'registr-input'}
                        component={MyInput}
                        name="quantity"
                    />
                </div>
                <div className={'registr-box'}>
                    <div className={''} style={{fontWeight: 'bold'}}>Число военнообязанных и призывников<sup>*</sup>
                    </div>
                    <Field
                        id="quantity_war"
                        className={'registr-input'}
                        component={MyInput}
                        name="quantity_war"
                    />
                </div>
            </div>

            <div className='registration-container'>
                <h3>Контактные данные организации<sup>*</sup></h3>
                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Телефон<sup>* <span
                        style={{color: 'red'}}>**</span> </sup></div>
                    <Field
                        id="organization_phone"
                        name="organization_phone"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="organization_phone">Пример: +375 17 200 00 00</FormHelperText>
                </div>

                <div className={'registr-box'} style={{fontWeight: 'bold'}}>
                    <div className={''}>Факс</div>
                    <Field
                        id="organization_fax"
                        name="organization_fax"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="organization_fax">Пример: +375 17 200 00 00</FormHelperText>
                </div>

                <div className={'registr-box'} style={{fontWeight: 'bold'}}>
                    <div className={''}>Веб-сайт</div>
                    <Field
                        id="organization_website"
                        name="organization_website"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="organization_website">Пример: http://example.com</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>E-mail<sup>* </sup></div>
                    <Field
                        id="organization_mail"
                        name="organization_mail"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="rcbo_psrn">Пример: myname@example.com</FormHelperText>
                </div>
            </div>

            <div className='registration-container'>
                <h3>Юридический адрес</h3>
                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Страна<sup>*</sup></div>
                    <Field
                        id="country"
                        name="country"
                        className={'registr-input'}
                        component={MyInputWithLength}
                    />
                </div>
                <div className={'registr-box'}>
                    <div className={''} style={{fontWeight: 'bold'}}>Населенный пункт<sup>*</sup></div>
                    <Field
                        id="settlement"
                        name="settlement"
                        className={'registr-input'}
                        component={MyInputWithLength}
                        onChange={(e: any) => {
                            return e.target.value.indexOf('минск') !== -1 || e.target.value.indexOf('Минск') !== -1 ?
                                setAreaEmpty(true) : setAreaEmpty(false)
                        }}
                    />
                    <FormHelperText id="organization_website">Пример: г. Минск</FormHelperText>
                </div>
                {!areaEmpty &&
                <div className={'registr-box'}>
                    <div className={''} style={{fontWeight: 'bold'}}>Область<sup>*</sup></div>
                    <Field
                        id="area"
                        name="area"
                        className={'registr-input'}
                        component={MyInputWithLength}
                    />
                </div>
                }
                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Индекс<sup>*</sup></div>
                    <Field
                        id="index"
                        name="index"
                        className={'registr-input'}
                        component={MyInputWithLength}
                    />
                    <FormHelperText id="rcbo_psrn">Пример: 220004</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={''} style={{fontWeight: 'bold'}}>Адрес<sup>*</sup></div>
                    <Field
                        id="address"
                        className={'registr-input'}
                        component={'textarea'}
                        name="address"
                        style={{width: '100%'}}
                    />
                    <FormHelperText id="rcbo_psrn">Пример: пр-т Победителей, 7, к. 1119</FormHelperText>
                </div>
            </div>

            <div className='registration-container'>
                <h3>Почтовый адрес</h3>
                <div className='company-checked'>
                    <div>
                        <Field name="isAutoAddress" id="isAutoAddress" component={'input'} type="checkbox"
                               onChange={props.onChangeAddress}/>
                    </div>
                    <label htmlFor="isAutoAddress">Заполнить почтовой адрес из юридического</label>
                </div>
                {!props.checkedAddr && <>
                    <div className={'registr-box'}>
                        <div className={''} style={{fontWeight: 'bold'}}>Населенный пункт<sup>*</sup></div>
                        <Field
                            id="postal_settlement"
                            name="postal_settlement"
                            className={'registr-input'}
                            component={MyInputWithLength}
                            onChange={(e: any) => {
                                return e.target.value.indexOf('минск') !== -1 || e.target.value.indexOf('Минск') !== -1 ?
                                    setPostalAreaEmpty(true) : setPostalAreaEmpty(false)
                            }}
                        />
                        <FormHelperText id="organization_website">Пример: г. Минск</FormHelperText>
                    </div>
                    {!postalAreaEmpty &&
                    <div className={'registr-box'}>
                        <div className={''} style={{fontWeight: 'bold'}}>Область<sup>*</sup></div>
                        <Field
                            id="postal_area"
                            name="postal_area"
                            className={'registr-input'}
                            component={MyInputWithLength}
                        />
                    </div>
                    }
                    <div className={'registr-box'}>
                        <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Индекс<sup>*</sup></div>
                        <Field
                            id="postal_index"
                            name="postal_index"
                            className={'registr-input'}
                            component={MyInputWithLength}
                        />
                        <FormHelperText id="rcbo_psrn">Пример: 220004</FormHelperText>
                    </div>

                    <div className={'registr-box'}>
                        <div className={''} style={{fontWeight: 'bold'}}>Адрес<sup>*</sup></div>
                        <Field
                            id="postal_address"
                            className={'registr-input'}
                            component={'textarea'}
                            name="postal_address"
                            style={{width: '100%'}}
                        />
                        <FormHelperText id="rcbo_psrn">Пример: пр-т Победителей, 7, к. 1119</FormHelperText>
                    </div>
                </>}

            </div>

            <div className='registration-container'>
                <h3>Данные руководителя предприятия</h3>
                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>ФИО<sup>*</sup></div>
                    <Field
                        id="full_name_head"
                        name="full_name_head"
                        className={'registr-input'}
                        component={MyInputWithLength}
                    />
                    <FormHelperText id="full_name_head">Пример: Иванов Иван Иванович</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>ФИО в родительном
                        падеже<sup>*</sup></div>
                    <Field
                        id="full_name_head_r"
                        name="full_name_head_r"
                        className={'registr-input'}
                        component={MyInputWithLength}
                    />
                    <FormHelperText id="full_name_head_r">Пример: Иванова Ивана Ивановича</FormHelperText>
                </div>


                <div className={'registr-box'}>
                    <div className={''} style={{fontWeight: 'bold'}}>Должность руководителя<sup>*</sup></div>
                    <Field
                        id="position_of_head"
                        name="position_of_head"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="position_of_head">Пример: Директор</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Должность руководителя в
                        родительном падеже<sup>*</sup></div>
                    <Field
                        id="position_of_head_r"
                        name="position_of_head_r"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="position_of_head_r">Пример: Директора</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Действует на основании (в
                        родительном падеже)<sup>*</sup></div>
                    <Field
                        id="basis_head_r"
                        name="basis_head_r"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="basis_head_r">Доверенности №278 от 05.01.2013</FormHelperText>
                </div>

            </div>

            <div className='registration-container'>
                <h3>Лицо, ответственное за воинский учёт</h3>
                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>ФИО<sup>* <span
                        style={{color: 'red'}}>**</span> </sup></div>
                    <Field
                        id="full_name"
                        name="full_name"
                        className={'registr-input'}
                        component={MyInputWithLength}
                    />
                    <FormHelperText id="full_name">Пример: Иванов Иван Иванович</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={''} style={{fontWeight: 'bold'}}>ФИО в родительном падеже<sup>*</sup></div>
                    <Field
                        id="full_name_r"
                        name="full_name_r"
                        className={'registr-input'}
                        component={MyInputWithLength}
                    />
                    <FormHelperText id="full_name_r">Пример: Иванова Ивана Ивановича</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={''} style={{fontWeight: 'bold'}}>Должность<sup>*</sup></div>
                    <Field
                        id="position"
                        name="position"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="position">Пример: Ответственный за ВУ</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Должность в родительном
                        падеже<sup>*</sup></div>
                    <Field
                        id="position_r"
                        name="position_r"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="position_r">Пример: Ответственного за ВУ</FormHelperText>
                </div>


                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>E-mail<sup>* <span
                        style={{color: 'red'}}>**</span></sup></div>
                    <Field
                        id="mail"
                        name="mail"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="mail">Пример: myname@example.com</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Телефон<sup>*</sup></div>
                    <Field
                        id="phone"
                        name="phone"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="phone">Пример: +375 17 200 00 00</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Мобильный телефон</div>
                    <Field
                        id="mobile_phone"
                        name="mobile_phone"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="mobile_phone">необязательное поле</FormHelperText>
                </div>

                <div className={'registr-box'}>
                    <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Факс</div>
                    <Field
                        id="fax"
                        name="fax"
                        className={'registr-input'}
                        component={MyInput}
                    />
                    <FormHelperText id="fax">необязательное поле</FormHelperText>
                </div>
                {url === '/aboutCompany' ? <div className={'registr-box'}>
                        <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Логотип организации</div>
                        <img src={props.initialValues.logotip} alt={''} className={'imgLogo'}/>
                        <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Изменить Логотип организации</div>
                        <Field
                            id="logotip"
                            name="logotip"
                            type='file'
                            className={'registr-input'}
                            component={FileInput}
                        />
                    </div> :
                    <div className={'registr-box'}>
                        <div className={'classes.boxChild'} style={{fontWeight: 'bold'}}>Логотип организации<sup>*</sup>
                        </div>
                        <Field
                            id="logotip"
                            name="logotip"
                            type='file'
                            className={'registr-input'}
                            component={FileInput}
                        />
                    </div>}
            </div>

            <div className='btn-save-wrapper_'>
                <div>    {(url !== '/registrationCompany' && flag_pdf ) &&
                <button className='btn-save-pdf' onClick={() => {
                    props.setSavePDF(true)
                }}>Сохранить в PDF
                </button>}
                    <button className='btn-save' onClick={() => {
                        props.setSavePDF(false);
                        window.scrollTo(0, 122);
                    }}>Сохранить
                    </button>
                </div>
                {(flag_pdf &&
                <span style={{fontSize: '11px', paddingTop: '5px'}}>Если Вы вносили какие-либо изменения в форму- перед загрузкой формы
                    в PDF-формат сохраните изменения (Нажмите кнопку "Сохранить") </span>)}
                {(!flag_pdf &&
                <span style={{fontSize: '11px', paddingTop: '5px'}}>Для сохранения в PDF-формат заполните все поля и сохраните данные (Нажмите кнопку "Сохранить")</span>
                    )}
            </div>
        </form>
    )
}

const mapStateToProps = (state: any, props: any) => {
    return ({
        initialValues: props.initialValues, // retrieve name from redux store
        url: props.url
    })
};

const RegistrationCompanyReduxForm = connect(
    mapStateToProps
)(reduxForm({
    form: 'registration_company_form',
    enableReinitialize: true
})(RegistrationCompanyForm));


const RegistrationCompany = (props: any) => {

    const [isSavePDF, setSavePDF] = useState(false);
    const [warningWindow, setWarningWindow] = useState(false);
    const [message, setMessage] = useState('');
    const [checkedAddr, setIsChecked] = useState(false);

    const onChangeAddress = (e: any) => {
        return e.target.checked === true ?
            setIsChecked(true) : setIsChecked(false)
    }

    const handleOpenModal = () => {
        setWarningWindow(true);
    };
    const handleCloseModal = () => {
        setWarningWindow(false);
    };

    const onSubmit = (formData: any) => {

        const toSaveInfo = () => {
            setWarningWindow(false);

            // let postal: any;
            // if (checkedAddr) {
            //     postal = `${formData.index}, ${formData.settlement}, ${formData.address}`
            // } else postal = formData.postal_address;

            let body_1 = {
                'address': formData.address,
                'area': formData.area,
                'bank_code': formData.bank_code,
                'bank_name': formData.bank_name,
                'basis': formData.basis,
                'basis_head_r': formData.basis_head_r,
                'business_entity': formData.business_entity,
                'country': "Республика Беларусь",
                'currency': formData.currency,
                'department': formData.department,
                'description_of_activity': formData.description_of_activity,
                'division': formData.division,
                'document': formData.document,
                'document_date': formData.document_date,
                'exported_products': formData.exported_products,
                'fax': formData.fax,
                'form_of_ownership': formData.form_of_ownership,
                'full_legal_name': formData.full_legal_name,
                'full_name': formData.full_name,
                'full_name_head': formData.full_name_head,
                'full_name_head_r': formData.full_name_head_r,
                'full_name_r': formData.full_name_r,
                'identity_document': formData.identity_document,
                'identity_document_data': formData.identity_document_data,
                'index': formData.index,
                'logotip': formData.logotip,
                'mail': formData.mail,
                'mobile_phone': formData.mobile_phone,
                'name': formData.name,
                'organization_fax': formData.organization_fax,
                'organization_mail': formData.organization_mail,
                'organization_phone': formData.organization_phone,
                'organization_website': formData.organization_website,
                'phone': formData.phone,
                'position': formData.position,
                'position_of_head': formData.position_of_head,
                'position_of_head_r': formData.position_of_head_r,
                'position_r': formData.position_r,
                'postal_address': checkedAddr ? formData.address : formData.postal_address,
                'postal_area': checkedAddr ? formData.area : formData.postal_area,
                'postal_index': checkedAddr ? formData.index : formData.postal_index,
                'postal_settlement': checkedAddr ? formData.settlement : formData.postal_settlement,
                'quantity': formData.quantity,
                'quantity_war': formData.quantity_war,
                'rcbo_psrn': formData.rcbo_psrn,
                'settlement': formData.settlement,
                'settlement_account': formData.settlement_account,
                'short_organization_name': formData.short_organization_name,
                'tsbu': formData.tsbu,
                'unp_inn': formData.unp_inn,
                'user': `${localStorage.getItem('userID')}`
            }

            const body = new FormData()
            body.append('address', formData.address);
            body.append('area', formData.area);
            body.append('bank_code', formData.bank_code);
            body.append('bank_name', formData.bank_name);
            body.append('basis', formData.basis);
            body.append('basis_head_r', formData.basis_head_r);
            body.append('business_entity', formData.business_entity);
            body.append('country', formData.country);
            body.append('currency', formData.currency);
            body.append('department', formData.department);
            body.append('description_of_activity', formData.description_of_activity);
            body.append('division', formData.division);
            body.append('document', formData.document);
            body.append('document_date', formData.document_date);
            body.append('exported_products', formData.exported_products);
            body.append('fax', formData.fax);
            body.append('form_of_ownership', formData.form_of_ownership);
            body.append('full_legal_name', formData.full_legal_name);
            body.append('full_name', formData.full_name);
            body.append('full_name_head', formData.full_name_head);
            body.append('full_name_head_r', formData.full_name_head_r);
            body.append('full_name_r', formData.full_name_r);
            body.append('identity_document', formData.identity_document);
            body.append('identity_document_data', formData.identity_document_data);
            body.append('index', formData.index);
            body.append('logotip', formData.logotip);
            body.append('mail', formData.mail);
            body.append('mobile_phone', formData.mobile_phone);
            body.append('name', formData.name);
            body.append('organization_fax', formData.organization_fax);
            body.append('organization_mail', formData.organization_mail);
            body.append('organization_phone', formData.organization_phone);
            body.append('organization_website', formData.organization_website);
            body.append('phone', formData.phone);
            body.append('position', formData.position);
            body.append('position_of_head', formData.position_of_head);
            body.append('position_of_head_r', formData.position_of_head_r);
            body.append('position_r', formData.position_r);
            body.append('postal_address', checkedAddr ? formData.address : formData.postal_address);
            body.append('postal_area', checkedAddr ? formData.area : formData.postal_area);
            body.append('postal_index', checkedAddr ? formData.index : formData.postal_index);
            body.append('postal_settlement', checkedAddr ? formData.settlement : formData.postal_settlement);
            body.append('quantity', formData.quantity);
            body.append('quantity_war', formData.quantity_war);
            body.append('rcbo_psrn', formData.rcbo_psrn);
            body.append('settlement', formData.settlement);
            body.append('settlement_account', formData.settlement_account);
            body.append('short_organization_name', formData.short_organization_name);
            body.append('tsbu', formData.tsbu);
            body.append('unp_inn', formData.unp_inn);
            body.append('user', `${localStorage.getItem('userID')}`);

            if (isSavePDF) {

                let dataObj = Object.assign({}, formData)

                const generatePDFDocument = async (data_pdf: { dataObj: any }) => {
                    // @ts-ignore
                    const blobPdf = pdf(CompanyPDF(data_pdf));
                    // @ts-ignore
                    blobPdf.updateContainer(CompanyPDF(data_pdf));
                    const result = await blobPdf.toBlob();

                    saveAs(result, "Company.pdf");
                };
                generatePDFDocument({dataObj})


            } else {
                const axios = new Axios();

                if (url === '/aboutCompany') {

                    axios.put(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}/`,
                        typeof formData.logotip === 'string' || formData.logotip === null ? body_1 : body
                    )
                        .then((res) => {
                            localStorage.setItem('company', formData.short_organization_name);
                            localStorage.setItem('addressCompany', formData.address);
                            localStorage.setItem('name_head', formData.full_name_head);
                            localStorage.setItem('position_head', formData.position_of_head);
                            props.history.push('/home')
                        })
                        .catch(err => {
                            console.log(err);
                        })
                } else {
                    axios.post(`worker/company/${localStorage.getItem('userID')}/`, body)
                        .then(res => {
                            localStorage.setItem('company', formData.short_organization_name);
                            localStorage.setItem('addressCompany', formData.address);
                            localStorage.setItem('name_head', formData.full_name_head);
                            localStorage.setItem('position_head', formData.position_of_head);
                            props.history.push('/company')
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
            }

        };
        const setWarning = (message: string) => {
            handleOpenModal();
            setMessage(`Вы не заполнили поле '${message}'`)
            setTimeout(handleCloseModal, 5000);
        }
        if (localStorage.getItem('userID') === '1') {
            return formData.short_organization_name === '' ? setWarning('Краткое название организации') :
                formData.quantity === '' ? setWarning('Численность штатных сотрудников на январь отчётного года') :
                    formData.full_name === '' ? setWarning('Лицо, ответственное за ведение ВУ(ФИО)') :
                        formData.mail === '' ? setWarning('Email лица, ответственного за ВУ') :
                            formData.organization_phone === '' ? setWarning('Телефон организации') :
                                toSaveInfo()

        } else {
            return formData.address === '' ? setWarning('Адрес организации') :
                formData.basis_head_r === '' ? setWarning('Действует на основании (в родительном падеже)') :
                    formData.country === '' ? setWarning('Страна') :
                        formData.mail === '' ? setWarning('Email лица, ответственного за ВУ') :
                            formData.form_of_ownership === '' ? setWarning('Форма собственности') :
                                formData.full_legal_name === '' ? setWarning('Полное юридическое название организации') :
                                    formData.full_name === '' ? setWarning('Лицо, ответственное за ведение ВУ(ФИО)') :
                                        formData.full_name_head === '' ? setWarning('ФИО руководителя организации') :
                                            formData.full_name_head_r === '' ? setWarning('ФИО руководителя организации в родительном падеже') :
                                                formData.full_name_r === '' ? setWarning('Лицо, ответственное за ведение ВУ(ФИО в родительном падеже)') :
                                                    formData.index === '' ? setWarning('Индекс') :
                                                        formData.short_organization_name === '' ? setWarning('Краткое название организации') :
                                                            formData.organization_mail === '' ? setWarning('Email организации') :
                                                                formData.organization_phone === '' ? setWarning('Телефон организации') :
                                                                    formData.phone === '' ? setWarning('Номер телефона лица, ответственного за ВУ') :
                                                                        formData.position === '' ? setWarning('Должность лица, ответственного за ВУ') :
                                                                            formData.position_r === '' ? setWarning('Должность лица, ответственного за ВУ в родительском падеже') :
                                                                                formData.position_of_head === '' ? setWarning('Должность руководителя организации') :
                                                                                    formData.position_of_head_r === '' ? setWarning('Должность руководителя организации в родительском падеже') :
                                                                                        formData.quantity === '' ? setWarning('Численность штатных сотрудников на январь отчётного года') :
                                                                                            formData.quantity_war === '' ? setWarning('Число военнообязанных и призывников') :
                                                                                                formData.rcbo_psrn === '' ? setWarning('ОКПО') :
                                                                                                    formData.settlement === '' ? setWarning('Город нахождения организации') :
                                                                                                        // formData.postal_address === '' ? setWarning('Адрес в почтовом адресе') :
                                                                                                        //     formData.postal_index === '' ? setWarning('Индекс в почтовом адресе') :
                                                                                                        //         formData.postal_settlement === '' ? setWarning('Город в почтовом адресе') :
                                                                                                                    formData.unp_inn === '' ? setWarning('УНП') : toSaveInfo()
        }
    }

    const [initialState, setInitialState] = useState({
        "name": "",
        "unp_inn": "",
        "rcbo_psrn": "",
        "full_legal_name": "",
        "short_organization_name": "",
        "description_of_activity": "",
        "department": "",
        "exported_products": "",
        "form_of_ownership": "",
        "business_entity": "",
        "organization_phone": "",
        "organization_fax": "",
        "organization_website": "",
        "organization_mail": "",
        "country": "Республика Беларусь",
        "area": "",
        "settlement": "",
        'postal_address': "",
        'postal_area': "",
        'postal_index': "",
        'postal_settlement': "",
        "index": "",
        "address": "",
        "bank_name": "",
        "bank_code": "",
        "branch": "",
        "tsbu": "",
        "settlement_account": "",
        "currency": "",
        "position_of_head": "",
        "position_of_head_r": "",
        "full_name_head": "",
        "full_name_head_r": "",
        "basis_head_r": "",
        "full_name": "",
        "full_name_r": "",
        "position": "",
        "position_r": "",
        "division": "",
        "basis": "",
        "identity_document": "",
        "document": "",
        "document_date": "",
        "identity_document_data": "",
        "mail": "",
        "phone": "",
        "mobile_phone": "",
        "fax": "",
        "user": 0,
        "quantity": '',
        'isAutoAddress': false,
        'logotip': ''
    })


    const url = props.location.pathname;

    useEffect(() => {

        if (url === '/aboutCompany') {
            const axios = new Axios();
            axios.get(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}`)
                .then(res => {
                    setInitialState(res.data as any);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [])

    // @ts-ignore
    return (<>
            <div className='container_registration'>
                <div className='toolbar'>
                    {
                        url === '/aboutCompany' ? <div className='btn-wrapper'><Button variant="contained"
                                                                                       onClick={() => props.history.push('/home')}>
                            Назад
                        </Button>
                        </div> : <div className='btn-wrapper'>
                            <Button variant="contained" onClick={() => props.history.push('/company')}>
                                Назад
                            </Button>
                        </div>
                    }
                </div>
                <RegistrationCompanyReduxForm
                    onSubmit={onSubmit}
                    initialValues={initialState}
                    url={url}
                    setSavePDF={setSavePDF}
                    checkedAddr={checkedAddr}
                    onChangeAddress={onChangeAddress}
                />
            </div>
            <Dialog
                open={warningWindow}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default RegistrationCompany;


