import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import './NewRegistrationLetter.css';
import RegistrationLetter from './RegistrationLetter'
import Axios from '../../utils/axios';
import {connect, useSelector} from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import OneRegistrationLetter from './OneRegistrationLetter';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {useHistory} from "react-router-dom";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import {DataGrid} from '@material-ui/data-grid';


const NewRegistrationLetter = ({user, ...props}: any) => {

    const history = useHistory()

    const [isAutoGenerate, setAutoGenerate] = useState(false);

    const [allLetters, setAllLetters] = useState([]);
    const [isSave, setSave] = useState(false);
    const [isEmptyOfficesArr, setEmptyOfficesArr] = useState(false);

    let numbersLetter = {} as any;
    const [numbersLettersState, setNumberLettersState] = useState({} as any);
    let dateLetter = {} as any;
    const [dateLettersState, setDateLettersState] = useState({} as any);
    const [idOffice, setIdOffice] = useState(0)
    const [dismissPersons, setDismissPersons] = useState([])
    const [isSavePdf, setIsSavePdf] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [listWorkers, setListWorkers] = useState([]);
    const [updateFile, setUpdateFile] = useState(false);


    let value = useSelector((state: any) => state.lettersReducer.office_id_value)

    useEffect(() => {
        const axios = new Axios();
        axios.post(`worker/${localStorage.getItem('id')}/filter/`, {time_end__isnull: true, type: 1,  report:false})
            .then(response => {
                let dismiss = response.data.results.filter((i: any) => {
                    return i.basic_military_id === 5
                })
                setDismissPersons(dismiss);
            })
        axios.get('worker/office/')
            .then(res => {
                let value_office = res.data.results.filter((i: any) => {
                    return value === i.name ? i.id : null
                });
                for (let i of value_office) {
                    setIdOffice(i.id)
                }
            })

        if (isAutoGenerate) {
            const id = localStorage.getItem('id');
            axios.get(`worker/letter/${id}`)
                .then(res => {

                    axios.get('worker/office/?page=1&page_size=135')
                        .then(response => {
                            let arrOffices: any = Object.values(res.data);
                            if (arrOffices.length !== 0) {
                                setEmptyOfficesArr(false)
                                let resultArr = arrOffices.map((i: any) => {
                                    for (let item of response.data.results) {
                                        if (i.id === item.id) {
                                            return {
                                                ...i,
                                                short_name: item.short_name,
                                                full_add: `${item.letter_street} \n ${item.index}, ${item.letter_locality} \n ${item.letter_district !== null ? item.letter_district : ''}`
                                            }
                                        }
                                    }
                                })
                                let arr = resultArr.filter((i: any) => {
                                    return i !== undefined
                                })
                                // setAllLetters(arr)
                                let newArr=arr.map((i:any)=>{
                                    return {
                                        ...i,worker:i.worker.filter((w:any)=>{
                                            return !w.report
                                        })
                                    }
                                })
                                let refactoringArr=newArr.filter((i:any)=>{
                                    return i.worker.length!==0
                                })
                                setAllLetters(refactoringArr)
                            } else {
                                setEmptyOfficesArr(true);
                                setTimeout(() => {
                                    setEmptyOfficesArr(false);
                                    history.push("/start_military")
                                }, 3000);
                            }
                        })
                })
                .catch(err => {
                    console.log(err);
                })

        }
    }, [isAutoGenerate, value]);

    useEffect(() => {

        const axios = new Axios();
        axios.post(`worker/${localStorage.getItem('id')}/filter/`, {
            time_end__isnull: true,
            type: 1,
            report:false
        })
            .then(res => {
                setListWorkers(res.data.results)
            })
    }, [])

    return (
        <div style={{margin: '216px auto 10px auto', width: '98%'}}>
            <span className='title_letters'>Письма о постановке организации на воинский учёт</span>
            <div className='toolbar' style={{marginBottom: '40px'}}>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={() => props.history.push('/start_military')}>
                        Назад
                    </Button>
                </div>
                {listWorkers.length !== 0 && <DataTable listWorkers={listWorkers}/>}

            </div>
            <div className='button-box'>
                <Button variant="outlined" onClick={() => {
                    setAutoGenerate(true)
                    // setSave(true);
                }}>
                    Создать письма автоматически
                </Button>
            </div>

            {isAutoGenerate ? allLetters.map((letter: any, num: number) => {

                return <RegistrationLetter

                    idOffice={letter.id}
                    letter={letter}
                    isSave={isSave}
                    num={num}
                    numbersLetter={numbersLetter}
                    dateLetter={dateLetter}
                    numbersLettersState={numbersLettersState}
                    dateLettersState={dateLettersState}
                    key={num}
                    dismissPersons={dismissPersons}
                    isAutoGenerate={isAutoGenerate}
                    isSavePdf={isSavePdf}
                    setDisabled={setDisabled}
                    updateFile={updateFile}
                />
            }) : <OneRegistrationLetter idOffice={idOffice} dismissPersons={dismissPersons}
                                        isAutoGenerate={isAutoGenerate}/>}

            {isAutoGenerate ? <div className='button-save-all'>
                <Button variant="contained" color="primary" startIcon={<ArtTrackIcon/>}
                        style={{marginRight: '20px'}} onClick={() => {
                    setSave(true);
                    setIsSavePdf(true);
                    setDisabled(false)
                }}>
                    Сохранить в PDF
                </Button>
                <Button variant="contained" color="primary" onClick={() => {
                    setNumberLettersState(numbersLetter);
                    setDateLettersState(dateLetter);
                    setIsSavePdf(true);
                    setUpdateFile(true)
                }} startIcon={<SaveIcon/>} disabled={isDisabled}>
                    Сохранить
                </Button>
            </div> : null}
            <Dialog onClose={() => {
                setEmptyOfficesArr(false)
            }} aria-labelledby="customized-dialog-title"
                    open={isEmptyOfficesArr}>
                <DialogContent dividers>
                    Нет данных для отправки Адресату
                </DialogContent>
            </Dialog>
        </div>
    )
}
// export default NewLetter;

const mapStateToProps = (state: any) => ({
    user: state.user,
});


const mapActionsToProps = {
    // loginUser
};

function DataTable({listWorkers}: any) {
    const sorting = (arr: any[]) => {
        arr.sort((a, b) => a.full_name > b.full_name ? 1 : -1);
    };
    sorting(listWorkers);

    const columns = [
        {field: 'id', headerName: '№', width: 70},
        {field: 'full_name', headerName: 'ФИО', width: 270},
        {field: 'war_name', headerName: 'Военкомат', width: 270},
    ];
    let rows: any[] = [];
    let nextI = 1
    for (let i of listWorkers) {
        rows = [...rows, {id: nextI, full_name: i.full_name, war_name: i.office}]
        nextI++
    }

    return (<>
            {listWorkers &&
            <div style={{height: 400, width: '100%'}}>
                <DataGrid rows={rows} columns={columns} pageSize={5} disableColumnMenu={true}/>
            </div>
            }
        </>
    );
}

export default connect(mapStateToProps, mapActionsToProps)(NewRegistrationLetter)