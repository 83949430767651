import React, {useState, useEffect} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Header from '../HeaderLandingPage';
import Footer from '../FooterLandingPage';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Axios from '../../../utils/axios';
import '../LandingPage.css';
import {NavLink} from "react-router-dom";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: 'rgba(250, 250, 250, 0.8)',
        fontSize: 'bold',
        color: 'black',
        height: 'inherit',
    },
}));

const getNameDoc = (str: string) => {
    if (str === 'null') {
        return '';
    }
    const index = str.lastIndexOf('/');
    let name = str.substring(index + 1);
    name = decodeURI(name)
    const index3 = name.lastIndexOf('_версия');
    if (index3!==-1)
    {
        const stroka = name.substring(0,index3)+".pdf";
        return stroka
    }
    return name;
};

const LegislationPage = () => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const [docsLaws, setDocsLaws] = useState([]);
    const [docsRegulations1, setDocsRegulations1] = useState([]);
    const [docsRegulations2, setDocsRegulations2] = useState([]);
    const [docsRegulations3, setDocsRegulations3] = useState([]);
    const [docsAlternative, setDocsAlternative] = useState([]);
    const [docsOther, setDocsOther] = useState([]);
    const [docsComments, setDocsComments] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const axios = new Axios();

        axios.post(`worker/report/1/filter/`, {
            "type__name": "Нормативный документ",
            subtype_id: 1
        })
            .then(res => {
                setDocsLaws(res.data.results)
            })
            .catch(err => {
                console.log(err);
            })

        axios.post(`worker/report/1/filter/`, {
            "type__name": "Нормативный документ",
            subtype_id: 2
        })
            .then(res => {
                setDocsRegulations1(res.data.results)
            })
            .catch(err => {
                console.log(err);
            })

        axios.post(`worker/report/1/filter/`, {
            "type__name": "Нормативный документ",
            subtype_id: 3
        })
            .then(res => {
                setDocsRegulations2(res.data.results)
            })
            .catch(err => {
                console.log(err);
            })

        axios.post(`worker/report/1/filter/`, {
            "type__name": "Нормативный документ",
            subtype_id: 4
        })
            .then(res => {
                setDocsRegulations3(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })

        axios.post(`worker/report/1/filter/`, {
            "type__name": "Нормативный документ",
            subtype_id: 5
        })
            .then(res => {
                setDocsAlternative(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
        axios.post(`worker/report/1/filter/`, {
            "type__name": "Нормативный документ",
            subtype_id: 6
        })
            .then(res => {
                setDocsOther(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
        axios.post(`worker/report/1/filter/`, {
            "type__name": "Нормативный документ",
            subtype_id: 7
        })
            .then(res => {
                setDocsComments(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const title = 'Законодательство по ведению воинского учёта и воинской службе в Республике Беларусь'
    return (
        <>
            <div className='wrapper_header_'>
                <Header/>
            </div>
            <div style={{minHeight:'100vh'}}>
            <div className='wrapper_main_legislation' style={{overflow:'scroll'}}>
                <div className='provisions_pre_title_2'>
                    <NavLink to='/' style={{textDecoration: 'none', color: 'black'}}><span>Главная</span></NavLink>
                    <span>/</span>
                    <span>Законодательсво</span>
                    <span>/</span>
                    <span style={{color: 'gray'}}>{title}</span>
                </div>

                <div className="provisions_title_1">
                    Законодательство по ведению воинского учёта и воинской службе в Республике Беларусь
                </div>
                <div className='provisions_body_1'>
                    <div className={classes.root}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Законы" {...a11yProps(0)} className='tab-bold'/>
                                <Tab label="Призыв, воинский учёт" {...a11yProps(1)} className='tab-bold'/>
                                <Tab label='Прохождение воинской службы' {...a11yProps(2)} className='tab-bold'/>
                                <Tab label="Медицинские ограничения" {...a11yProps(3)} className='tab-bold'/>
                                <Tab label="Альтернативная служба" {...a11yProps(4)} className='tab-bold'/>
                                <Tab label="Прочие" {...a11yProps(5)} className='tab-bold'/>
                                <Tab label="Комментарии" {...a11yProps(6)} className='tab-bold'/>
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            {docsLaws.map((item: any) => {
                                return <p className='docLegislation'><a className={'docLegislation43'} href={item.docs}
                                                                        download={item.type.name + item.type.id}>
                                    {getNameDoc(String(item.docs))}
                                </a></p>
                            })}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {docsRegulations1.map((item: any) => {
                                return <p className='docLegislation'><a className={'classes.linkTypeReport'}
                                                                        href={item.docs}
                                                                        download={item.type.name + item.type.id}>
                                    {getNameDoc(String(item.docs))}
                                </a></p>
                            })}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {docsRegulations2.map((item: any) => {
                                return <p className='docLegislation'><a className={'classes.linkTypeReport'}
                                                                        href={item.docs}
                                                                        download={item.type.name + item.type.id}>
                                    {getNameDoc(String(item.docs))}
                                </a></p>
                            })}
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            {docsRegulations3.map((item: any) => {
                                return <p className='docLegislation'><a className={'classes.linkTypeReport'}
                                                                        href={item.docs}
                                                                        download={item.type.name + item.type.id}>
                                    {getNameDoc(String(item.docs))}
                                </a></p>
                            })}
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            {docsAlternative.map((item: any) => {
                                return <p className='docLegislation'><a className={'classes.linkTypeReport'}
                                                                        href={item.docs}
                                                                        download={item.type.name + item.type.id}>
                                    {getNameDoc(String(item.docs))}
                                </a></p>
                            })}
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                            {docsOther.map((item: any) => {
                                return <p className='docLegislation'><a className={'classes.linkTypeReport'}
                                                                        href={item.docs}
                                                                        download={item.type.name + item.type.id}>
                                    {getNameDoc(String(item.docs))}
                                </a></p>
                            })}
                        </TabPanel>
                        <TabPanel value={value} index={6}>
                            {docsComments.map((item: any) => {
                                return <p className='docLegislation'><a className={'classes.linkTypeReport'}
                                                                        href={item.docs}
                                                                        download={item.type.name + item.type.id}>
                                    {getNameDoc(String(item.docs))}
                                </a></p>
                            })}
                        </TabPanel>
                    </div>
                </div>
            </div>
            </div>
            <div style={{position: 'relative'}}><Footer/></div>
        </>
    )
}
export default LegislationPage;
