import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatDate = (date: string) => {
    const d = new Date(date);
    console.log(d)
    console.log(date)
    if(date==="")
    {
        return "_____._____.20__"
    }
   

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear() + ' г.';

    return result;
}

const WorkPlanPDF = ({ date1, name1, office, position2, name2, position3, name3, company, year, date2, name, nameResponsible, city }: any) => {
    const card = {
        pageSize: 'A4',
        footer: function (currentPage: any, pageCount: any) {
            return {
                alignment: 'right',
                fontSize: 10,
                lineHeight: 1.1,
                text: `Лист ${currentPage.toString()} из ${pageCount}`,
                margin: [30, 0, 30, 0]
            }
        },
        content: [
            {
                columns: [
                    {
                        stack: [
                            {
                                text: `СОГЛАСОВАНО `,
                                margin: [10, 5, 0, 3],
                                fontSize: 12,
                                bold: true,
                                lineHeight: 1,
                            },
                            {
                                text: `Военный комиссар\n ${office.slice(20)}\n \n________________  ${name1}`,
                                margin: [10, 0, 0, 0],
                                fontSize: 12,
                            },
                            {
                                text: `${formatDate(date1)}`,
                                margin: [10, 0, 0, 10],
                                fontSize: 12,
                            },
                        ]
                    },
                    {
                        stack: [
                            {
                                text: `УТВЕРЖДАЮ `,
                                margin: [80, 5, 0, 3],
                                fontSize: 12,
                                bold: true,
                                lineHeight: 1,
                            },
                            {
                                text: `${position2} \n${company}\n \n__________________  ${name2}`,
                                margin: [80, 0, 0, 0],
                                fontSize: 12,
                            },
                            {
                                text: `${formatDate(date2)}`,
                                margin: [80, 0, 0, 10],
                                fontSize: 12,
                            },
                        ]
                    }
                ]
            },


            {
                fontSize: 14,
                alignment: 'center',
                text: `ПЛАН \nработы по ведению воинского учёта  военнообязанных и призывников в\n  ${company}\n на ${year} год`,
                margin: [0, 10, 0, 10],
                bold: true
            },
            {

                table: {
                    widths: [20, 190, '*', 95, 80],
                    headerRows: 2,
                    fontSize: 12,
                    body: [
                        [{ text: '№ \nп/п', alignment: 'center' }, { text: 'Наименование мероприятия', alignment: 'center' }, { text: 'Ответственный за исполнение', alignment: 'center' }, { text: 'Срок исполнения', alignment: 'center' }, { text: 'Отметка о выполнении', alignment: 'center' }],
                        [{ text: '1', alignment: 'center' }, { text: '2', alignment: 'center' }, { text: '3', alignment: 'center' }, { text: '4', alignment: 'center' }, { text: '5', alignment: 'center' }],
                        [{ text: '1', alignment: 'center' }, 'Ведение персонально-первичного воинского учёта по личным карточкам учёта', { text: 'Отв. за ведение ВУ ', alignment: 'center' }, { text: "Постоянно", alignment: 'center' }, ''],
                        [{ text: '2', alignment: 'center' }, 'Комплектование и ведение картотеки из личных карточек граждан по следующим разделам:\n1. Личные карточки на офицеров;\n2. Личные карточки на прапорщиков, сержантов, старшин и солдат запаса;\n3. Личные карточки на военнообязанных женского пола;\n4. Личные карточки на призывников.\n', { text: 'Отв. за ведение ВУ ', alignment: 'center' }, { text: "Постоянно", alignment: 'center' }, ''],
                        [{ text: '3', alignment: 'center' }, 'Формирование папки «Документы по воинскому учёту».', { text: 'Отв. за ведение ВУ ', alignment: 'center' }, { text: "Постоянно", alignment: 'center' }, ''],
                        [{ text: '4', alignment: 'center' }, 'Ведение служебного делопроизводства (отдельного дела) по вопросам ведения воинского учёта военнообязанных в организации.', { text: 'Отв. за ведение ВУ ', alignment: 'center' }, { text: "Постоянно", alignment: 'center' }, ''],
                        [{ text: '5', alignment: 'center' }, 'Проверка наличия учётно-воинских документов у военнообязанных, принимаемых на работу. Оформление личных карточек и заполнение раздела «Сведения о воинском учёте».', { text: 'Отв. за ведение ВУ ', alignment: 'center' }, { text: "Постоянно", alignment: 'center' }, ''],
                        [{ text: '6', alignment: 'center' }, 'Учёт изменений, касающихся семейного положения, состава семьи, образования, должности (специальности), места жительства (регистрации) граждан и письменное сообщение о них в РВК. Внесение изменений в личные карточки учёта.', { text: 'Отв. за ведение ВУ и', alignment: 'center' }, { text: "Постоянно", alignment: 'center' }, ''],
                        [{ text: '7', alignment: 'center' }, 'По требованию военного комиссариата оповещать военнообязанных о вызовах в военный комиссариат. ', { text: 'Отв. за ведение ВУ ', alignment: 'center' }, { text: "Постоянно", alignment: 'center' }, ''],
                        [{ text: '8', alignment: 'center' }, 'Обеспечивать военнообязанным возможность своевременной явки по повесткам в военный комиссариат.', { text: 'Отв. за ведение ВУ ', alignment: 'center' }, { text: "Постоянно", alignment: 'center' }, ''],
                        [{ text: '9', alignment: 'center' }, 'Своевременное (согласно графика военного комиссариата района) представление в райвоенкоматы г. Минска личных карточек для сверки с учётными документами райвоенкоматов.', { text: 'Отв. за ведение ВУ ', alignment: 'center' }, { text: "1 раз в 2 года", alignment: 'center' }, ''],
                        [{ text: '10', alignment: 'center' }, 'Направление сведений (отчёта) о численности призывников и военнообязанных.', { text: 'Отв. за ведение ВУ ', alignment: 'center' }, { text: "к 1 октября", alignment: 'center' }, ''],
                        [{ text: '11', alignment: 'center' }, 'Сообщение в РВК о всех призывниках и военнообязанных, принятых на работу или уволенных с работы.', { text: 'Отв. за ведение ВУ ', alignment: 'center' }, { text: "В месячный срок", alignment: 'center' }, ''],
                        [{ text: '12', alignment: 'center' }, 'Проведение другой текущей работы, изучение руководящих документов.', { text: 'Отв. за ведение ВУ ', alignment: 'center' }, { text: "Постоянно", alignment: 'center' }, ''],
                    ]
                }
            },
            {
                margin: [10, 50, 0, 0],
                columns: [
                    {
                        width: 130,
                        text: `Ответственный за ведение воинского учета `,
                        fontSize: 12,
                        lineHeight: 1,
                    },
                    {
                        width: '*',
                        alignment: 'center',
                        fontSize: 12,
                        lineHeight: 1.1,
                        text: ` _________________   ${name3}`,
                        margin: [0, 0, 100, 25],
                    }
                ]
            },
        ]
    }
    pdfMake.createPdf(card as any).download('План_работ.pdf');

};
export default WorkPlanPDF;