import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatDate = (date: string) => {
    const d = new Date(date)

    const day = d.getDate();
    const month = d.getMonth()<=9 ? `0${d.getMonth()+1}` : d.getMonth()+1
    let result = (day < 10 ? '0' + day : day) + '.' + month + '.' + d.getFullYear() + ' г.';

    return result;
};



const getData = (table2: any) => {
    let result = [
        [
            {text: '№', colSpan: 1, rowSpan: 2, alignment: 'center', margin:[0,15,0,0]},
         {
            text: 'Наименование РВК и его адрес',
            colSpan: 1,
            rowSpan: 2,
            margin:[0,15,0,0],
            alignment: 'center'
        },
         {text: 'Количество военнообязанных', colSpan: 2, alignment: 'center'},
         {},
          {text: 'Количество \n призывников', colSpan: 1, rowSpan: 2, alignment: 'center'},
           {text: 'Всего', colSpan: 1, rowSpan: 2, alignment: 'center'}],
        [{}, {},
             {text: 'всего ', alignment: 'center',  margin:[0,8,0,0]},
         {
            text: 'из них \n офицеры',
            alignment: 'center',
            margin:[0,8,0,0]
        },
         {},{}]
    ]

    if (table2) {
        table2.map((item: any, number: number) => {
            result.push([`${number + 1}`, `${item.office}`, `${item.sergeant}`, `${item.officer}`, `${item.recruit}`,  `${item.all}`] as any)
        })
    }
    return result;
};


const ReportPDF = ({
                       phoneHead,office, date1, number1, date2, number2, name, company, table1, table2, positionSender,
                       nameSender, setFile, date3, countWorkers, nameResp, affiliations, phoneResp, city
                   }: any) => {

    let nameResponsible = nameResp ? nameResp.split(' ')[1] && nameResp.split(' ')[2] && nameResp.split(' ')[0] ?
        nameResp.split(' ')[1][0] + '.' + nameResp.split(' ')[2][0] + "." + " " + nameResp.split(' ')[0]
        : nameResp : nameResp;

    let nameSenderPerson = nameSender ? nameSender.split(' ')[1] && nameSender.split(' ')[2] && nameSender.split(' ')[0] ?
        nameSender.split(' ')[1][0] + '.' + nameSender.split(' ')[2][0] + "."+" " + nameSender.split(' ')[0]
        : nameSender : nameSender

    const card = {
        pageSize: 'A4',
        content: [
            {
                text: ``,
                margin: [10, 10, 0, 0],

            },
            {
                text: `${formatDate(String(date1))}    № ${number1 ? number1 : '_____'} \n на № ${number2 ? number2 : '_____'} от ${date2 ? formatDate(String(date2)) : `__ _________ 20__ г.`}`,
                margin: [10, 10, 0, 0],
                fontSize: 9,
                lineHeight: 1,
            },
            {
                text: `Военному комиссару \n военного комиссариата \n ${office.slice(20)} \n ${name}`,
                margin: [300, 5, 0, 0],
                fontSize: 10,
                bold: true,
                lineHeight: 1,
            },
            {
                width: '*',
                alignment: 'center',
                fontSize: 9,
                lineHeight: 1.1,
                text: [
                    {text: `Сведения о количестве военнообязанных и призывников, работающих\n в ${company}, ${city} `,
                    fontSize: 10
}
                ],
                margin: [50, 15, 50, 10]
            },
            {
                alignment: 'right',
                margin: [0, 5, 0, 10],
                text: [{
                    text: `По состоянию на ${date3 ? formatDate(date3) : `__ ___________ 20__ г`}`,
                    fontSize: 10,
                    bold: true
                },]
            },
            {
                fontSize: 9,
                alignment: 'center',
                table: {
                    width: 1100,
                    widths: [110, 70, 70, 70, 70, 70],
                    headerRows: 3,
                    body: [
                        [{text: 'Всего работающих', colSpan: 1, rowSpan: 3, alignment: 'center',  margin:[0,13,0,0],},
                            {
                            text: 'из них',
                            colSpan: 5,
                            alignment: 'center'
                        }, {}, {},{},{}],
                        [{}, {text: 'военнообязанных', colSpan: 2, alignment: 'center'}, {}, {
                            text: 'Имеют МП',
                            colSpan: 2,
                            alignment: 'center',
                        },{},{text: 'Призывников', colSpan: 1,rowSpan: 2, alignment: 'center'}],
                        [{}, {text: 'всего', alignment: 'center',margin:[0,2,0,0],}, {
                            text: 'в т.ч.\n офицеров',
                            alignment: 'center'
                        },{text: 'всего', alignment: 'center',margin:[0,2,0,0],}, {
                            text: 'в т.ч.\n офицеров',
                            alignment: 'center'
                        }, {}],
                        [ {
                            text: table1.all_worker === countWorkers ? table1.all_worker : countWorkers,
                            fontSize: 9,
                            bold: true,
                            margin:[0,2,0,2],
                        }, {text: table1.all_war, fontSize: 9, bold: true, margin:[0,2,0,2],}, {
                            text: table1.officer,
                            fontSize: 9,
                            bold: true,
                            margin:[0,2,0,2],
                        },
                        {text: table1.war_mp, fontSize: 9, bold: true, margin:[0,2,0,2],}, {
                                text: table1.officer_mp,
                                fontSize: 9,
                                bold: true,
                                margin:[0,2,0,2],
                        }, {text: table1.recruit, fontSize: 9, bold: true, margin:[0,2,0,2],},]

                    ]
                }
            },
            {
                margin: [0, 15, 0, 10],
                text: [{
                    text: `Распределение военнообязанных по военным комиссариатам:`,
                    fontSize: 12,
                    decoration: 'underline',
                    alignment: 'center'
                },]
            },
            {
                fontSize: 9,
                alignment: 'center',
                width: 1100,
                table: {
                    widths: [20, 155, 75, 75, 75, '*'],
                    headerRows: 2,
                    body: getData(table2)
                }
            },
            {
                margin: [0, 20, 50, 30],
                columns: [
                    {
                        width: 250,
                        text: `ответственный за ведение\n воинского учета организации\n тел. ${phoneResp}`,
                        //`${positionSender} \n ${company} `,
                        fontSize: 9,
                        lineHeight: 1,
                        bold:true
                    },
                    {
                        width: 300,
                        text: ` _____________________  ${nameResponsible}`,
                        fontSize: 10,
                        lineHeight: 1,
                        margin:[20,9,0,0],
                        bold:true
                    },
                    {
                        text: `(подпись)`,
                        alignment: 'center',
                        fontSize: 9,
                        margin:[20,20,20,20]
                    },
                ]
            },
            {
                margin: [0, 0, 50, 0],
                columns: [
                    {
                        width: 250,
                        text: `М.П.\n ${positionSender}\n тел. ${phoneHead}`,
                        //`Исп. ${nameResponsible}`,
                        fontSize: 8,
                        lineHeight: 1,
                        bold:true
                    },
                    {
                        width: 300,
                        text: ` _____________________  ${nameSenderPerson}`,
                        fontSize: 10,
                        lineHeight: 1,
                        margin:[20,9,0,0],
                        bold:true
                    },
                    {
                        text: `(подпись)`,
                        alignment: 'center',
                        fontSize: 8,
                        margin:[20,5,0,0]
                    },
                ]
            },
        ],

    }
    const file = pdfMake.createPdf(card as any);
    file.download('Report.pdf');
    file.getBuffer((buffer) => {
        const blob = new Blob([buffer]);
        setFile(blob);
    });

};
export default ReportPDF;
