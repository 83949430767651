//@ts-nocheck
import React, {useState} from 'react';
import call_image from '../LandingPage/assets/images/call_image.svg';
import {NavLink} from "react-router-dom";
import {NavHashLink} from 'react-router-hash-link';
import login from './assets/images/login.svg';
import {slide as Menu} from "react-burger-menu";


const HeaderLandingPage = () => {

    const [classForLegislation, setClassForLegislation] = useState(false);

    const addedMainLink = () => {
        if (!classForLegislation) {
            setClassForLegislation(true)
        } else setClassForLegislation(false)
    }

    return (<>
            <div id="myTopnav" className='wrapper_header'>
                <div>
                    <NavHashLink to="/">
                        <div className='title'>Воинский учет</div>
                    </NavHashLink>
                    <div className='title_service'>облачный сервис</div>
                </div>
                <div className='menu_wrapper'>
                    <NavHashLink to="/#our_work" className='menu_wrapper_a' activeClassName='activeLink'>Как мы
                        работаем</NavHashLink>
                    <NavHashLink to="/#cost" className='menu_wrapper_a'
                                 activeClassName='activeLink'>Стоимость</NavHashLink>
                    <NavHashLink to="/faq" className='menu_wrapper_a' activeClassName='activeLink'>Частые
                        вопросы</NavHashLink>
                    <div
                        onClick={addedMainLink}
                        className={window.location.pathname === '/basicProvisions' ||
                        window.location.pathname === '/legislation' ||
                        window.location.pathname === '/responsibility' ? 'menu_wrapper_activeLink' : 'menu_wrapper_a'}>Законодательство
                    </div>
                    {classForLegislation && <div className='legislation_list'>
                        <div className='list_legislation_links'>
                            <NavLink to='/legislation'
                                     style={window.location.pathname === '/legislation' ? {color: 'rgba(240, 186, 20, 1)'} : {}}>Законодательство
                                по ведению ВУ в РБ</NavLink>
                            <NavLink to='/basicProvisions'
                                     style={window.location.pathname === '/basicProvisions' ? {color: 'rgba(240, 186, 20, 1)'} : {}}>Основные
                                положения ВУ в РБ</NavLink>
                            <NavLink to='/responsibility'
                                     style={window.location.pathname === '/responsibility' ? {color: 'rgba(240, 186, 20, 1)'} : {}}>Ответственность
                                за нарушения ВУ</NavLink>
                        </div>
                    </div>}
                    <NavHashLink to="/#contacts" className='menu_wrapper_a'
                                 activeClassName='activeLink'>Контакты</NavHashLink>
                </div>
                <div className='menu_wrapper_mobile'>
                    <NavLink to='/login'> <img src={login} alt='' className='login'/></NavLink>
                </div>
                <div className='call'>
                    <img alt='' src={call_image}/>
                    <NavHashLink to='/#call_me' className='menu_wrapper_a' activeClassName='activeLink'>Заказать
                        звонок</NavHashLink>
                </div>
                <NavHashLink to='/login' className='sign_in_btn'
                             activeClassName='activeLink'><span>Войти</span></NavHashLink>
            </div>
            <div className='burger-menu-mobile'>
                <Menu right>
                    <NavHashLink className="menu-item" to="/#our_work" activeClassName='activeLink'>
                        Как мы работаем
                    </NavHashLink>
                    <NavHashLink className="menu-item" to="/#cost" activeClassName='activeLink'>
                        Стоимость
                    </NavHashLink>
                    <NavHashLink className="menu-item" to="/faq" activeClassName='activeLink'>
                        Частые вопросы
                    </NavHashLink>
                    <div
                        onClick={addedMainLink}
                        className={window.location.pathname === '/basicProvisions' ||
                        window.location.pathname === '/legislation' ||
                        window.location.pathname === '/responsibility' ? 'menu-item_activeLink' : 'menu-item_low'}>Законодательство
                    </div>
                    {classForLegislation && <div>
                        <ul className='low_mobile_list'>
                            <li><NavLink to='/legislation'
                                         style={window.location.pathname === '/legislation' ? {color: 'rgba(240, 186, 20, 1)'} : {}}>Законодательство
                                по ведению ВУ в РБ</NavLink></li>
                            <li><NavLink to='/basicProvisions'
                                         style={window.location.pathname === '/basicProvisions' ? {color: 'rgba(240, 186, 20, 1)'} : {}}>Основные
                                положения ВУ в РБ</NavLink></li>
                            <li><NavLink to='/responsibility'
                                         style={window.location.pathname === '/responsibility' ? {color: 'rgba(240, 186, 20, 1)'} : {}}>Ответственность
                                за нарушения ВУ</NavLink></li>
                        </ul>
                    </div>}
                    <NavHashLink className="menu-item" to="/#contacts" activeClassName='activeLink'>
                        Контакты
                    </NavHashLink>
                    <NavHashLink className="menu-item" to="/#call_me" activeClassName='activeLink'>
                        <button>Позвоните мне</button>
                    </NavHashLink>
                </Menu>
            </div>
        </>
    )
}

export default HeaderLandingPage;
