import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatDate = (date: string) => {
  const d = new Date(date);

  const day = d.getDate();
  const month = d.getMonth() + 1;
  let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear();

  return result;
}

const createPDFPersonalCard = async (form: any) => {

  const getWorker = (worker: any) => {
    let result = [['Дата', 'Профессия, должность', 'Основание']];
    if (worker) {
      worker.map((item: any) => {
        result.push([`${item.time}`, `${item.position}`, `${item.basis}`])
      })
    }
    return result;
  }

  const card = {
    content: [
      {
        text: 'ЛИЧНАЯ КАРТОЧКА',
        margin: [200, 10, 0, 0],
        fontSize: 14
      },
      {
        text: 'I. Общие сведения',
        margin: [210, 30, 0, 20],
      },
      {
        alignment: 'justify',
        columns: [
          {
            stack: [
              `1. Фамилия:  ${form.full_name.split(' ')[0] || ''}`,
              {
                text: ` Имя:  ${form.full_name.split(' ')[1] || ''}`,
                margin: [13, 0, 0, 0],
              },
              {
                text: ` Отчество:  ${form.full_name.split(' ')[2] || ''}`,
                margin: [12, 0, 0, 0],
              },
              `2. Дата рождения: ${formatDate(form.date_of_birth)}`,
              `3. Место рождения: ${form.birthplace}`,
              `4. Национальность: ${form.nationality}`,
              `5. Образование: ${form.education}`,
              `Специальность по образованию:  \n ${form.edu_specialization}`,
              `Квалификация: ${form.edu_qualification}`,
              `Диплом: ${form.edu_diploma.split(' ')[0] || ''} № ${form.edu_diploma.split(' ')[1] || ''} от ${form.edu_diploma.split(' ')[2] || ''} г.`,
              `Свидетельство: ${form.edu_certificate.split(' ')[0] || ''} № ${form.edu_certificate.split(' ')[1] || ''} от ${form.edu_certificate.split(' ')[2] || ''} г.`
            ],
            lineHeight: 1.5,
            fontSize: 12
          },

          {
            stack: [
              `6. Должность:  ${form.position}`,
              `7. Семейное положение: ${form.marital_status}`,
              `Состав семьи: ${form.family}`,
              `8. Паспорт: серия ${form.passport.slice(0, 2)} № ${form.passport.slice(2)}`,
              `Кем выдан: ${form.pass_office}`,
              `Дата выдачи: ${form.pass_date}`,
              `Личный №: ${form.pass_number}`,
              `9. Адрес проживания: ${form.address}`,
              `10. Адрес регистрации: ${form.registered_address}`,
              `11. Телефон: ${form.phone}`,
              `11. Электронная почта: ${form.email}`,
            ],
            lineHeight: 1.5,
            fontSize: 12
          }
        ]
      },
      {
        text: 'II. Сведения о воинском учёте',
        margin: [190, 30, 0, 20],
      },
      {
        alignment: 'justify',
        columns: [
          {
            stack: [
              `Военный билет:  ${form.military.split(' ')[0]} №  ${form.military.split(' ')[1]}`,
              `Группа учёта:  ${form.accountc}`,
              `Категория учёта:  ${form.accountg}`,
              `Состав: ${form.structure}`,
              `Воинское звание: ${form.grade}`,
            ],
            lineHeight: 1.5,
            fontSize: 12
          },
          {
            stack: [
              `Военно-учётная специальность №:  ${form.wyc}`,
              `Годность к военной службе: ${form.status}`,
              `Наименование военного комиссариата по месту жительства: ${form.office}`,
              `Состоит ли на специальном учёте №: ${form.special ? `${form.specialN}` : 'Нет'}`,
            ],
            lineHeight: 1.4,
            fontSize: 12
          }
        ]
      },
      {
        text: 'III. Назначения и перемещения',
        margin: [190, 30, 0, 20],
      },
      {
        stack: [
          {
            table: {
              widths: [90, 200, 200],
              body: getWorker(form.worker),

            },
            margin: [0, 10],
          },
          `Особые отметки:  ${form.special_marks}`,
          `Мобилизационное предписание:  ${form.requirement}`,
          `Дата и причина увольнения:  ${form.time_end}`,
        ],
        lineHeight: 1.5,
        fontSize: 12,
        fontWidth: 100,
      }
    ]
  }
  pdfMake.createPdf(card as any).download('PersonalCard.pdf');

};
export default createPDFPersonalCard;