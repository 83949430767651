//@ts-nocheck
import React, {useState, useEffect} from 'react';
import Header from '../HeaderLandingPage'
import Footer from '../FooterLandingPage';
import Axios from '../../../utils/axios';
import SuccessfulSaveModal from "../../../components/PersonalCard/SuccessfulSaveModal";
import {Addresses, ContactsComponent} from "../BodyLandingPage";
import Input from 'react-phone-number-input/input';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store/store";
import {getDataAddressAC} from "../../../redux/reducers/addressesReducer";
import {NavLink} from "react-router-dom";
import {WarningModal} from "../../../utils/Modals/WarningModal";

const ContactsPage = () => {
    const axios = new Axios();
    const [errorCallback, setErrorCallback] = useState(false)
    const [value, setValue] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [question, setQuestion] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('');
    const [people, setPeople] = useState('');
    const [message, setMessage] = useState('');
    const [contacts, setContacts] = useState([]);
    const [isFieldEmpty, setIsFealdEmpty] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [companyValue, setCompanyValue] = useState([]);
    const [type_id, setType_id] = useState('');
    const [modeCompany, setModeCompany] = useState([]);
    const [type_use_id, setType_use_id] = useState('');
    const [promoCode,setpromoCode] = useState('')

    const [warningMessage,setWarningMessage]=useState('')
    const [openWarningModal,setOpenWarningModal]=useState(false)

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
        let addedCompany = companyValue.filter(i => {
            return i.name === event.target.value
        })
        let id: any;
        for (let i of addedCompany) {
            id = i.id
        }
        setType_id(id)
    };

    const handleChangeMode = (event: any) => {
        setType(event.target.value);
        let addedCompany = modeCompany.filter(i => {
            return i.name === event.target.value
        })
        let id: any;
        for (let i of addedCompany) {
            id = i.id
        }
        setType_use_id(id)
    }

    const [nameRegistration, setNameRegistration] = useState('');
    const [phoneRegistration, setPhoneRegistration] = useState('');


    const checkFields = () => {
        const emailExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailExp.test(email)) setErrorEmail(true)
        return !(nameRegistration.length === 0 || !emailExp.test(email) || people.length === 0);

    }

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const sendContactData = () => {

        const sendSuccess=()=>{
            const data = {
                phone: phone,
                question: question,
                name: name
            }
            const axios = new Axios();
            axios.post(`user/send/`, data)
                .then(res => {
                    setMessage('Менеджер свяжется с Вами в ближайшее время');
                    handleOpenModal();
                    setTimeout(handleCloseModal, 4000);
                })
                .catch(err => {
                    setMessage('Произошла ошибка. Пожалуйста, попробуйте ещё раз.');
                    handleOpenModal();
                    setTimeout(handleCloseModal, 4000);
                })
        }

        const warningFunc=(message:string)=>{
            setOpenWarningModal(true);
            setTimeout(()=>setOpenWarningModal(false),2000)
            setWarningMessage(message)
        }
        return phone==='+375' ? warningFunc('Не заполенено поле "Номер телефона"') :
            phone.length!==13 ? warningFunc('Неверно заполенено поле "Номер телефона"') :
                            name==='' ? warningFunc('Не заполенено поле "Имя"') :
                                question==='' ? warningFunc('Не заполенено поле "Вопрос"') :
                                    sendSuccess()
    }


    const sendData = () => {
        // setErrorCallback(false);
        // if (checkFields()) {

        const successData=()=>{
            const data = {
                email: email,
                phone: phoneRegistration,
                type_use_id: type_use_id,
                name: nameRegistration,
                people: people,
                type_id: type_id,
                promo_cod: promoCode
            }

            axios.post(`user/send/`, data)
                .then(res => {
                    setMessage(' Аккаунт успешно создан! Менеджер свяжется с Вами в ближайшее время');
                    handleOpenModal();
                    setTimeout(handleCloseModal, 4000);
                })
                .catch(err => {
                    setMessage('Произошла ошибка. Пожалуйста, попробуйте ещё раз.');
                    handleOpenModal();
                    setTimeout(handleCloseModal, 4000);
                })
        }

        const warningFunc=(message:string)=>{
            setOpenWarningModal(true);
            setTimeout(()=>setOpenWarningModal(false),2000)
            setWarningMessage(message)
        }
        return email==='' ? warningFunc('Не заполенено поле "e-mail"') :
         email.indexOf('@') ===-1 ? warningFunc('Неверно заполенено поле "e-mail"') :
            phoneRegistration==='+375' ? warningFunc('Не заполенено поле "Номер телефона"') :
            phoneRegistration.length!==13 ? warningFunc('Неверно заполенено поле "Номер телефона"') :
                type_use_id==='' ? warningFunc('Не заполенено поле "Тип организации"') :
                    nameRegistration==='' ? warningFunc('Не заполенено поле "Имя"') :
                        people==='' ? warningFunc('Не заполенено поле "Ориентировочная численность организации"') :
                            type_id==='' ? warningFunc('Не заполенено поле "Вариант сервиса ВУ"') : successData()
        // } else {
        //     setIsFealdEmpty(true);
        //     setErrorCallback(true);
        //     if (phoneRegistration === '') {
        //         let tel = document.querySelector('.InputInput');
        //         // @ts-ignore
        //         tel.style = 'border: 1px solid red';
        //     }
        // }
    }
    useEffect(() => {
        axios.get(`control/page/6/`)
            .then(res => {
                setContacts(res.data.page);
            })
            .catch(err => {
                console.log(err)
            })

    }, []);

    interface AddressData {
        addressData: any[]
    };

    const getAddressData = (state): AddressData => state.addressesReducer.addressData
    const addressData = useSelector<RootState>(getAddressData);
    const dispatch = useDispatch();

    useEffect(() => {
        const axios = new Axios();
        axios.get(`control/page/6/`)
            .then(res => {
                dispatch(getDataAddressAC(res.data.page))
            });
        axios.get('library/type/company/')
            .then(res => {
                setCompanyValue(res.data.results)
            });
        axios.get('library/type/use_service/')
            .then(res => {
                setModeCompany(res.data.results)
            })
    }, []);


    return (
        <div className='landing_body'>
            <div className='wrapper_header_'>
                <Header/>
            </div>
            <div className='provisions_pre_title_3'>
                <NavLink to='/' style={{textDecoration: 'none', color: 'black'}}><span>Главная</span></NavLink>
                <span>/</span>
                <span style={{color: 'gray'}}>Регистрация</span>
            </div>
            <div className='wrapper_titles'>
                <div className='registration_'>регистрация в сервисе “воинский учет”</div>
                <div className='titles_form'>
                    <div>Для регистрации в системе, пожалуйста, заполните форму.</div>
                    <div style={{marginBottom: '32px'}}>Наш менеджер свяжется с вами и ответит на вопросы, связанные с
                        работой системы или регистрации.
                    </div>
                    <div>Логин и пароль для входа в систему будет передан на указанную Вами электронную почту.</div>
                </div>
                <RegistrationCompanyForm nameRegistration={nameRegistration} setNameRegistration={setNameRegistration}
                                         isFieldEmpty={isFieldEmpty}
                                         phoneRegistration={phoneRegistration}
                                         setPhoneRegistration={setPhoneRegistration} email={email} setEmail={setEmail}
                                         errorEmail={errorEmail}
                                         setErrorEmail={setErrorEmail} value={value} handleChange={handleChange}
                                         people={people} setPeople={setPeople}
                                         type={type} setType={setType} errorCallback={errorCallback}
                                         sendData={sendData} companyValue={companyValue}
                                         setCompanyValue={setCompanyValue}
                                         setModeCompany={setModeCompany} modeCompany={modeCompany}
                                         handleChangeMode={handleChangeMode} setpromoCode={setpromoCode}/>
                <div id={'contacts'} className='wrapper_contacts'>
                    <div className='landing_questions'>остались вопросы?</div>
                    <div className='landing_questions_connect'>Наш менеджер свяжется с Вами и ответит на все
                        интересующие вопросы
                    </div>
                    <div className='landing_wrapper_contacts'>
                        <div className='form_control_landing'>
                            <div><input name="text" placeholder='Имя' value={name}
                                        onChange={(e: any) => {
                                            setName(e.target.value)
                                        }}/></div>
                            <div>
                                <Input
                                country="BY"
                                international
                                withCountryCallingCode
                                value={phone}
                                onChange={setPhone}/></div>
                            <div><textarea name="text" placeholder='Вопрос или описание проблемы' value={question}
                                           onChange={(e: any) => {
                                               setQuestion(e.target.value)
                                           }}/></div>
                            <div>
                                <button name="text" onClick={sendContactData} className='btn_call'> Позвоните мне
                                </button>
                            </div>
                        </div>
                        <Addresses addressData={addressData}/>
                    </div>
                </div>
                <div style={{position: 'relative'}}><Footer/></div>
            </div>
            <SuccessfulSaveModal
                open={openModal}
                handleClose={handleCloseModal}
                message={message}
            />
            <WarningModal message={warningMessage} open={openWarningModal}
                          handleClose={()=>{setOpenWarningModal(false)}}/>
        </div>
    )
}

const RegistrationCompanyForm = (props: {
    nameRegistration: string, setNameRegistration: any, isFieldEmpty: boolean,
    phoneRegistration: string, setPhoneRegistration: any, email: string, setEmail: any, errorEmail: boolean,
    setErrorEmail: any, value: string, handleChange: any, people: string, setPeople: any,
    type: string, setType: any, errorCallback: boolean, sendData: any,setpromoCode: any,promoCode: any
}) => {
    return (
        <div className='form-control'>

            <input name="text"
                   placeholder='Имя' value={props.nameRegistration}
                   onChange={(e: any) => props.setNameRegistration(e.target.value)}/>
            {/*<input name="text"*/}
            {/*       placeholder='Фамилия' value={props.nameRegistration}*/}
            {/*       onChange={(e: any) => props.setNameRegistration(e.target.value)}/>*/}
            {/*<input name="text"*/}
            {/*       placeholder='Отчество' value={props.nameRegistration}*/}
            {/*       onChange={(e: any) => props.setNameRegistration(e.target.value)}/>*/}
            <Input
                country="BY"
                international
                withCountryCallingCode
                value={props.phoneRegistration}
                onChange={props.setPhoneRegistration}/>
            <input name="text"
                   style={(props.email.length === 0 && props.isFieldEmpty) || props.errorEmail ? {border: '1px solid red'} : {border: '1px solid rgba(0, 0, 0, 0.5)'}}
                   placeholder='E-mail' value={props.email} onChange={(e: any) => {
                props.setErrorEmail(false)
                props.setEmail(e.target.value)
            }}/>
            <select onChange={props.handleChange}
                    style={props.value === '' && props.isFieldEmpty ? {border: '1px solid red'} : {border: '1px solid rgba(0, 0, 0, 0.5)'}}>
                <option>Тип организации</option>
                {props.companyValue.map((item, index) => {
                    return <option key={index}>{item.name}</option>
                })}
            </select>
            <input name="text"
                   style={ {border: '1px solid rgba(0, 0, 0, 0.5)'}}
                   type={'number'}
                   placeholder='Ориентировочная численность организации' value={props.people}
                   onChange={(e: any) => props.setPeople(e.target.value)}/>
            <select onChange={props.handleChangeMode}
                    style={{border: '1px solid rgba(0, 0, 0, 0.5)'}}>
                <option>Вариант сервиса ВУ</option>
                {props.modeCompany.map((item, index) => {
                    return <option key={index}>{item.name}</option>
                })}
            </select>
            <input name="promoCode"
                   style={ {border: '1px solid rgba(0, 0, 0, 0.5)'}}
                   type={'number'}
                   placeholder='Промокод' value={props.promoCode}
                   onChange={(e: any) => props.setpromoCode(e.target.value)}/>

            {/*{props.errorCallback ? <p style={{color: 'red'}}>Неверно заполнены данные!</p> : ''}*/}
            <div className="button-send-wrapp">
                <button name="text" className="button-send" onClick={props.sendData}> Отправить</button>
            </div>
        </div>
    )
}
export default ContactsPage;
