import React, {useState, useEffect} from 'react';
import Header from '../HeaderLandingPage'
import Footer from '../FooterLandingPage';
import Axios from '../../../utils/axios';
import '../LandingPage.css';

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}

const PublicOfferPage = () => {

    const [title, setTitle] = useState('');
    const [body, setBody] = useState([] as any);
    const [pdfForm, setPdfForm] = useState({
        name: '', doc:''
    });

    useEffect(() => {
        const axios = new Axios();

        axios.get(`control/page/2/`)
            .then(res => {
                setTitle(res.data.page[0].name);
                setBody(res.data.page[0].text.split('\r\n'));
                setPdfForm(res.data.page[1]);
            })

        // axios.get(`control/page/5/`)
        //     .then(res => {
        //         setContacts(res.data.page);
        //         console.log(res.data.page)
        //     })
    }, [])
if(Object.keys(pdfForm).length !==0) {
    console.log(pdfForm)
}
    return (<>
            <div>
                <div className='wrapper_header_'>
                    <Header/>
                </div>
                <div className='wrapper_offer_page'>
                    <div className='title_offer'>{title}</div>
                    {Object.keys(pdfForm).length !==0 &&
                    <a className='link_offer_pdf' href={pdfForm.doc}
                        target='_blank'> {pdfForm.name}
                       </a>
                    }
                    <div className='title_offer_1'><b>{title}</b></div>
                    <div className='body_offer'>
                        <div className='offer_date'>
                            <span>г.Минск</span>
                            <span> «___» _____ 2021 г.</span>
                        </div>
                        {
                            body.map((item: any) => {
                                return <p key={getRandomInt(457841)}>{item}</p>
                            })
                        }

                        {/*{constacts.map((item: any) => {*/}
                        {/*    return <p key={getRandomInt(47841)}>{item.name} {item.text}</p>*/}
                        {/*})*/}
                        {/*}*/}
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}
export default PublicOfferPage;
