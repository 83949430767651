import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormInitialValues, formValues} from 'redux-form';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AutocompleteOffice from '../../AutocompleteOffice/AutocompleteOffice';
import Button from '@material-ui/core/Button';
import Axios from '.../../../src/utils/axios';
import './PersonalCard_v2.css';
import TableVerification from './TableVerification';
import TableMarks from './TableMarks'
import classes from "../PersonalCard_v.1/PersonalCard.module.css";
import HistoryModal from "../../HistoryPersonalCardModal/HistoryModal";
import {useHistory} from "react-router-dom";


const MyInput = (props: any) => {
    if (props.input.name === "pass_number") {
        if (!(/^[a-zA-Z/@.-/[0-9]+$/.test(props.input.value[props.input.value.length - 1]))) {
            props.input.value = props.input.value.slice(0, -1);
        }
    }
    return <Input
        id={props.id}
        className={'personalCard2-input'}
        inputProps={props.input}
        disabled={props.disabled}
    />
};

const MyInput12 = (props: any) => {
    if (props.input.name === "pass_number") {
        if (!(/^[a-zA-Z/@.-/[0-9]+$/.test(props.input.value[props.input.value.length - 1]))) {
            props.input.value = props.input.value.slice(0, -1);
        }
    }
    return <Input
        id={props.id}
        inputProps={props.input}
        disabled={props.disabled}
    />
};
const InputPasport = (props: any) => {
    if (props.input.name === "pass_number") {
        if (!(/^[a-zA-Z/@.-/[0-9]+$/.test(props.input.value[props.input.value.length - 1]))) {
            props.input.value = props.input.value.slice(0, -1);
        }
    }
    return <Input
        id={props.id}
        inputProps={props.input}
        disabled={props.disabled}
    />
};
const InputDriving  = (props: any) => {
    if (props.input.name === "pass_number") {
        if (!(/^[a-zA-Z/@.-/[0-9]+$/.test(props.input.value[props.input.value.length - 1]))) {
            props.input.value = props.input.value.slice(0, -1);
        }
    }
    return <Input
        id={props.id}
        inputProps={props.input}
        disabled={props.disabled}
    />
};
const InputDisability = (props: any) => {
    if (props.input.name === "pass_number") {
        if (!(/^[a-zA-Z/@.-/[0-9]+$/.test(props.input.value[props.input.value.length - 1]))) {
            props.input.value = props.input.value.slice(0, -1);
        }
    }
    return <Input
        id={props.id}
        inputProps={props.input}
        disabled={props.disabled}
    />
};

const MyEmptyInput = (props: any) => {
    props.input.value=''
    return <Input
        id={props.id}
        className={'personalCard2-input'}
        inputProps={props.input}
        disabled={props.disabled}
    />
}

const InputSmall = (props: any) => {
    return <Input
        id={props.id}
        className={'personalCard2-input-small'}
        inputProps={props.input}
        disabled={props.disabled}
    />
}

const MyInputSecond = (props: any) => {
    return <Input
        id={props.id}
        className={'personalCard2-input-second'}
        inputProps={props.input}
        disabled={props.disabled}
    />
}


const InputTextArrea = (props: any) => {
    return <textarea
        className={'textarrea-personalCard2'}
        id={props.id}
        rows={props.rows}
        cols={props.cols}
    >
    </textarea>
}

const InputDate = (props: any) => {

    return <Input
        id={props.id}
        className={'personalCard2-input-date'}
        inputProps={props.input}
        type="date"
        // value={initialValues.time_end || ''}
    />
}


const PersonalCardForm = ({
                              passport,driving,disability,
                              initialValues, office, setOffice, handleChangeOffice,
                              valueStructure, handleChangeStructure, setOtherStructure, otherStructure,
                              valueStatus, handleChangeStatus, otherStatus, setOtherStatus,
                              valueReserve, handleChangeReserve, otherReserve, setOtherReserve,
                              verifications, setStateTableVerification, clearChangeStatus,onChangeGrade,
                              setSavePDF, setStateTableMarks, marks, fullname, handleFullname, id,
                              valueWyc,handleChangeWyc,valueSpecial,handleChangeSpecial,requirementVal,handleChangeRequirementVal,
                              ...props
                          }: any) => {
    //const id = props.match.params.id;

    const [listEducation, setListEducation] = useState([] as any);
    const [listProfile, setListProfile] = useState([] as any);
    const [listMarital, setListMarital] = useState([]);
    const [listStructure, setListStructure] = useState({} as any);
    const [listAccountG, setListAccountG] = useState([]);
    const [listGrade, setListGrade] = useState([]);
    const [listStatus, setListStatus] = useState([]);
    const [listReserve, setListReserve] = useState([]);
    const [listBasicMilitary, setListBasicMilitary] = useState([]);
    const [listForm6, setListForm6] = useState([]);

    const [idAccountG, setIdAccountG] = useState(0);

    const [itemsHistory, setItemsHistory] = useState([] as any);

    const [openHistoryModal, setOpenHistoryModal] = React.useState(false);

    const [isDisabled, setDisabled] = useState(false);

    const handleOpenHisoryModal = () => {
        setOpenHistoryModal(true);
    };

    const getHistory = () => {
        const axios = new Axios();
        axios.get(`worker/history/${id}/`) //${id} 210
            .then(res => {
                setItemsHistory(res.data.results);
                handleOpenHisoryModal();
            })
    };

    const handleCloseHistoryModal = () => {
        setOpenHistoryModal(false);
    };

    useEffect(() => {
        const axios = new Axios();

        axios.get(`library/education/`)
            .then(res => {
                setListEducation(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })

        axios.get(`library/profile/`)
            .then(res => {
                setListProfile(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })

        axios.get(`library/marital/`)
            .then(res => {
                setListMarital(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
        /* axios.get(`library/structure1/`)
             .then(res => {
                 setListStructure(res.data.results);
             })
             .catch(err => {
                 console.log(err);
             })*/
        axios.get(`library/accountg/`)
            .then(res => {
                setListAccountG(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
        axios.get(`library/rank/`)
            .then(res => {
                setListGrade(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })

        axios.get(`library/structure1/`)
            .then(res => {
                //debugger;
                setListStructure(res.data);
            })
            .catch(err => {
                console.log(err);
            })

        axios.get(`library/validity/`)
            .then(res => {
                setListStatus(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
        axios.get(`library/reserve/`)
            .then(res => {
                setListReserve(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
        axios.get(`library/basis/`)
            .then(res => {
                setListBasicMilitary(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
        axios.get(`library/professions/`)
            .then(res => {
                setListForm6(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const handleAccountG = (e: any) => {
        setIdAccountG(Number(e.target.value) as any);

        if (e.target.value === '5' || e.target.value === '6') {
            clearChangeStatus();
        }

        if (e.target.value === '4') {
            setDisabled(true)
        } else setDisabled(false)
        };

    return (
        <form onSubmit={props.handleSubmit} style={{width: '100%'}}>
            <div className='personalCard2-container'>
                <table className="personalCard2-table">
                    <tbody>
                    <tr className='header-table'>
                        <th colSpan={4}>ПЕРСОНАЛЬНЫЕ ДАННЫЕ</th>
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Фамилия</td>
                        <td colSpan={2}>

                            <Input
                                id={"lastname"}
                                className={'personalCard2-input'}
                                value={fullname.lastname}
                                onChange={handleFullname}
                            />

                        </td>

                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Собственное имя</td>
                        <td colSpan={2}>
                            {
                                <Input
                                    id={"firstname"}
                                    className={'personalCard2-input'}
                                    value={fullname.firstname}
                                    onChange={handleFullname}
                                />
                            }

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Отчество (если таковое имеется)</td>
                        <td colSpan={2}>
                            <Input
                                id={"middlename"}
                                className={'personalCard2-input'}
                                value={fullname.middlename}
                                onChange={handleFullname}
                            />

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Дата рождения</td>
                        <td colSpan={2}>
                            <Field
                                id="date_of_birth"
                                name="date_of_birth"
                                className={'registr-input'}
                                component={InputDate}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Место рождения</td>
                        <td colSpan={2}>
                            <Field
                                id="birthplace"
                                name="birthplace"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Идентификационный номер</td>
                        <td colSpan={2}>
                            <Field
                                id="pass_number"
                                name="pass_number"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Место жительства</td>
                        <td colSpan={2}>
                            <Field
                                id="registered_address"
                                name="registered_address"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Место пребывания</td>
                        <td colSpan={2}>
                            <Field
                                id="address"
                                name="address"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={1} rowSpan={4} className='data-left cell-bold'>Образование</td>
                        <td colSpan={1}>
                            уровень основного образования
                        </td>
                        <td colSpan={2}>
                            <Field name="education_id" component="select" className='mySelect'>
                                <option value={0} key={0 + '3dg'}></option>
                                {listEducation.map((item: any) => {
                                    return <option value={Number(item.id)} key={item.id + '3dg'}>{item.name}</option>
                                })}
                            </Field>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            учреждения образования <br/>и годы их окончания
                        </td>
                        <td colSpan={1}>
                            специальность (профессия)
                        </td>
                        <td colSpan={1}>
                            присвоенная квалификация
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            <Field
                                id="education_full"
                                name="education_full"
                                className={'registr-input textarrea-personalCard2'}
                                component={'textarea'}

                            />
                        </td>
                        <td colSpan={1}>
                            <Field
                                id="edu_specialization"
                                name="edu_specialization"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                        <td colSpan={1}>
                            <Field style={{width: '99%'}}
                                   id="edu_qualification"
                                   name="edu_qualification"
                                   className={'registr-input'}
                                   component={MyInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            <Field
                                id="edu_dop_full"
                                name="edu_dop_full"
                                className={'registr-input textarrea-personalCard2'}
                                component={'textarea'}

                            />
                        </td>
                        <td colSpan={1}>
                            <Field
                                id="edu_dop_specialization"
                                name="edu_dop_specialization"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                        <td colSpan={1}>
                            <Field
                                id="edu_dop_qualification"
                                name="edu_dop_qualification"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={1} rowSpan={4} className='data-left cell-bold'>Семья</td>
                        <td colSpan={1}>
                            семейное положение
                        </td>
                        <td colSpan={2}>
                            <Field name="marital_status_id" component="select" className='mySelect'>
                                <option value={0} key={0 + '3d11g'}></option>
                                {listMarital.map((item: any) => {
                                    return <option value={Number(item.id)} key={item.id + '313dg'}>{item.name}</option>
                                })}
                            </Field>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            супруга (супруг)
                        </td>
                        <td colSpan={2}>
                            <Field
                                id="family_half"
                                name="family_half"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            дети/родители, если <br/>гражданин холост (не замужем) <br/>и не имеет детей
                        </td>
                        <td colSpan={2}>
                            <Field
                                id="family"
                                name="family"
                                className={'registr-input textarrea-personalCard2'}
                                component={'textarea'}

                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            место жительства<br/> близких родственников, <br/>которые не проживают <br/>совместно с
                            гражданином
                        </td>
                        <td colSpan={2}>
                            <Field
                                id="family_home"
                                name="family_home"
                                className={'registr-input textarrea-personalCard2'}
                                component={'textarea'}

                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1} rowSpan={2} className='cell-bold'>
                            Работа <br/>(учёба)
                        </td>
                        <td colSpan={1}>
                            структурное подразделение
                        </td>
                        <td colSpan={2}>
                            <Field
                                id="dsivision"
                                name="dsivision"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            должность (профессия)
                        </td>
                        <td colSpan={2}>
                            <Field
                                id="position"
                                name="position"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1} rowSpan={3} className='cell-bold'>
                            Номера <br/>телефонов
                        </td>
                        <td colSpan={1}>
                            рабочий
                        </td>
                        <td colSpan={2}>
                            <Field
                                id="work_phone"
                                name="work_phone"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            домашний
                        </td>
                        <td colSpan={2}>
                            <Field
                                id="home_phone"
                                name="home_phone"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                            мобильный
                        </td>
                        <td colSpan={2}>
                            <Field
                                id="phone"
                                name="phone"
                                className={'registr-input'}
                                component={MyInput}
                            />
                        </td>
                    </tr>
                    <tr className='header-table'>
                        <th colSpan={4}>ПЕРВИЧНЫЕ ДАННЫЕ ВОИНСКОГО УЧЕТА</th>
                    </tr>

                    <tr>
                        <td colSpan={2} className='data-left'>Воинское звание</td>
                        <td colSpan={2}>
                            <Field name="grade_id" component="select" className='mySelect' disabled={(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4)
                            || idAccountG===4 ? true : isDisabled}
                            >
                                <option value={0} key={0 + 'grade_id'}  ></option>
                                {listGrade.map((item: any) => {
                                    return <option value={idAccountG === 4 ? 0 : Number(item.id)}
                                                   key={item.id + 'grade_id'}>{item.name}</option>
                                })}
                            </Field>
                            <br/>
                            {
                                (idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4)
                                || idAccountG===4 ? null : <Field
                                    id="grade_full"
                                    name="grade_full"
                                    className={'personalCard2-input'}
                                    component={MyInputSecond}

                                />
                            }

                        </td>
                    </tr>
                    <tr>

                        <td colSpan={2} className='data-left'>Категория запаса</td>
                        {listStructure[3] !==undefined  &&
                        <td colSpan={2}>
                            <Select
                                id="structure"
                                value={valueStructure}
                                onChange={handleChangeStructure}
                                className={'personalCard2-input'}
                                disabled={idAccountG === 4 || idAccountG === 5 || idAccountG === 6 || (idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id !==3)  ? true : isDisabled}
                                //error={isEmpty ? (state.status_id === '' ? true : false) : false}
                            >
                                {listStructure[3].map((item: any) => {
                                    return <MenuItem value={idAccountG === 4 ? null : idAccountG === 5 ? null :
                                        idAccountG === 6 ? null :  item.name} key={item.id + 'structure'}>{item.name}</MenuItem>
                                })}
                                <MenuItem id={'structure' + 7} value={''} key={140 + 'structure'}/>
                            </Select>
                            {/*{*/}
                            {/*    valueStructure === '' ?*/}
                            {/*        <div>*/}
                            {/*            <br/>*/}
                            {/*            <Field*/}
                            {/*                id="structure_empty"*/}
                            {/*                name="structure_empty"*/}
                            {/*                className={'personalCard2-input'}*/}
                            {/*                component={MyInputSecond}*/}
                            {/*                disabled={idAccountG === 4 || idAccountG === 5 || idAccountG === 6 || idAccountG === 0 ? true : isDisabled}*/}
                            {/*            />*/}
                            {/*        </div> : null*/}
                            {/*}*/}
                        </td>
                        }
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Группа учета</td>
                        <td colSpan={2}>
                            <Field name="accountg_id" component="select" className='mySelect' onChange={handleAccountG}>
                                <option value={0} key={0 + 'accountG'}></option>
                                {listAccountG.map((item: any) => {
                                    return <option value={item.id} key={item.id + 'accountG'}>{item.name}</option>
                                })}
                            </Field>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Профиль</td>
                        <td colSpan={2}>
                            <Field
                                id="profile_id"
                                name="profile_id"
                                className={'mySelect'}
                                // component={MyInput}
                                component="select"
                                disabled={(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4)
                                || idAccountG===4 ? true : isDisabled}>
                                <option value={0} key={0 + '3dg'}></option>
                                {(initialValues.accountg_id !==null && initialValues.accountg_id ===5) ||
                                (initialValues.accountg_id !==null && initialValues.accountg_id ===6) || idAccountG===5 || idAccountG===6 ?
                                    listProfile.slice(0,6).map((item: any) => {
                                            return <option value={idAccountG === 4 ? 0 : Number(item.id)} key={item.id + '3dg'}>{item.name}</option>
                                        }) :  (initialValues.accountg_id !==null && initialValues.accountg_id ===3 ) || idAccountG===3 ?
                                        listProfile.slice(6).map((item: any) => {
                                            return <option value={idAccountG === 4 ? 0 : Number(item.id)} key={item.id + '3dg'}>{item.name}</option>
                                        }) : null}
                                {/*{listProfile.map((item: any) => {*/}
                                {/*    return <option value={idAccountG === 4 ? 0 : Number(item.id)} key={item.id + '3dg'}>{item.name}</option>*/}
                                {/*})}*/}
                            </Field>
                        </td>
                    </tr>                    
                    <tr>
                        <td colSpan={2} className='data-left'>Номер военно-учетной специальности <br/>и код воинской
                            должности
                        </td>
                        <td colSpan={2}>
                            {(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4) ||
                                idAccountG===4 ?
                                <Field
                                id="wyc"
                                name="wyc"
                                className={'personalCard2-input'}
                                component={MyEmptyInput}
                                disabled={(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4) ||
                                idAccountG===4 ? true : false}
                                />
                                :
                            <Field
                                id="wyc"
                                name="wyc"
                                className={'personalCard2-input'}
                                component={MyInput}
                            />
                            }
                        </td>
                    </tr>


                    <tr>
                        <td colSpan={2} className='data-left'>Категория годности к военной службе</td>
                        <td colSpan={2}>
                            <Select
                                id="status"
                                value={(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4)
                                    || idAccountG===4 || idAccountG===5 || idAccountG===6 ? null :valueStatus}
                                onChange={handleChangeStatus}
                                className={'personalCard2-input'}
                                disabled={(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4)
                                || idAccountG===4 || idAccountG===5 || idAccountG===6 || initialValues.accountg_id===4 && idAccountG===0 ||
                                initialValues.accountg_id===5 && idAccountG===0 || initialValues.accountg_id===6 && idAccountG===0
                                    ? true : isDisabled}
                                //error={isEmpty ? (state.status_id === '' ? true : false) : false}
                            >
                                {listStatus.map((item: any) => {
                                    return <MenuItem value={item.name} key={item.id + 'status'}>{item.name}</MenuItem>
                                })}
                                <MenuItem id={'status_id' + 7} value={''} key={100 + 'status'}/>
                            </Select>
                            {/*{*/}
                            {/*    valueStatus === '' ?*/}
                            {/*        <div>*/}
                            {/*            <Field*/}
                            {/*                id="status_empty"*/}
                            {/*                name="status_empty"*/}
                            {/*                className={'personalCard2-input'}*/}
                            {/*                component={MyInputSecond}*/}
                            {/*                disabled={(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4)*/}
                            {/*                || idAccountG===4 || idAccountG===5 || idAccountG===6 || initialValues.accountg_id===4 && idAccountG===0 ||*/}
                            {/*                initialValues.accountg_id===5 && idAccountG===0 || initialValues.accountg_id===6 && idAccountG===0*/}
                            {/*                    ? true : isDisabled}*/}
                            {/*            />*/}
                            {/*        </div> : null*/}
                            {/*}*/}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Разряд запаса</td>
                        <td colSpan={2}>
                            <Select
                                id="reserve"
                                value={valueReserve}
                                onChange={handleChangeReserve}
                                className={'personalCard2-input'}
                                disabled={(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4)
                                || idAccountG===4 ? true : isDisabled}
                                //error={isEmpty ? (state.status_id === '' ? true : false) : false}
                            >
                                {listReserve.map((item: any) => {
                                    return <MenuItem value={ idAccountG===4 ? null :
                                        idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4 ? null : item.name} key={item.id + 'reserve'}>{item.name}</MenuItem>
                                })}
                                <MenuItem id={'reserve' + 7} value={''} key={140 + 'reserve'}/>
                            </Select>
                            {/*{*/}
                            {/*    valueReserve === '' ?*/}
                            {/*        <div>*/}
                            {/*            <br/>*/}
                            {/*            <Field*/}
                            {/*                id="reserve_level_empty"*/}
                            {/*                name="reserve_level_empty"*/}
                            {/*                className={'personalCard2-input'}*/}
                            {/*                component={MyInputSecond}*/}
                            {/*                disabled={isDisabled}*/}
                            {/*            />*/}
                            {/*        </div> : null*/}
                            {/*}*/}
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={2} className='data-left'>Наименование военного комиссариата по месту жительства</td>
                        <td colSpan={2}>
                        <div className={'registr-input personalCard2-autocomplete'}>
                                <AutocompleteOffice
                                    handleChangeOfficeName={handleChangeOffice}
                                    setOfficeName={setOffice}
                                    office={office}
                                />
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={2} className='data-left'>Состоит на специальном воинском учете</td>
                        <td colSpan={2}>

                            <Select
                                id="special"
                                value={valueSpecial}
                                onChange={handleChangeSpecial}
                            >
                                <MenuItem value={''} />
                                <MenuItem value={'true'} >да</MenuItem>
                                <MenuItem value={'false'} >нет</MenuItem>
                            </Select>

                            {
                                valueSpecial===true || valueSpecial==='true' ?
                                    <div>
                                        <br/>
                                        <Field
                                            id="specialN"
                                            name="specialN"
                                            className={'registr-input textarrea-personalCard2'}
                                            component={'textarea'}
                                            placeholder={'Введите данные о специальном учёте военнообязанного'}
                                        />
                                    </div> : <></>
                            }

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Дата приёма гражданина на воинский учет в организации</td>
                        <td colSpan={2}>
                            <Field
                                id="add_military"
                                name="add_military"
                                className={'registr-input second'}
                                component={InputDate}
                            />
                            <br/>
                            {/*Приказ №
                            <Field
                                id="order_military"
                                name="order_military"
                                className={'registr-input'}
                                component={InputSmall}
                            />
                            от
                            <div className='date-wrapp'>
                                <Field
                                    id="date_add_military"
                                    name="date_add_military"
                                    className={'registr-input'}
                                    component={InputDate}
                                />
                            </div>*/}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className='data-left'>Дата и основание снятия (исключения) <br/>гражданина с
                            воинского учета
                        </td>
                        <td colSpan={2}>
                            <Field
                                id="remove_military"
                                name="remove_military"
                                className={'registr-input second'}
                                component={InputDate}
                            />
                            <br/>
                            {/*Приказ №
                            <Field
                                id="order_remove_military"
                                name="order_remove_military"
                                className={'registr-input'}
                                component={InputSmall}
                            />
                            от
                            <div className='date-wrapp'>
                                <Field
                                    id="date_remove_military"
                                    name="date_remove_military"
                                    className={'registr-input'}
                                    component={InputDate}
                                />
                            </div>*/}
                            <br/>

                            <Field name="basic_military_id" component="select" className='mySecondSelect' >
                                <option value={0} key={0 + 'basic_military_id'} style={{color:'gray'}}>Основание снятия (исключения) с ВУ </option>
                                {listBasicMilitary.map((item: any) => {
                                    return <option value={
                                        Number(item.id)} key={item.id + 'basic_military_id'}>{item.name}</option>
                                })}
                            </Field>

                        </td>
                    </tr>


                    {/*<tr>*/}
                    {/*    <td colSpan={2} className='data-left'>Комментарий к разделу "Состоит ли на специальном учете"</td>*/}
                    {/*    <td colSpan={2}>*/}
                    {/*        <Field*/}
                    {/*            id="specialN"*/}
                    {/*            name="specialN"*/}
                    {/*            className={'registr-input textarrea-personalCard2'}*/}
                    {/*            component={'textarea'}*/}
                    {/*        />*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    <tr className='header-table'>
                        <th colSpan={4}>ОСОБЫЕ ОТМЕТКИ</th>
                    </tr>

                    <tr>
                        <td colSpan={1} className='data-left'>Пол</td>
                        <td colSpan={3}>
                            <Field name="sex" component="select" className='mySecondSelect' >
                                 <option value='' key={123+'_'}></option>
                                 <option value='m' key={123+'m'}>муж.</option>
                                 <option value='f' key={123+'f'}>жен.</option>
                            </Field>

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1} className='data-left'>Паспорт</td>
                        <td colSpan={3}>
                                <Field
                                    id="passport"
                                    name="passport"
                                    className={'registr-input'}
                                    component={InputPasport}
                                />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1} className='data-left'>Военный билет </td>
                        <td colSpan={3}>
                            {(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4) ||
                            idAccountG===4 ?
                                <Field
                                    id="military_1"
                                    name="military_1"
                                    className={'registr-input'}
                                    component={MyEmptyInput}
                                    disabled={(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4) ||
                                    idAccountG===4 ? true : false}
                                />
                                :
                                <>
                                {"серии "}
                                <Field
                                    id="military_1"
                                    name="military_1"
                                    className={'registr-input second'}
                                    component={MyInput12}
                                />
                                {" № "}
                                <Field
                                id="military_2"
                                name="military_2"
                                className={'registr-input'}
                                component={MyInput12}
                                />
                                </>
                            }

                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1} className='data-left'>Водительское удостоверение</td>
                        <td colSpan={3}>
                                <Field
                                    id="driving"
                                    name="driving"
                                    className={'registr-input'}
                                    component={InputDriving}
                                />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1} className='data-left'>Удостоверение инвалида</td>
                        <td colSpan={3}>
                                <Field
                                    id="disability"
                                    name="disability"
                                    className={'registr-input'}
                                    component={InputDisability}
                                />
                        </td>
                    </tr>
                        <tr>
                        <td colSpan={1} className='data-left'>Мобилизационное предписание</td>
                        <td colSpan={3}>

                            {(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4) ||
                            idAccountG===4 ?
                                <Field
                                    id="requirement"
                                    name="requirement"
                                    className={'registr-input'}
                                    component={MyEmptyInput}
                                    disabled={(idAccountG === 0 && initialValues.accountg_id !==null && initialValues.accountg_id ===4) ||
                                    idAccountG===4 ? true : false}
                                />
                                : <>
                                    <Select
                                        id="requirement"
                                        value={requirementVal}
                                        onChange={handleChangeRequirementVal}
                                    >
                                        <MenuItem value={''} />
                                        <MenuItem value={'false'} >нет</MenuItem>
                                        <MenuItem value={'true'} >да</MenuItem>
                                    </Select>
                                    {
                                        requirementVal==='true' || requirementVal===true  ?
                                            <div>
                                                <br/>
                                                <Field
                                                    placeholder={'Внесите данные о Моб. предписании'}
                                                    id="requirement_annotation"
                                                    name="requirement_annotation"
                                                    className={'registr-input textarrea-personalCard2'}
                                                    component={'textarea'}
                                                />
                                            </div> : <></>
                                    }
                                </>

                                // <Field
                                //     id="requirement"
                                //     name="requirement"
                                //     className={'registr-input'}
                                //     component={MyInput}
                                // />
                            }
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1} className='data-left'>Наименование категории должности рабочих</td>
                        <td colSpan={3}>
                            <Field name="form6_id" component="select" className='mySelect' onChange={handleAccountG}>
                                <option value={0} key={0 + 'form6_id'}></option>
                                {listForm6.map((item: any) => {
                                    return <option value={item.id} key={item.id + 'form6_id'}>{item.name}</option>
                                })}
                            </Field>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            <TableMarks
                                marks={marks}
                                setStateTable={setStateTableMarks}
                            />
                        </td>
                    </tr>
                    <tr className='header-table'>
                        <th colSpan={4}>ОТМЕТКИ О СВЕРКЕ ДАННЫХ</th>
                    </tr>
                    <tr>
                        <td colSpan={4} className='wrapper-table-personalCard2'>
                            <TableVerification
                                verifications={verifications}
                                setStateTable={setStateTableVerification}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div className='btn-save-wrapper'>
                    <button className='btn-save-pdf' onClick={() => {
                        setSavePDF(true)
                    }}>Отчёт
                    </button>
                    <button className='btn-save' onClick={()=>{props.setSaveInfo(true)}} >Сохранить</button>
                    <div className={classes.button}>
                        <Button variant="contained" onClick={getHistory}>История</Button>
                    </div>
                </div>


            </div>
            <HistoryModal
                open={openHistoryModal}
                handleClose={handleCloseHistoryModal}
                items={itemsHistory}
            />
        </form>
    )
}

const mapStateToProps = (state: any, props: any) => {
    return ({
        initialValues: props.initialValues, // retrieve name from redux store 
        url: props.url,
        setOffice: props.setOffice,
        office: props.office,
        handleChangeOffice: props.handleChangeOffice,
        valueStructure: props.valueStructure,
        handleChangeStructure: props.handleChangeStructure,
        setOtherStructure: props.setOtherStructure,
        otherStructure: props.otherStructure,
        valueStatus: props.valueStatus,
        handleChangeStatus: props.handleChangeStatus,
        otherStatus: props.otherStatus,
        setOtherStatus: props.setOtherStatus,
        verifications: props.verifications,
        setStateTable: props.setStateTable
    })
};

const PersonalCardReduxForm = connect(
    mapStateToProps
)(reduxForm({
    form: 'personalCard_form',
    enableReinitialize: true
})(PersonalCardForm));

export default PersonalCardReduxForm;
