import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Axios from '../../utils/axios';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReportModal from './ReportModal';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SettingsModalForDocuments from "../TableSettingsModal/SettingsModalForDocuments";
import {DeletedDocuments} from "../../pages/DeletedDocuments";
import DocumentsToolbar from "../Documents/DocumentsToolbar";


type Order = 'asc' | 'desc';


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        // maxHeight: '90vh',
        height: 'calc(100vh - 250px)'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    linkTypeReport: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: 'black'
    }
});

interface Item2 {
    id: string;
    office: string;
    officer: string;
    sergeant: string;
    recruit: string;
}

const formatDate = (date: string) => {
    const d = new Date(date);

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear();

    return result;
}

export default function ReportsTable(props: any) {
    const classes = useStyles();


    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('register_date');
    const [items, setItems] = React.useState([]);

    const [openModal, setOpenModal] = React.useState(false);

    const [stateTable2, setStateTable2] = React.useState<Item2[]>([]);
    const [stateTable1, setStateTable1] = React.useState({
        affiliations: 'нет',
        all_worker: '',
        all_war: '',
        officer: '',
        recruit: '',
        war_mp: '',
        officer_mp: '',
    });

    const [openModalSettings, setOpenModalSettings] = useState(false);

    const [columns, setColumns] = useState(null as any);
    const [defaultColumns, setDefaultColumns] = useState([] as any);
    const [newData, setNewData] = useState(false);
    const [update, setUpdate] = useState(0);
    const [countItems, setCountItems] = useState(0);
    const [rowId, setRowId] = useState('');


    const handleClickOpenSettings = () => {
        setOpenModalSettings(true);
    };

    const handleCloseSettings = () => {
        setOpenModalSettings(false);
    };

    useEffect(() => {
        const axios = new Axios();
        axios.get(`user/me/`)
            .then(res => {
                const result = Object.keys(res.data.setting['5']);
                setDefaultColumns(Object.assign(result, {delete: 'Удаление'}))
            })
    }, []);

    useEffect(() => {
        const axios = new Axios();
        axios.get(`worker/field/5`)
            .then(res => {
                let newArrData = Object.assign(res.data, {delete: 'Удаление'})

                const result = [];
                for (let i = 0; i < defaultColumns.length; i += 1) {
                    result.push({});
                }

                for (const [key, value] of Object.entries(newArrData)) {
                    const index = defaultColumns.indexOf(key);
                    if (index !== -1) {
                        result[index] = {
                            id: key,
                            label: value,
                            isSelected: true,
                        };
                    } else {
                        result.push({
                            id: key,
                            label: value,
                            isSelected: false,
                        })
                    }
                }
                let result_ = result.map((item) => {
                    return {
                        ...item, label: item.label === 'Docs' ? 'Документ' : item.label
                    }
                });
                let arr = result_.filter((i: any) => {
                    return Object.entries(i).length !== 0
                })
                let arr_ = arr.filter((i: any) => {
                    return i.label !== undefined && i.label !=='Entering' && i.label !=='Flag' && i.id !=='name'
                })
                setColumns(arr_)
            })
            .catch(err => {
                console.log(err);
            })
    }, [defaultColumns])

    const getData = () => {
        let order_ = '';
        if (order === 'asc') {
            order_ = '-'
        }

        const axios = new Axios();
        axios.post(`worker/report/${localStorage.getItem('id')}/filter/?page=${props.page + 1}&page_size=${props.rowsPerPage}`, {
            entering: false,
            order: `${order_}${orderBy}`
        })
            .then(res => {
                setItems(res.data.results);
                setNewData(false)
                setCountItems(res.data.count);
                //props.setPage(0);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        getData()
    }, [orderBy, order, props.page, props.rowsPerPage, newData, update, props.updateTable])

    const handleClickOpenModal = () => {
        setOpenModal(true);

        const axios = new Axios();
        axios.get(`worker/recruit/${localStorage.getItem('id')}`)
            .then(res => {

                setStateTable1({
                    affiliations: 'нет',
                    all_worker: res.data.all_worker,
                    all_war: res.data.all_war,
                    officer: res.data.officer,
                    recruit: res.data.recruit,
                    war_mp: res.data.war_mp,
                    officer_mp: res.data.officer_mp,
                })

                setStateTable2(res.data.table)

            })
            .catch(err => {
                console.log(err)
            })
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setRowsPerPage(+event.target.value);
        props.setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const _orderBy = (id: any) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, id)
        setOrderBy(id);
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (id: string) => {
        setRowId(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const deletedLetter = () => {
        handleClose();
        const axios = new Axios();
        axios.delete(`worker/report/${localStorage.getItem('id')}/${rowId}/`, {entering:false})
            .then(res => {
                setNewData(true)
                setUpdate(new Date().getMilliseconds())
            })
    };

    useEffect(()=>{
        props.getDeletedDate()
    },[update])






    const [valueDocumentType, setDocumentType] = useState(0);
    const [officeName, setOfficeName] = useState('');


    const handleChangeOfficeName = (event: any) => {
        setOfficeName(event.name as string);
    };
    const handleChangeDocumentType = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDocumentType(event.target.value as number);
    };

    useEffect(() => {
        let data = {};

        let order_ = '';
        if (order === 'asc') {
            order_ = '-'
        }

        if (officeName.length > 0 && valueDocumentType > 0) {
            data = {
                "office__name__contains": officeName,
                'type': valueDocumentType,
                order: `${order_}${orderBy}`,
                'entering': false,
            }
        } else if (officeName.length > 0) {
            data = {
                "office__name__contains": officeName,
                order: `${order_}${orderBy}`,
                'entering': false,
            }
        } else if (valueDocumentType > 0) {
            data = {
                'type': valueDocumentType,
                order: `${order_}${orderBy}`,
                'entering': false,
            }
        } else data = {
            order: `${order_}${orderBy}`,
            'entering': false,
        }

        const axios = new Axios();
        axios.post(`worker/report/${localStorage.getItem('id')}/filter/?page=${props.page + 1}&page_size=${props.rowsPerPage}`, data)
            .then(res => {
                setCountItems(res.data.count);
                setItems(res.data.results);
                //setPage(0);
            })
            .catch(err => {
                console.log(err);
            })
    }, [props.page, props.rowsPerPage, officeName, valueDocumentType, order, orderBy, props.updateTable]);


    return (
        <div style={{margin: '0 auto', width: '98%'}}>
            <div className='container_'>
                <Button variant="outlined" onClick={() => {
                    handleClickOpenSettings()
                }} style={{width: '100px'}}>
                    Вид
                </Button>
            </div>
            <div style={{width:'98%'}}>
                <DocumentsToolbar
                    valueDocumentType={valueDocumentType}
                    handleChangeDocumentType={handleChangeDocumentType}
                    handleChangeOfficeName={handleChangeOfficeName}
                    updateTable={props.updateTable}
                    setOfficeName={setOfficeName}
                    officeName={officeName}
                    handleClickOpen={handleClickOpenSettings}
                    type={'reports'}
                />
            </div>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns ? columns.map((column: any) => {
                                    if (column.isSelected)
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{minWidth: column.minWidth}}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id ? order : 'asc'}
                                                    onClick={_orderBy(column.id)}
                                                >
                                                    {column.label}
                                                    {
                                                        orderBy === column.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null
                                                    }
                                                </TableSortLabel>
                                            </TableCell>
                                        )
                                }) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row: any, number: number) => {
                                const koff2 = (props.page + 1) * props.rowsPerPage - props.rowsPerPage + 1;
                                const koff = order === 'desc' ? koff2 : countItems - koff2;
                                return (<>
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns ? columns.map((column: any) => {
                                                const columnID = column.id;
                                                const value = row[column.id];

                                                if (column.isSelected) {

                                                    if (columnID === 'id') {
                                                        return <TableCell key={column.id} align={column.align}>
                                                            {order === 'asc' ? koff - number + 1 : koff + number}
                                                        </TableCell>
                                                    }

                                                    if (columnID === 'office__short_name') {
                                                        return <TableCell key={column.id} align={column.align}>
                                                            {row.type.id===13 ? "" : row.office}
                                                        </TableCell>
                                                    }
                                                    if (columnID === 'type__name') {
                                                        return <TableCell key={column.id} align={column.align}>
                                                            {row.type.name}
                                                        </TableCell>
                                                    }
                                                    if (columnID === 'register_date') {
                                                        return <TableCell key={column.id} align={column.align}>
                                                            {formatDate(row.register_date)}
                                                        </TableCell>
                                                    }
                                                    if (columnID === 'flag_date') {
                                                        return <TableCell key={column.id} align={column.align}>
                                                            {row.flag_date ? formatDate(row.flag_date) : 'нет'}
                                                        </TableCell>
                                                    }
                                                    if (columnID === 'user') {
                                                        return <TableCell key={column.id} align={column.align}>
                                                            {row.name ? row.name : row.user_name}
                                                        </TableCell>
                                                    }

                                                    if (columnID === "delete") {
                                                        return <TableCell key={column.id} align={column.align}>
                                                            <button style={{cursor: 'pointer'}}
                                                                    onClick={() => {
                                                                        handleClickOpen(row.id)
                                                                    }}>Удалить
                                                            </button>
                                                        </TableCell>
                                                    }
                                                    if (columnID === "docs") {
                                                        return <TableCell key={column.id} align={column.align}>
                                                            {row.type.id===13 ? <a href={row.docs} style={{color:'black'}}
                                                                                     download={row.type.name + row.type.id}
                                                            >Отчёт о численности работающих и забронированных военнообязанных и призывников</a>
                                                            :
                                                                <a href={row.docs} style={{color:'black'}}
                                                                   download={row.type.name + row.type.id}
                                                                >{decodeURI(row.docs.slice(row.docs.indexOf('docs/')+5))}</a>
                                                            }

                                                        </TableCell>
                                                    }
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                }
                                            }) : null}
                                        </TableRow>
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Вы действительно хотите удалить
                        документ ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            У вас есть возможность восстановить документ в течение 7 дней.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Нет
                        </Button>
                        <Button onClick={deletedLetter} color="primary" autoFocus>
                            Да
                        </Button>
                    </DialogActions>
                </Dialog>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    labelRowsPerPage='Показывать по:'
                    backIconButtonText='Предыдущие'
                    nextIconButtonText='Следующие'
                    labelDisplayedRows={({from, to, count}) => `${from}-${to} из ${count}`}
                    count={countItems}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <ReportModal
                handleClickOpen={handleClickOpenModal}
                handleClose={handleCloseModal}
                open={openModal}
                stateTable2={stateTable2}
                stateTable1={stateTable1}
            />

            <SettingsModalForDocuments
                open={openModalSettings}
                handleClose={handleCloseSettings}
                columns={columns}
                setColumns={setColumns}
                essence={5}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                setItems={setItems}
                body={'reports'}
            />
            <DeletedDocuments setOpenDeleted={props.setOpenDeleted} isOpenDeleted={props.isOpenDeleted}
                              deletedData={props.deletedData} setUpdateTable={setUpdate}/>
        </div>
    );
}
