import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { combineReducers } from "redux";
import { withMobileDialog } from "@material-ui/core";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatDate = (date: string) => {
    const d = new Date(date);

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear();

    return result;
}

const PersonalCard_v2PDF = async (form: any) => {

    const fullName = (localStorage.getItem('resp-for-documents') as any);


    let nameResponsible=  fullName ? fullName.split(' ')[1] && fullName.split(' ')[2] && fullName.split(' ')[0] ?
        fullName.split(' ')[1][0] + '.' + fullName.split(' ')[2][0] + " " + fullName.split(' ')[0]
        : '' : fullName

    let accountg = null;

    switch (form.accountg_id) {
        case 3:
            accountg = 'ПСС';
            break;
        case 4:
            accountg = 'П'
            break;
        case 5:
            accountg = 'О'
            break;
        case 6:
            accountg = 'ОГБ'
            break;
        default:
            accountg = ' '
            break;
    }

    const getDataTable = (form: any) => {

        const result = [
                [{ text: 'ОСОБЫЕ ОТМЕТКИ', fontSize: 8, style: '', bold: true, colSpan: 4, alignment: 'center'}, '', '', ''],
            [{ text: 'Паспорт', fontSize: 8, style: '', colSpan: 1, alignment: 'left' }, { text: `${form.passport ? form.passport : ''}`, fontSize: 8, style: '', colSpan: 3, alignment: 'left', bold: true }, '', ''],
            [{ text: 'Военный билет', fontSize: 8, style: '', colSpan: 1, alignment: 'left' }, { text: `серии:${form.military ? form.military : ''}`, fontSize: 8, style: '', colSpan: 3, alignment: 'left', bold: true }, '', ''],
            [{ text: 'Водительское удостоверение', fontSize: 8, style: '', colSpan: 1, alignment: 'left' }, { text: `${form.driving ? form.driving : ''}`, fontSize: 8, style: '', colSpan: 3, alignment: 'left', bold: true }, '', ''],
            [{ text: 'Удостоверение инвалида', fontSize: 8, style: '', colSpan: 1, alignment: 'left' }, { text: `${form.disability ? form.disability : ''}`, fontSize: 8, style: '', colSpan: 3, alignment: 'left', bold: true }, '', ''],
            [{ text: 'Мобилизационное предписание', fontSize: 8, style: '', colSpan: 1, alignment: 'left' }, { text: `${form.requirement ? form.requirement===true ? ` ${form.requirement_annotation}` : '' : ''}`, fontSize: 8, style: '', colSpan: 3, alignment: 'left', bold: true }, '',
             ''],
            
            // [{ text: 'ОТМЕТКИ О СВЕРКЕ ДАННЫХ', style: '', colSpan: 4, alignment: 'center', bold: true }, '', '', ''],
            // [{ text: 'Дата сверки', style: '', colSpan: 1, alignment: 'center' }, { text: `С кем или чем проводилась сверка (наименование органа, уполномоченного проводить сверки, или документа)`, style: '', colSpan: 2, alignment: 'center' }, '', { text: 'Подпись, инициалы, фамилия лица, проводившего сверку', style: '', colSpan: 1, alignment: 'center' }],
        ] as any;
        if (form.marks) {
          
             form.marks.map((item: any) => {
                
                result.push([{ text: `${item.text ? item.text : ' '}`, fontSize: 8, style: '', colSpan: 4, alignment: 'left' }, '', '', ''])
                
            })
        }
         let emptyFields = form.marks.length;
   let methEmptyFields = 6 - emptyFields;
         while(0<methEmptyFields) {
                result.push([{ text: '', fontSize: 8, style: '', colSpan: 4, alignment: 'center',margin:5}, '', '', '']);
                methEmptyFields--
        }
        result.push([{ text: 'ОТМЕТКИ О СВЕРКЕ ДАННЫХ', fontSize: 8, style: '', colSpan: 4, alignment: 'center' }, '', '', '']);
        result.push([{ text: 'Дата сверки', fontSize: 8, style: '', colSpan: 1, alignment: 'center', bold:true }, { text: `С кем или чем проводилась сверка (наименование органа, уполномоченного проводить сверки, или документа)`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',bold:true }, '', { text: 'Подпись, инициалы, фамилия лица, проводившего сверку', fontSize: 8, style: '', colSpan: 1, alignment: 'center',bold:true}]);
         
        if (form.verification) {
            form.verification.map((item: any) => {
                result.push([{ text: `${item.time ? item.time : ' '}`, fontSize: 8, style: '', colSpan: 1, alignment: 'center' }, { text: `${item.checking ? item.checking : ' '}`, fontSize: 8, style: '', colSpan: 2, alignment: 'left' }, '', { text: `${item.signature ? item.signature : ' '}`, fontSize: 8, style: '', colSpan: 1, alignment: 'left' }])
            })
        }
         let  formLengthNumder = form.verification.length;
         let mahtFormLenghtNumber  = 33 - formLengthNumder*3;
         while (0<mahtFormLenghtNumber){
            result.push([{ text:'', style: '', fontSize: 8, colSpan: 1, alignment: 'center' }, { text: '', fontSize: 8, style: '', colSpan: 2, alignment: 'left' }, '', { text:  ' ', fontSize: 8, style: '', colSpan: 1, alignment: 'left' }])
            mahtFormLenghtNumber--
         }
        
    
         return result;
    }

        const card = {
        styles: {
            header: {
                fontSize: 15,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            emptyCell: {

            },
            uderline: {
                decoration: `underline`
            },
            box: {
                padding: [5, 5, 5, 5],
                decoration: `underline`,

            },
            tableExample: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black',
                margin:[0,-15,4,0], colSpan: 2
            },
            tableExampleRight: {
                margin: [160, 5, 0, 15]
            },
            tableHeaderRight: {
                bold: true,
                fontSize: 12,
                color: 'black',
            },

        },
        defaultStyle: {
            // alignment: 'justify'
            fontSize: 9,

        },
        content: [

            // {
            //     canvas: [
            //         { type: 'line', x1: 10, y1: 15, x2: 50, y2:15, lineWidth: 1 }, //Up line
            //        { type: 'line', x1: 10, y1: 60, x2: 50, y2: 60, lineWidth: 1 }, //Bottom line
            //         // { type: 'line', x1: 10, y1: 10, x2: 10, y2: 30, lineWidth: 1 }, //Left line
            //         // { type: 'line', x1: 30, y1: 10, x2: 30, y2: 30, lineWidth: 1 }, //Rigth line
            //
            //     ]
            // },
            {
                columns: [
                    {
                        style: 'tableExample',
                        color: '#444',
                        table: {
                            widths: [35, 'auto', 'auto'],
                            headerRows: 1,
                            body: [
                                [
                                    {
                                        text: `\u200B\t\t${form.full_name ? form.full_name.split(' ')[0][0] : ' '}\u200B\t\t`,style: 'tableHeader', colSpan: 1, alignment: 'center'
                                    }
                                ],
                            ]
                        },
                        fontSize: 11,
                    },
                        {
                            text: '\n(Первая буква фамилии)',
                            fontSize: 8,
                            margin:[-103,20,0,0]
                        },
                    {
                        text: 'ЛИЧНАЯ КАРТОЧКА',
                        // margin: [200, 10, 0, 0],
                        fontSize: 10,
                        alignment: 'center',
                        bold: true
                    },
                    {
                        // text: [
                        //     {
                        //         text: `   ${accountg}   `,
                        //         bold: true,
                        //         style: 'box',
                        //     },margin: [0, 0, 0, 200],
                        //     {margin: [0, 0, 0, 200],
                        //         marmargin: [0, 0, 0, 200],gin: [0, 5, 0, 5],
                        //         text: '\n(Группа учёта \n(О, ОГБ, ПСС, П))',
                        //         fontSize: 8,
                        //     }
                        // ],
                        // alignment: 'right',
                        // fontSize: 12,
                        style: 'tableExampleRight',
                        color: '#444',
                        table: {
                            widths: [35, 'auto', 'auto'],
                            headerRows: 1,
                            body: [
                                [
                                    {
                                        text: `${accountg}`,style: 'tableHeaderRight', colSpan: 1, alignment: 'center'
                                    }
                                ],
                            ]
                        },
                        fontSize: 12,
                    },
                        {
                            margin: [42, 20, 0, 0],
                            text: '\n(Группа учёта \n(О, ОГБ, ПСС, П))',
                            fontSize: 8,
                        }
                ],
                margin: [0, 0, 0, 10],
            },
            {

                table: {
                    widths: [80, 150, '*', '*'],
                    fontSize: 6,
                    body: [
                        [{ text: 'ПЕРСОНАЛЬНЫЕ ДАННЫЕ', fontSize: 8, style: '', bold: true, colSpan: 4, alignment: 'center' }, '', '', ''],
                        [{ text: 'Фамилия', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1}, '', { text: `${form.full_name && form.full_name !=='undefined undefined undefined' ? form.full_name.split(' ')[0] : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',margin:1}, ''],
                        [{ text: 'Собственное имя', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1 }, '', { text: `${ form.full_name && form.full_name !=='undefined undefined undefined' ? form.full_name.split(' ')[1] : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',margin:1 }, ''],
                        [{ text: 'Отчество (если такое имеется)', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1}, '', { text: `${form.full_name && form.full_name !=='undefined undefined undefined' ? form.full_name.split(' ')[2] : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',margin:1}, ''],
                        [{ text: 'Дата рождения', fontSize: 8, style:'bold', colSpan: 2, alignment: 'left' ,bold:true,marginTop:1,marginBottom:1}, '', { text: `${form.date_of_birth ? formatDate(form.date_of_birth) : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',margin:1}, ''],
                        [{ text: 'Место рождения', fontSize: 8, style: '', colSpan: 2, alignment: 'left' ,bold:true,marginTop:1,marginBottom:1}, '', { text: `${form.birthplace ? form.birthplace : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',margin:1}, ''],
                        [{ text: 'Идентификационный номер', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1}, '', { text: `${form.pass_number ? form.pass_number : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',margin:1 }, ''],
                        [{ text: 'Место жительства', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1 }, '', { text: `${form.registered_address ? form.registered_address : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',margin:1 }, ''],
                        [{ text: 'Место пребывания', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1}, '', { text: `${form.address ? form.address : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',margin:1,}, ''],                 
                        [{ text: 'Образование', fontSize: 8, style: '', colSpan: 1, rowSpan: 4, alignment: 'center', bold: true,marginTop:35 }, { text: 'уровень основного образования', fontSize: 8, style: '', colSpan: 1, alignment: 'center',bold:true,margin:1 }, { text: `${form.education ? form.education : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center' }, ''],
                        ['', { text: 'учреждения образования и годы их окончания ', fontSize: 8, style: '', colSpan: 1, alignment: 'center',bold:true,marginTop:1 }, { text: 'специальность (профессия)', fontSize: 8, style: '', colSpan: 1, alignment: 'center',bold:true,margin:1 }, { text: 'присвоенная квалификация', fontSize: 8, style: '', colSpan: 1, alignment: 'center',bold:true,margin:1}],
                        ['', { text: `${form.education_full ? form.education_full : ' '}`, fontSize: 8, style: '', colSpan: 1, alignment: 'center',margin:1}, { text: `${form.edu_specialization ? form.edu_specialization : ''}`, fontSize: 8, style: '', colSpan: 1, alignment: 'center' }, { text: `${form.edu_qualification ? form.edu_qualification : ''}`, fontSize: 8, style: '', colSpan: 1, alignment: 'center', margin:1 }],
                        ['', { text: `${form.edu_dop_full ? form.edu_dop_full : ' '}`, fontSize: 8, style: '', colSpan: 1, alignment: 'center',margin:1}, { text: `${form.edu_dop_specialization ? form.edu_dop_specialization : ''}`, fontSize: 8, style: '', colSpan: 1, alignment: 'center' }, { text: `${form.edu_dop_qualification ? form.edu_dop_qualification : ''}`, fontSize: 8, style: '', colSpan: 1, alignment: 'center' }],
                        [{ text: 'Семья', fontSize: 8, style: '', colSpan: 1, rowSpan: 4, alignment: 'center', bold: true,marginTop:55 }, { text: 'семейное положение', fontSize: 8, style: '', colSpan: 1, alignment: 'left', bold:true,marginTop:1,marginBottom:1}, { text: `${form.marital_status ? form.marital_status : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',marginTop:1,marginBottom:1}, ''],
                        ['', { text: 'супруга (супруг)', fontSize: 8, style: '', colSpan: 1, alignment: 'left', bold:true,marginTop:1,marginBottom:5}, { text: `${form.family_half ? form.family_half : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',marginTop:1,marginBottom:1}, ''],
                        ['', { text: 'дети/родители, если гражданин холост (не замужем) и не имеет детей', fontSize: 8, style: '', colSpan: 1, alignment: 'left', bold: true,marginTop:1,marginBottom:1 }, { text: `${form.family ? form.family : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold:false,marginTop:1,marginBottom:1}, ''],
                        ['', { text: 'место жительства близких родственников, которые не проживают совместно с гражданином', fontSize: 8, style: '', colSpan: 1, alignment: 'left', bold:true,marginTop:1,marginBottom:1 }, { text: `${form.family_home ? form.family_home : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold:false,marginTop:1,marginBottom:1 }, ''],
                        [{ text: 'Работа (учёба)', fontSize: 8, style: '', colSpan: 1,rowSpan:2, alignment: 'center', bold: true,marginTop:15,marginBottom:15 }, { text: 'структурное подразделение', fontSize: 8, style: '', colSpan: 1, alignment: 'left',bold:true,marginTop:5,marginBottom:5 }, { text: `${form.dsivision ? form.dsivision : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold:false,marginTop:5,marginBottom:5}, ''],
                        ['', { text: 'должность (профессия)', fontSize: 8, style: '', colSpan: 1, alignment: 'left',bold:true,marginTop:1,marginBottom:1 }, { text: `${form.position ? form.position : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold: false,marginTop:1,marginBottom:1 }, ''],
                        [{ text: 'Номера телефонов', fontSize: 8, style: '', colSpan: 1, rowSpan: 3, alignment: 'center',bold:true,marginTop:25 }, { text: 'рабочий', fontSize: 8, style: '', colSpan: 1, alignment: 'left',bold:true,marginTop:5,marginBottom:5}, { text: `${form.work_phone ? form.work_phone : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold: false,marginTop:5,marginBottom:5 }, ''],
                        ['', { text: 'домашний', fontSize: 8, style: '', colSpan: 1, alignment: 'left',bold:true,marginTop:1,marginBottom:1}, { text: `${form.home_phone ? form.home_phone : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold:false,marginTop:1,marginBottom:1 }, ''],
                        ['', { text: 'мобильный', fontSize: 8, style: '', colSpan: 1, alignment: 'left',bold: true,marginTop:1,marginBottom:1}, { text: `${form.phone ? form.phone : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold: false,marginTop:1,marginBottom:1 }, ''],
                        
                        [{ text: 'ПЕРВИЧНЫЕ ДАННЫЕ ВОИНСКОГО УЧЕТА', fontSize: 8, style: '', colSpan: 4, alignment: 'center', bold: true }, '', '', ''],
                        [{ text: 'Группа учёта', fontSize: 8, colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1 }, '', { text: `${form.accountg ? form.accountg : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold:false,marginTop:1,marginBottom:1}, ''],
                        [{ text: 'Воинское звание', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1}, '', { text: `${form.grade ? form.grade : ''} ${form.grade_full ? `\n${form.grade_full}` : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold:false,marginTop:1,marginBottom:1}, ''],
                        [{ text: 'Разряд запаса', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1 }, '', { text: `${form.reserve_level ? form.reserve_level : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold:false,marginTop:1,marginBottom:1 }, ''],
                        [{ text: 'Номер военно-учётной специальности и код воинской должности', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1 }, '', { text: `${form.wyc ? form.wyc : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',bold:false,marginTop:1,marginBottom:1 }, ''],
                        [{ text: 'Профиль', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1 }, '', { text: `${form.profile ? form.profile : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold:false,marginTop:1,marginBottom:1 }, ''],
                        [{ text: 'Категория запаса', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1 }, '', { text: `${form.structure ? form.structure : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold:false,marginTop:1,marginBottom:1}, ''],
                        [{ text: 'Дата приёма гражданина на воинский учет в организации', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1}, '', { text: `${form.add_military ? formatDate(form.add_military) : ''}  `, fontSize: 8, style: '', colSpan: 2, alignment: 'center' }, ''],
                        [{ text: 'Дата и основание снятия (исключения) гражданина с воинского учёта', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1 }, '',
                            { text: `${form.basic_military ? `${form.basic_military}` : ''}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center', bold:false }],
                        [{ text: 'Состоит на специальном воинском учете', fontSize: 8, style: '', colSpan: 2, alignment: 'left',bold:true,marginTop:1,marginBottom:1}, '',
                            { text: `${form.special===true ? `да \n${form.specialN ? form.specialN : ''}` : 'нет'}`, fontSize: 8, style: '', colSpan: 2, alignment: 'center',bold:false,marginTop:1,marginBottom:1 }, ''],
                        
                    ]
                }
            },
            {
                pageBreak: 'before',
                table: {
                    widths: [82, 150, '*', '*'],
                    fontSize: 6,
                    body: getDataTable(form)
                }
            },
            {
                margin: [5, 20, 0, 0],
                columns: [
                    {
                        width: 250,
                        text: `Ответственный за ведение воинского учета`,
                        //`${positionSender} \n ${company} `,
                        fontSize: 8,
                        lineHeight: 1,
                        bold:true
                    },
                    {
                        width: 300,
                        text: ` _____________________  ${nameResponsible}\n (подпись)`,
                        fontSize: 8,
                        lineHeight: 1,
                        bold:true
                    },
                ]
            }
        ]
    }
    pdfMake.createPdf(card as any).download('Личная_карточка.pdf');

};
export default PersonalCard_v2PDF;
