import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    TableCell,
    AlignmentType,
    HeadingLevel,
    Table,
    WidthType,
    TableRow, BorderStyle, VerticalAlign
} from "docx";
import {saveAs} from "file-saver";

const formatDate = (date: string) => {
    const d = new Date(date);

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear() + ' г.';

    return result;
}

const FunctionalResponsibilitiesDOCX = ({
                                            date, name1, position1, name2, position2, company, name2_, position2_,
                                            dateInText, limitation, workerName, workerPos
                                        }: any) => {

    const doc = new Document(
        {
            title: "Функциональные обязанности",
            styles: {
                paragraphStyles: [
                    {
                        id: "Heading1",
                        name: "Heading 1",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 26,
                            bold: true,
                        },
                        paragraph: {
                            spacing: {
                                after: 0,
                            },
                        },
                    },
                    {
                        id: "listParagraph",
                        name: "ListParagraph",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 21,
                            font: "Times New Roman",
                        },
                        paragraph: {
                            indent: {
                                firstLine: 700,
                            },
                            spacing: {
                                // before: 240,
                                // after: 120,
                            },
                            alignment: AlignmentType.JUSTIFIED,
                        },
                    },
                    {
                        id: "header",
                        name: "header",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 22,
                            font: "Times New Roman",
                        },
                        paragraph: {
                            indent: {
                                left: 5500,
                            },
                            spacing: {
                                //   before: -240,
                                // after: 120,
                            },
                            alignment: AlignmentType.LEFT,
                        },
                    },
                    {
                        id: "box1",
                        name: "box1",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 26,
                            bold: true
                        },
                        paragraph: {
                            indent: {
                                //left: 5500,
                            },

                            alignment: AlignmentType.LEFT,
                        },
                    },
                    {
                        id: "title",
                        name: "title",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 27,
                            //bold: true
                        },
                        paragraph: {
                            indent: {
                                //left: 5500,
                            },

                            alignment: AlignmentType.CENTER,
                        },
                    },
                    {
                        id: "footer",
                        name: "footer",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 22,
                        },
                        paragraph: {
                            indent: {
                                left: 0,
                            },
                        },

                    },
                    {
                        id: "footer_",
                        name: "footer_",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 24,
                            bold: true,
                            font: "Times New Roman",
                        },
                        paragraph: {
                            indent: {
                                left: 0,
                            },
                        },
                    },
                ],
            },
        }
    );

    const table = new Table({

        columnWidths: [2500,3000,3500],
        margins: {
            bottom: 0,
            top: 50,
            left: 0,
            right: 0
        },

        rows: [

            new TableRow({

                children: [
                    new TableCell({
                        borders: {
                            top: {style: BorderStyle.NONE, size: 0, color: "white"},
                            bottom: {style: BorderStyle.NONE, size: 0, color: "white"},
                            left: {style: BorderStyle.NONE, size: 0, color: "white"},
                            right: {style: BorderStyle.NONE, size: 0, color: "white"},
                        },
                        width: {
                            size: 3000,
                            type: WidthType.DXA,
                        },
                        children: [new Paragraph({
                            style: 'footer',
                            children:[
                                new TextRun({
                                    text: `${workerPos ? `${workerPos}` : ''}`,
                                    bold: true,
                                    font: "Times New Roman",
                                }),
                            ]
                        })],
                    }),
                    new TableCell({
                        borders: {
                            top: {style: BorderStyle.NONE, size: 0, color: "white"},
                            bottom: {style: BorderStyle.NONE, size: 0, color: "white"},
                            left: {style: BorderStyle.NONE, size: 0, color: "white"},
                            right: {style: BorderStyle.NONE, size: 0, color: "white"},
                        },
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        children: [new Paragraph({
                            style: 'footer',
                            children:[
                                new TextRun({
                                    text: '    ___________________',
                                    bold: true,
                                    font: "Times New Roman",
                                }),
                            ]
                        })],
                    }),
                    new TableCell({
                        borders: {
                            top: {style: BorderStyle.NONE, size: 0, color: "white"},
                            bottom: {style: BorderStyle.NONE, size: 0, color: "white"},
                            left: {style: BorderStyle.NONE, size: 0, color: "white"},
                            right: {style: BorderStyle.NONE, size: 0, color: "white"},
                        },
                        width: {
                            size: 3500,
                            type: WidthType.DXA,
                        },
                        children: [new Paragraph({
                            style: 'footer',
                            children:[
                                new TextRun({
                                    text: `${workerName === '' ? '' : workerName}`,
                                    bold: true,
                                    font: "Times New Roman",
                                }),
                            ]
                        })],
                    }),
                ],
            }),
        ],
    });

    const table_1 = new Table({

        columnWidths: [2500,3000,3500],
        margins: {
            bottom: 0,
            top: 0,
            left: 0,
            right: 0
        },

        rows: [

            new TableRow({
                children: [
                    new TableCell({
                        borders: {
                            top: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                            bottom: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                            left: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                            right: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                        },
                        width: {
                            size: 3000,
                            type: WidthType.DXA,
                        },
                        children: [new Paragraph({
                            style: 'footer',
                            children:[
                                        new TextRun({
                                            text: `${position2 === '' ? position2_ : position2}`,
                                            bold: true,
                                            font: "Times New Roman",
                                        }),
                            ]
                        })],
                    }),
                    new TableCell({
                        borders: {
                            top: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                            bottom: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                            left: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                            right: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                        },
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        children: [new Paragraph({
                            style: 'footer',
                            children:[
                                new TextRun({
                                    text: '    ___________________',
                                    bold: true,
                                    font: "Times New Roman",
                                }),
                            ]
                        })],
                    }),
                    new TableCell({
                        borders: {
                            top: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                            bottom: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                            left: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                            right: {style: BorderStyle.NONE, size: 0, color: "FFFFFF"},
                        },
                        width: {
                            size: 3500,
                            type: WidthType.DXA,
                        },
                        children: [new Paragraph({
                            style: 'footer',
                            children:[
                                new TextRun({
                                    text: `${name2 === '' ? name2_ : name2}`,
                                    bold: true,
                                    font: "Times New Roman",
                                }),
                            ]
                        })],
                    }),
                ],
            }),
        ],
    });


    // @ts-ignore
    doc.addSection({
        properties: {},
        children: [
            new Paragraph({
                style: "header",
                children: [
                    new TextRun({
                        text: "Утверждаю",
                        bold: true,
                        font: "Times New Roman",
                        size: 22
                    }),
                ],
            }),
            new Paragraph({
                style: "header",
                text: ``,
            }),
            new Paragraph({
                style: "header",
                children: [
                    new TextRun({
                        text: "__________________________",
                        bold: true,
                        size: 22,
                        font: "Times New Roman",
                    }),
                ],
            }),
            new Paragraph({
                style: "header",
                text: ``,
            }),
            new Paragraph({
                style: "header",
                children: [
                    new TextRun({
                        text: `${name1}`,
                        bold: true,
                        size: 22,
                        font: "Times New Roman",
                    }),
                ],
            }),
            new Paragraph({
                style: "header",
                children: [
                    new TextRun({
                        text: `${position1}`,
                        bold: true,
                        size: 22,
                        font: "Times New Roman",
                    }),
                ],
            }),
            new Paragraph({
                style: "header",
                children: [
                    new TextRun({
                        text: `${company}`,
                        bold: true,
                        size: 22,
                        font: "Times New Roman",
                    }),
                ],
            }),
            new Paragraph({
                style: "header",
                text: `${formatDate(date)}`,
            }),
            new Paragraph({
                style: "header",
                text: ``,
            }),
            new Paragraph({
                style: "header",
                text: ``,
            }),
            new Paragraph({
                style: "header",
                text: ``,
            }),
            new Paragraph({
                style: "box1",
                children: [
                    new TextRun({
                        text: `Функциональные обязанности`,
                        bold: true,
                        size: 22,
                        font: "Times New Roman",
                    }),
                ],
            }),
            new Paragraph({
                style: "box1",
                children: [
                    new TextRun({
                        text: `ответственного за ведение воинского учёта `,
                        bold: true,
                        size: 22,
                        font: "Times New Roman",
                    }),
                ],
            }),
            new Paragraph({
                style: "box1",
                children: [
                    new TextRun({
                        text: `военнообязанных.`,
                        bold: true,
                        size: 22,
                        font: "Times New Roman",
                    }),
                ],
            }),
            new Paragraph({
                style: "box1",
                children: [
                    new TextRun({
                        text: ``,
                        font: "Times New Roman",
                    }),
                ],
            }),

            new Paragraph({
                style: 'title',
                children: [
                    new TextRun({
                        text: "Ответственный за ведение воинского учёта обязан:",
                        bold: true,
                        size: 22,
                        font: "Times New Roman",
                    }),
                ],
            }),
            new Paragraph({
                style: 'box1',
                children: [
                    new TextRun({
                        text: "",
                        bold: true,
                        font: "Times New Roman",
                    }),
                ],
            }),
            new Paragraph({
                style: "listParagraph",
                text: "проверять при приеме на работу (учебу) у призывников удостоверения призывников, а у военнообязанных - военные билеты;",
            }),
            new Paragraph(
                {
                    style: "listParagraph",
                    text: "установить, состоят ли граждане, принимаемые на работу (учебу), на воинском учете по месту регистрации;",
                }),
            new Paragraph(
                {
                    style: "listParagraph",
                    text: "направлять граждан, подлежащих приему на воинский учет, в соответствующий орган, ведущий воинский учет по месту  регистрации;",
                }),
            new Paragraph({
                style: "listParagraph",
                text: "обеспечить полноту и качество воинского учета призывников и военнообязанных из числа работающих (обучающихся) в организации;",
            }),
            new Paragraph({
                style: "listParagraph",
                text: "осуществлять учет изменений, касающихся должности (специальности), образования, состава семьи, семейного положения и места прописки  граждан, и сообщать о них в военный комиссариат;",
            }),
            new Paragraph({
                style: "listParagraph",
                text: "ежегодно сверять личные карточки призывников и военнообязанных с записями в удостоверениях призывников и военных билетах, представлять эти карточки для сверки с учетными данными военного комиссариата;",
            }),
            new Paragraph({
                style: "listParagraph",
                text: `направлять в месячный срок в местные исполнительные и распорядительные органы, военные комиссариаты и организации сведения о гражданах, становящихся на воинский учет, состоящих на воинском учете, а также не состоящих, но обязанных состоять на воинском учете;`,
            }),
            new Paragraph({
                style: "listParagraph",
                text: `ежегодно до ${dateInText} представлять в военные комиссариаты списки граждан мужского пола, подлежащих приписке к призывным участкам в следующем году;`,
            }),
            new Paragraph({
                style: "listParagraph",
                text: "по требованию военного комиссариата оповещать граждан о вызовах в военный комиссариат;",
            }),
            new Paragraph({
                style: "listParagraph",
                text: "обеспечивать гражданам возможность своевременной явки по вызовам (повесткам) в военный комиссариат;",
            }),
            new Paragraph({
                style: "listParagraph",
                text: `сообщать в ${limitation} в соответствующий военный комиссариат о всех призывниках и военнообязанных, принятых на работу (учебу) или уволенных с работы (отчисленных из учреждений образования);`,
            }),
            new Paragraph({
                style: "listParagraph",
                text: "направлять ежегодно в военный комиссариат сведения о численности работников организации (призывников и военнообязанных).",
            }),

            new Paragraph({
                style: "listParagraph",
                text: "",
            }),

            new Paragraph({
                style: "listParagraph",
                text: "",
            }),
            new Paragraph({
                style: "listParagraph",
                text: "",
            }),


            table,


            // new Paragraph({
            //     style: 'footer',
            //     children: [
            //         new TextRun({
            //             text: `${workerPos ? `${workerPos}` : '                                        '}`,
            //             bold: true,
            //             font: "Times New Roman",
            //         }),
            //         new TextRun({
            //             text: "______________________",
            //             bold: true,
            //             font: "Times New Roman",
            //         }),
            //         new TextRun({
            //             text: `     ${workerName}`,
            //             bold: true,
            //             font: "Times New Roman",
            //         }),
            //     ],
            // }),
            new Paragraph({
                style: "listParagraph",
                text: "",
            }),
            new Paragraph({
                style: "listParagraph",
                text: "",
            }),
            // new Paragraph({
            //     style: "listParagraph",
            //     text: "",
            // }),
            new Paragraph({
                style: "footer_",
                text: "Ознакомлен:"
            }),
            new Paragraph({
                style: "listParagraph",
                text: "",
            }),
            // new Paragraph({
            //     style: "listParagraph",
            //     text: "",
            // }),
            // new Paragraph({
            //     style: 'footer',
            //     children: [
            //         new TextRun({
            //             text: `${position2 === '' ? position2_ : position2}`,
            //             bold: true,
            //             font: "Times New Roman",
            //         }),
            //         new TextRun({
            //                 text: "        ______________________",
            //                 bold: true,
            //                 font: "Times New Roman",
            //             },
            //         ),
            //         new TextRun({
            //             text: `     ${name2 === '' ? name2_ : name2}`,
            //             bold: true,
            //             font: "Times New Roman",
            //         }),
            //
            //     ],
            // }),
table_1
        ],
    });

    Packer.toBlob(doc).then((blob) => {
        //fs.writeFileSync("Document.docx", buffer);
        saveAs(blob, "Функциональные_обязанности.docx");
    });
}

export default FunctionalResponsibilitiesDOCX;
