//@ts-nocheck
import React, {useState, useEffect, CSSProperties, useCallback} from 'react';
import {NavLink} from 'react-router-dom';
import Axios from '../../utils/axios';
import SuccessfulSaveModal from '../../components/PersonalCard/SuccessfulSaveModal';
import './LandingPage.css';
import why_us from '../LandingPage/assets/images/why_us.png';
import our_work_mr from '../LandingPage/assets/images/our_work_mr.svg';
import right_vector from '../LandingPage/assets/images/right_vector.svg';
import left_vector from '../LandingPage/assets/images/left_vector.svg';
import {NavHashLink} from 'react-router-hash-link';
import work from '../LandingPage/assets/images/work.svg';
import flag from '../LandingPage/assets/images/flag.svg';
import vector_note from '../LandingPage/assets/images/vector_note.svg';
import star from '../LandingPage/assets/images/star.svg';
import Input from 'react-phone-number-input/input';
import Footer from "./FooterLandingPage";
import vector_note_open from '../LandingPage/assets/images/vector_note_open.svg';
import polygon_wrapper from '../LandingPage/assets/images/polygon_wrapper.png';
import polygon_wrapper_right from '../LandingPage/assets/images/polygon_wrapper_right.png';
import MainModeText from "./MainModeText";
import ExtendedModeText from "./ExtendedModeText";
import {getDataAddressAC} from "../../redux/reducers/addressesReducer";
import {RootState} from "../../redux/store/store";
import {useDispatch, useSelector} from "react-redux";
import mask from '../LandingPage/assets/images/mask.svg';
import erip from './assets/images/erip.png';
import web_pay from './assets/images/web_pay.png';
import loupe from './assets/images/why_us_list/loupe.png';
import photo from './assets/images/why_us_list/photo.png';
import hands from './assets/images/why_us_list/hands.png';
import archive from './assets/images/why_us_list/archive.png';
import money from './assets/images/why_us_list/money.png';
import card from './assets/images/why_us_list/card.png';
import settings from './assets/images/why_us_list/settings.png';
import question_ from './assets/images/why_us_list/question.png';

import one_c from './assets/images/po/1c.png';
import sap from './assets/images/po/sap.png';
import excel from './assets/images/po/excel.png';
import google from './assets/images/po/google-docs.png';
import another from './assets/images/po/another.png';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import close_btn from './assets/images/close_btn.png';
import { useFetchLandingResData} from "../../utils/customHooks/useFetchLanding";

import {WarningModal} from "../../utils/Modals/WarningModal";
import Modal from 'react-bootstrap/Modal'
import {DocsExample} from "./DocsExample";



const BodyLandingPage = () => {

    // const [listMilitaryRegistration, setMilitaryRegistration] = useState([
    //     'возложено на юридические лица и ИП, в штат которых на основное место работы приняты физические лица призывного возраста и военнообязанные гражданe',
    //     'включает порядка 20 видов документов',
    //     'включает работу с запросами военкоматов',
    //     'требует специально подготовленного штатного сотрудника',
    //     'за нарушение положений закона может повлечь многократное наложение штрафов'
    // ])
    const listMilitaryRegistration = [
        {img: loupe, title: 'Контролируемое управление воинским учетом'},
        {img: photo, title: 'Своевременную реакцию на изменения нормативных документов'},
        {img: hands, title: 'Перенос ответственности на профессионального специалиста'},
        {img: archive, title: 'Информационная связь с используемыми программными средствами'},
    ]
    const listMilitaryRegistration_ = [
        {img: money, title: 'Минимизацию финансовых издержек'},
        {img: card, title: 'Прозрачные взаимоотношения с контрольными органами'},
        {img: settings, title: 'Помощь специалистов по вопросам учета'},
        {img: question_, title: 'Справочник ответов по повторяющимся вопросам'},
    ]

    const [isOpenDetails, setOpenDetails] = useState(false)
    const [isOpenDetailsMore, setOpenDetailsMore] = useState(false)

    const isOpeningDetails = () => {
        setOpenDetails(true);
        setOpenDetailsMore(false)
    };

    const isCloseDetails = () => {
        setOpenDetails(false);
    };
    const isOpeningDetailsMore = () => {
        setOpenDetailsMore(true);
        setOpenDetails(false);
    };
    const isCloseDetailsMore = () => {
        setOpenDetailsMore(false)
    };

    const [phone, setPhone] = useState('');
    const [question, setQuestion] = useState('');
    const [name, setName] = useState('');

    const toSaveName = (e: any) => {
        setName(e.target.value)
    }

    const toSaveQuestion = (e: any) => {
        setQuestion(e.target.value)
    }

    const [message, setMessage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [warningMessage,setWarningMessage]=useState('')
    const [openWarningModal,setOpenWarningModal]=useState(false)

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    interface AddressData {
        addressData: any[]
    };

    const getAddressData = (state): AddressData => state.addressesReducer.addressData
    const addressData = useSelector<RootState>(getAddressData);
    const dispatch = useDispatch();

    useEffect(() => {
        const axios = new Axios();
        axios.get(`control/page/6/`)
            .then(res => {
                dispatch(getDataAddressAC(res.data.page))
            })
    }, []);

    const sendDataContacts = () => {

        const sendSuccess=()=>{
            const data = {
                phone: phone,
                question: question,
                name: name
            }
            const axios = new Axios();
            axios.post(`user/send/`, data)
                .then(res => {
                    setMessage('Менеджер свяжется с Вами в ближайшее время');
                    handleOpenModal();
                    setTimeout(handleCloseModal, 4000);
                })
                .catch(err => {
                    setMessage('Произошла ошибка. Пожалуйста, попробуйте ещё раз.');
                    handleOpenModal();
                    setTimeout(handleCloseModal, 4000);
                })
        }

        const warningFunc=(message:string)=>{
            setOpenWarningModal(true);
            setTimeout(()=>setOpenWarningModal(false),2000)
            setWarningMessage(message)
        }
        return phone==='+375' ? warningFunc('Не заполенено поле "Номер телефона"') :
            phone.length!==13 ? warningFunc('Неверно заполенено поле "Номер телефона"') :
                name==='' ? warningFunc('Не заполенено поле "Имя"') :
                    question==='' ? warningFunc('Не заполенено поле "Вопрос"') :
                        sendSuccess()
    }

    const classForLandingBody = isOpenDetails ? 'body_landing_wrapper_mobile' : 'body_landing_wrapper'

    return (
        <>  
            <div className="landing_main__welcome"/>
            <div className={classForLandingBody} id={'faq'}>
                <DocsExample/>
                <MilitaryRegistration listMilitaryRegistration={listMilitaryRegistration}
                                      listMilitaryRegistration_={listMilitaryRegistration_}/>
                <OurWork isOpeningDetails={isOpeningDetails} isOpenDetails={isOpenDetails}
                         isCloseDetails={isCloseDetails} isOpeningDetailsMore={isOpeningDetailsMore}
                         isCloseDetailsMore={isCloseDetailsMore} isOpenDetailsMore={isOpenDetailsMore}/>
            </div>
            <Cost isOpenDetails={isOpenDetails} isOpenDetailsMore={isOpenDetailsMore}/>
            <PriceCalculator/>
            <div className='landing_wrapper_footer' id='call_me'>
                <ContactsComponent name={name} question={question} phone={phone} toSaveName={toSaveName}
                                   setPhone={setPhone} toSaveQuestion={toSaveQuestion} sendDataContacts={sendDataContacts}
                                   addressData={addressData}/>
            </div>
            <div className='footer_desktop'>
                <Footer/>
            </div>

            <SuccessfulSaveModal
                open={openModal}
                handleClose={handleCloseModal}
                message={message}
            />
            <WarningModal message={warningMessage} open={openWarningModal}
                          handleClose={()=>{setOpenWarningModal(false)}}/>
                         
        </>
    )
}

const MilitaryRegistration = (props) => {

    return (
        <div className='wrapper_military_registration'>
            <img alt='' src={why_us} className='what_is_mr'/>
            <div className='what_is_mr_mobile'>
                <span>ПОЧЕМУ МЫ? </span>
            </div>
            <div style={{border: '1px solid white'}}>
                <div className='title_law_military_registration'>
                    Сервис «Воинский учет» обеспечит:
                </div>
                <div className={'wrapper-why-us-comp'}>
                    <div className={'wrapper-why-us-comp-item'} style={{marginRight: '75px'}}>
                        {props.listMilitaryRegistration.map((item: any, index: number) => {
                            return <div key={index}>
                                <img src={item.img} alt={''} style={{marginRight: '20px'}}/>
                                <span>{item.title}</span>
                            </div>
                        })}
                    </div>
                    <div className={'wrapper-why-us-comp-item'}>
                        {props.listMilitaryRegistration_.map((item: any, index: number) => {
                            return <div key={index}>
                                <img src={item.img} alt={''} style={{marginRight: '20px'}}/>
                                <span>{item.title}</span>
                            </div>
                        })}
                    </div>
                </div>
                {/*<ul className='list_mr'>*/}
                {/*    {props.listMilitaryRegistration.map((item, index) => {*/}
                {/*        return <li key={index}>*/}
                {/*            <div className='list_mr_1'><img src={arrow_mr}/> <span>{item}</span></div>*/}
                {/*        </li>*/}
                {/*    })}*/}
                {/*</ul>*/}
                {/*<div className='footer_military_registration'>Сервис “Воинский учет” не просто упрощает ведение ВУ, но и*/}
                {/*    помогает значительно снизить затраты на его сопровождение.*/}
                {/*</div>*/}
            </div>
        </div>
    )
};

const OurWork = (props: {
    isOpeningDetails: any, isOpenDetails: boolean,
    isCloseDetails: any, isOpeningDetailsMore: any, isCloseDetailsMore: any,
    isOpenDetailsMore: boolean
}) => {

    const classForModePoint_2 = props.isOpenDetails ? 'title_mode_point_22' : 'title_mode_point_2';
    return (
        <div className={'wrapper_our_work'} id={'our_work'}>
            <div className='wrap_work'><img alt='' src={our_work_mr} className='our_work_mr_img'/></div>
            <div className='wrap_work_mobile'><img alt='' src={work} className='our_work_mr_img'/></div>
            <div className='title_our_work_mr'>Варианты использования сервиса “Воинский учет”:</div>
            <div className='vectors'>
                <img alt='' src={left_vector} className='left_vector'/>
                <img alt='' src={right_vector} className='right_vector'/>
            </div>
            <div className='wrapper_work_description'>
                <div className='title_mode'>
                    <div className='title_mode_point_1'>
                        <img alt='' src={flag}/>
                        <div>Основной режим</div>
                    </div>
                    <div className={'title_mode_point_2'}>
                        <img alt='' src={flag}/>
                        <div>Расширенный режим</div>
                    </div>
                </div>
                <div className='mode_list'>
                    <ul className='mode_list_left'>
                        <li><strong>Вы</strong> - определяете специалиста Вашей организации, который будет вести
                            воинский
                            учёт,
                            и приобретаете лицензию
                        </li>
                        <li><strong>Мы</strong> - предоставляем доступ, обучаем и консультируем по использованию нашего
                            сервиса
                        </li>
                        <li>
                            <a>Последовательность шагов при основном режиме</a>
                            {!props.isOpenDetails ?
                                <img alt='' src={vector_note} onClick={props.isOpeningDetails} className='img_note'/>
                                : <><img alt='' src={vector_note_open} onClick={props.isCloseDetails}
                                         className='img_note'/>
                                    <MainModeText/>
                                </>}
                        </li>
                    </ul>
                    <ul className='mode_list_right'>
                        <li><strong>Вы</strong> - приобретаете расширенную лицензию</li>
                        <li><strong>Мы</strong> - оказываем услуги по полному сопровождению программного продукта</li>
                        <li><a>Последовательность шагов при расширенном режиме</a>
                            {!props.isOpenDetailsMore ?
                                <img alt='' src={vector_note} onClick={props.isOpeningDetailsMore}
                                     className='img_note'/>
                                : <><img alt='' src={vector_note_open} onClick={props.isCloseDetailsMore}
                                         className='img_note'/>
                                    <ExtendedModeText/>
                                </>}
                        </li>
                    </ul>
                </div>
                <NavLink className={'mode_list_registration'} to={'/our_contacts'}
                    onClick={()=>{window.scrollTo(0,0)}}>
                        Перейти к регистрации в сервисе
                    </NavLink>
            </div>

            <div className='wrapper_work_description_mobile'>
                <div style={{width: '99%'}}>
                    <div className='title_mode_point_1'>
                        <div className='title_mode_point_1_title'><img alt='' src={flag}/>
                            <div>Основной режим</div>
                        </div>
                        <ul className='mode_list_left'>
                            <li><strong>Вы</strong> - определяете специалиста Вашей организации, который будет вести
                                воинский
                                учёт,
                                и приобретаете лицензию
                            </li>
                            <li><strong>Мы</strong> - предоставляем доступ, обучаем и консультируем по использованию
                                нашего
                                сервиса
                            </li>
                            <li>
                                <a>Последовательность шагов при основном режиме</a>
                                {!props.isOpenDetails ?
                                    <img alt='' src={vector_note} onClick={props.isOpeningDetails}
                                         className='img_note'/>
                                    : <><img alt='' src={vector_note_open} onClick={props.isCloseDetails}
                                             className='img_note'/>
                                        <div className='mainMode_mobile'><MainModeText/></div>
                                    </>}
                            </li>
                        </ul>
                    </div>
                    <div className={classForModePoint_2}>
                        <div className='title_mode_point_2_title'><img alt='' src={flag} style={{marginRight: '10px'}}/>
                            <div>Расширенный режим</div>
                        </div>
                        <ul className='mode_list_right'>
                            <li><strong>Вы</strong> - приобретаете расширенную лицензию</li>
                            <li><strong>Мы</strong> - оказываем услуги по полному сопровождению программного продукта
                            </li>
                            <li><a>Последовательность шагов при расширенном режиме</a>
                                {!props.isOpenDetailsMore ?
                                    <img alt='' src={vector_note} onClick={props.isOpeningDetailsMore}
                                         className='img_note'/>
                                    : <><img alt='' src={vector_note_open} onClick={props.isCloseDetailsMore}
                                             className='img_note'/>
                                        <div className='mainMode_mobile'><ExtendedModeText/></div>
                                    </>}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

const PriceCalculator = () => {

    const axios=new Axios()

    const poImages = [
        {name:'1C',source:one_c},
        {name:'SAP',source:sap},
        {name:'Excel',source:excel},
        {name:'Google',source:google}
    ];
    const [isOpen, setOpen] = useState(false)
    const [openOkForm, setOpenOkForm] = useState(false)
    const [countMan, setCountMan] = useState('')
    const [ownership, setOwnership] = useState(0)
    const [typeComp, setTypeComp] = useState(0)
    const [emailForCalc, setEmailForCalc] = useState('')

    const [message, setMessage] = useState('');
    const [openWarning, setOpenWarning] = useState(false);

    const [monthlyPrice, setMonthlyPrice] = useState(0);
    const [oneTimePrice, setOneTimePrice] = useState(0);
    const [oneWarPeoplePayment, setOneWarPeoplePayment] = useState(0);
    const [oneSpecPayment, setOneSpecPayment] = useState(0);
    const [payment, setPayment] = useState(0);
    const [countWarPeople, setCountWarPeople] = useState(0);
    const [total, setTotal] = useState(0);

    const [anotherPoVal, setAnotherPoVal] = useState('');


    const ownerShipType= useFetchLandingResData('library/type/company/');
    const mode= useFetchLandingResData('library/type/use_service/');

    const handleCloseWarning=()=>{
        setOpenWarning(false)
    }
    const handleOpenWarning=()=>{
        setOpenWarning(true)
    }

    const calculate=()=>{

        const sendData=()=>{
            const data={
                'type_use_id': Number(typeComp),
                'type_company_id' :Number(ownership),
                'count_war_people': Number(countMan)
            };
            axios.post('control/calculate/cost',data)
                .then(res=>{
                    setMonthlyPrice(res.data.monthly_price)
                    setOneTimePrice(res.data.one_time_price)
                    setOneWarPeoplePayment(res.data.one_war_people_payment)
                    setOneSpecPayment(res.data.our_spec_payment)
                    setPayment(res.data.payment)
                    setCountWarPeople(res.data.count_war_people)
                    setTotal(res.data.total)
                })
        };

        const sendWarning=()=>{
            handleOpenWarning()
            setTimeout(handleCloseWarning,2000)
            setMessage('Вы заполнили не все поля')
        }
        return countMan==='' || ownership===0 || typeComp===0 ? sendWarning() : sendData()
    };

    const emailCalculateSend=()=>{

        const sendData=()=>{

            const data={
                'type_use_id': Number(typeComp),
                'type_company_id' :Number(ownership),
                'count_war_people': Number(countMan),
                'email': emailForCalc
            }
            axios.post('control/calculate/cost',data)
                .then(res=>{
                    handleOpenWarning();
                    setTimeout(handleCloseWarning,3000);
                    setMessage('Результат расчетов отправлен на указанную почту');
                })
        };

        const sendWarning=()=>{
            handleOpenWarning()
            setTimeout(handleCloseWarning,2000)
            setMessage('Проверьте правильность введенного email')
        };
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(emailForCalc) ? sendData() : sendWarning()
    };

    const sendDefaultIntegrationPO=(value)=>{
        axios.post('control/vote/integration',
            {'integration_name': value})
            .then(res=> setOpenOkForm(true))
    }

const sendAnotherPO=()=>{
    axios.post('control/vote/integration',
        {'integration_name': anotherPoVal})
        .then(res=> {
            setOpen(false);
            setOpenOkForm(true);
        })
}
    return (<>
            <div className={'wrapper-calculator'}>
                <div className={'title-calc'}>Калькулятор стоимости</div>
                <div className={'pre-title-calc'}>Рассчитайте стоимость пользования сервисом для организаций с
                    численностью военнообязанных до 100 человек
                </div>
                <div className={'desc-calc'}>Если в вашей организации более 100 военнобязанных,
                    <NavHashLink to={'/#call_me'} style={{color: 'black', textDecoration: 'underline'}}> обратитесь к
                        менеджеру </NavHashLink> для получения индивидуальных условий и скидок
                </div>

                <div className={'wrapper-calculator-form'}>
                    <select onChange={(e:any)=>{setOwnership(e.target.value)}}>
                        <option>Форма собственности</option>
                        {ownerShipType.data.length !==0 &&
                        <>{ownerShipType.data.map((i:{id:number,name:string},index:number)=>{
                            return <option key={index} value={i.id}>{i.name}</option>
                        })}</>
                        }
                    </select>
                    <input placeholder={'Численность военнообязанных'} value={countMan}
                    onChange={(e:any)=>{setCountMan(e.target.value)}}/>
                    <select  onChange={(e:any)=>{setTypeComp(e.target.value)}} >
                        <option>Вариант сервиса ВУ</option>
                        {mode.data.length !==0 &&
                        <>{mode.data.map((i:{id:number,name:string},index:number)=>{
                            return <option key={index} value={i.id}>{i.name}</option>
                        })}</>
                        }
                    </select>
                    <button onClick={calculate}>рассчитать стоимость</button>
                </div>
                <WarningModal message={message} open={openWarning} handleClose={handleCloseWarning}/>
                <div className={'calculation-window'}>
                    <div className={'title-calc-window'}>Стоимость пользования сервисом «Воинский учет» для вашей
                        организации составит:
                    </div>
                    <section className={'calc-results'}>
                        <div>
                            <span>Разовый платеж за приобретение лицензии</span>
                            <span style={{fontWeight: "bold"}}>{oneTimePrice} BYN</span>
                        </div>
                        <div>
                            <span>Ежемесячный платеж за сопровождение лицензии</span>
                            <span style={{fontWeight: "bold"}}>{monthlyPrice} BYN</span>
                        </div>
                        <div>
                            <span>Ведение сервиса “Воинский учет” нашим специалистом</span>
                            <span style={{fontWeight: "bold"}}>{oneSpecPayment} + ({countWarPeople} - 10) * {oneWarPeoplePayment} = {payment} BYN</span>
                        </div>
                    </section>
                    {payment !== 0 && total !== 0 ?
                        <div className={'results-calc-title'}>
                            <b>Итого,</b> после приобретения лицензии ежемесячный платеж составит {monthlyPrice} 
                            + {payment} = <b>{total} BYN</b>
                        </div> : <></>
                    }
                    <div className={'send-form-calc'}>
                        <input placeholder={'Укажите e-mail, чтобы получить результат расчета на Вашу почту'} type="email"
                        value={emailForCalc} onChange={(e:any)=>{setEmailForCalc(e.target.value)}}/>
                        <button onClick={emailCalculateSend}>Отправить расчет</button>
                    </div>

                    <NavLink className={'go-to-registration-organization'} to={'/our_contacts'}
                    onClick={()=>{window.scrollTo(0,0)}}>
                        Перейти к регистрации в сервисе
                    </NavLink>

                </div>

                <div className={'wrapper-po'}>
                    <div className={'title-po'}>С каким ПО Вам была бы удобна интеграция сервиса «Воинский учет»?</div>
                    <div className={'pre-title-po'}>Выберите, пожалуйста, один из вариантов ответа</div>
                    <div className={'po-images'}>
                        {poImages.map((item: any, index: number) => {
                            return <img src={item.source} key={index} alt={''} name={item.name}
                                        onClick={()=>{sendDefaultIntegrationPO(item.name); setOpenOkForm(true)}}/>
                        })}
                        <img onClick={() => {
                            setOpen(true)
                        }} src={another} alt={''}/>
                    </div>
                </div>
            </div>
            <Dialog className={'dialog-po'}
                    open={isOpen}
                    onClose={() => {
                        setOpen(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={'title-dialog-po'}>
                    <img src={close_btn} alt={''} onClick={() => {
                        setOpen(false)
                    }}/>
                    Пожалуйста, укажите ПО, с которым была бы удобна интеграция сервиса «Воинский учет» для вашей
                    компании</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={'content-dialog-po'}>
                        <div><input value={anotherPoVal} onChange={(e:any)=>{setAnotherPoVal(e.target.value)}}/>
                            <button onClick={sendAnotherPO}>Отправить ответ
                            </button>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <Dialog className={'dialog-po'}
                    open={openOkForm}
                    onClose={() => {
                        setOpenOkForm(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={'title-dialog-po'}>
                    <img src={close_btn} alt={''} onClick={() => {
                        setOpenOkForm(false)
                    }}/>
                    <div className={'title-gratitude'}>Спасибо за ваш ответ!</div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={'content-dialog-po'}>
                        <div>
                            <div className={'pre-title-gratitude'}>
                                Благодарим за обратную связь с Вашей стороны по вопросам работы сервиса «Воинский учет»
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <button style={{width: '246px'}}
                                        onClick={() => {
                                            setOpenOkForm(false)
                                        }}>Ok
                                </button>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}

const Cost = (props: { isOpenDetails: boolean, isOpenDetailsMore: boolean }) => {


    const [costData,setCostData]=useState(null)

    useEffect(()=>{
        const axios=new Axios()
        axios.get('control/page/9/')
            .then(res=>{
                setCostData(res.data)
            })
    },[])

    const [paidWays, setPaidWays] = useState(false);

    const modalStyle: CSSProperties = {
        left: '50%',
        transform: 'translateX(-50%)',
        width: '230%'
    };


    let classForWrapperCost = props.isOpenDetails ? 'wrapper_cost_mg' : props.isOpenDetailsMore ? 'wrapper_cost_mg_1' : 'wrapper_cost';
    return (
        <>
            {costData !==null  && costData.page.length !==0 &&
            <div className={classForWrapperCost} id={'cost'}>
                <div className='wrapper_title_cost'>
                    <img alt='' src={star} className='cost_img'/>
                    <span className='title_cost'>{costData.name}</span>
                    <img alt='' src={star} className='cost_img'/>
                </div>
                <div className='wrapper_description_cost'>
                    <div className='wrapper_description_cost_left'>
                        <div className='wrapper_description_cost_left_title_license'>{costData.page[0].name}</div>
                        <div className='wrapper_description_cost_left_license'>
                            {/*<div>Приобритение лицензии - <strong>50 BYN</strong></div>*/}
                            {/*<div>Сопровождение лицензии - <strong>25 BYN / месяц </strong></div>*/}
                            <div>{costData.page[0].text.split('\r\n').map((i: string, index: number) => {
                                return <div key={index}> {i}</div>
                            })}</div>
                        </div>
                    </div>
                    <div className='wrapper_description_cost_right'>
                        <div className='wrapper_description_cost_left_title_license'>{costData.page[1].name}</div>
                        <div className='wrapper_description_cost_left_license'>
                            <div>{costData.page[1].text.split('\r\n').map((i: string, index: number) => {
                                return <div key={index}> {i}</div>
                            })}</div>
                        </div>
                    </div>
                </div>
                <div className='cost_footer'>
                    <div>
                        {/*В случае, если воинский учёт с использованием ПО "Сервис "Воинский учёт" " будет выполнять предложенный*/}
                        {/*нами в штат лицензиата специалист, затраты лицензиата возрастут на зарплату специалиста: дополнительно*/}
                        {/*20 BYN + 2.5 BYN за каждого военнообязанного или призывника сверх 10 человек, которая выплачивается непосредственно*/}
                        {/*лицензиатом своему штатному специалисту.*/}
                        {costData.page[2].text.split("за каждого военнообязанного в месяц.")[0] + " за каждого военнообязанного в месяц."}
                    </div>
                    <div><b>{costData.page[2].text.split("за каждого военнообязанного в месяц.")[1]}</b></div>
                </div>
                <div className={'wrapper_cost_pdf'}>
                    <button className={'paid'} onClick={() => {
                        setPaidWays(true)
                    }}>Посмотреть способы оплаты
                    </button>
                    <a className='link_cost_pdf' href='/our_contacts'
                       target='_blank'>
                        <button className={'paid_pdf'}>{costData.page[4].name}</button>
                    </a>
                    <a className='link_cost_pdf' href={costData.page[3].doc}
                       target='_blank'>
                        <button className={'paid_pdf'}> {costData.page[3].name}</button>
                    </a>
                </div>
                <Modal show={paidWays} onHide={() => {
                    setPaidWays(false)
                }} contentClassName={'contentModal'}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body style={{width: '96%', margin: '0 auto'}}>
                        <div className={'titleWays'}>Способы оплаты</div>
                        <div className={'lineWays'}/>
                        <div className={'text_way_paid'}>
                            <p>Оплатить право пользования программным обеспечением «Система «Воинский Учёт» компании ООО
                                «БелХард Программинг» можно :</p>
                            <ul>
                                <li>1. <b>Безналичным переводом</b> по реквизитам Лицензиара, указанным в п. 8.2
                                    договора публичной оферты.
                                </li>
                                <li className={'erip_text'}>
                                    <img src={erip} alt={'erip'} style={{width: '218px', height: '144px'}}/>
                                    <p style={{width: '71%'}}>2. Провести оплату <b>через систему "Расчет" (ЕРИП)</b> в
                                        любом удобном для Вас месте,
                                        в удобное для Вас время,в удобном для Вас пункте банковского обслуживания —
                                        интернет-банке,
                                        с помощью мобильного банкинга, инфокиоске, кассе банков, банкомате и т.д.
                                        Совершить оплату
                                        можно с использованием наличных денежных средств, электронных денег и банковских
                                        платежных
                                        карточек в пунктах банковского обслуживания банков, которые оказывают услуги по
                                        приему
                                        платежей, а также посредством инструментов дистанционного банковского
                                        обслуживания.</p>
                                </li>
                                <li>
                                    <p style={{display: 'flex', flexDirection: 'column'}}>
                                        <span style={{paddingBottom: '20px'}}>ДЛЯ ПРОВЕДЕНИЯ ПЛАТЕЖА НЕОБХОДИМО:</span>
                                        <span>1. В системе «Расчёт» (АИС ЕРИП) выберите:</span>
                                         <ul className='listEripStyle'>
                                             <li>• Бизнесy</li> 
                                             <li>• АИС "Рассчет" </li> 
                                             <li> • Дерево ЕРИП </li> 
                                             <li>• ИТ услуги </li> 
                                             <li>• Прочие организации </li> 
                                             <li>• Минск </li> 
                                             <li>• БелХард Программинг</li> 
                                             <li>• Лицензия ПО voenkomat.by </li>
                                            </ul> 
                                        <span>2. Для оплаты ввести свой номер выставленного счёта.</span>
                                        <span>3. Ввести сумму платежа (если не указана).</span>
                                        <span>4. Проверить корректность информации.</span>
                                        <span style={{paddingBottom: '20px'}}>5. Совершить платеж.</span>
                                        <span>Если Вы осуществляете платеж в кассе банка, пожалуйста, сообщите кассиру о необходимости проведения платежа через систему "Расчет" (ЕРИП).</span>
                                    </p>
                                </li>
                                <li className={'webPay_text'}>
                                    <img src={web_pay} alt={''} style={{width: '270px', height: '50px'}}/>
                                    <p style={{width: '71%'}}>3. Провести оплату <b>через систему WEBPAY™.</b>
                                        К оплате принимаются карточки VISA и MasterCard всех типов.
                                        Мы рекомендуем Вам предварительно уточнить в своем банке и удостовериться в том,
                                        что Ваша карта может быть использована для оплат через интернет.</p>
                                </li>
                                <li style={{display: 'flex', flexDirection: 'column'}}>
                                    <p>
                                        ДЛЯ ПРОВЕДЕНИЯ ПЛАТЕЖА НЕОБХОДИМО:</p>
                                    <span>1. В личном кабинете перейти в модуль "Оплата".</span>
                                    <span>2. Выбрать срок продления лицензии или ввести сумму для пополнения баланса.</span>
                                    <span>3. Вы будете перенаправлены на защищённую страницу платёжной системы WEBPAY™,
                                где необходимо заполнить данные о платёжной карте.
                                Безопасный сервер WEBPAY™ устанавливает шифрованное соединение по защищенному
                                протоколу TLS и конфиденциально принимает от клиента данные его пластиковой
                                банковской карты (номер карты, имя держателя, дату окончания действия,
                                и контрольный номер банковской карточке CVC/CVC2).</span>
                                    <span style={{paddingBottom: '20px'}}>4. Проверить корректность информации и выполнить платёж.
                                Оплаченная сумма будет отражена в истории платежей в личном кабинете.
                                В случае, если в личном кабинете не отразился совершенный платёж,
                                Вам необходимо обратиться в службу технической поддержки по телефонам и e-mail.
                                Менеджеры Вас обязательно проконсультируют. </span>
                                    <span style={{paddingBottom: '20px'}}>  После совершения оплаты с использованием банковской карточки
                                  необходимо сохранять полученные карт-чеки (подтверждения об оплате)
                                  для сверки с выпиской из карт-счёта (с целью подтверждения
                                  совершённых операций в случае возникновения спорных ситуаций).</span>
                                    <span style={{paddingBottom: '20px'}}>Обращаем Ваше внимание, что электронная система платежей WEBPAY™ не взимает
                                комиссии за перевод денег с карт-счёта. О возможных комиссиях за
                                конверсию валют и услуги по проведению операций с карт-счётом
                                уточняйте в банке-эмитенте, в котором открыт Ваш карт-счёт.</span>
                                    <span style={{paddingBottom: '20px'}}>Возврат денежных средств
                                (если данное предусмотрено условиями тарифа) будет инициирован
                                компанией Белхард не позднее 5 (пяти) рабочих дней с даты получения
                                запроса об отмене оплаты. Фактическое зачисление денежных средств на
                                счет банковской карточки клиента может занимать от 4 до 6 недель в зависимости
                                от правил и условий межбанковских процессинговых центров и банков,
                                участвующих в данной операции. Возврат осуществляется на ту же карточку,
                                с которой была произведена оплата.</span>
                                </li>
                            </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>
            }
        </>
    )
}
//@ts-ignore
const ContactsComponent = (props: {
    name: string, question: string, phone: string,
    toSaveName: any, setPhone: any, toSaveQuestion: any, sendDataContacts: any, addressData: any
}) => {
    return (
        <>
            <div className='landing_questions'>остались вопросы?</div>
            <div className='landing_questions_connect'>Наш менеджер свяжется с Вами и ответит на все
                интересующие вопросы
            </div>
            <div className='landing_wrapper_contacts'>
                <div className='form_control_landing'>
                    <div><input name="text" placeholder='Имя' value={props.name}
                                onChange={props.toSaveName}></input></div>
                    <div><Input
                        country="BY"
                        international
                        withCountryCallingCode
                        value={props.phone}
                        onChange={props.setPhone}/></div>
                    <div><textarea name="text" placeholder='Вопрос или описание проблемы' value={props.question}
                                   onChange={props.toSaveQuestion}></textarea></div>
                    <div>
                        <button name="text" onClick={props.sendDataContacts} className='btn_call'> Позвоните мне</button>
                    </div>
                </div>
                <Addresses addressData={props.addressData}/>
            </div>
        </>
    )
}

export const Addresses = (props: { addressData: any }) => {

    const {addressData} = props;

    return (<>
            <div className='wrapper_addresses' id={'contacts'}>
                <img src={mask} alt={''} className='mask'/>
                <div className='top_block_addresses'>
                    <div className='top_block_addresses_contacts'>контакты</div>
                    <span
                        className='top_block_addresses_title'><strong>{addressData[0] !== undefined && addressData[0].name}</strong></span>
                    <div className='top_block_addresses_title_2'>
                        <span
                            className={'title_recv'}>{addressData[0] !== undefined && addressData[0].text.substr(0, 34)}</span>
                        <span
                            className={'title_recv'}>{addressData[0] !== undefined && addressData[0].text.substr(34)}</span>
                        {addressData[1] !== undefined && addressData[2] !== undefined &&
                        <div className='top_block_addresses_title_3'>
                            <span>{addressData[1].name}{addressData[1].text}</span>
                            <span>{addressData[2].name}{addressData[2].text}</span>
                            <span>тел. (+37529) 220-84-26</span>
                        </div>
                        }
                    </div>
                    <div className='down_block_addresses_title'>
                        {addressData[3] !== undefined && <section>
                            <div><strong>{addressData[3].name}</strong></div>
                            <div>{addressData[3].text}</div>
                        </section>}
                        {addressData[4] !== undefined && <section>
                            <div><strong>{addressData[4].name}</strong></div>
                            <div>{addressData[4].text}</div>
                        </section>}
                        {addressData[5] !== undefined && <section>
                            <div><strong>{addressData[5].name}</strong></div>
                            <div>{addressData[5].text}</div>
                        </section>}
                        <div>
                            {addressData[6] !== undefined && <section>
                                <div><strong>{addressData[6].name}</strong></div>
                                <div>{addressData[6].text}</div>
                            </section>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer_mobile'>
                <Footer/>
            </div>
        </>
    )
}

export default BodyLandingPage;
