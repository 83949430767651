import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 450,
        //minHeight: 300,
         
    },
    row: {
        cursor: 'pointer'
    }
});

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}

const formatDate = (date: string) => {
    const d = new Date(date);

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear() + ' г.';

    return result;
};


export default function HistoryOnePropsTable({ items, ...props }: any) {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Дата изменения</TableCell>
                        <TableCell align="center">Значение</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item: any) => (
                        <TableRow key={getRandomInt(64532)} onClick={() => { }}>
                            <TableCell component="th" scope="row">
                                {formatDate(item.data)}
                            </TableCell>
                            <TableCell align="center">{item.name}</TableCell>
                            
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}