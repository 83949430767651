import {useState} from "react";

export const useInputDate = (initial: string) => {
    const [value, setValue] = useState(initial);
    const [error, setError] = useState(false)
    return {
        value,
        error,
        onChangeValue: (e: any) => {
            setValue(e.target.value)
        },
        isError: () => {
            return value === initial ? setError(true) : setError(false)
        }
    }
}