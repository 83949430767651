import { SET_ERRORS } from '../types'

const initialState = {
    textError: ''
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        
        case SET_ERRORS:
            return {
                textError: action.payload
            }
        default:
            return state;
    }
}