import React, {useState, useEffect} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './OfficesToolbar.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Axios from '../../utils/axios';
import {networkInterfaces} from "os";


interface RegionType {
    region: string,
    district: any,
    city: any
}


const OfficesToolbar = ({isActive, setActive, setFilterCity}: any) => {
    const [idCompany, setIdCompany] = useState(0);

    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');

    const [districts, setDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState('');

    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');

    const [allData, setAllData] = useState({} as any);

    useEffect(() => {
        if (isActive) {
            setIdCompany(Number(localStorage.getItem('id')))
        } else {
            setIdCompany(0);
        }
    }, [isActive]);

    useEffect(() => {
        setFilterCity({
            city: selectedCity,
            district: selectedDistrict,
            region: selectedRegion
        })
    }, [selectedDistrict, selectedCity, selectedRegion])

    useEffect(() => {
        const axios = new Axios();
        axios.get(`worker/area/${idCompany}`)
            .then((res: any) => {
                setAllData(res.data);
                const result = [] as any;
                for (const [key, value] of Object.entries(res.data)) {
                    result.push({
                        region: key,
                        district: value,
                        city: value
                    } as RegionType)
                }
                setRegions(result);
            })
            .catch(err => {
                console.log(err);
            })
    }, [idCompany]);

    useEffect(() => {
        if (selectedRegion != '') {
            const result = allData[selectedRegion] ? allData[selectedRegion].map((item: any) => {
                return {name: Object.keys(item)[0]}
            }) : [{name: ''}]
            setDistricts(result)
        }

    }, [selectedRegion]);

    useEffect(() => {
        if (selectedDistrict != '' && selectedRegion != '') {
            const buf_result = allData[selectedRegion] ?
                allData[selectedRegion].filter((item: any) => Object.keys(item)[0] === selectedDistrict ? true : false)[0]

                : [{name: ''}];

            const result = buf_result[selectedDistrict] ? buf_result[selectedDistrict].map((item: any) => {
                return {name: item}
            }) : [{name: ''}];

            setCities(result)
        }

    }, [selectedDistrict]);

    return (
        <div className='officesToolbar'>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isActive}
                        onChange={() => setActive(!isActive)}
                        name="checkedActivity"
                        color="primary"
                    />
                }
                label="Активные"
            />

            <div className='box-filter'>

                <Autocomplete
                    id="combo-box-demo"
                    options={regions}
                    getOptionLabel={(option: RegionType) => option.region}
                    onChange={(e: any) => {
                        setSelectedRegion(e.target.textContent)
                    }}
                    style={{width: 200}}
                    renderInput={(params) => <TextField {...params} label="Область" variant="outlined"/>}
                />
                {
                    /* <p>Область: </p>
                    <AutocompleteRegion 
                                handleChangeFullname={() => { }}
                                setFullname={() => { }} 
                            />*/
                }

            </div>

            <div className='box-filter'>
                <Autocomplete
                    id="combo-box-demo"
                    options={districts}
                    disabled={selectedRegion === '' ? true : false}
                    getOptionLabel={(option: any) => {
                        return option.name || ''
                    }}
                    onChange={(e: any) => {
                        setSelectedDistrict(e.target.textContent)

                    }}

                    style={{width: 200}}
                    renderInput={(params) => <TextField {...params} label="Район" variant="outlined"/>}
                />
                {
                    /*<p>Район: </p>
                 <AutocompleteDistrict />*/
                }

            </div>

            <div className='box-filter'>
                <Autocomplete
                    disabled={selectedRegion === "" || selectedDistrict === "" ? true : false}
                    id="combo-box-demo"
                    options={cities}
                    getOptionLabel={(option: any) => {
                        return option.name || ''
                    }}
                    onChange={(e: any) => {
                        setSelectedCity(e.target.textContent)
                    }}
                    style={{width: 200}}
                    renderInput={(params) => <TextField {...params} label="Город" variant="outlined"/>}
                />
                {
                    /*<p>Город: </p>
                <AutocompleteCity />*/
                }

            </div>
        </div>
    )
}

export default OfficesToolbar;