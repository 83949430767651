import { SET_USER, SET_AUTHENTICATED, SET_UNAUTHENTICATED, LOADING_USER } from '../types'
export const AGAIN_SEND_LETTER = "AGAIN-SEND-LETTER"
export const USER_ID_AGAIN_LETTER = "USER-ID-AGAIN-LETTER"

const initialState = {
    authenticated: false,
    credentials: {},
    loading: false,
    againSendLetter:'',
    userIdAgainLetter:0
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true
            };
        case AGAIN_SEND_LETTER:
            return {
                ...state,
                againSendLetter: action.againSendLetter
            };
        case USER_ID_AGAIN_LETTER:
            return {
                ...state,
                userIdAgainLetter: action.userIdAgainLetter
            };
        case SET_UNAUTHENTICATED:
            return initialState;
        case SET_USER:
            return {
                authenticated: true,
                loading: false,
                ...action.payload
            };
        default:
            return state;
    }
}