import React, {useState, useEffect} from 'react';
import style from './StartingMilitary.module.css';
import Button from "@material-ui/core/Button";

function StartingMilitary(props: any) {


    return (
        <div style={{margin: '330px auto 10px auto'}}>
            <span className='title_letters'>Начиная воинский учёт организации</span>
            <div className='btn-wrapper'>
                <Button variant="contained" onClick={() => props.history.push('/home')}>
                    Назад
                </Button>
            </div>
            <div className={'columns'}>
                <div className={'btn-wrapp'}>
                    <div className='btn-letter' style={{width: '500px'}}>
                        <Button variant="contained" className={'btn'}
                          onClick={() => props.history.push('/organization_wy_order')}>Приказ "Об организации воинского учета граждан"</Button>
                    </div>
                    <div className='btn-letter' style={{width: '500px'}}>
                        <Button variant="contained" className={'btn'}
                                onClick={() => props.history.push('/functionalResponsibilities')}>
                            Функциональные обязанности ветственного за ВУ</Button>
                    </div>
                    <div className='btn-letter' style={{width: '500px'}}>
                        <Button variant="contained" className={'btn'}
                            onClick={() => props.history.push('/job_description')}>Должностная инструкция ответственного за воинский учёт</Button>
                    </div>
                    <div className='btn-letter' style={{width: '500px'}}>
                        <Button variant="contained" className={'btn'}
                                onClick={() => props.history.push('/newRegistrationLetter')}>ПИСЬМА ПРИ ПОСТАНОВКЕ ОРГАНИЗАЦИИ НА ВОИНСКИЙ УЧЁТ</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StartingMilitary;
