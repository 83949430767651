import React, { useState, useEffect, useRef } from 'react';
import Input from '@material-ui/core/Input';
import classes from './PersonalCard.module.css'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Axios from '../../../utils/axios';
import ContentEditable from 'react-contenteditable';

export default function GeneralInformation({ state, updateField, setState, updateFullName, updateDiploma, updateCertificate, updatePassport, isEmpty, otherNationality, setOtherNationality, ...props }: any) {
    const [valueNationality, setValueNationality] = React.useState(state.generalInfState1.nationality_id);
    const [valueMaritalStatus, setValueMaritalStatus] = React.useState(state.generalInfState3.marital_status_id);
    const [valueEducation, setValueEducation] = React.useState(state.generalInfEducationState.education_id);
    const [listEducation, setListEducation] = useState([] as any);
    const [listMarital, setListMarital] = useState([]);
    const [listNationality, setListNationality] = useState([] as any);
    const [emailError, setEmailError] = useState(false);


    useEffect(() => {
        setValueNationality(state.generalInfState1.nationality_id);
        setValueMaritalStatus(state.generalInfState3.marital_status_id);
        setValueEducation(state.generalInfEducationState.education_id);
    }, [state])

    const handleChangeNationality = (event: any) => {
        setValueNationality(event.target.value as string);
        setState.setGeneralInfState1({
            ...state.generalInfState1,
            'nationality_id': event.target.value
        });

        setState.setSelectState({
            ...state.selectState,
            'nationality': event.currentTarget.textContent
        })
    };

    const handleChangeMaritalStatus = (event: any) => {
        setState.setGeneralInfState3({
            ...state.generalInfState3,
            'marital_status_id': event.target.value
        });
        setState.setSelectState({
            ...state.selectState,
            'marital_status': event.currentTarget.textContent
        })
        setValueMaritalStatus(event.target.value as string);
    };

    const handleChangeEducation = (event: any) => {
        setValueEducation(event.target.value as string);
        setState.setGeneralInfEducationState({
            ...state.generalInfEducationState,
            'education_id': event.target.value
        });

        setState.setSelectState({
            ...state.selectState,
            'education': event.currentTarget.textContent
        })
    };

    useEffect(() => {
        const axios = new Axios();

        axios.get(`library/education/`)
            .then(res => {
                setListEducation(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })

        axios.get(`library/marital/`)
            .then(res => {
                setListMarital(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })

        axios.get(`library/nationality/`)
            .then(res => {
                setListNationality(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const getName = (fullName: string, index: number) => {
        const name = fullName.split(' ').filter(item => item !== '');
        return name[index] || '';
    }

    const onChangePassSeries = (e: any) => {
        if (e.target.value.length < 3) updatePassport(e);
    };

    const validateEmail = (email: string) => {
        if (email.length === 0) {
            setEmailError(false);
        } else {
            const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            setEmailError(!reg.test(email));
        }

    }

    return (
        <div>
            <h3 className={classes.headers}>I. Общие сведения</h3>
            <div className={classes.grid}>
                <div>
                    <div>
                        <div className={classes.box}>
                            <p className={classes.boxChild}>Фамилия: </p>
                            <Input
                                error={isEmpty ? (getName(state.generalInfState1.full_name, 0) === '' ? true : false) : false}
                                id="lastName"
                                className={classes.boxChildInput}
                                onChange={updateFullName}
                                value={getName(state.generalInfState1.full_name, 0)}
                            />
                        </div>
                        <div className={classes.box}>
                            <p className={classes.boxChild}>Имя: </p>
                            <Input
                                error={isEmpty ? (getName(state.generalInfState1.full_name, 1) === '' ? true : false) : false}
                                id="firstName"
                                className={classes.boxChildInput}
                                onChange={updateFullName}
                                value={getName(state.generalInfState1.full_name, 1)}
                            />
                        </div>
                        <div className={classes.box}>
                            <p className={classes.boxChild}>Отчество: </p>
                            <Input
                                id="middleName"
                                error={isEmpty ? (getName(state.generalInfState1.full_name, 2) === '' ? true : false) : false}
                                className={classes.boxChildInput}
                                onChange={updateFullName}
                                value={getName(state.generalInfState1.full_name, 2)}
                            />
                        </div>
                    </div>

                    <div className={classes.box}>
                        <p className={classes.boxChild}>Дата рождения: </p>
                        <Input
                            id="date_of_birth"
                            // error={isEmpty ? (state.generalInfState1.date_of_birth === '' ? true : false) : false}
                            className={classes.boxChildInput}
                            type="date"
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfState1, state.generalInfState1) }}
                            value={state.generalInfState1.date_of_birth || ''}
                        />
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Место рождения: </p>
                        <textarea
                            className={classes.familyInput}
                            id="birthplace"
                            rows={2}
                            cols={30}
                            value={state.generalInfState1.birthplace || ''}
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfState1, state.generalInfState1) }}
                        >
                        </textarea>
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Национальность: </p>
                        <div className={classes.boxChildInput} >
                            <Select
                                id="nationality_id"
                                value={valueNationality}
                                onChange={handleChangeNationality}
                                className={classes.select}
                            // error={isEmpty ? (state.generalInfState1.nationality_id === '' ? true : false) : false}
                            >
                                {
                                    listNationality.map((item: any) => {
                                        return <MenuItem id={'nationality_id' + item.name} value={item.name} key={item.id + 'nationality'}>{item.name}</MenuItem>
                                    })
                                }
                                <MenuItem id={'nationality_id' + 7} value={0} key={100 + 'nationality'}>пусто</MenuItem>
                            </Select>

                        </div>
                    </div>
                    {
                        valueNationality === 0 ?
                            <div className={classes.box}>
                                <p className={classes.boxChild}></p>
                                <Input
                                    id="otherNationality"
                                    //error={isEmpty ? (getName(state.generalInfState1.full_name, 2) === '' ? true : false) : false}
                                    className={classes.boxChildInput}
                                    onChange={(e: any) => { setOtherNationality(e.target.value) }}
                                    value={otherNationality}
                                />
                            </div> : null
                    }
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Образование: </p>
                        <div className={classes.boxChildInput} >
                            <Select
                                id="education"
                                value={valueEducation}
                                onChange={handleChangeEducation}
                                className={classes.select}
                            // error={isEmpty ? (state.generalInfEducationState.education_id === '' ? true : false) : false}
                            >
                                {listEducation.map((item: any) => {
                                    return <MenuItem value={item.id} key={item.id + 'education'}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </div>
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Специальность по образованию: </p>
                        <textarea
                            className={classes.familyInput}
                            id="edu_specialization"
                            rows={2}
                            cols={30}
                            value={state.generalInfEducationState.edu_specialization || ''}
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfEducationState, state.generalInfEducationState) }}
                        >
                        </textarea>
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Квалификация: </p>
                        <textarea
                            className={classes.familyInput}
                            id="edu_qualification"
                            rows={2}
                            cols={30}
                            value={state.generalInfEducationState.edu_qualification || ''}
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfEducationState, state.generalInfEducationState) }}
                        >
                        </textarea>
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Диплом: </p>
                        <div className={classes.boxChildInput}>
                            <Input
                                id="series_diploma"
                                className={classes.diplomInput}
                                classes={{ root: classes.inputSmall }}
                                onChange={updateDiploma}
                                value={state.generalInfEducationState.edu_diploma.split(' ')[0] || ''}
                            // error={isEmpty ? (state.generalInfEducationState.edu_diploma === '' ? true : false) : false}
                            />
                            <p className={classes.passportChild}> № </p>
                            <Input
                                id="number_diploma"
                                className={classes.passportChild}
                                onChange={updateDiploma}
                                value={state.generalInfEducationState.edu_diploma.split(' ')[1] || ''}
                            // error={isEmpty ? (state.generalInfEducationState.edu_diploma === '' ? true : false) : false}
                            />
                            <p className={classes.passportChild}> от </p>
                            <Input
                                id="date_diploma"
                                className={classes.diplomInput}
                                onChange={updateDiploma}
                                value={state.generalInfEducationState.edu_diploma.split(' ')[2] || ''}
                            // error={isEmpty ? (state.generalInfEducationState.edu_diploma === '' ? true : false) : false}
                            />
                            <p className={classes.passportChild}>г. </p>
                        </div>
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Свидетельство: </p>
                        <div className={classes.boxChildInput}>
                            <Input
                                id="series_certificate"
                                className={classes.boxChildNum}
                                classes={{ root: classes.inputSmall }}
                                onChange={updateCertificate}
                                value={state.generalInfEducationState.edu_certificate.split(' ')[0] || ''}
                            //  error={isEmpty ? (state.generalInfEducationState.edu_certificate.split(' ')[0] === '' ? true : false) : false}
                            />
                            <p className={classes.boxChildNum}> № </p>
                            <Input
                                id="number_certificate"
                                className={classes.boxChildInput}
                                onChange={updateCertificate}
                                value={state.generalInfEducationState.edu_certificate.split(' ')[1] || ''}
                            // error={isEmpty ? (state.generalInfEducationState.edu_certificate.split(' ')[1] === '' ? true : false) : false}
                            />
                        </div>
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}> от </p>
                        <Input
                            id="date_certificate"
                            className={classes.boxChildInput}
                            onChange={updateCertificate}
                            value={state.generalInfEducationState.edu_certificate.split(' ')[2] || ''}
                        // error={isEmpty ? (state.generalInfEducationState.edu_certificate.split(' ')[2] === '' ? true : false) : false}
                        />

                    </div>
                </div>
                <div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Должность (специальность): </p>
                        <Input
                            id="position"
                            className={classes.boxChildInput}
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfState1, state.generalInfState1) }}
                            value={state.generalInfState1.position || ''}
                        // error={isEmpty ? (state.generalInfState1.position === '' ? true : false) : false}
                        />
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Семейное положение: </p>
                        <div className={classes.boxChildInput} >
                            <Select
                                id="marital_status select"
                                value={valueMaritalStatus}
                                onChange={handleChangeMaritalStatus}
                                className={classes.select}
                            // error={isEmpty ? (state.generalInfState3.marital_status_id === '' ? true : false) : false}
                            >
                                {listMarital.map((item: any) => {
                                    return <MenuItem value={item.id} key={item.id + 'marital'}>{item.name}</MenuItem>
                                })}

                            </Select>
                        </div>
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Состав семьи: </p>
                        <textarea
                            className={classes.familyInput}
                            id="family"
                            rows={1}
                            cols={30}
                            value={state.generalInfState3.family}
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfState3, state.generalInfState3) }}
                        >
                        </textarea>
                    </div>

                    <div className={classes.box}>
                        <p className={classes.boxChild}>Паспорт: </p>
                        <div className={classes.boxChildInput}>
                            <p className={classes.passportChild}> серия </p>
                            <Input id="pass_series"
                                className={classes.passportChild} classes={{ root: classes.inputSmall }}
                                value={state.generalInfState3.passport.split(' ')[0] || ''}
                                onChange={(e: any) => onChangePassSeries(e)}
                            //error={isEmpty ? (state.generalInfState3.passport.slice(0, 2) === '' ? true : false) : false}
                            />

                            <p className={classes.passportChild}> № </p>
                            <Input
                                id="pass_num"
                                className={classes.boxChildInput}
                                onChange={updatePassport}
                                value={state.generalInfState3.passport.split(' ')[1] || ''}
                            //error={isEmpty ? (state.generalInfState3.passport.slice(2) === '' ? true : false) : false}
                            />
                        </div>
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Кем выдан: </p>
                        <Input
                            id="pass_office"
                            className={classes.boxChildInput}
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfState3, state.generalInfState3) }}
                            value={state.generalInfState3.pass_office}
                        //error={isEmpty ? (state.generalInfState3.pass_office === '' ? true : false) : false}
                        />
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Дата выдачи: </p>
                        <Input
                            id="pass_date"
                            type="date"
                            className={classes.boxChildInput}
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfState3, state.generalInfState3) }}
                            value={state.generalInfState3.pass_date}
                        // error={isEmpty ? (state.generalInfState3.pass_date === '' ? true : false) : false}
                        />
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Личный №: </p>
                        <Input
                            id="pass_number"
                            className={classes.boxChildInput}
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfState3, state.generalInfState3) }}
                            value={state.generalInfState3.pass_number}
                        //error={isEmpty ? (state.generalInfState3.pass_number === '' ? true : false) : false}
                        />
                    </div>

                    <div className={classes.box}>
                        <p className={classes.boxChild}>Адрес проживания: </p>
                        <Input
                            id="address"
                            className={classes.boxChildInput}
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfState1, state.generalInfState1) }}
                            value={state.generalInfState1.address}
                        //error={isEmpty ? (state.generalInfState1.address === '' ? true : false) : false}
                        />
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Адрес регистрации: </p>
                        <Input
                            id="registered_address"
                            className={classes.boxChildInput}
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfState1, state.generalInfState1) }}
                            value={state.generalInfState1.registered_address}
                        //error={isEmpty ? (state.generalInfState1.registered_address === '' ? true : false) : false}
                        />
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Телефон: </p>
                        <Input
                            id="phone"
                            className={classes.boxChildInput}
                            onChange={(e: any) => { updateField(e, setState.setGeneralInfState1, state.generalInfState1) }}
                            value={state.generalInfState1.phone}
                        // error={isEmpty ? (state.generalInfState1.phone === '' ? true : false) : false}
                        />
                    </div>
                    <div className={classes.box}>
                        <p className={classes.boxChild}>Электронная почта:</p>
                        <Input
                            id="email"
                            className={classes.boxChildInput}
                            onChange={(e: any) => { validateEmail(e.target.value); updateField(e, setState.setGeneralInfState1, state.generalInfState1) }}
                            value={state.generalInfState1.email}
                            error={emailError}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
