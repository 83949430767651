import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const SelectVersionCardModal = ({ open, handleClose, setNew }: any) => {

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Выберете версию для просмотра личной карточки"}</DialogTitle>
                <DialogContent>
                    <div className='btns-selectVersionCard'>
                        <Button onClick={() => { setNew('/user'); handleClose() }} color="primary" variant="contained">
                            Старая
                    </Button>
                        <Button onClick={() => { setNew('/user_new'); handleClose() }} color="primary" variant="contained">
                            Новая
                    </Button>
                    </div>

                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </div>
    );
}

export default SelectVersionCardModal;