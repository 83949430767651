export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';

export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const LOADED_COMPANY = 'LOADED_COMPANY';

export const SET_TABLE_SETTINGS = 'SET_TABLE_SETTINGS';
export const UPDATE_TABLE_SETTINGS = 'UPDATE_TABLE_SETTINGS';
export const OFFICE_ID_VALUE = 'OFFICE_ID_VALUE';

export const IS_UPDATE_CARD = 'IS_UPDATE_CARD';
