import axios from 'axios';

const outPutFunction=()=>{
    localStorage.setItem('responsible person','');
    localStorage.setItem('username','');
    localStorage.setItem('token','');
    localStorage.setItem('userID','');
    localStorage.setItem('id','');
    localStorage.setItem('company','');
    axios.get('https://voenkomat.by/war/api/logout')
        .then(res=>{

        })
        .catch(err=>{
            console.log(err)
        })
}
export default outPutFunction
