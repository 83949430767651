import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import DismissalLetter from '../../components/Letters/DismissalLetter';
import './NewDismissalLetter.css';
import AutocompleteOffice from '../../components/AutocompleteOffice/AutocompleteOffice';
import Axios from '../../utils/axios';
import {connect, useDispatch, useSelector} from 'react-redux';
import SuccessfulSaveModal from '../../components/SuccessfulSaveModal/SuccessfulSaveModal';
import { DataGrid } from '@material-ui/data-grid';
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import {getUserIdAgainLetterAC, onAgainSendLetterAC} from "../../redux/actions/userActions";


const getDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;

    return today.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
}

const NewDismissalLetter = ({user, ...props}: any) => {

    const againSendLetter=useSelector((state:any)=>state.user.againSendLetter)
    const user_id_again_letter=useSelector((state:any)=>state.user.userIdAgainLetter)

    const dispatch=useDispatch()
    const [fullAddressAgain, setFullAddressAgain] = useState({
        area: '',
        city: '',
        district: '',
        index: '',
        address: ''
    });
    const [againOffice, setAgainOffice] = useState('');
    const [shortOfficeAgain, setShortOfficeAgain] = useState('');

    const [office, setOffice] = useState('');
    const [date, setDate] = useState(getDate());
    const [number, setNumber] = useState('');
    const [address, setAddress] = useState('');
    const [fullAddress, setFullAddress] = useState({
        area: '',
        city: '',
        district: '',
        index: '',
        address: ''
    });
    const [items, setItems] = useState([] as any);

    const name = `${(localStorage.getItem('responsible person') as string).split(' ')[2][0]}.${(localStorage.getItem('responsible person') as string).split(' ')[1][0]}. ${(localStorage.getItem('responsible person') as string).split(' ')[0]} `
    const phone = localStorage.getItem('hr_department_phone');
    const company = localStorage.getItem('company');

    const [file, setFile] = useState<any>('');
    const [isDisabled, setDisabled] = useState(true);

    const [openModal, setOpenModal] = useState(false);
    const [fired, setFired] = useState([]);
    const [organizationName, setOrganizationName] = useState('');
    const [organizationRespPerson, setOrganizationRespPerson] = useState('');
    const [organizationPhone, setOrganizationPhone] = useState('');
    const [organizationPositionResp, setOrganizationPositionResp] = useState('');
    const [shortOffice, setShortOffice] = useState('');
    const [isSavePdf, setIsSavePdf] = useState(false);
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const [idOffice, setIdOffice] = useState(0)
    let office_id_val = useSelector((state: any) => state.lettersReducer.office_id_value)

    const handleChangeOffice = (event: any) => {
        setOffice(event.name as string);
        setAddress(event.address as string);
        setShortOffice(event.short_name as string);
        setFullAddress({
            area: event.area,
            city: event.city,
            district: event.district,
            address: `${event.letter_street} \n ${event.index}, ${event.letter_locality} \n ${event.letter_district!==null ? event.letter_district : '' }`,
            index: event.index
        })
    };

    useEffect(() => {
        const axios = new Axios();
        const user_id = localStorage.getItem('id');
        axios.get(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}/`)
            .then(res => {
                setOrganizationName(res.data.short_organization_name);
                setOrganizationRespPerson(res.data.full_name);
                setOrganizationPhone(res.data.phone);
                setOrganizationPositionResp(res.data.position);
            });

        if(againSendLetter==='' && user_id_again_letter===0) {
            axios.get('worker/office/')
                .then(res => {
                    let value_office = res.data.results.filter((i: any) => {
                        return office_id_val === i.name ? i.id : null
                    })
                  if(value_office.length !==0) {
                      setIdOffice(value_office[0].id)
                  }

                })
            axios.post(`worker/${user_id}/filter/`, {
                dismissal_report: false,
                office__name: office
            })
                .then(res => {
                    setItems(res.data.results.filter((i:{basic_military_id:number})=>{
                        return i.basic_military_id ===5
                    }));
                })
                .catch(err => {
                    console.log(err);
                })
        } else if (againSendLetter==='dismiss_letter_again' && user_id_again_letter !==0) {
            axios.get(`worker/${user_id}/${user_id_again_letter}/`)
                .then(response => {
                    setItems([response.data]);
                    setIdOffice(response.data.office_id)
                    setShortOfficeAgain(response.data.office)
                    axios.get('worker/office/')
                        .then(res=>{
                            for(let i of res.data.results){
                                if(i.short_name===response.data.office) {
                                    setAgainOffice(i.name)
                                    setFullAddressAgain(
                                        {
                                            area: i.area,
                                            city: i.city,
                                            district: i.district,
                                            address: `${i.letter_street} \n ${i.index}, ${i.letter_locality} \n ${i.letter_district !== null ? i.letter_district : ''}`,
                                            index: i.index
                                        }
                                    )
                                }

                            }
                        })
                })
        }

    }, [office,againSendLetter,office_id_val]);

    useEffect(() => {
        const axios = new Axios();
        axios.post(`worker/${localStorage.getItem('id')}/filter_date/`, {
                "date_field_name": "time_end__gte",
                "count_day": '',
                "dismissal_report": false
            }
        )
            .then(res => {
                setFired(res.data.results.filter((item:any)=>{
                    return item.basic_military_id===5
                }))
            });
    }, [openModal])

    const postData=() => {
        let body = new FormData();
        body.append("docs", file,'Письмо об уволенных');
      body.append("register_date", `${date}T12:00:00.016954+03:00`);
        body.append("office_id", idOffice.toString());
        body.append("type_id", 'Письмо об уволенных');
        body.append("user", String(localStorage.getItem('id')));
        if (file !== '') {
            const axios = new Axios();
            axios.post(`worker/report/${localStorage.getItem('id')}/`, body)
                .then(res => {
                    axios.post(`worker/${localStorage.getItem('id')}/report/`, {
                        report: 0,
                        office__name: againOffice==='' ?  office : againOffice
                    })
                        .then(res => {
                            handleOpenModal();
                            setTimeout(handleCloseModal, 5000);
                            setIsSavePdf(false)
                            dispatch(onAgainSendLetterAC(''))
                            dispatch(getUserIdAgainLetterAC(0))
                            setItems([])
                            // window.location.reload()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
                .catch(err => {
                    console.log(err);
                })
        }

    }

    const save = () => {
        setIsSavePdf(true)
        const data = {
            office,
            date,
            number,
            address,
            items,
            name,
            phone,
            company,
            setFile,
            fullAddress : againSendLetter==='' && user_id_again_letter===0
                ? fullAddress : fullAddressAgain,
            organizationName,
            organizationRespPerson,
            organizationPhone,
            organizationPositionResp,
            shortOffice: shortOfficeAgain==='' ? shortOffice : shortOfficeAgain
        }
        DismissalLetter(data);
    }

    useEffect(() => {
        if (number === '' || date === '' || (office === '' && againOffice==='')  || !isSavePdf) {
            setDisabled(true)
        } else {
            setDisabled(false);
        }
    }, [number, date, office,isSavePdf]);


    const onBack=()=>{
        props.history.push('/letters')
        dispatch(onAgainSendLetterAC(''))
        dispatch(getUserIdAgainLetterAC(0))
    }


    return (
        <div style={{margin: '105px auto 10px auto', width: '98%'}}>
            <div style={{marginBottom: '180px'}}>
                {fired.length!==0 && <FiredPersons fired={fired}/>}
                </div>
            <span className='title_letters'>Письмо об увольнении с работы</span>
            <div className='toolbar'>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={onBack}>
                        Назад
                    </Button>
                </div>
                <div style={{marginBottom: '20px',fontSize: '14px',color:'red'}}><sup>*</sup>Прежде, чем отправить данные в электронный архив -пожалуйста, сохраните документ в PDF формате.</div>
            </div>

            <Button variant="contained" color="primary" startIcon={<ArtTrackIcon/>}
                    style={{marginRight: '20px'}}   onClick={save} >
                Сохранить в PDF
            </Button>
            <Button variant="contained" disabled={isDisabled} onClick={postData}>
                Сохранить
            </Button>
            <div className='letter'>
                <div className='header'>
                    <div></div>
                    <div>
                        <span>Военному комиссару <br/></span>
                        <AutocompleteOffice
                            handleChangeOfficeName={handleChangeOffice}
                            setOfficeName={setOffice}
                            againOffice={againOffice}
                        />
                        {fullAddress.address &&
                        <span className='underHeader'>
                            <br/>
                            {fullAddress.address}
                        </span>
                        }
                    </div>
                </div>

                <div className='description'>
                    <div>
                        <div className='description-child'>
                            Исх. №
                            <div>
                                <input type="text" value={number} onChange={(e: any) => setNumber(e.target.value)}
                                       className='number-input'/>
                            </div>
                        </div>
                        <div className='description-child'>
                            от
                            <div>
                                <input type="date" id="start"
                                       value={date}
                                       min="2017-01-01" max="2022-12-31"
                                       onChange={(e: any) => setDate(e.target.value)}
                                       className='number-input'
                                />
                            </div>

                        </div>
                    </div>
                    <div className="description-text">
                        <div>
                            <b>СВЕДЕНИЯ</b> <br/>
                            <b> о приеме на работу (поступлению на учебу) граждан, состоящих или обязанных
                                состоять на воинском учете,</b><br/>
                            <b><span style={{fontSize:'14px',textDecoration:'underline'}}>об их увольнении с работы </span> (отчислении), о произошедших изменениях данных воинского учета</b>
                        </div>
                        <div className={'org-name-let'}>

                            <div style={{borderBottom: '1px solid gray'}}><b style={{fontSize:'14px'}}>{organizationName}</b></div>
                            <span>(наименование государственного органа, организации, учреждения образования,
                                местного исполнительного и распорядительного органа)</span>
                        </div>
                    </div>
                </div>

                <div className='table'>
                    <table className="tg">
                        <col width='36'/>
                        <col width='150'/>
                        <col width='242'/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                        <col width='110'/>
                        <col/>
                        <col/>
                        <col/>
                        <thead>
                        <tr className='table-header'>
                            <th className="tg-1">№ <br/> п/п</th>
                            <th className="tg-1">Основание</th>
                            <th className="tg-1">Воинское звание, номер военно-учетной специальности и код воинской
                                должности
                            </th>
                            <th className="tg-1">Фамилия, собственное имя, отчество(если
                                таковое имеется), идентификационный номер
                            </th>
                            <th className="tg-1">Дата рождения</th>
                            <th className="tg-1">Место жительства</th>
                            <th className="tg-1">Место пребывания</th>
                            <th className="tg-1">Образование</th>
                            <th className="tg-1">Семья</th>
                            <th className="tg-1">Работа(учеба)</th>
                            <th className="tg-1">Номера телефонов</th>
                            <th className="tg-1">Особые отметки</th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item: any, number: number) => {
                            return (<tr key={number + 1}  className={'letter-columns-td'}>
                                <td className="tg-2">{number + 1}</td>
                                <td className="tg-2">{item.letter_base}<br/>
                                    {item.basic_military === null ? '' : `Причина: ${item.basic_military}`}<br/>
                                    {item.date_remove_military ? item.date_remove_military.split('-').reverse().join('-') : '' }
                                    <br/>

                                </td>
                                <td className="tg-2"> {item.accountg_id===4 ? 'Призывник' :
                                    <>{item.grade}<br/><br/>
                                        {item.wyc ? item.wyc : '' }
                                    </>
                                }</td>
                                <td className="tg-2">{item.full_name}<br/><br/>
                                    {item.pass_number}</td>
                                <td className="tg-2">{item.date_of_birth ? item.date_of_birth.split('-').reverse().join('-') : ''}</td>
                                <td className="tg-2">{item.registered_address}</td>
                                <td className="tg-2">{item.address}</td>
                                <td className="tg-2"> {item.education ? item.education : ''}
                                    <br/> {item.education_full ? item.education_full : ''}
                                    <br/> {item.edu_specialization ? item.edu_specialization : ''}
                                    {/*<br/> {item.edu_qualification ? item.edu_qualification : ''}*/}
                                    <br/> {item.edu_dop_full ? item.edu_dop_full : ''}
                                    <br/> {item.edu_dop_specialization ? item.edu_dop_specialization : ''}

                                </td>
                                <td className="tg-2">{item.marital_status ? <span>{item.marital_status}
                                    <br/>
                                    {item.family_half && item.family ? `Состав семьи: \n супруга (супруг): \n ${item.family_half} \n ${item.family}` :
                                        !item.family_half && item.family ? `Состав семьи: \n ${item.family}` :
                                            item.family_half && !item.family ? `Состав семьи: \n супруга (супруг): \n ${item.family_half}` : ''}
                                    </span>:<span></span>}</td>
                                <td style={{whiteSpace: 'pre-line', width:'200px'}}>{item.position ? `${item.position} \n Дата приёма: ${item.add_military ? item.add_military.split('-').reverse().join('-') : ''}
                                    ${item.order_military? 'Приказ № ' + item.order_military : ''} 
                                         ${item.date_add_military ? 'от ' + item.date_add_military.split('-').reverse().join('-') : ''}
                                           ${item.time_end ? ` \n Дата увольнения: ${(item.time_end.split('-').reverse().join('-'))}` : ''}
                                           ${item.order_remove_military ? `Приказ № ${item.order_remove_military}` : ''}
                                         ${item.date_remove_military  ? `от ${item.date_remove_military.split('-').reverse().join('-')}` : ''}
                                          ` :
                                    item.education}  </td>
                                <td className="tg-2">{item.home_phone ? 'дом. ' + item.home_phone : ''}<br/><br/>
                                    {item.work_phone ? `рабоч. ${item.work_phone}` : ''}<br/><br/>
                                    {item.phone ? `моб. ${item.phone}` : ''}</td>
                                <td className="tg-2">{item.status ? `Годность к военной службе: ${item.status}` : ''}<br/><br/>
                                    {item.requirement === true ? <><span>Моб. предписание:</span><div>Да</div>
                                            <div>{item.requirement_annotation}</div>
                                        </>
                                        : <><span>Моб. предписание:</span><div>Нет</div></>
                                    }
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>

                <div className='footers_'>
                    <div className={'name_styles'}>
                        {organizationRespPerson}
                        <span style={{fontSize: '10px', width: '243px'}}> (подпись, инициалы, фамилия должностного лица, ответственного за ведение военно-учетной работы)</span>
                    </div>
                    <div style={{fontSize: '14px', marginTop: '10px'}}>
                        Тел. {organizationPhone}
                    </div>

                </div>
            </div>
            <SuccessfulSaveModal
                message={'Письмо о Снятии с Учёта отправлено в '}
                open={openModal}
                handleClose={handleCloseModal}
                office={office}
                againOffice={againOffice}
            />
        </div>
    )
}
// export default NewLetter;
const mapStateToProps = (state: any) => ({
    user: state.user,
});

const mapActionsToProps = {
    // loginUser
};
export default connect(mapStateToProps, mapActionsToProps)(NewDismissalLetter)

const FiredPersons = (props: any) => {

    const [firedList, setFiredList] = useState([]);
    const sorting=(arr:any[])=>{
        arr.sort((a, b) => a.full_name > b.full_name ? 1 : -1);
    };

    useEffect(() => {
        sorting(props.fired);
        if(props.fired.length !==0) {
            setFiredList(props.fired)
        }
    }, [props.fired]);

    const columns = [
        { field: 'id', headerName: '№', width: 70 },
        { field: 'full_name', headerName: 'ФИО', width: 270 },
        { field: 'war_name', headerName: 'Военкомат', width: 270 },
    ];
    let rows:any[]=[];
    let nextI=1
    for(let i of firedList) {
        // @ts-ignore
        rows=[...rows,{id:nextI,full_name:i.full_name,war_name:i.office}]
        nextI++
    }

    return (<>
            {firedList&&
            <div style={{ height: 400, width: '100%' }}>
                <div style={{fontWeight:'bold'}}>Список сотрудников, уволенных с организации, по которым еще не отправлены письма</div>
                <DataGrid rows={rows} columns={columns} pageSize={5} disableColumnMenu={true}   />
            </div>
            }
        </>
    );
}
