import React, {useEffect, useState, ChangeEvent} from 'react';
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import style from '../Documents/Documents.module.css'
import Axios from '../../utils/axios';
import AutocompleteOffice from '../AutocompleteOffice/AutocompleteOffice';
import ReportPDF from './ReportPDF';
import './NewReportModal.css';
import {QuestionModal} from "../../utils/Modals/QuestionModal";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            maxWidth: '1000px',
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        paper: {
            maxWidth: '1000px'
        },
        paperWidthMd: {
            maxWidth: '1000px'
        },
        paperWidthSm: {
            maxWidth: '1000px'
        },
        children: {
            padding: '0 3px'
        }


    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
    // handleChangeOfficeName: any;
    // setOfficeName: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className={classes.children} variant="h6"> {children} </Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),

    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        maxWidth: '1000px'
    },
}))(MuiDialogActions);


interface Item {
    id: string;
    office: string;
    officer: string;
    sergeant: string;
    recruit: string;
};

const getDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;

    return today.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
};

const formatDate = (date: string) => {
    const d = new Date(date)

    const arr = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + ' ' + (arr[month - 1]) + ' ' + d.getFullYear() + ' г.';

    return result;
};

export default function NewReportModal(props: any) {

    const [file, setFile] = useState<any>('');

    const [office, setOffice] = useState('');
    const [office_id, setOffice_id] = useState(0);
    const [date1, setDate1] = useState(getDate());
    const [number1, setNumber1] = useState('');
    const [date2, setDate2] = useState('');
    const [number2, setNumber2] = useState('');
    const [name, setName] = useState('');
    const company = localStorage.getItem('company');

    const [date3, setDate3] = useState('')
    const [countWorkers, setCountWorkers] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [disableSave, setDisableSave] = useState(true)
    const [affiliations, setAffiliations] = React.useState('');


    useEffect(() => {
        setCountWorkers(props.stateTable1.all_worker)
        setAffiliations(props.stateTable1.affiliations)
    }, [props.stateTable1.all_worker,props.stateTable1.affiliations])

    const handleChangeOffice = (event: any) => {
        setOffice_id(Number(event.id));
        setOffice(event.name as string);
    };


    const saveReport = () => {

        let nameResp = localStorage.getItem('resp-for-documents')
        let positionResp = localStorage.getItem('resp-for-documents-position');
        const data = {
            nameResp,
            positionResp,
            office,
            date1,
            number1,
            date2,
            number2,
            name,
            company,
            city: props.city,
            table1: props.stateTable1,
            table2: props.stateTable2,
            nameSender: props.headName,
            positionSender: props.headPosition,
            setFile,
            date3,
            countWorkers,
            phoneResp: props.phoneResp,
            affiliations,
            phoneHead: props.phoneHead
        }
        ReportPDF(data);
        setDisableSave(false)
    }

    const saveReportServer = () => {
        let body = new FormData();
        body.append("docs", file);
        body.append("office_id", String(office_id));
      //  body.append("office_id", '1');
        body.append("type_id", "Отчёт о военнообязанных и призывниках");
        body.append("user", String(localStorage.getItem('id')));

        const axios = new Axios();
        axios.post(`worker/report/${localStorage.getItem('id')}/`, body)
            .then(res => {
                props.handleClose();
                setOpenModal(false);
                setDisableSave(true)
            })
            .catch(err => {
                console.log(err);
            })
    }

    let nameResp: any = localStorage.getItem('resp-for-documents')

    // @ts-ignore
    // @ts-ignore
    return (
        <div>
            <Dialog
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth='xl'
                scroll='body'
            >
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    Отчёт
                </DialogTitle>
                <DialogContent dividers>
                    <div className='wrapper'>
                        <div className=''>
                            <div className='descriptionReport-child'>
                                <div>
                                    <input type="date" id="start"
                                           value={date1}
                                           min="2017-01-01" max="2022-12-31"
                                           onChange={(e: any) => setDate1(e.target.value)}
                                           className='numberReport-input'
                                    ></input>
                                    №
                                    <input type="text" value={number1} onChange={(e: any) => setNumber1(e.target.value)}
                                           className='number-input-small'/>
                                </div>
                                <div>
                                    на №
                                    <input type="text" value={number2} onChange={(e: any) => setNumber2(e.target.value)}
                                           className='number-input-small'/>
                                    от
                                    <input type="date" id="start"
                                           value={date2}
                                           min="2017-01-01" max="2022-12-31"
                                           onChange={(e: any) => setDate2(e.target.value)}
                                           className='numberReport-input'
                                    ></input>
                                </div>
                            </div>
                        </div>
                        <div className='headerReport'>
                            <div></div>
                            <div>
                                <span>Военному комиссару<br/></span>
                                <AutocompleteOffice
                                    handleChangeOfficeName={handleChangeOffice}
                                />
                            </div>
                        </div>

                        <div className='descriptionReport'>
                            <div>
                            </div>
                            <div className="descriptionReport-text">
                                Сведения о количестве военнообязанных и призывников, работающих в <br/> {company}, {props.city}
                            </div>
                        </div>

                        <h5 className={style.h4}>По состоянию на
                            <input type="date" id="start"
    value={date3}
    min="2017-01-01" max="2030-12-31"
    onChange={(e: any) => setDate3(e.target.value)}
    className='numberReport-input'
    />
                        </h5>
                        <table className="tg">
                            <thead>
                            <tr>
                                <th className="tg-0pky" rowSpan={3}><sup>* </sup>Всего работающих</th>
                                <th className="tg-lboi" colSpan={5}>из них</th>
                            </tr>
                            <tr>
                                <td className="tg-0pky" colSpan={2}>военнообязанных</td>
                                <td className="tg-0pky" colSpan={2}>Имеют МП</td>
                                <td className="tg-zd5i" rowSpan={2}>Призывников</td>
                            </tr>
                            <tr>
                                <td className="tg-0pky">всего</td>
                                <td className="tg-0pky">в т.ч. офицеров</td>
                                <td className="tg-0pky">всего</td>
                                <td className="tg-0pky">в т.ч. офицеров</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="tg-0pky"><input value={countWorkers} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setCountWorkers(e.target.value)
                                }}/></td>
                                {console.log(props.stateTable1)}
                                <td className="tg-0pky">{props.stateTable1.all_war}</td>
                                <td className="tg-0pky">{props.stateTable1.officer}</td>
                                <td className="tg-0pky">{props.stateTable1.officer_mp}</td>
                                <td className="tg-0pky">{props.stateTable1.war_mp}</td>
                                <td className="tg-0pky">{props.stateTable1.recruit}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div style={{fontSize: '11px'}}><sup>*</sup> Количетсво действующих сотрудников взято с формы
                            "Контакты". При необходимости измените
                            количество сотрудников, кликнув поле, где указано их количество.
                        </div>
                        <div className={style.box2}>
                            <p className={`p-report`}>Распределение по военным комиссариатам</p>
                            <table className="tg">
                                <thead>
                                <tr>
                                    <th className="tg-0lax" rowSpan={2}>№ <br/>п/п</th>
                                    <th className="tg-0lax" rowSpan={2}>Наименование РВК и его адрес</th>
                                    <th className="tg-0lax" colSpan={2}>Количество военнообязанных</th>
                                    <th className="tg-0lax" rowSpan={2}>Количество призывников</th>
                                    <th className="tg-0lax" rowSpan={2}>Всего</th>
                                </tr>
                                <tr>
                                    <td className="tg-0lax">Всего</td>
                                    <td className="tg-0lax">Офицеры</td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    props.stateTable2.map((item: any) => {
                                        //console.log(item)
                                        return <tr key={item.id + 9}>
                                            <td className="tg-0lax">{item.id}.</td>
                                            <td className="tg-0lax">{item.office}</td>
                                            <td className="tg-0lax">{item.sergeant}</td>
                                            <td className="tg-0lax">{item.officer}</td>
                                            <td className="tg-0lax">{item.recruit}</td>
                                            <td className="tg-0lax">{item.all}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className='footerReport_'>
                            <span>
                            Ответственный за ведение воинского учета организации
                            </span>
                            <span>{nameResp.split(' ')[1] && nameResp.split(' ')[2] && nameResp.split(' ')[0] ? ' ' +
                                nameResp.split(' ')[1][0] + '.' + nameResp.split(' ')[2][0] + "." + " " + nameResp.split(' ')[0]
                                : nameResp === null ? '' : nameResp}</span>
                            <div>{props.phoneResp}</div>
                        </div>
                        <div className='footerReport'>

                            <>
                                <div>
                                    <input type="text" value=
                                        {props.headPosition ? props.headPosition : ''}
                                           onChange={(e: any) => props.setHeadPosition(e.target.value)}
                                           className='name-input' placeholder='Должность отправителя'/>
                                 </div>
                                <div>
                                    <input type="text"
                                           value={props.headName ? props.headName.split(' ')[1] && props.headName.split(' ')[2] && props.headName.split(' ')[0] ?
                                               props.headName.split(' ')[1][0] + '.' + props.headName.split(' ')[2][0] + " " + props.headName.split(' ')[0] : props.headName : props.headName}

                                           onChange={(e: any) => props.setHeadName(e.target.value)}
                                           className='name-input' placeholder='Фамилия отправителя'/>
                                </div>
                                <div>
                                    <input type="text" value=
                                        {props.phoneHead ? props.phoneHead : ''}
                                           onChange={(e: any) => props.setPhoneHead(e.target.value)}
                                           className='name-input' placeholder='Телефон отправителя'/>
                                </div>
                            </>

                        </div>
    
                    </div>


                </DialogContent>
                <div className={'report-set'}><sup>*</sup> Перед сохранением отчёта в Исходящих документах необходимо
                    Скачать отчёт
                </div>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={props.handleClose}>
                        Отмена
                    </Button>
                    <Button autoFocus onClick={saveReport} variant="contained" color="primary">
                        Скачать отчёт
                    </Button>
                    <Button autoFocus
                            disabled={disableSave}
                            onClick={() => {
                                setOpenModal(true)
                            }} variant="contained" color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
            <QuestionModal message={'Сохранить данный отчёт в Исходящих документах ?'}
                           open={openModal}
                           handleClose={() => {
                               setOpenModal(false)
                           }}
                           handleAccept={saveReportServer}/>
        </div>
    );
}
