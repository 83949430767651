import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ListCorrectLetter from '../../components/Letters/ListCorrectLetter';
import AutocompleteOffice from '../../components/AutocompleteOffice/AutocompleteOffice';
import SuccessfulSaveModal from '../../components/SuccessfulSaveModal/SuccessfulSaveModal';
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import { DataGrid } from '@material-ui/data-grid';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getUserIdAgainLetterAC, onAgainSendLetterAC } from "../../redux/actions/userActions";
import Axios from '../../utils/axios';
import './ListForCorrect.css';


const getDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;

    return today.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
}

const ListForCorrect = ({ user, handleChangeStructure, ...props }: any) => {

    //@ts-ignore
    const againSendLetter=useSelector((state:any)=>state.user.againSendLetter)
    const user_id_again_letter=useSelector((state:any)=>state.user.userIdAgainLetter)

    const dispatch = useDispatch();

    const [office, setOffice] = useState('');
    const [date, setDate] = useState(getDate());
    const [number, setNumber] = useState('');

    // добавленые поля в конце формы
    const [typeCity, setTypeCity] = useState('');
    const [dataYear, setDataYear] = useState('');

    const [address, setAddress] = useState('');
    const [fullAddress, setFullAddress] = useState({
        area: '',
        city: '',
        district: '',
        index: '',
        address: ''
    });
    const [fullAddressAgain, setFullAddressAgain] = useState({
        area: '',
        city: '',
        district: '',
        index: '',
        address: ''
    });
    const [items, setItems] = useState([] as any);

    const name = `${(localStorage.getItem('responsible person') as string).split(' ')[2][0]}.${(localStorage.getItem('responsible person') as string).split(' ')[1][0]}. ${(localStorage.getItem('responsible person') as string).split(' ')[0]} `
    const phone = localStorage.getItem('hr_department_phone');
    const company = localStorage.getItem('company');
    const addressCompany = localStorage.getItem('addressCompany');

    const [file, setFile] = useState<any>('');
    const [isDisabled, setDisabled] = useState(true);

    const [openModal, setOpenModal] = useState(false);

    const [hired, setHired] = useState([]);
    const [hiredTrue, setHiredTrue] = useState([]);
    const [organizationName, setOrganizationName] = useState('');
    const [organizationRespPerson, setOrganizationRespPerson] = useState('');
    const [organizationPhone, setOrganizationPhone] = useState('');
    const [organizationPositionResp, setOrganizationPositionResp] = useState('');
    const [shortOffice, setShortOffice] = useState('');
    const [isSavePdf, setIsSavePdf] = useState(false);
    const [shortOfficeAgain, setShortOfficeAgain] = useState('');
    const [againOffice, setAgainOffice] = useState('');
    const [dataSelect, setDataSelect] = useState([]);
    const [accountg, setAccountg] = useState(0);


console.log(items, 'items')
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };


    const [idOffice,setIdOffice] = useState(0)
    let office_id_val = useSelector((state: any) => state.lettersReducer.office_id_value)

    const handleChangeOffice = (event: any) => {
        setOffice(event.name as string);
        setShortOffice(event.short_name as string);
        setAddress(event.address as string);
        setFullAddress({
            area: event.area,
            city: event.city,
            district: event.district,
            address: `${event.letter_street} \n ${event.index}, ${event.letter_locality} \n ${event.letter_district!==null ? event.letter_district : '' }`,
            index: event.index
        })
    };


    useEffect(() => {
        const axios = new Axios();
        axios.get('library/accountg/')
        .then(res => {
            setDataSelect(res.data.results);
        })
    },[]);


    useEffect(() => {
        const axios = new Axios();
        const user_id = localStorage.getItem('id');
        axios.get(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}/`)
            .then(res=>{
                setOrganizationName(res.data.short_organization_name);
                setOrganizationRespPerson(res.data.full_name);
                setOrganizationPhone(res.data.phone);
                setOrganizationPositionResp(res.data.position);
            })

        if(againSendLetter === '' && user_id_again_letter === 0) {

            axios.get('worker/office/')
                .then(res=>{
                    let value_office = res.data.results.filter((i:any)=>{
                        return office_id_val === i.name ? i.id : null
                    })
                    for (let i of value_office) {
                        setIdOffice(i.id)
                    }
                })

            axios.post(`worker/${user_id}/filter/`, {
                delete: false,
                office__name: office,
                accountg: accountg
            })
                .then(res => {
                    setItems(res.data.results);
                })
                .catch(err => {
                    console.log(err);
                })
        } else if(againSendLetter === 'hiring_letter_again' && user_id_again_letter !== 0)  {

            axios.get(`worker/${user_id}/${user_id_again_letter}/`)
                .then(response => {
                    setItems([response.data]);
                    setIdOffice(response.data.office_id)
                    setShortOfficeAgain(response.data.office)
                    axios.get('worker/office/')
                        .then(res=>{
                           for(let i of res.data.results){
                               if(i.short_name === response.data.office) {
                                   setAgainOffice(i.name)
                                   setFullAddressAgain(
                                       {
                                           area: i.area,
                                           city: i.city,
                                           district: i.district,
                                           address: `${i.letter_street} \n ${i.index}, ${i.letter_locality} \n ${i.letter_district !== null ? i.letter_district : ''}`,
                                           index: i.index
                                       }
                                   )
                               }

                           }
                        })
                })
        }



    }, [office, againSendLetter, user_id_again_letter, office_id_val, accountg]);

    

    useEffect(()=>{
        const axios = new Axios();
        axios.post(`worker/${localStorage.getItem('id')}/filter_date/`,{
                "date_field_name" : "time__gte",
                "count_day" : '',
                "delete":false,
            }
        )
            .then(res=>{
                setHired(res.data.results)
            });
        axios.post(`worker/${localStorage.getItem('id')}/filter_date/`,{
                "date_field_name" : "time__gte",
                "count_day" : '',
                "delete":true,
                "accountg": accountg
            }
        )
            .then(res=>{
                setHiredTrue(res.data.results)
            });

    },[accountg, openModal])


    const postData = () => {

        let body = new FormData();
        body.append("docs", file, 'Список для сверки');
        body.append("register_date", `${date}T12:00:00.016954+03:00`);
        body.append("office_id", idOffice.toString());
        body.append("type_id", 'Список для сверки');
        body.append("user", String(localStorage.getItem('id')));
        if (file !== '') {
            const axios = new Axios();
            axios.post(`worker/report/${localStorage.getItem('id')}/`, body)
                .then(res => {
                    axios.post(`worker/${localStorage.getItem('id')}/report/`, {
                        delete: false,
                        office__name: againOffice === '' ?  office : againOffice,
                        accountg: accountg

                    })
                        .then(res => {
                            handleOpenModal();
                            setTimeout(handleCloseModal, 5000);
                            setIsSavePdf(false)
                            dispatch(onAgainSendLetterAC(''))
                            dispatch(getUserIdAgainLetterAC(0))
                            setItems([])
                          //  window.location.reload()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
                .catch(err => {
                    console.log(err);
                })
        }

    };

    useEffect(() => {
        if (number === '' || date === '' || (office === '' && againOffice === '')   || !isSavePdf) {
            setDisabled(true)
        } else {
            setDisabled(false);
        }
    }, [number, date, office, isSavePdf, againOffice]);

    const save = () => {
        setIsSavePdf(true)
        const data = {
            office,
            typeCity,
            dataYear,
            addressCompany,
            date,
            number,
            address,
            items,
            name,
            phone,
            company,
            setFile,
            fullAddress : againSendLetter === '' && user_id_again_letter === 0
              ? fullAddress : fullAddressAgain,
            organizationName,
            organizationRespPerson,
            organizationPhone,
            organizationPositionResp,
            shortOffice: shortOfficeAgain === '' ? shortOffice : shortOfficeAgain
        }
        ListCorrectLetter(data);
    }
const onBack=()=>{
    props.history.push('/letters')
    dispatch(onAgainSendLetterAC(''))
    dispatch(getUserIdAgainLetterAC(0))
}
    // @ts-ignore
    return (<>
        <div style={{margin:'216px auto 10px auto', width:'98%'}}>
            <span className='title_letters'>Список для сверки</span>
            <div className='toolbar'>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={onBack} >
                        Назад
                    </Button>
                </div>
                <div style={{marginBottom: '20px',fontSize: '14px',color:'red'}}><sup>*</sup>Прежде, чем отправить данные в электронный архив -пожалуйста, сохраните документ в PDF формате.</div>
            </div>

            <div className="container_buttonAndSelect">
                <div>
                    <Button variant="contained" color="primary" startIcon={<ArtTrackIcon/>}
                            style={{marginRight: '20px'}}   onClick={save} >
                        Сохранить в PDF
                    </Button>
                    <Button variant="contained"
                            disabled={isDisabled} onClick={postData}>
                        Сохранить
                    </Button>
                </div>
            
                <div>
                    <span style={{fontSize:"14px"}}>
                        Для заполнение выберите категорию запаса: 
                    </span>
                    <select name="select" id="select_id"  className='mySelect'  onChange={(e:any) => (setAccountg(+e.target.value))}>
                        <option value={0} key={0 + '3dg'}>Выбор</option>
                        {dataSelect.map((item: any) => {
                            return <option value={Number(item.id)} key={item.id + '3dg'}> {item.name}</option>
                        })}
                    </select>
                </div>
            </div>
           
            <div className='letter'>
                <div className='header'>
                    <div>
                       
                    </div>
                    <div>
                        <span>Военному комиссару<br/></span>
                        <AutocompleteOffice
                            handleChangeOfficeName={handleChangeOffice}
                            setOfficeName={setOffice}
                            againOffice={againOffice}
                        />
                        {fullAddress.address &&
                        <span className='underHeader'>
                            <br/>
                             {fullAddress.address}
                        </span>
                        }
                    </div>
                </div>

                <div className='descriptionList'>
                    <div className="descriptionList-text">
                        <div>
                            <b>СПИСОК</b><br/>

                            <b>военнообязанных и призывников, работающих в <span style={{fontSize: "13px"}}>{organizationName}</span> </b>
                            <br/>  

                            <b>для сверки учетных сведений о воинском учете, содержащихся в личных карточках</b><br/> 

                            <b>Адрес организации: <span style={{fontSize: "13px"}}>{addressCompany}</span></b> 
                            <br/>

                            <b>Ответственный за ведение воинского учета: <span style={{fontSize: "13px"}}>{organizationRespPerson}</span></b>
                            <br/>

                            <b>(тел. <span style={{fontSize: "13px"}}>{organizationPhone}</span>)</b>

                        </div>
                    </div>
                </div>

                <div className='table'>
                    <table className="tg">
                        <thead>
                            <tr className='table-header'>
                                <th className="tg-1">№ <br/> п/п</th>
                                <th className="tg-1">Воинское звание, ВУС и код должности, номер военного билета</th>
                                <th className="tg-1">Фамилия, имя, отчество, идентификационный номер, номер паспорта, дата выдачи</th>
                                <th className="tg-1">Дата рождения</th>
                                <th className="tg-1">Категория запаса<br/> (для ПСС)</th>
                                <th className="tg-1">Образование (наименование учреждения образования, год окончания, специальность по диплому)</th>
                                <th className="tg-1">Дата приема на работу<br/> Должность</th>
                                <th className="tg-1">Регистрация по месту жительства, по месту пребывания, телефон</th>
                                <th className="tg-1">Семеное положение</th>
                                <th className="tg-1">Предназначен в команду</th>
                            </tr>
                        </thead>
                        <tbody>
                        {items.map((item: any, number: number) => {
                            return (<tr key={number + 1}  className={'letter-columns-td'}>
                                <td className="tg-2">{number + 1}</td>
                                {/*<td className="tg-2">{item.letter_base}<br/>*/}
                                <td className="tg-2"> {item.accountg_id === 4 ? 'Призывник' :
                                    <>{item.grade}<br/><br/>
                                        {item.wyc ? item.wyc : '' }
                                    </>
                                }</td>
                                <td className="tg-2">{item.full_name}<br/>{item.pass_number}<br/>{item.passport}<br/>{item.pass_date}</td>
                                <td className="tg-2">{item.date_of_birth ? item.date_of_birth.split('-').reverse().join('-') : ''}</td>
                                <td className="tg-2">{item.accountg}</td>
                                <td className="tg-2"> {item.education ? item.education : ''}
                                    <br/> {item.education_full ? item.education_full : ''}
                                    <br/> {item.edu_specialization ? item.edu_specialization : ''}
                                    {/*<br/> {item.edu_qualification ? item.edu_qualification : ''}*/}
                                    <br/> {item.edu_dop_full ? item.edu_dop_full : ''}
                                    <br/> {item.edu_dop_specialization ? item.edu_dop_specialization : ''}
                                </td>
                                <td style={{whiteSpace: 'pre-line', width:'200px'}}>
                                    {item.position ? `${item.position} \n Дата приёма: ${item.add_military ? item.add_military.split('-').reverse().join('-') : ''}
                                    ${item.order_military ? 'Приказ № ' + item.order_military : ''} 
                                         ${item.date_add_military ? 'от ' + item.date_add_military.split('-').reverse().join('-') : ''} ` :
                                        item.education}  
                                </td>
                                <td className="tg-2">{`
                                ${item.registered_address ? item.registered_address+'\n(Регистрация по месту жительства )' :''}
                                ${item.address ? item.address+'\n(Регистрация по месту пребывания)' : ''}
                                \n${item.home_phone ? 'дом. ' + item.home_phone + ',' : ''} 
                                \n ${item.work_phone ? 'рабоч. ' + item.work_phone : ''}
                                \n ${item.phone ? 'моб. ' + item.phone : ''}`}
                                </td>

                                


                                <td className="tg-2">{item.marital_status ? <span>{item.marital_status}
                                    <br/>
                                    {item.family_half && item.family ? `Состав семьи: \n супруга (супруг): \n ${item.family_half} \n ${item.family}` :
                                        !item.family_half && item.family ? `Состав семьи: \n ${item.family}` :
                                            item.family_half && !item.family ? `Состав семьи: \n супруга (супруг): \n ${item.family_half}` : ''}
                                    </span>:<span></span>}
                                </td>
                                <td className="tg-2">{!item.special ? "-" : item.specialN }</td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>

                <div className='footersListText_'>
                    <b>
                        Примечание. Дата проведения сверки согласно выписки из плана сверки личных 
                        карточек призывников и военнообязанных с учетными данными военных комиссариатов 
                        районов города Минска 
                            <input 
                                type="text" 
                                className='number-input'
                                value={typeCity} 
                                onChange={(e: any) => setTypeCity(e.target.value)}
                            />
                            202
                            <input 
                                type="text" 
                                className='number-input-dataYear'
                                value={dataYear} 
                                onChange={(e: any) => setDataYear(e.target.value)}
                            /> г.
                    </b>
                </div>

                <div className='footersList_'>
                    <div>
                        <b>Ответственный за ведение воинского учета</b>
                    </div>
                    <div className={'nameList_styles'}>
                        {organizationRespPerson}
                    </div>
                </div>
            </div>

            <SuccessfulSaveModal
                message={'Письмо о становке на Учёт отправлено в '}
                open={openModal}
                handleClose={handleCloseModal}
                office={office}
                againOffice={againOffice}
            />
        </div>
        </>
    )
}

// export default NewLetter;
const mapStateToProps = (state: any) => ({
    user: state.user,
    handleChangeStructure: state.handleChangeStructure,
});

const mapActionsToProps = {
    // loginUser
};
export default connect(mapStateToProps, mapActionsToProps)(ListForCorrect)


const HiredPersons=(props:any)=>{
    const sorting=(arr:any[])=>{
        arr.sort((a, b) => a.full_name > b.full_name ? 1 : -1);
    };
    sorting(props.hired);
    let hiredWithoutFired=props.hired.filter((i: {basic_military_id:number })=>{
        return i.basic_military_id !==5
    })

    const columns = [
        { field: 'id', headerName: '№', width: 70 },
        { field: 'full_name', headerName: 'ФИО', width: 270 },
        { field: 'war_name', headerName: 'Военкомат', width: 270 },
    ];
    let rows:any[]=[];
    let nextI=1
    for(let i of hiredWithoutFired) {
        rows=[...rows,{id:nextI,full_name:i.full_name,war_name:i.office}]
        nextI++
    }

    return (<>
            {hiredWithoutFired &&
            <div style={{ height: 400, width: '100%' }}>
                <div style={{fontWeight:'bold'}}>Список сотрудников, принятых на работу, по которым еще не отправлены письма</div>
                <DataGrid rows={rows} columns={columns} pageSize={5} disableColumnMenu={true}  />
            </div>
            }
        </>
    );

}
