import React, { useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Axios from '../../utils/axios'

import './TableAllUsers.css'

interface FormControlSelectProps {
    selectBy: any,
    setSelectGrade: any,
    selectedGrade: any,
    handleClickOpen: any,
    valueAccountG: any,
    setValueAccountG: any,
    setSearchSet:any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

export const FormControlSelect: React.FC<FormControlSelectProps> = ({ selectBy, selectedGrade,
         setSelectGrade, handleClickOpen, valueAccountG, setValueAccountG,setSearchSet }: FormControlSelectProps) => {
    const classes = useStyles();
    const [selected, setSelect] = React.useState('full_name');

    const [ranks, setRanks] = React.useState([
        {title:'Картотека',name:'all',active:false},
        {title:'Офицеры запаса',name:'officers',active:false},
        {title:'Офицеры запаса ОГБ',name:'officers_ogb',active:false},
        {title:'Прапорщики, сержанты и солдаты запаса',name:'soldiers',active:false},
        {title:'Призывники',name:'conscripts',active:false},
        {title:'Уволены/сняты с учета',name:'fired',active:false},
    ]);
    const [value, setValue] = React.useState('');
    const [firstAge, setFirstAge] = React.useState('');
    const [secondAge, setSecondAge] = React.useState('');

    const [listAccountG, setListAccountG] = useState([]);

    const handleChangeAccountG = (event: any) => {
        setValueAccountG(event.target.value);
    };

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelect(event.target.value as string);
    };

    const handleChangeSelectedGrade = (event: any) => {
        setSelectGrade(event.target.name);
        let newRankArr=ranks.map((i:any)=>{
            if(event.target.name===i.name) {
                return {
                    ...i,active:!i.active
                }
            } else return {
                ...i,active:false
            }
        })
   setRanks(newRankArr)
    };

    const handleChangeTextField = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
    };

    const localSelectBy = () => {
        selectBy(selected, value, { firstAge, secondAge });
    };

    useEffect(() => {
        const axios = new Axios();
        axios.get(`library/accountg/`)
            .then(res => {
                const result = res.data.results as any;
                result.unshift({ id: 0, name: 'Все' })
                setListAccountG(result);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);
    useEffect(()=>{
        return valueAccountG !==0 || firstAge!=='' || secondAge !=='' || value !=='' ? setSearchSet(true) : setSearchSet(false)
    },[valueAccountG,firstAge,secondAge,value])
    return (
        <div className='formControl'>
            <div>
                <Button variant="outlined" onClick={() => { handleClickOpen() }} >
                    Вид
                </Button>
            </div>
            <div className='formControl-right'>
                <div className="search">
                    <div className="search-box">
                        {ranks.map((item,index)=>{
                            return <button className={item.active ? 'active-rank' : 'rank-button'} key={index} name={item.name} onClick={handleChangeSelectedGrade}>{item.title}</button>
                        })}

                    </div>
                </div>
<div className="search">
                <div className="search">
                    <div className="search-box">
                        <InputLabel id="demo-simple-select-label">Группа учета</InputLabel>
                        <Select
                            id="document-type"
                            value={valueAccountG}
                            onChange={handleChangeAccountG}
                        >
                            {listAccountG ? listAccountG.map((item: any) => {
                                return <MenuItem value={item.id} key={item.id + 'listAccountG'}>{item.name}</MenuItem>
                            }) : null}
                        </Select>
                    </div>
                </div>

                <div className='wrapp-age'>
                    Возраст:
                <div className='age'>
                        <TextField id="age_first" className="filterAge" label="От" onChange={(e: any) => setFirstAge(e.target.value)} />
                    </div>
                    —
                <div className='age'>
                        <TextField id="age_second" className="filterAge" label="До" onChange={(e: any) => setSecondAge(e.target.value)} />
                    </div>
                </div>

                <div className="search">
                    <div className="search-box">
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selected}
                            onChange={handleChange}
                        >
                            <MenuItem value={'grade__name'}>Звание</MenuItem>
                            <MenuItem value={'full_name'}>ФИО</MenuItem>
                            <MenuItem value={'date_of_birth'}>Дата рождения</MenuItem>
                            <MenuItem value={'office__short_name'}>Военкомат</MenuItem>
                            <MenuItem value={'position__name'}>Должность</MenuItem>
                            <MenuItem value={'marital_status__name'}>Семейное положение</MenuItem>
                            <MenuItem value={'education__name'}>Образование</MenuItem>
                            <MenuItem value={'address'}>Адрес</MenuItem>
                            <MenuItem value={'special'}>Бронь</MenuItem>
                            <MenuItem value={'family_half'}>Состав семьи</MenuItem>
                        </Select>
                    </div>
                    <div className="search-box">
                        <TextField id="standard-basic" label="Поиск" onChange={handleChangeTextField} />
                    </div>
                    <div className="search-box">
                        <Button onClick={localSelectBy}>Поиск</Button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
