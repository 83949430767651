import React, {useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Axios from '../../utils/axios';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import './SettingsModal.css';


const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}


const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',

    },

    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 450,
        maxHeight: 320,
        'body': {
            width: '100%'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    linkTypeReport: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    btnUpdate: {
        marginLeft: '10px'
    }
}));

const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const useStylesTable = makeStyles((theme: Theme) => createStyles({

    container: {
        maxHeight: 440,
    },
    paper: {
        marginBottom: theme.spacing(2),
    },
    table: {
        width: 100,
        textAlign: 'left',
        minWidth: 400,

    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    linkTypeReport: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    btnUpdate: {
        marginLeft: '10px'
    },
    body: {
        width: '100%'
    }
}));

const SettingsModalForDocuments = ({
                                       open, handleClose, columns, setColumns,
                                       rowsPerPage, page, setItems,body
                                   }: any) => {


    const classes = useStyles();
    const classesTable = useStylesTable();

    const [selected, setSelected] = React.useState({
        id: '',
        index: null as any
    });

    const [upVisible, setUpVisible] = React.useState(false);
    const [downVisible, setDownVisible] = React.useState(false);


    const save = () => {
        const axios = new Axios();
        let data;
       if(body==='regulations') {
           data={
               delete: false,
               docs__contains: "",
               order: "-docs",
               type__name: "Нормативный документ",
           }
       } else if (body==='reports') {
           data={
               entering: false,
               order: "-register_date"
           }
       } else if(body==='documents') {
           data = {
               entering: true,
               order: "-register_date"
           }
       }
        axios.post(`worker/report/${localStorage.getItem('id')}/filter/?page=${page + 1}&page_size=${rowsPerPage}`, data)
            .then(res => {
                setItems(res.data.results);
                handleClose()
                //props.setPage(0);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleClick = (event: React.MouseEvent<unknown>, idObj: string, index: number) => {

        let arr=columns.map((i:any)=>{
            if(i.id===idObj) {
                return {...i,isSelected:!i.isSelected}
            } else return i
        })
        setColumns(arr)
        // setColumns((prevArray: any) =>
        //     prevArray.reduce((acc: any, {id, isSelected, ...rest}: any) => {
        //         if (id === idObj) {
        //             return [
        //                 ...acc,
        //                 {
        //                     ...rest,
        //                     id,
        //                     isSelected: !isSelected,
        //                 },
        //             ];
        //         }
        //
        //         return [
        //             ...acc,
        //             {
        //                 ...rest,
        //                 id,
        //                 isSelected
        //             },
        //         ];
        //     }, []),
        // );
    };

    const upColumn = () => {
        const index = selected.index;

        if (index !== null && index !== 0) {
            setColumns((prevState: any) => {
                let data = [...prevState];

                let temp = data[index - 1];
                data[index - 1] = data[index];
                data[index] = temp;

                setSelected((prevState: any) => ({
                    ...prevState,
                    index: index - 1,
                }));
                return data;
            })
        }
    }

    useEffect(() => {
        //debugger;
        if (selected.index === null) {
            setUpVisible(false);
            setDownVisible(false);
        }
        if (selected.index !== null && selected.index === 0) {
            setUpVisible(false);
            setDownVisible(true);
        }
        if (selected.index !== null && selected.index === columns.length - 1) {
            setUpVisible(true);
            setDownVisible(false);
        }
        if (selected.index !== null && selected.index !== 0 && selected.index !== columns.length - 1) {
            setUpVisible(true);
            setDownVisible(true);
        }


    }, [selected]);

    const downColumn = () => {
        const index = selected.index;
        if (index !== null && index != columns.length - 1)
            setColumns((prevState: any) => {
                let data = [...prevState];

                let temp = data[index + 1];
                data[index + 1] = data[index];
                data[index] = temp;

                setSelected((prevState: any) => ({
                    ...prevState,
                    index: index + 1,
                }));

                return data;
            })
    }

    const onDragEnd = (result: any) => {

        if (!result.destination) {
            return;
        }

        const items = reorder(
            columns,
            result.source.index,
            result.destination.index
        );

        setSelected({
            id: result.draggableId,
            index: result.destination.index
        });

        setColumns(items as any);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"

        >
            <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                Настройки таблицы
            </DialogTitle>
            <DialogContent>
                <div className='wrapp-body-settingsModal'>
                    <div className={classes.root}>
                        <Paper className={classesTable.paper}>
                            <TableContainer className={classesTable.container}>
                                <Table
                                    className={classesTable.table}
                                    aria-labelledby="tableTitle"
                                    aria-label="enhanced table"

                                >
                                    <TableHead>
                                    </TableHead>
                                    <TableBody>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        //style={getListStyle(snapshot.isDraggingOver)}
                                                    >
                                                        {columns ? columns.map((row: any, index: number) => {
                                                            const isItemChecked = row.isSelected;

                                                            return (
                                                                <Draggable key={row.id} draggableId={row.id}
                                                                           index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <TableRow
                                                                                hover
                                                                                role="checkbox"
                                                                                //aria-checked={isItemSelected}
                                                                                tabIndex={-1}
                                                                                key={getRandomInt(18524)}
                                                                                selected={selected.id === row.id ? true : false}

                                                                            >
                                                                                <TableCell
                                                                                    padding="checkbox"
                                                                                     onClick={(event: any) => handleClick(event, row.id, index)}>
                                                                                    <Checkbox
                                                                                        checked={isItemChecked}
                                                                                        //inputProps={{ 'aria-labelledby': labelId }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    onClick={() => {
                                                                                        selected.id === row.id ? setSelected({
                                                                                            id: '',
                                                                                            index: null
                                                                                        }) : setSelected({
                                                                                            id: row.id,
                                                                                            index: index
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    {row.label}
                                                                                </TableCell>

                                                                            </TableRow>
                                                                        </div>
                                                                    )}
                                                                </Draggable>

                                                            );
                                                        }) : null}

                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className={selected.id !== '' ? 'toolbar-buttons-visible' : 'toolbar-buttons'}>
                        <div className={upVisible ? 'arrowIcon' : 'arrowIcon-not-visible'} onClick={() => {
                            upColumn()
                        }}>
                            <ArrowUpwardIcon/>
                        </div>
                        <div className={downVisible ? 'arrowIcon' : 'arrowIcon-not-visible'} onClick={() => {
                            downColumn()
                        }}>
                            <ArrowDownwardIcon/>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    save()
                }} color="primary">
                    Ок
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default SettingsModalForDocuments;
