import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import userReducer from '../reducers/userReducer';
import companyReducer from '../reducers/companyReducer';
import { reducer as formReducer } from 'redux-form';
import initialFormReducer from '../reducers/formReducer';
import formPersonalCardReducer from '../reducers/formPersonalCardReducer';
import tableSettingsReducer from '../reducers/tableSettingsReducer';
import errorReducer from '../reducers/errorReducer'
import addressesReducer from "../reducers/addressesReducer";
import lettersReducer from "../reducers/lettersReducer";

//import formReducer from '../reducers/formReducer'

const initialState = {};
const middleware = [thunk];

//this is for redux devtool purpose
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
    }
}

const reducer = combineReducers({
    user: userReducer,
    company : companyReducer,
    form : formReducer,
    tableSettings: tableSettingsReducer,
    error: errorReducer,
    initialFormReducer,
    formPersonalCard: formPersonalCardReducer,
    addressesReducer:addressesReducer,
    lettersReducer:lettersReducer
});

const store = createStore(
    reducer,
    initialState,
    compose(applyMiddleware(...middleware))
);
export type RootState = ReturnType<typeof reducer>

export default store;
