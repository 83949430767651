import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Axios from '../../utils/axios';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReportModal from '../Reports/ReportModal';
import ConfirmDialog from './ConfirmDialog';
import style from './Documents.module.css';
import SettingsModalForDocuments from '../TableSettingsModal/SettingsModalForDocuments';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        //maxHeight: '90vh',
        height: 'calc(100vh - 300px)'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    linkTypeReport: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    btnUpdate: {
        marginLeft: '10px'
    }
});

interface Item2 {
    id: string;
    office: string;
    officer: string;
    sergeant: string;
    recruit: string;
}

const formatDate = (date: string) => {
    // let diff = Number(new Date()) - Number(new Date(date));
    const d = new Date(date);

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear();

    return result;
}

export default function DocumentsTable(props: any) {
    const classes = useStyles();

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [id, setID] = React.useState('');

    const [openModal, setOpenModal] = React.useState(false);

    const [stateTable2, setStateTable2] = React.useState<Item2[]>([]);
    const [stateTable1, setStateTable1] = React.useState({
        affiliations: 'нет',
        all_worker: '',
        all_war: '',
        officer: '',
        recruit: '',
        war_mp: '',
        officer_mp: '',
    });
    const [rowId, setRowId] = useState('');
    const [open, setOpen] = React.useState(false);

    const handleClickOpenModal = () => {
        setOpenModal(true);

        const axios = new Axios();
        axios.get(`worker/recruit/${localStorage.getItem('id')}`)
            .then(res => {

                setStateTable1({
                    affiliations: 'нет',
                    all_worker: res.data.all_worker,
                    all_war: res.data.all_war,
                    officer: res.data.officer,
                    recruit: res.data.recruit,
                    war_mp: res.data.war_mp,
                    officer_mp: res.data.officer_mp,
                })

                setStateTable2(res.data.table)

            })
            .catch(err => {
                console.log(err)
            })
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setRowsPerPage(+event.target.value);
        props.setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = props.orderBy === property && props.order === 'asc';
        props.setOrder(isAsc ? 'desc' : 'asc');
        props.setOrderBy(property);
    };

    const _orderBy = (id: any) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, id)
        props.setOrderBy(id);
    };

    const updateItem = (e: any) => {

        setID(e.currentTarget.id);
        setOpenConfirm(true);
    };
    const handleClickOpen = (id: string) => {
        setRowId(id)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const deletedLetter = () => {
        handleClose();
        const axios = new Axios();
        axios.delete(`worker/report/${localStorage.getItem('id')}/${rowId}/`, {entering:true})
            .then(res => {
                props.setUpdateTable(new Date().getMilliseconds())
            })
    }

    return (
        <div style={{margin:'0 auto', width:'100%'}}>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {props.columns ? props.columns.map((column: any) => {
                                    if (column.isSelected)
                                        return (
                                            <TableCell
                                                key={column.id + getRandomInt(8526)}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                <TableSortLabel
                                                    active={props.orderBy === column.id}
                                                    direction={props.orderBy === column.id ? props.order : 'asc'}
                                                    onClick={_orderBy(column.id)}
                                                >
                                                    {column.label}
                                                    {
                                                        props.orderBy === column.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null
                                                    }
                                                </TableSortLabel>
                                            </TableCell>
                                        )
                                }) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.items.map((row: any, number: number) => {

                                const koff2 = (props.page + 1) * props.rowsPerPage - props.rowsPerPage + 1;
                                const koff = props.order === 'desc' ? koff2 : props.countItems - koff2;

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id + getRandomInt(41526)}>
                                        {props.columns ? props.columns.map((column: any) => {
                                            const columnID = column.id;
                                            const value = row[column.id];
                                            if (column.isSelected) {
                                                if (columnID === 'type') {
                                                    return <TableCell key={column.id + getRandomInt(15741)} align={column.align}>
                                                        <a className={classes.linkTypeReport} href={row.docs} download={row.type.name + row.type.id}>
                                                            {row.type.name}
                                                        </a>
                                                        {row.flag ? <span className={style.date}>{formatDate(row.flag_date)}</span> :
                                                            <button className={style.btnUpdate} id={String(row.id)} onClick={updateItem}>Подтвердить выполнение</button>
                                                        }
                                                    </TableCell>
                                                }
                                                if (columnID === 'register_date') {
                                                    return <TableCell key={column.id + getRandomInt(22222)} align={column.align}>
                                                        {formatDate(row.register_date)}
                                                    </TableCell>
                                                }
                                                if (columnID === 'register_date_end') {
                                                        return <TableCell key={column.id} align={column.align}>
                                                            {row.register_date_end ? formatDate(row.register_date_end) : ''}
                                                        </TableCell>
                                                }
                                                if (columnID === 'office__short_name') {
                                                    return <TableCell key={column.id} align={column.align}>
                                                        {row.office}
                                                    </TableCell>
                                                }
                                                if (columnID === "delete") {
                                                    return <TableCell key={column.id} align={column.align}>
                                                        <button style={{cursor: 'pointer'}}
                                                                onClick={() => {
                                                                    handleClickOpen(row.id)
                                                                }}>Удалить
                                                        </button>
                                                    </TableCell>
                                                }
                                                if (columnID === 'type__name') {
                                                    return <TableCell key={column.id} align={column.align}>
                                                        {row.type.name}
                                                    </TableCell>
                                                }
                                                if (columnID === "docs")  {
                                                    return <TableCell key={column.id} align={column.align}>
                                                        {row.flag_date ?  <a className={style.titleFlagDate}
                                                                     onClick={()=>{props.setOpenChangeFlagDate(true);
                                                                         props.setAddedData(row)}}
                                                                                style={{color:'black'}}
                                                                                href={row.docs}>
                                                                {decodeURI(row.docs.slice(row.docs.indexOf('docs/')+5))}</a>
                                                            :
                                                            <a href={row.docs} style={{color:'black'}}
                                                              download={row.type.name + row.type.id}
                                                            >{decodeURI(row.docs.slice(row.docs.indexOf('docs/')+5))}</a>
                                                        }

                                                    </TableCell>
                                                }
                                                if (columnID === 'flag_date') {
                                                    return <TableCell key={column.id} align={column.align}>
                                                        {row.flag_date ? formatDate(row.flag_date) : 'нет'}
                                                    </TableCell>
                                                }
                                                if (columnID === 'id') {
                                                    return <TableCell key={column.id + getRandomInt(22222)} align={column.align}>
                                                        {props.order === 'asc' ? koff - number + 1 : koff + number}
                                                    </TableCell>
                                                }
                                                if (columnID === 'user') {
                                                    return <TableCell key={column.id + getRandomInt(22222)} align={column.align}>
                                                        {row.name ? row.name : row.user_name}
                                                    </TableCell>
                                                }
                                                return (
                                                    <TableCell key={column.id + getRandomInt(698741)} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            }
                                        }) : null
                                        }
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Вы действительно хотите удалить
                        документ ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            У вас есть возможность восстановить документ в течение 7 дней.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Нет
                        </Button>
                        <Button onClick={deletedLetter} color="primary" autoFocus>
                            Да
                        </Button>
                    </DialogActions>
                </Dialog>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    labelRowsPerPage='Показывать по:'
                    backIconButtonText='Предыдущие'
                    nextIconButtonText='Следующие'
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                    count={props.countItems}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <ReportModal
                handleClickOpen={handleClickOpenModal}
                handleClose={handleCloseModal}
                open={openModal}
                stateTable2={stateTable2}
                stateTable1={stateTable1}
            />

            <ConfirmDialog
                open={openConfirm}
                setOpen={setOpenConfirm}
                id={id}
                setUpdateTable={props.setUpdateTable}
            />
            <SettingsModalForDocuments
                open={props.openModalSettings}
                handleClose={props.handleCloseSettings}
                columns={props.columns}
                setColumns={props.setColumns}
                essence={3}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                setItems={props.setItems}
                body={'documents'}
            />
        </div>
    );
}


// <Checkbox id={String(row.id)} disabled icon={<DoneIcon />} />
