//@ts-nocheck
import React, {useState, useEffect} from 'react';
import Header from '../HeaderLandingPage'
import Footer from '../FooterLandingPage';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Axios from '../../../utils/axios';
import {Addresses} from "../BodyLandingPage";
import Input from 'react-phone-number-input/input';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store/store";
import {getDataAddressAC} from "../../../redux/reducers/addressesReducer";
import SuccessfulSaveModal from "../../../components/PersonalCard/SuccessfulSaveModal";
import {NavLink} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, .03)',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,

        },
    }),
);

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}

const FAQPage = () => {
    const classes = useStyles();
    const [body, setBody] = useState([]);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [question, setQuestion] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage] = useState('');

    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        const axios = new Axios();

        axios.get(`control/page/7/`)
            .then(res => {
                setBody(res.data.page);
            })
            .catch(err => {
                console.log(err)
            })

    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const sendContactData = () => {
        const data = {
            phone: phone,
            question: question,
            name: name
        }
        const axios = new Axios();
        axios.post(`user/send/`, data)
            .then(res => {
                setMessage('Менеджер свяжется с Вами в ближайшее время');
                handleOpenModal();
                setTimeout(handleCloseModal, 4000);
            })
            .catch(err => {
                setMessage('Произошла ошибка. Пожалуйста, попробуйте ещё раз.');
                handleOpenModal();
                setTimeout(handleCloseModal, 4000);
            })
    };

    interface AddressData {
        addressData: any[]
    };

    const getAddressData = (state): AddressData => state.addressesReducer.addressData
    const addressData = useSelector<RootState>(getAddressData);
    const dispatch = useDispatch();

    useEffect(() => {
        const axios = new Axios();
        axios.get(`control/page/6/`)
            .then(res => {
                dispatch(getDataAddressAC(res.data.page))
            })
    }, []);


    return (<>
            <div className='wrapper_header_'>
                <Header/>
            </div>
            <div className='wrapper_faq'>
                <div className='provisions_pre_title_4'>
                    <NavLink to='/' style={{textDecoration: 'none', color: 'black'}}><span>Главная</span></NavLink>
                    <span>/</span>
                    <span style={{color: 'gray'}}>Часто задаваемые вопросы</span>
                </div>
                <div className="provisions_title_2">
                    ответы на часто задаваемые вопросы
                </div>
                <div className='provisions_body_2'>
                    {
                        body.map((item: any) => {
                            return <Accordion key={getRandomInt(4567890)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>{item.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {item.text.split(`\r\n`).map((i: any) => {
                                            const index = i.indexOf('http');
                                            if(index>0) {
                                                const buf = i.slice(index).split(' ') as any;
                                                const link = buf.shift();
                                                return <p key={getRandomInt(55555)} className='p_second_faq'>
                                                    {i.substr(0, index)} <a href={link}>{link}</a> {buf.join(' ')}
                                                </p>;
                                            } else return <p key={getRandomInt(55555)} className='p_second_faq'>{i}</p>;
                                        })}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        })
                    }
                    {/*<Accordion>*/}
                    {/*    <AccordionSummary*/}
                    {/*        expandIcon={<ExpandMoreIcon/>}*/}
                    {/*        aria-controls="panel3a-content"*/}
                    {/*        id="panel3a-header"*/}
                    {/*    >*/}
                    {/*        <Typography className={classes.heading}>{body.length + 1}. Каков предельный возраст*/}
                    {/*            состояния в запасе?</Typography>*/}
                    {/*    </AccordionSummary>*/}
                    {/*    <AccordionDetails>*/}
                    {/*        <Typography>*/}
                    {/*            <p className='p_second_faq'>*/}
                    {/*                Запас военнообязанных по возрастному признаку делится на три разряда запаса.*/}
                    {/*                Предельным возрастом состояния военнообязанного в запасе является предельный возраст*/}
                    {/*                его состояния в запасе третьего разряда.*/}
                    {/*            </p>*/}
                    {/*            <p className='p_second_faq'>*/}
                    {/*                Устанавливается следующий предельный возраст состояния военнообязанных в запасе по*/}
                    {/*                разрядам запаса:*/}
                    {/*            </p>*/}
                    {/*            <table className="tg">*/}
                    {/*                <thead>*/}
                    {/*                <tr>*/}
                    {/*                    <th className="tg-amwm" rowSpan={2}><b>Состав запаса</b></th>*/}
                    {/*                    <th className="tg-1wig" colSpan={3}><b>Разряд запаса</b></th>*/}
                    {/*                </tr>*/}
                    {/*                <tr>*/}
                    {/*                    <td className="tg-1wig"><b>первый</b></td>*/}
                    {/*                    <td className="tg-1wig"><b>второй</b></td>*/}
                    {/*                    <td className="tg-1wig"><b>третий</b></td>*/}
                    {/*                </tr>*/}
                    {/*                </thead>*/}
                    {/*                <tbody>*/}
                    {/*                <tr>*/}
                    {/*                    <td className="tg-0lax">Солдаты и матросы, сержанты и старшины</td>*/}
                    {/*                    <td className="tg-0lax">до 35 лет</td>*/}
                    {/*                    <td className="tg-0lax">до 45 лет</td>*/}
                    {/*                    <td className="tg-0lax">до 50 лет</td>*/}
                    {/*                </tr>*/}
                    {/*                <tr>*/}
                    {/*                    <td className="tg-0lax">Прапорщики и мичманы, младшие офицеры</td>*/}
                    {/*                    <td className="tg-0lax">до 45 лет</td>*/}
                    {/*                    <td className="tg-0lax">до 50 лет</td>*/}
                    {/*                    <td className="tg-0lax">до 55 лет</td>*/}
                    {/*                </tr>*/}
                    {/*                <tr>*/}
                    {/*                    <td className="tg-0lax">Майоры и подполковники (капитаны 3-го и 2-го ранга)</td>*/}
                    {/*                    <td className="tg-0lax">до 50 лет</td>*/}
                    {/*                    <td className="tg-0lax">до 55 лет</td>*/}
                    {/*                    <td className="tg-0lax">до 60 лет</td>*/}
                    {/*                </tr>*/}
                    {/*                <tr>*/}
                    {/*                    <td className="tg-0lax">Полковники (капитаны 1-го ранга)</td>*/}
                    {/*                    <td className="tg-0lax">до 55 лет</td>*/}
                    {/*                    <td className="tg-0lax"></td>*/}
                    {/*                    <td className="tg-0lax">до 60 лет</td>*/}
                    {/*                </tr>*/}
                    {/*                <tr>*/}
                    {/*                    <td className="tg-0lax">Высшие офицеры</td>*/}
                    {/*                    <td className="tg-0lax">до 60 лет</td>*/}
                    {/*                    <td className="tg-0lax"></td>*/}
                    {/*                    <td className="tg-0lax">до 65 лет</td>*/}
                    {/*                </tr>*/}
                    {/*                </tbody>*/}
                    {/*            </table>*/}
                    {/*            <p className='p_second_faq'>*/}
                    {/*                Военнообязанные женского пола независимо от присвоенных им воинских званий*/}
                    {/*                зачисляются в запас третьего разряда. Устанавливается следующий предельный возраст*/}
                    {/*                состояния их в запасе:*/}
                    {/*            </p>*/}
                    {/*            <p className='p_second_faq'>*/}
                    {/*                - для военнообязанных женского пола, имеющих воинские звания прапорщиков, мичманов*/}
                    {/*                или офицеров, – 50 лет;*/}
                    {/*            </p>*/}
                    {/*            <p className='p_second_faq'>*/}
                    {/*                - для остальных военнообязанных женского пола – 45 лет.*/}
                    {/*            </p>*/}
                    {/*            <p className='p_second_faq'>*/}
                    {/*                Граждане, состоящие в запасе и достигшие предельного возраста состояния в запасе или*/}
                    {/*                признанные негодными к военной службе с исключением с воинского учета по состоянию*/}
                    {/*                здоровья, переводятся военным комиссариатом в отставку и исключаются с воинского*/}
                    {/*                учета.*/}
                    {/*            </p>*/}
                    {/*        </Typography>*/}
                    {/*    </AccordionDetails>*/}
                    {/*</Accordion>*/}
                </div>
                <div>
                    <div>
                        <div className='landing_questions'>остались вопросы?</div>
                        <div className='landing_questions_connect'>Наш менеджер свяжется с Вами и ответит на все
                            интересующие вопросы
                        </div>
                        <div className='landing_wrapper_contacts'>
                            <div className='form_control_landing'>
                                <div><input name="text" placeholder='Имя' value={name}
                                            onChange={(e: any) => {
                                                setName(e.target.value)
                                            }}/></div>
                                <div><Input
                                    country="BY"
                                    international
                                    withCountryCallingCode
                                    value={phone}
                                    onChange={setPhone}/></div>
                                <div><textarea name="text" placeholder='Вопрос или описание проблемы' value={question}
                                               onChange={(e: any) => {
                                                   setQuestion(e.target.value)
                                               }}/></div>
                                <div>
                                    <button name="text" onClick={sendContactData} className='btn_call'> Позвоните мне
                                    </button>
                                </div>
                            </div>
                            <Addresses addressData={addressData}/>
                        </div>
                        <div style={{position: 'relative'}} className='faq'><Footer/></div>
                    </div>
                </div>
            </div>
            <SuccessfulSaveModal
                open={openModal}
                handleClose={handleCloseModal}
                message={message}
            />
        </>
    )
}
export default FAQPage;
