import React, {useEffect, Suspense, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Axios from '../../utils/axios';
import {FormControlSelect} from './FormControlSelect';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SettingsModal from '../TableSettingsModal/SettingsModal'
import SelectVersionCardModal from './SelectVersionCardModal';
import './TableAllUsers.css';
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {getUserIdAgainLetterAC, onAgainSendLetterAC} from "../../redux/actions/userActions";


type Order = 'asc' | 'desc';


interface Column {
    id: 'id' | 'grade' | 'full_name' | 'date_of_birth' | 'status' | 'office' | 'requirement' | 'marital_status' |
        'education' | 'address' | 'registered_address' | 'military' | 'wyc' | 'worker' | 'time_end';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center';
    format?: (value: number) => string;
    maxWidth?: number;
    isSelected?: boolean
}

export interface Item {
    id: string | number;
    grade: string;
    full_name: string;
    date_of_birth: string | number | Date;
    office: string;
    status: string;
    prescription: string;
    marital_status: string;
    education: string;
    address: string;
    report: boolean;
    time: string | number | Date;
    wyc: string;
    registered_address: string;
    military: string;
    worker: boolean;
}


const useStyles = makeStyles({
    root: {
        width: '100%',
        marginBottom: '100px'
    },
    container: {
        height: 'calc(100vh - 250px)'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    colorRed: {
        background: 'rgba(199, 0, 0, 0.2)',
    },
    colorOrange: {
        background: 'rgba(255, 168, 18, 0.2)',
    },
    colorBlack: {
        background: 'rgba(2, 2, 2, 0.2)',
    },
});


const formatDate = (date: string) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear();

    return result;
}

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}

export default function PeopleTable({nameOfficeDefault, props, isUpdate}: any) {
    const classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [countItems, setCountItems] = React.useState(0);
    const [items, setItems] = React.useState<Item[]>([]);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState("full_name");

    const [defaultColumns, setDefaultColumns] = useState([] as any);

    const [selectedGrade, setSelectGrade] = React.useState('');

    const [openSelectCardModal, setOpenSelectCardModal] = useState(false);
    const [isNew, setNew] = useState('');
    const [defCol, setDefCol] = useState([]);
    const [searchSet, setSearchSet] = useState(false);
//@ts-ignore
    const isUpdateCard = useSelector(state => state.tableSettings.isUpdateCard)

    const handleClickOpenSelectCardModal = () => {
        setOpenSelectCardModal(true);
    };

    const handleCloseSelectCardModal = () => {
        setOpenSelectCardModal(false);
    };

    const [filter, setFilter] = React.useState({
        name: '',
        value: ''
    }as any);

    const [filterDateOfBirdth, setFilterDateOfBirdth] = React.useState({
        date_of_birth__gte: null as any,
        date_of_birth__lte: null as any
    });


    const [openModal, setOpenModal] = useState(false);

    const [columns, setColumns] = useState(null as any);

    const [valueAccountG, setValueAccountG] = React.useState(0);

    const [booked,setBooked]=useState(false)



    const handleClickOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        const axios = new Axios();
        axios.get(`user/me/`)
            .then(res => {
                // const result = Object.keys(res.data.setting['4']);
                const data = res.data.setting['4']
                let result = [data.id.name, data.grade.name, data.full_name.name,
                    data.date_of_birth.name, data.wyc.name, data.office.name, data.registered_address.name, data.address.name,
                    data.education.name, data.marital_status.name, data.family.name,
                    data.position.name, data.phone.name]
                setDefaultColumns(result)
            })
    }, []);

    useEffect(() => {
        const axios = new Axios();
        axios.get(`worker/field/4`)
            .then(res => {
                let arr = Object.values(res.data);

                let defArr = ["№", "Воинское звание", "ФИО", "Дата рождения", "ВУС", "Военкомат", "Место пребывания", "Место жительства (Регистрации)", "Образование", "Семейное положение", "Состав семьи", "Должность. Дата (Принятия/Снятия) с учета", "Телефон"]

                let diff = function (a1: any, a2: any) {
                    return a1.filter((i: any) => !a2.includes(i))
                        .concat(a2.filter((i: any) => !a1.includes(i)))
                }
                defArr = [...defArr, ...diff(arr, defArr)];
                let val = Object.entries(res.data)
                let allArr = defArr.map((i: any) => {
                    return {
                        id: val.filter((v: any) => {
                            return v[1] === i ? v[0] : null
                        }),
                        label: i,
                        isSelected: defaultColumns.filter((d: any) => {
                            return i === d ? true : false
                        })
                    }
                })
                let addedArr = allArr.map((i) => {
                    return {
                        ...i, id: i.id.flat()[0], isSelected: i.isSelected.length === 0 ? false : true
                    }
                })
                setColumns(addedArr);
                // @ts-ignore
                setDefCol(addedArr)
                // setColumns(result);
            })
            .catch(err => {
                console.log(err);
            })
    }, [defaultColumns])

    useEffect(() => {

        let orderByBuf = null as any;

        if (orderBy === 'office') {
            orderByBuf = 'office__name'
        } else if (selectedGrade === 'all') {
            orderByBuf = "-sex, full_name"
        } else if (selectedGrade === 'officers_ogb' || selectedGrade === 'officers') {
            orderByBuf = '-special, -sex, full_name'
        } else if (selectedGrade === 'soldiers') {
            orderByBuf = '-special, -sex, full_name'
        }else if (selectedGrade === 'conscripts') {
            orderByBuf = '-sex, full_name'
        } else if (selectedGrade==='fired') {
            orderByBuf = "-sex, full_name"
        }  else {
            orderByBuf = orderBy;
        }

        let grade: any;
        let gradeFilter = 'grade__name';
        let time_end__isnull: any;
        let delete_: any;
        let accountg__name: any;
        let accountg__id: any;
        let type__: any = ''

        switch (selectedGrade) {

            case 'all':
                grade = 3;
                gradeFilter = 'category';
                delete_ = false;
                type__ = 2;
                setColumns(defCol)
                setBooked(false)
                // let newColArr = columns.map((item: any) => {
                //     if (item.id === 'special_marks') {
                //         return {
                //             ...item, isSelected: true
                //         }
                //     } else return item
                // })
                // setColumns(newColArr)
                break;
            case 'soldiers':
                type__ = 2;
                accountg__id = 3;
                delete_ = false;
                setBooked(true)
                setColumns(defCol)
                break;
            case 'fired':
                grade = 4;
                gradeFilter = 'category';
                setColumns(defCol)
                type__ = 1;
                setBooked(false)
                break;
            case 'officers':
                type__ = 2;
                grade = 1;
                gradeFilter = 'category';
                accountg__name = 'Офицеры запаса'
                delete_ = false
                setColumns(defCol)
                setBooked(true)
                break;
            case 'officers_ogb':
                type__ = 2;
                grade = 1;
                gradeFilter = 'category';
                accountg__name = 'Офицер запаса ОГБ'
                delete_ = false
                setColumns(defCol)
                setBooked(true)
                break;
            case 'conscripts':
                gradeFilter = 'category';
                type__ = 2;
                accountg__name = 'Призывник';
                delete_ = false
                setColumns(defCol)
                setBooked(false)
                break;
            default:
                setColumns(defCol)
                setBooked(false)
                break;
        }

        let data = null as any;
        let ordered = orderByBuf

        let arr = ordered.split(',').map((i: any) => {
            if (i.indexOf('-') === -1) {
                return `-${i.trim().slice(0)}`
            } else return i
        })

        if (filter.name !== '') {
            data = {
                // order: `${order === `desc` ? `` : `-`}${orderByBuf}`,
                order: order === `desc` ? orderByBuf : arr.join(', '),
                [gradeFilter]: grade,
                time_end__isnull: time_end__isnull,
                accountg__name: accountg__name,
                [filter.name]: filter.value,
                accountg__id: accountg__id,
                date_of_birth__gte: filterDateOfBirdth.date_of_birth__gte ? filterDateOfBirdth.date_of_birth__gte : undefined,
                date_of_birth__lte: filterDateOfBirdth.date_of_birth__lte ? filterDateOfBirdth.date_of_birth__lte : undefined,
                office__name: nameOfficeDefault,
                accountg_id: valueAccountG === 0 ? undefined : valueAccountG,
                delete: delete_,
                type: type__ === '' ? 3 : type__
            };
        } else {
            data = {
                time_end__isnull: time_end__isnull,
                // order: `${order === `desc` ? `` : `-`}${orderByBuf}`,
                order: order === `desc` ? orderByBuf : arr.join(', '),
                [gradeFilter]: grade,
                accountg__name: accountg__name,
                accountg__id: accountg__id,
                date_of_birth__gte: filterDateOfBirdth.date_of_birth__gte ? filterDateOfBirdth.date_of_birth__gte : undefined,
                date_of_birth__lte: filterDateOfBirdth.date_of_birth__lte ? filterDateOfBirdth.date_of_birth__lte : undefined,
                office__name: nameOfficeDefault,
                accountg_id: valueAccountG === 0 ? undefined : valueAccountG,
                delete: delete_,
                type: type__ === '' ? 3 : type__
            };
        }

        const axios = new Axios();
        const user_id = localStorage.getItem('id') || '';
        axios.post(`worker/${user_id}/filter/?page=${page + 1}&page_size=${rowsPerPage}`, data)
            .then(res => {
                setCountItems(res.data.count);
                setItems(res.data.results);
            })
            .catch(err => {
                console.log(err);
            })

    }, [page, rowsPerPage, orderBy, filterDateOfBirdth, filter, order, selectedGrade, valueAccountG, isUpdate, isUpdateCard]);


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const _orderBy = (id: any) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, id);
    };

    const selectBy = (parametr: string, value: string, age?: any) => {
        let firstDate = '';
        let secondDate = '';


        if (age.firstAge || age.secondAge) {
            const today = new Date();
            const todayYear = today.getFullYear();
            const todayMonth = today.getMonth();
            const todayDay = today.getDate();

            const gte = todayYear - Number(age.firstAge);
            const lte = todayYear - Number(age.secondAge);

            firstDate = gte + '-' + (todayMonth < 10 ? '0' + todayMonth : todayMonth) + '-' + (todayDay < 10 ? '0' + todayDay : todayDay);
            secondDate = lte + '-' + (todayMonth < 10 ? '0' + todayMonth : todayMonth) + '-' + (todayDay < 10 ? '0' + todayDay : todayDay);

            if (age.firstAge === '') {
                setFilter({
                    name: `${parametr}__contains`,
                    value:  value==='' ? '' : value[0].toUpperCase() + value.slice(1)
                });
                setFilterDateOfBirdth({
                    date_of_birth__lte: null,
                    date_of_birth__gte: secondDate
                });
            } else if (age.secondAge === '') {
                setFilter({
                    name: `${parametr}__contains`,
                    value:  value==='' ? '' : value[0].toUpperCase() + value.slice(1)
                });
                setFilterDateOfBirdth({
                    date_of_birth__gte: null,
                    date_of_birth__lte: firstDate
                });
            } else {
                setFilter({
                    name: `${parametr}__contains`,
                    value: value[0].toUpperCase() + value.slice(1)
                });
                setFilterDateOfBirdth({
                    date_of_birth__gte: secondDate,
                    date_of_birth__lte: firstDate
                });
            }
        }   else if(parametr==='special' && (value==='нет' || value==='Нет')) {
            setFilter({
                name: `${parametr}`,
                value: false,

            });
        } else if(parametr==='special' && (value.indexOf('да') !==1 || value.indexOf('Да') !==-1)) {
            setFilter({
                name: `${parametr}`,
                value: true
            });
        } else if(parametr==='grade__name' && (value.indexOf('приз') !==-1 || value.indexOf('Приз') !==-1) ) {
            setFilter({
                name: `accountg__name`,
                value: 'Призывник'
            });
        }  else {
            setFilter({
                name: `${parametr}__contains`,
                value: value==='' ? '' : value[0].toUpperCase() + value.slice(1)
            });

            setFilterDateOfBirdth({
                date_of_birth__gte: null,
                date_of_birth__lte: null
            });
        }
        ;
    }

    useEffect(() => {
        if (isNew !== '') {
            window.open(window.location.origin + `${isNew}`, '_blank');
            setNew('');
        }
    }, [isNew])

    useEffect(()=>{
        if(columns !==null ){
            let arr=  columns.filter((i: any) => {
                return i.id === 'special'
            });
            if(arr.length !==0) {
              return  arr[0].isSelected ? setBooked(true) : setBooked(false)
            }
        }
    },[columns])

    const dispatch=useDispatch();

    const sendInfoForAgainLetter=(type:string,user_id:number)=>{
        dispatch(onAgainSendLetterAC(type))
        dispatch(getUserIdAgainLetterAC(user_id))
    }

    return (<>
            {booked &&
            <div className={'note-table-personals'}>* Забронированные сотрудники выводятся в верхней части таблицы и
                выделены фоном</div>
            }
            {searchSet && <div className={'search-workers-cl'}>Найдено {items.length} сотрудник(ов) </div>}
        <div className='_tableAllUser_'>
            <FormControlSelect
                setSearchSet={setSearchSet}
                selectBy={selectBy}
                selectedGrade={selectedGrade}
                setSelectGrade={setSelectGrade}
                handleClickOpen={handleClickOpen}
                valueAccountG={valueAccountG}
                setValueAccountG={setValueAccountG}
            />

            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns ? columns.map((column: any, index: number) => {
                                    if (column.isSelected)
                                        return (
                                            <Suspense fallback={<div>Загрузка...</div>} key={index}>

                                                <TableCell
                                                    key={getRandomInt(453902)}
                                                    align={column.align}
                                                    style={column.id === 'position' ? {minWidth: '191px'} : column.id === 'family' ? {minWidth: '220px'} :
                                                        {minWidth: column.minWidth}}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === column.id}
                                                        direction={orderBy === column.id ? order : 'asc'}
                                                        onClick={_orderBy(column.id)}
                                                    >
                                                        {column.label}
                                                        {
                                                            orderBy === column.id ? (
                                                                <span className={classes.visuallyHidden}>
                                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                </span>
                                                            ) : null
                                                        }
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Suspense>
                                        )
                                }) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row: any, number: number) => {

                                const koff2 = (page + 1) * rowsPerPage - rowsPerPage + 1;
                                const koff = order === 'desc' ? koff2 : countItems - koff2;

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id + getRandomInt(124672)}
                                              style={ (row.special && row.basic_military ===null && booked )  ? {background: 'rgb(233 161 161)'} :
                                                  (row.remove_military !==null || row.basic_military !==null )
                                                  ? {background: 'lightgray'}
                                                      :{}}
                                    >
                                        {columns ? columns.map((column: any) => {
                                            const columnID = column.id;
                                            const value = row[column.id] as any;
                                            if (column.isSelected) {
                                                if (columnID === 'full_name') {
                                                    return <TableCell key={column.id + 'jdk'}
                                                                      align={column.align} className={'fullName'}
                                                                      onClick={() => {
                                                                          setNew(`/user_new/${row.id}`)
                                                                      }}
                                                    >
                                                        {value}

                                                    </TableCell>

                                                }
                                                if (columnID === 'letter_time') {
                                                    return <TableCell key={column.id + getRandomInt(1111)}
                                                                      align={column.align}>
                                                        {value ? <div className={'wrap-again-send'}>
                                                                <span>{formatDate(value)}</span>
                                                                <NavLink to={'/newHiringLetter'}><button
                                                                    onClick={()=>{sendInfoForAgainLetter('hiring_letter_again',row.id)}}>
                                                                    Отправить еще раз</button></NavLink>
                                                            </div>
                                                            : <></>}
                                                    </TableCell>
                                                }

                                                if (columnID === 'dismissal_letter_time') {
                                                    return <TableCell key={column.id + getRandomInt(1111)}
                                                                      align={column.align}>
                                                        {value ? <div className={'wrap-again-send'}>
                                                            <span>{formatDate(value)}</span>
                                                            <NavLink to={'/newDismissalLetter'}><button
                                                                onClick={()=>{sendInfoForAgainLetter('dismiss_letter_again',row.id)}}>
                                                                Отправить еще раз</button></NavLink>
                                                        </div> : <></>}
                                                    </TableCell>
                                                }
                                                if (columnID === 'education') {
                                                    return <TableCell key={column.id + 'key-col'}
                                                                      align={column.align}>
                                                        {value ? <>
                                                            {value}
                                                            <br/> {row.education_full ? row.education_full + ',' : ''}
                                                            <br/>{row.edu_specialization ? row.edu_specialization : ''}

                                                            <br/> {row.edu_dop_full ?  row.edu_dop_full+ ','  : ''}
                                                            <br/> {row.edu_dop_specialization ? row.edu_dop_specialization  : ''}
                                                        </> : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'id') {
                                                    return <TableCell key={column.id + getRandomInt(6802)}
                                                                      align={column.align}>
                                                        {order === 'asc' ? koff - number + 1 : koff + number}
                                                    </TableCell>
                                                }
                                                if (columnID === 'special') {
                                                    return <TableCell key={column.id + getRandomInt(4843)}
                                                                      align={column.align}>
                                                        {row.special ? 'Да' : 'Нет'}
                                                        <br/>
                                                        <br/>
                                                        {row.specialN ? row.specialN: ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'special_marks') {
                                                    return <TableCell key={column.id + getRandomInt(835443)}
                                                                      align={column.align}>
                                                        {row.sex ? `Пол: ${row.sex === 'f' ? 'жен.' : 'муж.'}` : ''}
                                                        <br/>
                                                        {row.status ? `Годность к воен. службе: ${row.status}` : ''}
                                                        <br/>
                                                        {row.military ? `№ воен. билета: ${row.military}` : ''} <br/>
                                                          {row.requirement===true ? `Моб. предписание:да \n ${row.requirement_annotation}` : 'Моб. предписание:нет'}
                                                        <br/>
                                                        {row.form6 ? `Намен. кат. должности рабочих: ${row.form6}` : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'user') {
                                                    return <TableCell key={column.id + getRandomInt(89033)}
                                                                      align={column.align}>
                                                        {localStorage.getItem('resp-for-documents')}
                                                    </TableCell>
                                                }
                                                if (columnID === 'family') {
                                                    return <TableCell key={column.id + getRandomInt(6802)}
                                                                      align={column.align}
                                                                      className={'family-column'}>
                                                        <span>
                                                            {row.family_half && row.family ? `жена (муж): ${row.family_half}, ${row.family}` :
                                                                !row.family_half && row.family ? `${row.family}` :
                                                                    row.family_half && !row.family ? `жена (муж): ${row.family_half}` : ``}
                                    </span>
                                                    </TableCell>
                                                }
                                                if (columnID === 'requirement') {
                                                    return <TableCell key={column.id + getRandomInt(15004)}
                                                                      align={column.align}>
                                                        {row.requirement === true ? <><span>Да</span>
                                                                <div>{row.requirement_annotation}</div>
                                                            </>
                                                            : <span>Нет</span>
                                                        }
                                                    </TableCell>
                                                }
                                                if (columnID === 'date_of_birth') {
                                                    return <TableCell key={column.id + getRandomInt(3232)}
                                                                      align={column.align}>
                                                        {value ? formatDate(value as string) : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'letter_time') {
                                                    return <TableCell key={column.id + getRandomInt(22222)}
                                                                      align={column.align}>
                                                        {value ? formatDate(value as string) : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'position') {
                                                    return <TableCell key={column.id + getRandomInt(22227665)}
                                                                      align={column.align}>
                                                         <p style={{marginBottom: '0px'}}>
                                                                {row.add_military ? `Принят(а) на учет: ${formatDate(row.add_military)} ` : ''}
                                                                {row.position ? `, ${row.position} ` : ''}
                                                            </p>
                                                                <p>
                                                                    {row.order_military ? `Приказ № ${row.order_military} от ${formatDate(row.date_add_military as string)}` : ''}
                                                                </p>
                                                                <p style={{marginBottom: '0px'}}>
                                                                    { row.remove_military ? `Снят(а) с учета: \n ${formatDate(row.remove_military)}` : ''}
                                                                </p>
                                                                <p style={{marginBottom: '0px'}}>
                                                                    {row.order_remove_military ? `Приказ № ${row.order_remove_military} от ${formatDate(row.date_remove_military as string)}` : ''}
                                                                </p>
                                                                <p>
                                                                    {row.basic_military && !row.order_remove_military && !row.date_remove_military ? `Снят(а) с учёта: ${row.basic_military}` :
                                                                        !row.basic_military && !row.order_remove_military && !row.date_remove_military ? '' :
                                                                        `Основание: ${row.basic_military}` }
                                                                </p>

                                                    </TableCell>
                                                }
                                                if (columnID === 'grade') {
                                                    return <TableCell key={column.id + getRandomInt(7327)}
                                                                      align={column.align}>
                                                        {value ? `${row.grade}\n ${row.grade_full || ''}` :
                                                            row.accountg_id===4 ? 'Призывник' : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'accountc') {
                                                    return <TableCell key={column.id + getRandomInt(974)}
                                                                      align={column.align}>
                                                        {row.profile ? row.profile : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'phone') {
                                                    return <TableCell key={column.id + getRandomInt(64748) + 2}
                                                                      align={column.align}>
                                                        {value ? <>{row.phone ? `моб. ${row.phone}` : ''}
                                                            <br/><br/>
                                                        {row.work_phone ? `раб. ${row.work_phone}` : ''}
                                                        <br/><br/>
                                                        {row.home_phone ? `дом. ${row.home_phone} ` : ''}</> : ''}
                                                    </TableCell>
                                                }
                                                if (columnID === 'marital_status') {
                                                    return <TableCell key={column.id + getRandomInt(54242)}
                                                                      align={column.align}>
                                                        {value ? value : ''}
                                                    </TableCell>
                                                }
                                                return (
                                                    <TableCell key={column.id + 'dj'}
                                                               align={column.align}>

                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            }
                                        }) : null}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    labelRowsPerPage='Показывать по:'
                    backIconButtonText='Предыдущие'
                    nextIconButtonText='Следующие'
                    labelDisplayedRows={({from, to, count}) => `${from}-${to} из ${count}`}
                    count={countItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

                <SettingsModal
                    selectedGrade={selectedGrade}
                    open={openModal}
                    handleClose={handleClose}
                    columns={columns}
                    setColumns={setColumns}
                    essence={4}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    items={items}
                    setItems={setItems}
                />

                <SelectVersionCardModal
                    open={openSelectCardModal}
                    handleClose={handleCloseSelectCardModal}
                    setNew={setNew}
                />
            </Paper>
        </div>
        </>
    );
}
