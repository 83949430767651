import React, {useState, useEffect} from 'react';
import Header from '../HeaderLandingPage'
import Footer from '../FooterLandingPage';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneInput from 'react-phone-number-input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {createStyles, makeStyles, withStyles, Theme} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Axios from '../../../utils/axios';
import SuccessfulSaveModal from "../../../components/PersonalCard/SuccessfulSaveModal";


const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
}

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            //margin: theme.spacing(1),
            minWidth: 120,
            width: '100%',
            fontSize: 20
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
            marginLeft: 0
        },
    }),
);


const HowWeWorkPage = () => {

    const axios = new Axios();
    const classes = useStyles();
    const [errorCallback, setErrorCallback] = useState(false)
    const [value, setValue] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('');
    const [people, setPeople] = useState('');
    const [message, setMessage] = useState('');
    const [isFieldEmpty, setIsFealdEmpty] = useState(false);
    const [errorName, setNameError] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorType, setTypeError] = useState(false);
    const [errorPeople, setPeopleError] = useState(false);


    const [data, setData] = useState([] as any);

    useEffect(() => {
        const axios = new Axios();
        axios.get(`control/page/1/`)
            .then(res => {
                setData(res.data.page);
            })
    }, []);

    const title = 'Как мы работаем';

    const [expanded, setExpanded] = React.useState<string | false>('');

    const handleChange12 = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
    };

    const checkFields = () => {
        const emailExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailExp.test(email)) setErrorEmail(true)
        return !(name.length === 0 || !emailExp.test(email) || people.length === 0 || type.length === 0);

    }

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const [age, setAge] = React.useState('');

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const sendData = () => {
        setErrorCallback(false);
        setPeopleError(false);
        setErrorEmail(false);
        setTypeError(false);
        setNameError(false);

        if (checkFields()) {
            const data = {
                email,
                phone,
                type,
                name,
                people,
                value
            }

            axios.post(`user/send/`, data)
                .then(res => {
                    setMessage('Менеджер свяжется с Вами в ближайшее время');
                    handleOpenModal();
                    setTimeout(handleCloseModal, 4000);
                })
                .catch(err => {
                    setMessage('Произошла ошибка. Пожалуйста, попробуйте ещё раз.');
                    handleOpenModal();
                    setTimeout(handleCloseModal, 4000);
                })
        } else {
            setIsFealdEmpty(true);
            setErrorCallback(true);
            if (phone === '') {
                let tel = document.querySelector('.PhoneInputInput');
                // @ts-ignore
                tel.style = 'border: 1px solid red';
            }
        }
    }

    return (
        <div className='landing_body'>
            <Header/>
            <div className='landing_main_howWeWork'>
                <div className="landing_main__howWeWork">
                    <h1>{title} <span className="mycompany"></span></h1>
                </div>

                <div className="wrapp_body_provision">

                    <div className='landingPage-wrappAccordion'>
                        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                              expandIcon={<ExpandMoreIcon/>}>
                                <Typography>
                                    <b>{data[1] ? data[1].name.split('\r\n')[1] : null}</b>
                                    {data[1] ? data[1].text.split('\r\nКнопка Подробнее:\r\n')[0].split('\r\n').map((item: any) => {
                                        return <p key={getRandomInt(376543)}>{item}</p>
                                    }) : null}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {data[1] ? data[1].text.split('\r\nКнопка Подробнее:\r\n')[1].split('\r\n').map((item: any) => {
                                        const index = item.indexOf('http')
                                        if (index > 0) {
                                            const buf = item.slice(index).split(' ') as any;
                                            const link = buf.shift();
                                            return <p key={getRandomInt(876543)}>
                                                {item.substr(0, index)} <a href={link}>{link}</a> {buf.join(' ')}
                                            </p>
                                        } else
                                            return <p key={getRandomInt(876543)}>{item}</p>
                                    }) : null}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header"
                                              expandIcon={<ExpandMoreIcon/>}>
                                <Typography>
                                    <b>{data[2] ? data[2].name.split('\r\n')[1] : null}</b>
                                    {data[2] ? data[2].text.split('\r\nКнопка Подробнее:\r\n')[0].split('\r\n').map((item: any) => {
                                        return <p key={getRandomInt(376543)}>{item}</p>
                                    }) : null}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {data[2] ? data[2].text.split('\r\nКнопка Подробнее:\r\n')[1].split('\r\n').map((item: any) => {
                                        const index = item.indexOf('http')
                                        if (index > 0) {
                                            const buf = item.slice(index).split(' ') as any;
                                            const link = buf.shift();
                                            return <p key={getRandomInt(876543)}>
                                                {item.substr(0, index)} <a href={link}>{link}</a> {buf.join(' ')}
                                            </p>
                                        } else
                                            return <p key={getRandomInt(876543)}>{item}</p>
                                    }) : null}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                    </div>
                </div>
            </div>

            <Footer/>
            <SuccessfulSaveModal
                open={openModal}
                handleClose={handleCloseModal}
                message={message}
            />
        </div>
    )
}
export default HowWeWorkPage;
