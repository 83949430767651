import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {connect} from 'react-redux';
import {loginUser} from '../redux/actions/userActions';
import ReCAPTCHA from "react-google-recaptcha";
import {ForgetPassword} from "../components/PersonalCard/SuccessfulSaveModal";
import Header from "./LandingPage/HeaderLandingPage";
import './LandingPage/LandingPage.css';
import Footer from "./LandingPage/FooterLandingPage";


export interface UserDataProps {
    username: string,
    password: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '30px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        fontSize: `16px`
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    check: {
        fontSize: '12px'
    },
    body1: {
        fontSize: '12px'
    },
    root: {
        fontSize: '12px'
    }
}));


function useFormFields<T>(initialState: T): [T, (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void] {
    const [inputs, setValues] = useState<T>(initialState);

    return [
        inputs,
        function (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
            setValues({
                ...inputs,
                [event.target.id]: event.target.value
            });
        }
    ];
}

function Login({error, ...props}: any) {

    const classes = useStyles();

    const [disabled, setDisabled] = useState(true);

    const [openModal, setOpenModal] = useState(false);

    const [inputs, handleInputChange] = useFormFields<UserDataProps>({
        username: '',
        password: '',
    });

    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const [isCaptcha, setCaptcha] = useState(false);

    const handleCloseModal = () => {

        setOpenModal(false);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    useEffect(() => {
        if (isCaptcha && check1 && check2) {
            setDisabled(false);
        } else setDisabled(true);
    }, [check1, check2, isCaptcha])

    const handleSubmit = (e: any) => {

        const userData = {
            username: inputs.username,
            password: inputs.password
        };

        props.loginUser(userData, props.history);
    }



    return (
        <>
            <div className='wrapper_header_'>
                <Header/>
            </div>
            <div className='nameSystem' style={{color: '#EAB40E', fontFamily: 'Rubik', marginTop: '50px'}}>
                <p>Автоматизированная информационно-аналитическая система «Воинский учёт»<br/> ДИФУ 062019.01-РП 01 ИС.</p>
            </div>

            <Container component="main" maxWidth="xs" style={{marginBottom: '220px'}}>

                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar} style={{background: '#014001'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5" style={{fontFamily: 'Rubik'}}>
                        Вход
                    </Typography>
                    <form className={classes.form} noValidate>
                        {<h4 className='login-star-error'>{error.textError ? error.textError : null}</h4>}
                        <span className='login-star' style={{fontFamily: 'Rubik'}}>* - отмечены поля обязательные для заполнения</span>


                        <TextField
                            style={error.textError ? {border:'3px solid red', borderRadius:'4px'} : {}}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Логин"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            onChange={handleInputChange}
                        />
                        <TextField
                            style={error.textError ? {border:'3px solid red', borderRadius:'4px'} : {}}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Пароль"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleInputChange}
                        />
                        <div className='check'>
                            <input type="checkbox" id="scales" name="scales" checked={check1} onChange={() => {
                                setCheck1(!check1)
                            }}/>
                            <label style={{fontFamily: 'Rubik'}}>Ознакомлен с договором публичной оферты для использования программного
                                продукта «Сервис «Воинский Учет» и подтверждаю свое согласие с его положениями.</label>
                        </div>
                        <div className='check'>
                            <input type="checkbox" id="scales" name="scales" checked={check2} onChange={() => {
                                setCheck2(!check2)
                            }}/>
                            <label style={{fontFamily: 'Rubik'}}>Настоящим подтверждаем согласие для регистрации
                                персональных данных сотрудников организации, необходимых для воинского учёта в
                                Республике Беларусь</label>
                        </div>
                        <ReCAPTCHA
                            sitekey="6Ldqa1oaAAAAAGbTfEcbv0eg4eWODd5mrFPVaS6h"
                            onChange={() => {
                                setCaptcha(true)
                            }}
                        />
                        <Button style={{background: '#EAB40E'}}
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleSubmit}
                                disabled={disabled}
                        >
                            Вход
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <span style={{cursor: 'pointer', fontFamily: 'Rubik'}} onClick={handleOpenModal}>Забыли пароль?</span>
                            </Grid>
                            <Grid item>
                                <Link href={"/our_contacts"} variant="body2" style={{color: 'black'}}>
                                    {"Нет аккаунта? Зарегистрироваться"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
            <ForgetPassword
                open={openModal}
                handleClose={handleCloseModal}
            />
            <div style={{position: 'relative'}}>
                <Footer/>
            </div>
        </>
    );
}

const mapStateToProps = (state: any) => ({
    user: state.user,
    error: state.error,
});

const mapActionsToProps = {
    loginUser
};
export default connect(mapStateToProps, mapActionsToProps)(Login);
