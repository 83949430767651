import React from 'react';
import style from '../StartingMilitary/StartingMilitary.module.css';
import Button from "@material-ui/core/Button";



const OrganizationOrder=(props: any)=> {

    return (
        <div style={{margin: '330px auto 10px auto'}}>
            <span className='title_letters'>Приказ "Об организации воинского учета граждан"</span>
            <div className='btn-wrapper'>
                <Button variant="contained" onClick={() => props.history.push('/start_military')}>
                    Назад
                </Button>
            </div>

            <div className={style.wrapLetter}>
             <span>1. Выведите проект Приказа и внесите необходимые данные</span>
                <span>2. Распечатайте Приказ на бланке организации</span>
                <span>3. Согласованную копию Приказа необходимо предоставить в военный комиссариат района</span>
                <Button variant="contained"  color="primary">
                  <a href={'http://voenkomat.by/war/media/Приказ_об_организации_ВУ.docx'} style={{color:'white',fontSize:'14px'}}>Вывести проект ПРИКАЗА</a>
                </Button>
            </div>

        </div>
    )
}

export default OrganizationOrder;
