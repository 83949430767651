import {IS_UPDATE_CARD, SET_TABLE_SETTINGS, UPDATE_TABLE_SETTINGS,} from '../types'

const initialState = {
    // authenticated: false,
    credentials: {},
    isUpdateCard: false
    // loading: false
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case UPDATE_TABLE_SETTINGS:
            return initialState;
        case SET_TABLE_SETTINGS:
            return {
                // authenticated: true,
                // loading: false,
                ...action.payload
            };
        case IS_UPDATE_CARD :
            return {
                ...state,
                isUpdateCard: action.isUpdateCard
            }
        default:
            return state;
    }
};

export const isUpdateSelfCard = (isUpdateCard: boolean) => {
    return {type: IS_UPDATE_CARD, isUpdateCard}
};