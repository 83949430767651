import * as React from 'react';
import './LandingPage.css'
import 'react-phone-number-input/style.css'
import Header from './HeaderLandingPage'
import Body from './BodyLandingPage';
import BodyDescription from './BodyDescription';
import {useEffect} from "react";
import {getStyleChat} from "../../chatBot";

const useScript = (url: string) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, [url]);
};

const LandingPage = () => {

    useScript(' https://mesago.app/chat-widget/chat-widget.js');

    useEffect(() => {
        const widget = document.getElementById('widget');
        getStyleChat(widget)
    }, [])
    return (
        <>

            <chat-widget id="widget"/>
            <script src="https://mesago.app/chat-widget.js"></script>
            <Header/>
            <BodyDescription/>
            <Body/>
        </>
    )
}
export default LandingPage;
