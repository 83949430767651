import React, { useState, useEffect } from 'react';
import Input from '@material-ui/core/Input';
import classes from './PersonalCard.module.css'
import Table from './Table';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Axios from '../../../utils/axios'

export default function DestinationAndMovement({ state, setSelectState, selectState, updateField, setState, stateTable, setStateTable, isEmpty }: any) {

    const [listForm6, setListForm6] = useState([]);
    const [valueForm6, setValueForm6] = React.useState(state.form6_id);

    const handleChangeForm6 = (event: any) => {
        setValueForm6(event.target.value as string);
        setState({
            ...state,
            'form6_id': event.target.value
        });

        setSelectState({
            ...selectState,
            'form6': event.currentTarget.textContent
        });
    };

    useEffect(() => {
        setValueForm6(state.form6_id);
    }, [state]);

    useEffect(() => {
        const fetchData = () => {
            const axios = new Axios();

            axios.get(`library/professions/`)
                .then(res => {
                    setListForm6(res.data.results);
                })
                .catch(err => {
                    console.log(err);
                })            
        };
        fetchData();
    }, []);

    return (
        <div>
            <h3 className={classes.headers}>III. Назначения и перемещения</h3>

            <div className={classes.box}>
                <Table
                    worker={state.worker}
                    setStateTable={setStateTable}
                    stateTable={stateTable} />
            </div>

            <div className={classes.box}>
                <p className={classes.boxChild}>Особые отметки: </p>
                <Input
                    id="special_marks"
                    className={classes.inputBoxThree}
                    value={state.special_marks || ''}
                    onChange={(e: any) => { updateField(e, setState, state) }} />
            </div>

            <div className={classes.box}>
                <p className={classes.boxChild}>Мобилизационное предписание: </p>
                <Input
                    id="requirement"
                    className={classes.inputBoxThree}
                    value={state.requirement || ''}
                    onChange={(e: any) => { updateField(e, setState, state) }} />
            </div>

            <div className={classes.box}>
                <p className={classes.boxChild}>Наименование категории должности рабочих: </p>
                <Select
                    id="form6_id"
                    value={state.form6_id}
                    onChange={handleChangeForm6}
                    className={classes.select}
                //error={isEmpty ? (state.accountg_id === '' ? true : false) : false}
                >
                    {listForm6.map((item: any) => {
                        return <MenuItem value={item.id} key={item.id + 'form6'}>{item.name}</MenuItem>
                    })}
                </Select>
            </div>

            <div className={classes.box}>
                <p className={classes.boxChild}>Дата и причина увольнения: </p>
                <Input
                    id="time_end"
                    className={classes.inputBoxThree}
                    value={state.time_end || ''}
                    onChange={(e: any) => { updateField(e, setState, state) }} />
            </div>
        </div>
    )
}
