import React, { useState, useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { FileUploader } from '../../FileUploader/FileUploader';
import './NewDecreeModal.css';
import Axios from '../../../utils/axios';


const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function NewDecreeModal(props: any) {
    const [file, setFile] = useState<any>('');
    const [name, setName] = useState('');
    const [date, setDate] = useState('');

    const save = () => {

        let body = new FormData();
        if (file !== '') {
            body.append("docs", file);
        } 

        body.append("name", name);

        const axios = new Axios();

        if (props.id) {
            axios.patch(`worker/order/${localStorage.getItem('id')}/${props.id}/`, body)
                .then(res => {
                    // localStorage.setItem('responsible person', name);
                    props.handleClose();
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            body.append("type_id", "Приказ");
            body.append("user", String(localStorage.getItem('id')));

            axios.post(`worker/order/${localStorage.getItem('id')}/`, body)
                .then(res => {
                    localStorage.setItem('responsible person', name);
                    props.handleClose();
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    useEffect(() => {
        if (props.id) {
            const axios = new Axios();
            axios.get(`worker/order/${localStorage.getItem('id')}/${props.id}`)
                .then(res => {
                    setName(res.data.name);
                    //setFile(res.data.docs)

                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [props.id])

    return (
        <div>
            <Dialog
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth='xl'
            >
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    Добавление ответственного
                </DialogTitle>
                <DialogContent dividers>
                    <div className='name'>
                        <p>Ответственный:  </p>
                        <input type="text" value={name} onChange={(e: any) => setName(e.target.value)} className='input-name' placeholder='ФИО' />
                    </div>
                    {
                        /*
<div className='name'>
                        <p>Дата назначения: </p>
                        <input type="date" id="start"
                            value={date}
                            min="2016-01-01" max="2040-12-31"
                            onChange={(e: any) => setDate(e.target.value)}
                            className='input-name'
                        ></input>
                    </div>
                        */
                    }

                    <div className={'fileUpload'}>
                        <FileUploader setFile={setFile} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.handleClose} color="primary" >
                        Отмена
                    </Button>
                    <Button autoFocus onClick={save} color="primary" variant="contained">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}