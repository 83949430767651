import React, {useState, useEffect} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {connect} from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import './Company.css';
import ErrorBoundary from '../../utils/ErrorBoundary';
import Axios from '../../utils/axios';
import RemoveIcon from '@material-ui/icons/Remove';
import ConfirmDialogDeleteCompany from './ConfirmDialogDeleteCompany';
import {makeStyles} from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from "@material-ui/lab/PaginationItem";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import clear from '../assets/images/clear.svg';
import event from '../assets/images/event.svg';
import ArrearsOfLettersModal from "../../components/ArrearsOfLettersModal/ArrearsOfLettersModal";
import {Document, Font, Page, pdf, StyleSheet, Text, View} from "@react-pdf/renderer";
import font_1 from '../../fonts/Roboto/Roboto-Light.ttf';
import font_2 from '../../fonts/Roboto/Roboto-Bold.ttf';
import {NavLink} from "react-router-dom";
import outPutFunction from "../OutPut";
import TextField from '@material-ui/core/TextField';
import {useInputDate} from '../../utils/customHooks/useInputDate';
import {ICompany, IHistory, ILocation, IMatch, IUser} from "../../utils/type";
import {useTheme} from "@material-ui/core";


Font.register({
    family: "Roboto",
    src: font_1,
    format: "truetype",
});
Font.register({
    family: "Roboto",
    src: font_2,
    format: "truetype",
});

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
};
const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь"
];

interface IProps {
    company: { company: Array<any> }
    history: IHistory,
    location: ILocation,
    match: IMatch
    staticContext: any
    user: {
        authenticated: boolean,
        credentials: any,
        loading: boolean
    }
}

const Company = ({...props}: IProps) => {


    const [company, setCompany] = useState([] as Array<ICompany>);
    const [isUpdate, setUpdate] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [idCompany, setIdCompany] = useState(0);
    const [users, setUsers] = useState([] as Array<IUser>);
    const [isAddNewUser, setAddNewUser] = useState(false);
    const [addedCompanyList, setAddedCompanyList] = useState([]);
    const [addedPhoneList, setAddedPhoneList] = useState([]);
    const [addedEmailList, setAddedEmailList] = useState([]);
    const [addedUserNameList, setAddedUserNameList] = useState([]);
    const [isBlockedUser, setIsBlockedUser] = useState(false);
    const [preAdmin, setPreAdmin] = useState(0);
    const [uniqueUserCompany, setUniqueUserCompany] = useState([]);
    const [ordinaryUserCompany, setOrdinaryUserCompany] = useState({} as any);

    const handleClickOpen = (id: number) => {
        setIdCompany(id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenToAddedNewUser = () => {
        setAddNewUser(true);
    };
    const handleCloseToAddedNewUser = () => {
        setAddNewUser(false);
    };

    const getData = () => {
        const data = {
            "delete": false
        }
        const axios = new Axios();
        if (localStorage.getItem('userID') !== '1') {
            axios.get(`user/me/`)
                .then(res => {
                    setPreAdmin(res.data.role);
                    setOrdinaryUserCompany(res.data)
                    return res.data.delete ? setIsBlockedUser(true) : setIsBlockedUser(false)

                })
        }

        axios.post(`worker/company/${localStorage.getItem('userID')}/filter/`, data)
            .then((res) => {
                if (localStorage.getItem('userID') === '1') {
                    axios.get('worker/time')
                        .then(response => {

                            let arr: any[] = []
                            for (let r in response.data) {
                                arr.push({id: Number(r), data: response.data[r]})

                            }

                            let y: Array<{ data: ICompany, reports: number }> = []
                            res.data.results.map((i: ICompany) => {
                                for (let a of arr) {
                                    if (Number(i.id) === Number(a.id)) {
                                        y = [...y, {
                                            data: i,
                                            reports: a.data.changes.length + a.data.report.length + a.data.dismissal.length
                                        }]
                                    }
                                }
                            })

                            let company = y.map((i: { data: ICompany, reports: number }) => {
                                return {...i.data, reports: i.reports}
                            })

                            setCompany(company)
                        })

                    let addedCompanyList = res.data.results.map((i: { short_organization_name: string | null, name: string | null }) => {
                        if (i.short_organization_name) {
                            return i.short_organization_name
                        } else return i.name
                    })
                    setAddedCompanyList(addedCompanyList)
                    let addedPhoneList = res.data.results.map((i: { organization_phone: string | null }) => {
                        return i.organization_phone
                    })
                    setAddedPhoneList(addedPhoneList);
                } else setCompany(res.data.results)


            })
            .catch(err => {
                console.log(err);
            });
        if (localStorage.getItem('userID') === '1') {

            axios.get('admin/users/')
                .then(res => {
                    setUsers(res.data.results);
                    let addedEmailList = res.data.results.map((i: { email: string | null }) => {
                        return i.email
                    })
                    setAddedEmailList(addedEmailList);

                    let addedUserName = res.data.results.map((i: { username: string | null }) => {
                        return i.username
                    })
                    setAddedUserNameList(addedUserName);
                })
        }
    }

    useEffect(() => {
        getData()
    }, [isUpdate]);


    const deleteCompany = () => {

        const axios = new Axios();
        axios.delete(`worker/company/${localStorage.getItem('userID')}/${idCompany}`, {})
            .then(() => {
                setUpdate(new Date().getMilliseconds());
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (<div className={'comp-wrap-dialog'}>
            <div className='title_cabinet_'>{localStorage.getItem('userID') === '1' ?
                'Кабинет Администратора системы Воинского Учета' : 'Личный кабинет'}</div>
            {preAdmin === 1 && <div className={'pre-adm-title'}> Помощника Администратора </div>}
            <div className={localStorage.getItem('userID') === '1' ? 'wrap_list' : 'wrap_list_'}>
                <div className={localStorage.getItem('userID') === '1' ? 'container_admin' : '_container_'}
                     style={{marginTop: '25px'}}>
                    {localStorage.getItem('userID') !== '1' && company.length !== 0 &&
                    !isBlockedUser &&
                    <span className='title_company_added'>Выберите организацию</span>}
                    <div className='toolbar'>
                        <div className='btn-wrapper'>
                            <Button variant="contained" onClick={() => props.history.goBack()}>
                                Назад
                            </Button>
                            <div style={{marginTop: '20px'}}>
                                <NavLink to={'/'}>
                                    <button className={'wrap_resp_btn'} style={{width: '80px'}}
                                            onClick={outPutFunction}>Выход
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                        {!isBlockedUser && preAdmin !== 2 &&
                        <Button style={{position: 'fixed', right: '30px', top: '16px'}}
                                variant="contained"
                                onClick={() => {
                                    props.history.push('/registrationCompany');
                                }}
                        >
                            Добавить организацию
                        </Button>}
                        {localStorage.getItem('userID') === '1' &&
                        <Button style={{position: 'fixed', right: '30px', top: '64px'}}
                                variant="contained" onClick={handleOpenToAddedNewUser}
                        >
                            Добавить пользователя
                        </Button>}

                    </div>

                    {localStorage.getItem('userID') !== '1' && !isBlockedUser  ?
                        preAdmin === 1 ?
                            <div className='company-wrapp'>
                                <ErrorBoundary>
                                    <UsersCompanies company={company} history={props.history}
                                                    setCompany={setCompany}/>
                                </ErrorBoundary>

                                <ConfirmDialogDeleteCompany
                                    open={open}
                                    handleClose={handleClose}
                                    deleteCompany={deleteCompany}
                                />
                            </div> : <OrdinaryUsersCompany history={props.history}
                                                           ordinaryUserCompany={ordinaryUserCompany}/>:
                        localStorage.getItem('userID') !== '1' && isBlockedUser ?
                            <div className={'wrap-user-block-title'}> Внимание! <br/>
                                Ваш Аккаунт заблокирован! Для снятия блокировки просим связаться с Администратором!
                            </div> :
                            <>
                                {company.length !== 0 && company !== undefined && users.length !== 0 && users !== undefined ?
                                    <ErrorBoundary>
                                        <div className={'company_wrap-admin'}>
                                            <EnhancedTable company={company} users={users}
                                                           handleClickOpen={handleClickOpen}
                                                           history={props.history} setCompany={setCompany}
                                                           setUsers={setUsers} getData={getData} setUpdate={setUpdate}
                                                           isUpdate={isUpdate}
                                            />
                                        </div>
                                    </ErrorBoundary> : <div className={'loading'}>Загрузка...</div>}
                                <ConfirmDialogDeleteCompany
                                    open={open}
                                    handleClose={handleClose}
                                    deleteCompany={deleteCompany}
                                />
                                <AddedNewUserComponent handleCloseToAddedNewUser={handleCloseToAddedNewUser}
                                                       isAddNewUser={isAddNewUser} users={users}
                                                       setUpdate={setUpdate}
                                                       addedCompanyList={addedCompanyList}
                                                       addedPhoneList={addedPhoneList} addedEmailList={addedEmailList}
                                                       addedUserNameList={addedUserNameList} getData={getData}/>
                            </>
                    }
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    company: state.company,
    user: state.user
});

const mapActionsToProps = {
    // loginUser
};

export default connect(mapStateToProps, mapActionsToProps)(Company);


function descendingComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order: any, orderBy: any) {
    return order === 'desc'
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any, comparator: any) {

    let list = array.map((item: any) => {
        return {...item, date: new Date(item.date)}
    })

    const stabilizedThis = list.map((el: any, index: any) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;

        return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
}

const headCells = [
    {id: 'notification', numeric: false, disablePadding: false, label: 'Уведомления'},
    {id: 'user', numeric: false, disablePadding: true, label: 'Администратор/Помощник Администратора'},
    {id: 'email', numeric: false, disablePadding: false, label: 'Email администратора'},
    {id: 'person', numeric: false, disablePadding: true, label: 'Пользователь'},
    {id: 'email_person', numeric: false, disablePadding: false, label: 'Email пользователя'},
    {id: 'company', numeric: false, disablePadding: false, label: 'Организация'},
    {id: 'man_war', numeric: false, disablePadding: false, label: 'Ответственный за ВУ'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Дата регистрации'},
    {id: 'password_', numeric: false, disablePadding: false, label: 'Выслать новый пароль'},
    {id: 'is_active', numeric: false, disablePadding: false, label: 'Блокирование пользователя'},
];

function EnhancedTableHead(props: any) {

    const {classes, order, orderBy, onRequestSort} = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
}));

interface IEnhancedTableProps {
    company: Array<ICompany>,
    getData: () => void,
    handleClickOpen: (id: number) => void,
    history: IHistory,
    isUpdate: number,
    setCompany: (value: Array<ICompany>) => void,
    setUpdate: (value: number) => void,
    setUsers: (value: Array<IUser>) => void,
    users: any
}

export function EnhancedTable(props: any) {

    let companyFull_ = props.company.map((i: ICompany) => {
        if (i.short_organization_name === null) {
            return {
                ...i, short_organization_name: '-'
            }
        } else return i
    });
    let companyFull = companyFull_.map((i: any) => {
        return i.director === null ? {...i, director: i.user} : {...i, director: i.director}
    })

    const [companyAdmin, setCompanyAdmin] = useState(companyFull)
    const [usersForAdmin, setUsersForAdmin] = useState(props.users)
    const [dataNotification, setDataNotification] = useState({} as object)
    const [isOpenNotification, setOpenNotification] = useState(false)
    const [isDeleteUser, setDeleteUser] = useState(false)

    interface IListCompany {
        company: null | string,
        man_war: null | string,
        date: null | string,
        reports: number | null | undefined,
        user: Array<IUser>,
        role?: number | string,
        email: any,
        id: number,
        address: null | string,
        idUser: number,
        is_active: any,
    }

    interface IRows {
        is_active_admin?:any,
        user_id?: any,
        person?: any,
        email_person?: string | null,
        address: string,
        company: string,
        date: string,
        email: string,
        id: string | number,
        idUser: string | number,
        is_active: boolean,
        man_war: string,
        notification: string,
        password_: string,
        reports: string | number,
        user: string,
        role?: number | string
    }


    let listCompany: any[] = [];
    for (let i of companyAdmin) {

        let person_name=""
        if (i.director.last_name)
        {
            person_name=person_name+i.director.last_name
        }
        if (i.director.first_name)
        {
            person_name=person_name+" "+i.director.first_name
        }
        if (i.director.middle_name)
        {
            person_name=person_name+" "+i.director.middle_name
        }

        listCompany = [...listCompany, {
            company: i.short_organization_name,
            man_war: i.full_name, date: i.register_date,
            reports: i.reports,
            role: usersForAdmin.filter((item: any) => {
                return i.user.id === item.id ? item : ''
            }),
            user:
                usersForAdmin.filter((item: any) => {
                    return i.user.id === item.id ? item : ''
                }),

            email:
                usersForAdmin.filter((item: any) => {
                    return i.user.id === item.id ? item : ''
                }),
            person: `${person_name}`,
            email_person: i.director.email,
            id: i.id,
            address: i.address,
            idUser: i.user.id,
            is_active: i.director.is_active,
            is_active_admin: i.user.is_active,
            user_id: i.director.id
        }]
    }

    const closeNotification = () => {
        setOpenNotification(false)
    }
    const getNotification = (id: number) => {
        const axios = new Axios();
        axios.get(`worker/time/${id}`)
            .then(res => {
                setDataNotification(res.data);
                if (res.data.report.length > 0 || res.data.dismissal.length > 0 || res.data.changes.length > 0) {
                    setOpenNotification(true)
                } else {
                    setOpenNotification(false)
                }
            })
    }

    let a: any[] = [];
    for (let i of listCompany) {
        a = [...a, {
            company: i.company,
            man_war: i.man_war, date: i.date,
            user: i.user[0],
            email: i.user[0],
            password_: 'Выслать новый пароль', notification: '',
            role: i.user[0].role,
            id: i.id,
            address: i.address,
            idUser: i.idUser,
            is_active: i.is_active,
            reports: i.reports,
            person: i.person,
            email_person: i.email_person,
            user_id: i.user_id,
            is_active_admin:i.is_active_admin
        }]
    }

    let listCompanyForAdmin: any[] = []
    for (let i of a) {

        listCompanyForAdmin = [...listCompanyForAdmin, {
            company: i.company,
            man_war: i.man_war, date: i.date,
            user: `${i.user.last_name} ${i.user.first_name} ${i.user.middle_name}`,
            email: i.email.email,
            password_: 'Выслать новый пароль', notification: '',
            id: i.id,
            role: i.role,
            address: i.address,
            idUser: i.idUser,
            is_active: i.is_active,
            reports: i.reports,
            person: i.person,
            email_person: i.email_person,
            user_id: i.user_id,
            is_active_admin:i.is_active_admin
        }]
    }

    function createData(user: string, email: string, person: any, email_person: string | null,
                        company: string, man_war: string,
                        date: string, password_: string, notification: string,
                        id: number | string, address: string, idUser: number | string, is_active: boolean
        , reports: number | string, role: number | string, user_id: string,is_active_admin:boolean) {
        return {
            user, email, person, email_person, company, man_war, date, password_, notification,
            id, address, idUser, is_active, reports, role, user_id,is_active_admin
        };
    }

    const rows =
        listCompanyForAdmin.map((i: IRows) => {
            return createData(`${i.user}`, `${i.email}`, `${i.person}`, `${i.email_person}`,
                `${i.company}`, `${i.man_war}`, `${i.date}`,
                `${i.password_}`, `${i.notification}`,
                `${i.id}`, `${i.address}`, `${i.idUser}`,
                i.is_active, `${i.reports}`, `${i.role}`, `${i.user_id}`,i.is_active_admin)
        });

    const classes = useStyles();
    const [idCompanyForEvents, setIdCompanyForEvents] = useState(0)
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([] as string[]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [savedMessage, setSavedMessage] = useState("");
    const [savedMessageForUsers, setSavedMessageForUsers] = useState("");
    const [modalForSavedUsers, setModalForSavedUsers] = useState(false);
    const [openDeletedCompanies, setOpenDeletedCompanies] = useState(false);
    const [deletedList, setDeletedList] = useState([]);
    const [openEventLog, setOpenEventLog] = useState(false);
    const [events, setEvents] = useState([]);
    const [companyForEventsLog, setCompanyForEventsLog] = useState('');
    const [openDeletedUsers, setOpenDeletedUsers] = useState(false);
    const [deletedUsersList, setDeletedUsersList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const pageSize = 100
    const [countItems, setCountItems] = useState(0)
    const [adminsList, setAdminsList] = useState([] as any)
    const [isOpenAdminsList, setOpenAdminsList] = useState(false)


    const savedNewUsersPastDelete = () => {
        setModalForSavedUsers(false);
        props.getData()
    }
    const clearSearch = () => {
        props.getData();
        setCompanyAdmin(companyFull);
        setUsersForAdmin(props.users)
    };

    useEffect(() => {
        setCompanyAdmin(companyFull);
        setUsersForAdmin(props.users)
    }, [props.users])

    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenModalDeleted = () => {
        setOpenDeletedCompanies(true);
    };
    const handleCloseModalDeleted = () => {
        setOpenDeletedCompanies(false);
    };

    const handleRequestSort = (event: React.ChangeEvent<HTMLInputElement>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event: React.ChangeEvent<EventTarget>, name: string) => {

        // @ts-ignore
        const selectedIndex: number = selected.indexOf(name);

        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //@ts-ignore
    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const changePassword = (id: string | number) => {

        const axios = new Axios();
        axios.get(`password/recovery/${id}`)
            .then(res => {
                handleOpenModal();
                setTimeout(handleCloseModal, 5000);
                setSavedMessage('Новый пароль выслан на email пользователя!')
            })
            .catch(err => {
                handleOpenModal();
                setTimeout(handleCloseModal, 5000);
                setSavedMessage('Произошла ошибка. Попробуйте снова!')
            })
    };
    const getDeletedCompanies = () => {
        const axios = new Axios();
        axios.post('worker/company/1/filter/', {"delete": true})
            .then(res => {
                setDeletedList(res.data.results)
            })
    }
    const deletedCompanies = () => {
        handleOpenModalDeleted();
        getDeletedCompanies()
    };

    const handleOpenModalDeletedUsers = () => {
        setOpenDeletedUsers(true)
    };
    const handleCloseModalDeletedUsers = () => {
        setOpenDeletedUsers(false)
    };
    const getDeletedUsers = () => {
        const axios = new Axios();
        axios.get('admin/users/archived/')
            .then(res => {
                setDeletedUsersList(res.data)
            })
    }
    const deletedUsers = () => {
        handleOpenModalDeletedUsers()
        getDeletedUsers()
    };

    const blockUser = (idUser: number | string, activity: boolean) => {

        const axios = new Axios()
        let listUsers = usersForAdmin.filter((item: IUser) => {
            return item.id === Number(idUser)
        })
        let newListUsers = listUsers.map((item: IUser) => {
            return {...item, is_active: !activity}
        })

        axios.put(`admin/users/${idUser}/`, newListUsers[0])
            .then(res => {
                clearSearch();
                props.setUpdate(new Date().getMilliseconds());
            })
    }

    const handleOpenEventsLog = (id: number) => {
        setIdCompanyForEvents(id)
        const axios = new Axios;
        axios.get(`worker/eventlog/${id}/?page=${currentPage}&page_size=${pageSize}`)
            .then(res => {
                setEvents(res.data.results);
                setCountItems(res.data.count)
            })
        setOpenEventLog(true);
        axios.get(`worker/company/1/${id}/`)
            .then(res => {
                setCompanyForEventsLog(res.data.short_organization_name)
            })
    };

    useEffect(() => {
        if (idCompanyForEvents) {
            handleOpenEventsLog(idCompanyForEvents)
        }
    }, [currentPage])

    const closeEventsLog = () => {
        setOpenEventLog(false)
    }
    let arr = listCompanyForAdmin.map((i: IRows) => {
        return Object.values(i)
    })

    let uniqueArr = [...new Set(arr.flat())]

    let searchArr = uniqueArr.filter((item: string | number | null | undefined) => {
        if (item !== undefined) {
            return item !== null && typeof item !== 'number' && typeof item !== 'boolean' && typeof item !== 'object'
                && item !== "Выслать новый пароль" && item !== "Заблокировать" && item.indexOf('+03:00') === -1 &&
                item.indexOf('ул.') === -1 && item.indexOf('пер.') === -1 && item.indexOf('переулок') === -1
                && item.indexOf('бульвар') === -1
        }
    })

    const [textVal, setTextVal] = useState(null)
    const [idDeletedUser, setIdDeletedUser] = useState('')
    useEffect(() => {
        const axios = new Axios();

        const getCompanyList = () => {
            axios.post(`worker/company/${localStorage.getItem('userID')}/filter/`,
                {short_organization_name__icontains: textVal, delete: false})
                .then(res => {
                    let comp = res.data.results.map((i: any) => {
                        return companyFull.filter((f: any) => {
                            if (i.id === f.id) {
                                return {...i, reports: f.reports}
                            }
                        })
                    })

                    setCompanyAdmin(comp.flat())
                })
        };
        const getUsersFullName = () => {
            axios.post(`worker/company/${localStorage.getItem('userID')}/filter/`, {
                full_name__icontains: textVal
            })
                .then(res => {
                    let comp = res.data.results.map((i: any) => {
                        return companyFull.filter((f: any) => {
                            if (i.id === f.id) {
                                return {...i, reports: f.reports}
                            }
                        })
                    })
                    setCompanyAdmin(comp.flat())
                })
        };

        const getUsersEmail = () => {
            let newList: any[] = []
            let newListUsers: any[] = []
            axios.post(`admin/users/filter/`, {email__icontains: textVal})
                .then(res => {
                    newListUsers = res.data.results
                    newList = res.data.results.map((u: any) => {
                        return companyAdmin.filter((item: any) => {
                            return item.user.id === u.id
                        })
                    })
                    // setCompanyAdmin(newList.flat())
                    let comp = newList.flat().map((i: any) => {
                        return companyFull.filter((f: any) => {
                            if (i.id === f.id) {
                                return {...i, reports: f.reports}
                            }
                        })
                    })
                    setCompanyAdmin(comp.flat())
                    setUsersForAdmin(newListUsers)
                })
        };

        const getUsers = () => {
            let newList: any[] = []
            let newListUsers: any[] = []
            axios.post(`admin/users/filter/`, {full_name__icontains: textVal, delete: false})
                .then(res => {
                    newListUsers = res.data.results
                    newList = res.data.results.map((u: any) => {
                        return companyAdmin.filter((item: any) => {
                            return item.user.id === u.id
                        })
                    })
                    //   setCompanyAdmin(newList.flat())
                    let comp = newList.flat().map((i: any) => {
                        return companyFull.filter((f: any) => {
                            if (i.id === f.id) {
                                return {...i, reports: f.reports}
                            }
                        })
                    })
                    setCompanyAdmin(comp.flat())
                    setUsersForAdmin(newListUsers)
                })
        }

        let companies = listCompanyForAdmin.filter((item: any) => {
            return item.company === textVal
        });

        let emails = listCompanyForAdmin.filter((item: any) => {
            return item.email === textVal
        });
        let names = listCompanyForAdmin.filter((item: any) => {
            return item.man_war === textVal
        });
        let users = listCompanyForAdmin.filter((item: any) => {
            return item.user === textVal
        });

        if (companies.length !== 0 && textVal !== null) {
            getCompanyList()
        } else if (emails.length !== 0 && textVal !== null) {
            getUsersEmail()
        } else if (names.length !== 0 && textVal !== null) {
            getUsersFullName()
        } else if (users.length !== 0 && textVal !== null) {
            getUsers()
        } else props.getData()
    }, [textVal, props.isUpdate]);

    const openWarningDeletedUserWindow = (id: any) => {
        setIdDeletedUser(id)
        setDeleteUser(true)
    };
    const closeWarningDeletedUserWindow = () => {
        setDeleteUser(false)
    };
    const toDeleteUser = () => {
        const axios = new Axios();
        closeWarningDeletedUserWindow();
        axios.delete(`admin/users/${idDeletedUser}/`, {})
            .then(res => {
                //  clearSearch();
                // props.setUpdate(new Date().getMilliseconds());

                // props.getData()
                //  setCompanyAdmin(companyFull);
                //  setUsersForAdmin(props.users)
                window.location.reload()
            })
    }
    const getMinute = (utc: any) => {
        let minute = new Date(utc).getMinutes() <= 9 ? `0${new Date(utc).getMinutes()}` : new Date(utc).getMinutes()
        return minute
    };
    const getHour = (utc: any) => {
        let hour = new Date(utc).getHours() <= 9 ? `0${new Date(utc).getHours()}` : new Date(utc).getHours()
        return hour
    }

    const getAdminsList = () => {
        setOpenAdminsList(true)
        const axios = new Axios();
        axios.post('admin/users/filter/', {role: 1, delete: false})
            .then(res => {
                setAdminsList(res.data.results)
            })
    }

    return (
        <>

            {<div className={classes.root}>
                <ConfirmDialogDeleteUser
                    toDeleteUser={toDeleteUser} isDeleteUser={isDeleteUser}
                    closeWarningDeletedUserWindow={closeWarningDeletedUserWindow}/>
                <DeletedCompanies openDeletedCompanies={openDeletedCompanies} deletedList={deletedList}
                                  handleCloseModalDeleted={handleCloseModalDeleted} handleOpenModal={handleOpenModal}
                                  handleCloseModal={handleCloseModal} setSavedMessage={setSavedMessage}
                                  getData={props.getData} getDeletedCompanies={getDeletedCompanies}
                                  clearSearch={clearSearch}/>

                <DeletedUsers getDeletedUsers={getDeletedUsers} getData={props.getData}
                              openDeletedUsers={openDeletedUsers} deletedUsersList={deletedUsersList}
                              handleCloseModalDeletedUsers={handleCloseModalDeletedUsers}
                              clearSearch={clearSearch} setModalForSavedUsers={setModalForSavedUsers}
                              setSavedMessageForUsers={setSavedMessageForUsers}/>
                <EventsLog openEventLog={openEventLog} closeEventsLog={closeEventsLog} events={events}
                           companyForEventsLog={companyForEventsLog} idCompanyForEvents={idCompanyForEvents}
                           currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize}
                           countItems={countItems} handleOpenModal={handleOpenModal} setEvents={setEvents}
                           handleCloseModal={handleCloseModal} setSavedMessage={setSavedMessage}
                />
                <AdminsList adminsList={adminsList} isOpenAdminsList={isOpenAdminsList}
                            setOpenAdminsList={setOpenAdminsList} setAdminsList={setAdminsList} setUpdate={props.setUpdate}/>
                <ArrearsOfLettersModal
                    open={isOpenNotification}
                    handleClose={closeNotification}
                    history={props.history}
                    notifications={dataNotification}
                />

                <div style={{position: 'fixed', right: '30px', top: '112px'}}><Button variant="contained"
                                                                                      style={{background: '#dc9a9a'}}
                                                                                      onClick={deletedCompanies}>Удалённые
                    организации</Button></div>
                <div style={{position: 'fixed', right: '30px', top: '207px'}}><Button variant="contained"
                                                                                      style={{background: 'rgb(235 225 29)'}}
                                                                                      onClick={getAdminsList}>
                    Помощники Администратора</Button></div>

                <div style={{position: 'fixed', right: '30px', top: '160px'}}><Button variant="contained"
                                                                                      style={{background: '#dc9a9a'}}
                                                                                      onClick={deletedUsers}
                >Удалённые
                    пользователи и помощники администратора</Button></div>

                <span className='title_company_added'> Список организаций</span>
                <div style={{marginBottom: '65px'}}>
                    <Autocomplete
                        value={textVal}
                        onChange={(event: any, newValue: any) => {
                            setTextVal(newValue);
                        }}
                        id="controlled-demo"
                        clearText='Очистить'
                        noOptionsText='...'
                        openText='Открыть'
                        options={searchArr}
                        getOptionLabel={(option: any) => option}
                        style={{width: 300, marginTop: '0px'}}
                        renderInput={(params) => <TextField {...params} label="Поиск" margin="normal"/>}
                    />
                </div>
                <Paper className={classes.paper}>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                listCompanyForAdmin={listCompanyForAdmin}
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row: any, index: number) => {
                                        const isItemSelected = isSelected(row.user);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (<React.Fragment key={index}>
                                            <TableRow
                                                hover
                                                onClick={(event: React.ChangeEvent<EventTarget>) => handleClick(event, row.user)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={index}
                                                selected={isItemSelected}
                                            >

                                                <TableCell align="center">{row.notification}

                                                    <Tooltip title={row.reports === '0' ? "" :
                                                        `У Пользователя системы обнаружено ${row.reports} критичных событий!
                                                         Необходимо открыть перечень этих событий и сообщить Пользователю о критичной ситуации! `
                                                    }>
                                                        <NotificationImportantIcon
                                                            style={row.reports !== '0' ? {
                                                                color: '#dc9a9a'
                                                            } : {}}
                                                            onClick={() => {
                                                                getNotification(row.id)
                                                            }}/>
                                                    </Tooltip>

                                                </TableCell>
                                                <TableCell align="center" id={labelId} scope="row"
                                                           padding="none"
                                                           style={row.idUser !== '1' && row.role === '1' && row.is_active_admin ? {background: 'rgb(235 225 29)'} :
                                                               row.idUser !== '1' && row.role === '1' && !row.is_active_admin ? {background: '#dc9a9a'} :
                                                                   {}}>
                                                    <div className={'wrap-clear-user'}><img onClick={() => {
                                                        openWarningDeletedUserWindow(row.idUser)
                                                    }}
                                                                                            src={clear}
                                                                                            alt={'delete_user'}
                                                                                            title='Удалить пользователя'
                                                                                            className={'image_clear'}/>
                                                        {row.user}
                                                    </div>
                                                </TableCell>

                                                <TableCell align="center">{row.email}</TableCell>
                                                <TableCell
                                                    align="center">{row.person === 'null' ? '-' : row.person}</TableCell>
                                                <TableCell
                                                    align="center">{row.email_person !== 'null' ? row.email_person : '-'}</TableCell>
                                                <TableCell align="center"
                                                           className={row.is_active ? 'company-link-org' : 'company-link-org err'}> <span
                                                    onClick={() => {
                                                        localStorage.setItem('company', row.company);
                                                        localStorage.setItem('id', row.id);
                                                        localStorage.setItem('addressCompany', row.address);
                                                        props.history.push('/home');
                                                    }}
                                                    className='comp-link'
                                                >
                                                {row.company === 'null' ? '-' : row.company}
                                </span>

                                                    <Tooltip title="Удалить организацию" placement="right-start">
                                                        <div className='deleteIcon' onClick={() => {
                                                            props.handleClickOpen(Number(row.id))
                                                        }}>
                                                            <RemoveIcon/>
                                                        </div>
                                                    </Tooltip>
                                                    <Tooltip title="Журнал событий" placement="right-start">
                                                        <img src={event} alt={''} className={'icon-event'}
                                                             onClick={() => {
                                                                 handleOpenEventsLog(Number(row.id))
                                                             }}/>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell
                                                    align="center">{row.man_war === 'null' ? '-' : row.man_war}</TableCell>

                                                <TableCell
                                                    align="center">{months[new Date(new Date(row.date)).getMonth()] + ' ' +
                                                new Date(new Date(row.date)).getDate() + ', ' + new Date(new Date(row.date)).getFullYear()}
                                                    <br/> {getHour(new Date(row.date)) + ":" + getMinute(new Date(row.date))}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <button className={'btn-table'}
                                                            onClick={() => {
                                                                changePassword(row.user_id)
                                                            }}
                                                    >{row.password_}</button>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <button className={'btn-table'}
                                                            style={row.is_active ? {color: 'black'} : {color: '#dc9a9a'}}
                                                            onClick={() => {
                                                                blockUser(row.user_id, row.is_active)
                                                            }}
                                                            disabled={row.user_id === "1" ? true : false}>
                                                        {row.is_active ? 'Заблокировать' : 'Разблокировать'}</button>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>);
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{height: (53) * emptyRows}}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                <>
                    <Dialog
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{savedMessage}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={modalForSavedUsers}
                        onClose={() => {
                            setModalForSavedUsers(false)
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{savedMessageForUsers}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Button onClick={savedNewUsersPastDelete}>Ok</Button>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </>
            </div>}
        </>
    );
}


const DeletedCompanies = (props: any) => {

    const restoreCompany = (id: any) => {
        const axios = new Axios();
        axios.post('worker/company/1/recovery/', {"company_id": `${id}`})
            .then(res => {
                props.handleOpenModal();
                setTimeout(props.handleCloseModal, 3000);
                props.setSavedMessage('Восстановление прошло успешно');
                props.getDeletedCompanies();
                // props.getData()
                props.clearSearch()
            })
            .catch(err => {
                props.handleOpenModal();
                setTimeout(props.handleCloseModal, 3000);
                props.setSavedMessage('Произошла ошибка. Попробуйте снова!');
                // window.location.reload();
            })
    };


    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });
    let sortCompanies = props.deletedList.sort((a: any, b: any) => {
        // @ts-ignore
        return new Date(b.delete_date) - new Date(a.delete_date)
    })

    function createData(name: string, date: string, restore: string, id: any) {
        return {name, date, restore, id};
    }

    const rows = sortCompanies.map((i: any) => {
        return createData(`${i.short_organization_name ? i.short_organization_name : i.name}`,
            `${i.delete_date}`, `Восстановить`, `${i.id}`)
    });

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog onClose={props.handleCloseModalDeleted} aria-labelledby="customized-dialog-title"
                open={props.openDeletedCompanies} fullScreen={fullScreen}>
            <DialogContent dividers>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Организация</TableCell>
                                <TableCell align="right">Дата удаления</TableCell>
                                <TableCell align="right">Восстановление</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell align="right">{months[new Date(new Date(row.date)).getMonth()] + ' ' +
                                    new Date(new Date(row.date)).getDate() + ', ' + new Date(new Date(row.date)).getFullYear()}</TableCell>
                                    <TableCell align="right">
                                        <button className={'btn-table'} onClick={
                                            () => {
                                                restoreCompany(row.id)
                                            }
                                        }>{row.restore}</button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.handleCloseModalDeleted} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const EventsLog = (props: any) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                display: 'flex',
                justifyContent: 'center',
            },
        },
    }));
    const classes = useStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const axios = new Axios()
    const events = props.events;
    const companyForEventsLog = props.companyForEventsLog;

    const periods = ['Весь период', 'Выбрать период']
    const [isAllPeriods, setAllPeriods] = useState(false)
    const [uniquePeriod, setUniquePeriod] = useState(false)
    const [addedEvents, setAddedEvents] = useState([])
    const [isAddedEvents, setIsAddedEvents] = useState(false)

    const [currentPage_, setCurrentPage_] = useState(1)
    const pageSize_ = 100
    const [countItems_, setCountItems_] = useState(0)


    const closeEventLogData = () => {
        setAllPeriods(false)
        setUniquePeriod(false)
        props.closeEventsLog()
        setIsAddedEvents(false)
        props.setCurrentPage(1)
        setCurrentPage_(1)
    }
    const uniquePeriodFunc = () => {
        setAllPeriods(false)
        setUniquePeriod(true)
        setIsAddedEvents(false)
        props.setCurrentPage(1)
    };
    const allPeriodsFunc = () => {
        setAllPeriods(true)
        setUniquePeriod(false)
        setIsAddedEvents(false)
        setCurrentPage_(1);
        setAddedEvents([])
    }
    const onAddedTypePeriod = (name: string) => {
        return name === 'Весь период' ? allPeriodsFunc() : uniquePeriodFunc()
    };

    const toShowWarning = () => {
        props.handleOpenModal();
        setTimeout(props.handleCloseModal, 3000);
        props.setSavedMessage('Перед просмотром документа в PDF выберете период и ' +
            ' нажмите кнопу Показать');
    }

    const inp_1 = useInputDate('');
    const inp_2 = useInputDate('');


    const toShowAddedEvents = () => {
        setUniquePeriod(false)
        axios.post(`worker/eventlog/${props.idCompanyForEvents}/filter/?page=${currentPage_}&page_size=${pageSize_}`,
            {"from_day": inp_1.value, "to_day": inp_2.value}
        )
            .then(res => {
                setAddedEvents(res.data.results);
                setIsAddedEvents(true)
                setCountItems_(res.data.count)
            })
    };

    useEffect(() => {
        if (props.idCompanyForEvents) {
            toShowAddedEvents()
        }
    }, [currentPage_])

    const toShow = () => {
        inp_1.isError();
        inp_2.isError();
        return inp_1.value === '' || inp_2.value === '' ? setUniquePeriod(true) : toShowAddedEvents()
    };

    const handleChange = (event: any, value: number) => {
        props.setCurrentPage(value)
    };
    const handleChangeAdded = (event: any, value: number) => {
        setCurrentPage_(value)
    };

    const resultTotalPages = Math.ceil(props.countItems / props.pageSize);
    const resultTotalPages_ = Math.ceil(countItems_ / pageSize_);

    // @ts-ignore
    return (
        <Dialog onClose={props.closeEventsLog} aria-labelledby="customized-dialog-title"
                open={props.openEventLog} fullScreen={fullScreen}>
            <DialogContent dividers>
                <div className={'wrapPeriods'}>{periods.map((i: string, index: number) => {
                    return <Button variant="contained" color="primary" key={index}
                                   onClick={() => {
                                       onAddedTypePeriod(i)
                                   }}>
                        {i}
                    </Button>
                })}</div>

                {isAllPeriods && <>
                    {events.length === 0 ? <div>Журнал событий пуст</div> :
                        <>
                            <div className={'wrapTitleEvents'}>Журнал событий за весь период</div>
                            <div style={{fontWeight: 'bold', color: 'red'}}>* - Событие проведено Администратором</div>
                            <div style={{fontWeight: 'bold', color: 'black', marginBottom: '20px'}}>* - Событие
                                проведено в кабинете {companyForEventsLog}</div>
                            <table className={'event-log-list'} width="100%" cellPadding="5">
                                <tr>
                                    <th>№</th>
                                    <th>Событие</th>
                                    <th>Дата</th>
                                </tr>
                                {events.map((item: { name: string; datatime: Date, user_id: number, comment: string, worker_id: number }, index: number) => {
                                    let utc = new Date(item.datatime)
                                    let minute = new Date(utc).getMinutes() <= 9 ? `0${new Date(utc).getMinutes()}` : new Date(utc).getMinutes()
                                    let hour = new Date(utc).getHours() <= 9 ? `0${new Date(utc).getHours()}` : new Date(utc).getHours()
                                    return <tr key={index}
                                               style={item.user_id === 1 ? {color: 'red'} : {color: 'black'}}>
                                        <td>{index + 1}</td>
                                        {item.worker_id ? <td>
                                                {item.name} <a href={`/user_new/${item.worker_id}`} title={'Перейти'}
                                                               target={'_blank'} style={{color: 'black'}}>{item.comment}</a>
                                            </td> :
                                            <td>{item.name} {item.comment ? ` - ${item.comment.indexOf('Находящийся') !== -1 ?
                                                item.comment.slice(0, item.comment.indexOf('Находящийся')) : item.comment}` : ''}</td>
                                        }
                                        <td>{months[new Date(utc).getMonth()] + ' ' +
                                        new Date(utc).getDate() + ', ' + new Date(utc).getFullYear() +
                                        '    ' + hour + ":" + minute}</td>
                                    </tr>
                                })}
                            </table>
                            <div className={classes.root}>
                                <Pagination style={{marginBottom: '120px'}}
                                            count={resultTotalPages} defaultPage={1}
                                            shape="rounded" page={props.currentPage} onChange={handleChange}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    //@ts-ignore
                                                    type={"start-ellipsis"}
                                                    // component={Link}
                                                    //@ts-ignore
                                                    selected
                                                    // to={`#page=${item.page}`}
                                                    {...item}
                                                />
                                            )}
                                />
                            </div>
                        </>
                    }

                </>}

                {uniquePeriod && <div className={'wrapperUniquePeriodEvents'}>
                    <div><span>С</span><input className={'inp_1-period'}
                                              type='date' value={inp_1.value} onChange={inp_1.onChangeValue}/> <span>(с 0-00 часов текущего дня)</span>
                    </div>
                    <div><span>По</span><input className={'inp_2-period'}
                                               type='date' value={inp_2.value} onChange={inp_2.onChangeValue}/> <span> (по 24-00 часов текущего дня)</span>
                    </div>
                    <div className={'wrapperBtnsEv'}><Button variant="contained" onClick={toShow}
                                                             style={{marginRight: '15px'}}>Показать</Button>
                        <Button variant="contained" onClick={() => {
                            setUniquePeriod(false);
                            props.setCurrentPage(1)
                        }}>Отмена</Button></div>
                </div>}

                {inp_1.error || inp_2.error ?
                    <span style={{color: 'red', fontWeight: 'bold'}}>Вы выбрали не все Даты</span> : <></>}

                {isAddedEvents && <>
                    {addedEvents.length === 0 ? <div>Журнал событий за указанный период пуст</div> :
                        <>
                            <div className={'wrapTitleEvents'}>
                                Журнал событий за период
                                c {inp_1.value.split('-').reverse().join('-')} по {inp_2.value.split('-').reverse().join('-')}</div>
                            <div style={{fontWeight: 'bold', color: 'red'}}>* - Событие проведено Администратором</div>
                            <div style={{fontWeight: 'bold', color: 'black', marginBottom: '20px'}}>* - Событие
                                проведено в кабинете {companyForEventsLog}</div>
                            <table className={'event-log-list'} width="100%" cellPadding="5">
                                <tr>
                                    <th>№</th>
                                    <th>Событие</th>
                                    <th>Дата</th>
                                </tr>
                                {addedEvents.map((item: { name: string; datatime: Date, comment: string, user_id: number, worker_id: number }, index: number) => {
                                    let utc = new Date(item.datatime);
                                    let minute = new Date(utc).getMinutes() <= 9 ? `0${new Date(utc).getMinutes()}` : new Date(utc).getMinutes()
                                    let hour = new Date(utc).getHours() <= 9 ? `0${new Date(utc).getHours()}` : new Date(utc).getHours()
                                    return <tr key={index}
                                               style={item.user_id === 1 ? {color: 'red'} : {color: 'black'}}>
                                        <td>{index + 1}</td>
                                        {item.worker_id ? <td>
                                                {item.name} <a href={`/user_new/${item.worker_id}`} title={'Перейти'}
                                                               target={'_blank'} style={{color: 'black'}}>{item.comment}</a>
                                            </td> :
                                            <td>{item.name} {item.comment ? ` - ${item.comment.indexOf('Находящийся') !== -1 ?
                                                item.comment.slice(0, item.comment.indexOf('Находящийся')) : item.comment}` : ''}</td>
                                        }
                                        <td>{months[new Date(utc).getMonth()] + ' ' +
                                        new Date(utc).getDate() + ', ' + new Date(utc).getFullYear() +
                                        '    ' + hour + ":" + minute}</td>
                                    </tr>
                                })}
                            </table>
                            <div className={classes.root}>
                                <Pagination style={{marginBottom: '120px'}}
                                            count={resultTotalPages_} defaultPage={1}
                                            shape="rounded" page={currentPage_} onChange={handleChangeAdded}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    //@ts-ignore
                                                    type={"start-ellipsis"}
                                                    // component={Link}
                                                    //@ts-ignore
                                                    selected
                                                    // to={`#page=${item.page}`}
                                                    {...item}
                                                />
                                            )}
                                />
                            </div>
                        </>}
                </>}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => {
                    return isAllPeriods || isAddedEvents ?
                        generatePDFDocument({
                            isAddedEvents, isAllPeriods, events, companyForEventsLog, addedEvents,
                            inp_1, inp_2, uniquePeriod
                        }) : toShowWarning()
                }} color="primary">
                    Скачать PDF
                </Button>
                <Button autoFocus onClick={closeEventLogData} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}
//@ts-ignore
const generatePDFDocument = async (data_pdf: {
    events: any, companyForEventsLog: string,
    addedEvents: any,
    isAddedEvents: boolean, isAllPeriods: boolean,
    inp_1: any, inp_2: any, uniquePeriod: boolean
}) => {

    const blobPdf = pdf(EventsLogPdf(data_pdf));
    blobPdf.updateContainer(EventsLogPdf(data_pdf));
    const result = await blobPdf.toBlob();
    saveAs(result, "Events_log.pdf");
};

const styles = StyleSheet.create({


    table: {
        display: "table",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        width: '99%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 30
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColHeaderName: {
        width: "57%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
    },
    tableColHeader: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        width: "33%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColHeaderNum: {
        width: "10%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'center'
    },
    tableColName: {
        width: "57%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 5,
        fontSize: 13
    },
    tableCol: {
        width: "33%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 5,
        fontSize: 13
    },
    tableColNum: {
        width: "10%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 5,
        fontSize: 13
    },
    tableCellHeader: {
        // margin: "auto",
        // margin: 5,
        fontSize: 12,
        fontFamily: "Roboto",
        fontWeight: 500,
    },
    tableCell: {
        // margin: "auto",
        // margin: 5,
        fontSize: 10,
        fontFamily: "Roboto",
    },
    tableCellInd: {
        // margin: "auto",
        // margin: 5,
        fontSize: 10,
        fontFamily: "Roboto",
        textAlign: 'center'
    },

    page: {
        width: '90%',
        marginTop: 40,
        marginBottom: 40,
        marginRight: 'auto',
        marginLeft: 'auto',
        border: '1px solid black',
        fontSize: 12,
        paddingBottom: 60,
        paddingTop: 30
    },
    wrapText: {
        marginBottom: 20
    },
    text: {
        fontFamily: "Roboto",
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: 12,
        textAlign: 'center',
    },
    pageNumbers: {
        fontFamily: "Roboto",
        paddingTop: 20,
        textAlign: 'right',
        fontSize: 10,
        paddingRight: 5
    },

});


const EventsLogPdf = (props: any) => {

    return (<Document>
            <Page style={styles.page}>
                <View style={styles.wrapText}>
                    <Text style={styles.text}>Журнал событий</Text>
                    <Text style={styles.text}> в личном кабинете {props.companyForEventsLog} системы "Воинский
                        учёт"</Text>
                    <Text style={styles.text}>  {props.addedEvents.length !== 0 ?
                        ` c ${props.inp_1.value.split('-').reverse().join('-')} по ${props.inp_2.value.split('-').reverse().join('-')}` : ' за весь период.'} </Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow} fixed>
                        <View style={styles.tableColHeaderNum}>
                            <Text style={styles.tableCellHeader}>№</Text>
                        </View>
                        <View style={styles.tableColHeaderName}>
                            <Text style={styles.tableCellHeader}>Событие</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Дата</Text>
                        </View>
                    </View>

                    {props.isAllPeriods && props.addedEvents.length === 0 &&
                    props.events.map((item: any, index: number) => {
                        let utc = new Date(item.datatime)
                        let minute = new Date(utc).getMinutes() <= 9 ? `0${new Date(utc).getMinutes()}` : new Date(utc).getMinutes()
                        let hour = new Date(utc).getHours() <= 9 ? `0${new Date(utc).getHours()}` : new Date(utc).getHours()
                        return <View style={styles.tableRow} wrap={false} key={index}>
                            <View style={styles.tableColNum}>
                                <Text style={styles.tableCellInd}>{index + 1}</Text>
                            </View>
                            <View style={styles.tableColName}>
                                <Text style={styles.tableCell}>
                                    {item.name} {item.comment ? ` - ${item.comment.indexOf('Находящийся') !== -1 ?
                                    item.comment.slice(0, item.comment.indexOf('Находящийся')) : item.comment}` : ''}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {months[new Date(utc).getMonth()] + ' ' +
                                    new Date(utc).getDate() + ', ' + new Date(utc).getFullYear() +
                                    '    ' + hour + ":" + minute}
                                </Text>
                            </View>
                        </View>
                    })}

                    {props.isAddedEvents && !props.uniquePeriod && props.addedEvents.length !== 0 &&
                    props.addedEvents.map((item: any, index: number) => {
                        let utc = new Date(item.datatime)
                        let minute = new Date(utc).getMinutes() <= 9 ? `0${new Date(utc).getMinutes()}` : new Date(utc).getMinutes()
                        let hour = new Date(utc).getHours() <= 9 ? `0${new Date(utc).getHours()}` : new Date(utc).getHours()
                        return <View style={styles.tableRow} key={index}>
                            <View style={styles.tableColNum}>
                                <Text style={styles.tableCellInd}>{index + 1}</Text>
                            </View>
                            <View style={styles.tableColName}>
                                <Text style={styles.tableCell}>
                                    {item.name} {item.comment ? ` - ${item.comment.indexOf('Находящийся') !== -1 ?
                                    item.comment.slice(0, item.comment.indexOf('Находящийся')) : item.comment}` : ''}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {months[new Date(utc).getMonth()] + ' ' +
                                    new Date(utc).getDate() + ', ' + new Date(utc).getFullYear() +
                                    '    ' + hour + ":" + minute}
                                </Text>
                            </View>
                        </View>
                    })}

                </View>
                <Text style={styles.pageNumbers} render={({pageNumber, totalPages}) => (
                    `${pageNumber} стр. из ${totalPages}`
                )} fixed/>
            </Page>
        </Document>

    )
}

const AddedNewUserComponent = (props: {
    setUpdate: any,
    isAddNewUser: boolean, handleCloseToAddedNewUser: any, users: any[],
    addedCompanyList: Array<string>, addedPhoneList: Array<string>, addedEmailList: Array<string>,
    addedUserNameList: Array<string>, getData: any
}) => {

    const month = new Date().getMonth() + 1 <= 9 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1;
    const day = new Date().getDate() <= 9 ? `0${new Date().getDate()}` : new Date().getDate();
    const date_ = `${new Date().getFullYear()}-${month}-${day}`

    const [company, setCompany] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [openWarningWindow, setOpenWarningWindow] = useState(false)
    const [warningMessage, setWarningMessage] = useState('');
    const [companyValue, setCompanyValue] = useState([]);
    const [modeCompany, setModeCompany] = useState([]);
    const [people, setPeople] = useState('');
    const [company_type, setCompany_type] = useState('');
    const [type_use_service, setType_use_service] = useState('');
    const [date, setDate] = useState(date_);
    const [role, setRole] = useState(0);
    const [userValue, setUserValue] = useState([{name: 'Помощник администратора', id: 1},
        {name: 'Пользователь', id: 2}]);


    useEffect(() => {
        const axios = new Axios();
        axios.get('library/type/company/')
            .then(res => {
                setCompanyValue(res.data.results)
            });
        axios.get('library/type/use_service/')
            .then(res => {
                setModeCompany(res.data.results)
            });

    }, []);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let addedCompany = companyValue.filter((i: { name: string }) => {
            return i.name === event.target.value
        })
        for (let i of addedCompany) {
            //@ts-ignore
            setCompany_type(i.id)
        }
    };
    const handleChangeRole = (event: any) => {
        setRole(Number(event.target.value))
    };

    const handleChangeMode = (event: any) => {
        let addedCompany = modeCompany.filter((i: { name: string }) => {
            return i.name === event.target.value
        })

        for (let i of addedCompany) {
            //@ts-ignore
            setType_use_service(i.id)
        }
    }

    const handleOpenWindow = () => {
        setOpenWarningWindow(true)
    }
    const handleCloseWindow = () => {
        setOpenWarningWindow(false);
        //  window.location.reload()
    };
    const warningWindow = (text: string) => {
        handleOpenWindow()
        setWarningMessage(text)
    };

    const createUser = () => {
        let body = {
            "date_joined": new Date(date).toISOString(),
            "company": company,
            "first_name": firstName,
            "last_name": lastName,
            "middle_name": middleName,
            "username": username,
            "password": password,
            "email": email,
            "phone": phone,
            "indicative_number": people,
            "company_type": company_type,
            "type_use_service": type_use_service,
            'is_active': false,
            "setting": [1],
            "role": role
        }
        const sendData = () => {

            const axios = new Axios();
            axios.post('admin/users/', body)
                .then(res => {
                    props.handleCloseToAddedNewUser();
                    handleOpenWindow();
                    setWarningMessage('Новый пользователь добавлен!')
                    setCompany('');
                    setFirstName('');
                    setLastName('');
                    setMiddleName('');
                    setUsername('');
                    setPassword('');
                    setEmail('');
                    setPhone('');
                    setRole(0);
                    props.getData()
                })
                .catch(err => {
                    handleOpenWindow();
                    setTimeout(handleCloseWindow, 5000);
                    setWarningMessage('Произошла ошибка. Попробуйте снова!')
                })
        };
        let isAddedCompany = props.addedCompanyList.filter((i: string) => {
            return company === i
        });
        let addedPhonesRefactoring = props.addedPhoneList.filter((i: any) => {
            return i !== null
        }).map((i: string) => i.replace(/\s/g, ''))

        let isAddedPhone = addedPhonesRefactoring.filter((i: string) => {
            return phone.replace(/\s/g, '') === i
        });

        let isAddedEmail = props.addedEmailList.filter((i: string) => {
            return email === i
        });
        let isAddedUserName = props.addedUserNameList.filter((i: string) => {
            return username === i
        });
        return isAddedCompany.length !== 0 ? warningWindow('Организация с таким названием уже существует') :
            isAddedEmail.length !== 0 ? warningWindow('Пользователь с таким email уже существует') :
                isAddedPhone.length !== 0 && phone !== '' ? warningWindow('Организация с таким номером телефона уже существует') :
                    isAddedUserName.length !== 0 ? warningWindow('Пользователь с таким логином уже существует') :
                        company === '' ? warningWindow('Не заполено поле: "Название организации" ') :
                            firstName === '' ? warningWindow('Не заполено поле: "Имя" ') :
                                lastName === '' ? warningWindow('Не заполено поле: "Фамилия" ') :
                                    middleName === '' ? warningWindow('Не заполено поле: "Отчество" ') :
                                        username === '' ? warningWindow('Не заполено поле: "Логин" ') :
                                            password === '' ? warningWindow('Не заполено поле: "Пароль" ') :
                                                email === '' ? warningWindow('Не заполено поле: "Email" ') :
                                                    phone === '' ? warningWindow('Не заполено поле: "Телефон организации" ') :
                                                        people === '' ? warningWindow('Не заполено поле: "Ориентировочная численность организации" ') :
                                                            company_type === '' ? warningWindow('Не заполено поле: "Тип организации" ') :
                                                                type_use_service === '' ? warningWindow('Не заполено поле: "Варианты сервиса ВУ" ') :
                                                                    role === 0 ? warningWindow('Не заполено поле: "Роль" ') :
                                                                        sendData()
    }

    return (<>
            <Dialog onClose={props.handleCloseToAddedNewUser} aria-labelledby="customized-dialog-title"
                    open={props.isAddNewUser}>
                <DialogContent dividers style={{width: '421px'}}>
                    <div className={'wrapper-added-user'}>
                        <select onChange={handleChangeRole}>
                            <option>Роль пользователя</option>
                            {userValue.map((item: { name: string, id: number }, index: number) => {
                                return <option key={index} value={item.id}>{item.name}</option>
                            })}
                        </select>
                        <input placeholder={'Название организации'} value={company} onChange={(e: any) => {
                            setCompany(e.target.value)
                        }}/>
                        <select onChange={handleChange}>
                            <option>Тип организации</option>
                            {companyValue.map((item: { name: string }, index: number) => {
                                return <option key={index}>{item.name}</option>
                            })}
                        </select>
                        <input name="text"
                               placeholder='Ориентировочная численность организации' value={people}
                               onChange={(e: any) => setPeople(e.target.value)}/>
                        <select onChange={handleChangeMode}>
                            <option>Вариант сервиса ВУ</option>
                            {modeCompany.map((item: { name: string }, index: number) => {
                                return <option key={index}>{item.name}</option>
                            })}
                        </select>
                        <input placeholder={'Имя'} value={firstName} onChange={(e: any) => {
                            setFirstName(e.target.value)
                        }}/>
                        <input placeholder={'Фамилия'} value={lastName} onChange={(e: any) => {
                            setLastName(e.target.value)
                        }}/>
                        <input placeholder={'Отчество'} value={middleName} onChange={(e: any) => {
                            setMiddleName(e.target.value)
                        }}/>
                        <input placeholder={'Логин'} value={username} onChange={(e: any) => {
                            setUsername(e.target.value)
                        }}/>
                        <input placeholder={'Пароль'} value={password} onChange={(e: any) => {
                            setPassword(e.target.value)
                        }}/>
                        <input placeholder={'Email'} value={email} onChange={(e: any) => {
                            setEmail(e.target.value)
                        }}/>
                        <input placeholder={'Телефон организации'} value={phone} onChange={(e: any) => {
                            setPhone(e.target.value)
                        }}/>
                        <div>Дата регистрации</div>
                        <input placeholder={'Дата регистрации'} type={'date'}
                               value={date} onChange={(e: any) => {
                            setDate(e.target.value)
                        }}/>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={props.handleCloseToAddedNewUser} color="primary">
                        Отмена
                    </Button>
                    <Button autoFocus onClick={createUser} color="primary">
                        Создать
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog onClose={handleCloseWindow} aria-labelledby="customized-dialog-title"
                    open={openWarningWindow}>
                <DialogContent dividers>
                    {warningMessage}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseWindow} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const ConfirmDialogDeleteUser = ({
                                     toDeleteUser, openWarningDeletedUserWindow, isDeleteUser,
                                     closeWarningDeletedUserWindow
                                 }: any) => {

    return (
        <div>
            <Dialog onClose={closeWarningDeletedUserWindow} aria-labelledby="customized-dialog-title"
                    open={isDeleteUser}>
                <DialogTitle>
                    Предупреждение
                </DialogTitle>
                <DialogContent dividers>
                    Вы уверены, что хотите удалить пользователя ?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={closeWarningDeletedUserWindow} color="primary">
                        Отмена
                    </Button>
                    <Button autoFocus onClick={toDeleteUser} color="primary">
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const DeletedUsers = (props: any) => {

    const restoreUser = (id: any) => {
        const axios = new Axios();
        axios.get(`admin/users/${id}/unarchive/`)
            .then(res => {
                props.setModalForSavedUsers(true);
                props.setSavedMessageForUsers('Восстановление прошло успешно');
                props.getDeletedUsers();
                // props.getData()
                props.clearSearch()
            })
            .catch(err => {
                props.setModalForSavedUsers(true)
                setTimeout(() => {
                    props.setModalForSavedUsers(false)
                }, 3000);
                props.setSavedMessageForUsers('Произошла ошибка. Попробуйте снова!');
                // window.location.reload();
            })
    };


    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });

    function createData(name: string, company: string, date: string, restore: string, id: any) {
        return {name, company, date, restore, id};
    }

    let sortUsers = props.deletedUsersList.sort((a: any, b: any) => {
        // @ts-ignore
        return new Date(b.delete_date) - new Date(a.delete_date)
    })
    const rows = sortUsers.map((i: any) => {
        return createData(`${i.last_name} ${i.first_name} ${i.middle_name}`,
            `${i.company}`, `${i.delete_date}`, `Восстановить`, `${i.id}`)
    });

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog onClose={props.handleCloseModalDeletedUsers} aria-labelledby="customized-dialog-title"
                open={props.openDeletedUsers} fullScreen={fullScreen}>
            <DialogContent dividers>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Пользователь</TableCell>
                                <TableCell>Организация</TableCell>
                                <TableCell align="right">Дата удаления</TableCell>
                                <TableCell align="right">Восстановление</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell component="th" scope="row">{row.company}</TableCell>
                                    <TableCell align="right">{months[new Date(new Date(row.date)).getMonth()] + ' ' +
                                    new Date(new Date(row.date)).getDate() + ', ' + new Date(new Date(row.date)).getFullYear()}</TableCell>
                                    <TableCell align="right">
                                        <button className={'btn-table'} onClick={() => {
                                            restoreUser(row.id)
                                        }}>{row.restore}</button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.handleCloseModalDeletedUsers} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}


const UsersCompanies = ({company, history}: any) => {

    const [openUserEventLog, setOpenUserEventLog] = useState(false)
    const [events, setEvents] = useState([])
    const [countItems, setCountItems] = useState(0)
    const [companyForEventsLog, setCompanyForEventsLog] = useState('')
    const [idCompanyForEvents, setIdCompanyForEvents] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const pageSize = 100
    const [openModal_, setOpenModal_] = useState(false);
    const [savedMessage_, setSavedMessage_] = useState("");

    const handleOpenEventsLog = (id: number) => {
        setIdCompanyForEvents(id)
        const axios = new Axios;
        axios.get(`worker/eventlog/${id}/?page=1&page_size=100`)
            .then(res => {
                //   let arr=res.data.results.filter((i:any)=>i.user_id===Number(localStorage.getItem('userID')))
                setEvents(res.data.results);
                setCountItems(res.data.count)
            })
        setOpenUserEventLog(true);
        axios.get(`worker/company/${localStorage.getItem('userID')}/${id}/`)
            .then(res => {
                setCompanyForEventsLog(res.data.short_organization_name)
            })
    };
    const closeEventsLog = () => {
        setOpenUserEventLog(false)
    };
    const handleOpenModal = () => {
        setOpenModal_(true);
    };
    const handleCloseModal = () => {
        setOpenModal_(false);
    };

    return (
        <div className={'company-grid-container_'}
        >
            {company.map((item: any, number: number) => {

                return <div className='company-link' key={number + getRandomInt(775296)}>
                                <span
                                    onClick={() => {
                                        localStorage.setItem('company', item.short_organization_name);
                                        localStorage.setItem('id', item.id);
                                        localStorage.setItem('addressCompany', item.address);
                                        history.push('/home');
                                    }}

                                    className='span-company-link'
                                >
                                    {item.short_organization_name}
                                </span>

                    <Tooltip title="Журнал событий" placement="right-start">
                        <img src={event} alt={''} className={'user-eventlog'}
                             onClick={() => {
                                 handleOpenEventsLog(Number(item.id))
                             }}/>
                    </Tooltip>
                </div>
            })}
            <EventsLog openEventLog={openUserEventLog} closeEventsLog={closeEventsLog} events={events}
                       companyForEventsLog={companyForEventsLog} idCompanyForEvents={idCompanyForEvents}
                       currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize}
                       countItems={countItems} handleOpenModal={handleOpenModal} setEvents={setEvents}
                       handleCloseModal={handleCloseModal} setSavedMessage={setSavedMessage_}
            />
        </div>
    )
};

const AdminsList = ({
                        adminsList, setAdminsList,
                        isOpenAdminsList, setOpenAdminsList,setUpdate
                    }: any) => {

    interface IComp {
        name: string,
        idComp: number,
    }

    const axios = new Axios()
    const [isOpenAddedOrg, setIsOpenAddedOrg] = useState(false)
    const [addedComp, setAddedComp] = useState([])
    const [choiceComp, setChoiceComp] = useState([] as any)
    const [adminId, setAdminId] = useState('')
    const [upd, setUpd] = useState(0)


    function createData(name: string, company: any, add_org: any, id: string, is_active:boolean) {
        return {name, company, add_org, id,is_active};
    }

    const rows = adminsList.map((i: any) => {
        return createData(`${i.first_name}`,
            <ul>
                <li>{i.company}</li>
                {i.org.map((i: string, index: number) => <li key={index}>{i}</li>)}</ul>,
            'Добавить организацию', `${i.id}`,i.is_active)
    });

    const getAddedOrgs = () => {
        axios.post('worker/company/1/filter/', {delete: false})
            .then(res => {
                let arr = res.data.results.map((i: { short_organization_name: string, name: string, id: number }) => {
                    return {name: i.short_organization_name ? i.short_organization_name : i.name, idComp: i.id}
                })
                setAddedComp(arr)
            })
    }

    const openAddedOrg = (id: string) => {
        setIsOpenAddedOrg(true);
        setAdminId(id)
        getAddedOrgs()
    }


    const onChangeVal = (e: any) => {
        addedComp.map((i: IComp) => {
            if (i.idComp.toString() === e.target.value) {
                setChoiceComp([...choiceComp, {name: i.name, idComp: i.idComp}])
            }
        })
    };

    const deletedAddedComp = (id: number) => {
        setChoiceComp(choiceComp.filter((i: IComp) => {
            return i.idComp !== id
        }))
    }

    const onAddAdminsCompany = (id: number) => {
        setChoiceComp(choiceComp.filter((i: IComp) => {
            return i.idComp !== id
        }));

        axios.put(`worker/company/1/${id}/`, {user: adminId})
            .then(res => {
                setUpd(new Date().getMilliseconds())
            })
    }

    useEffect(() => {
        axios.post('admin/users/filter/', {role: 1, delete: false})
            .then(res => {
                setAdminsList(res.data.results)
            });

        getAddedOrgs()
    }, [upd])

    const blockUserAdmin = (idUser: number | string, activity: boolean) => {

        const axios = new Axios()
        let listUsers = adminsList.filter((item: IUser) => {
            return item.id === Number(idUser)
        })

        let newListUsers = listUsers.map((item: IUser) => {
            return {...item, is_active: !activity}
        })

        axios.put(`admin/users/${idUser}/`, newListUsers[0])
            .then(res => {
                setUpd(new Date().getMilliseconds())
                setUpdate(new Date().getMilliseconds())
            })
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (<>
            <Dialog onClose={() => {
                setOpenAdminsList(false)
            }} aria-labelledby="customized-dialog-title"
                    open={isOpenAdminsList} fullScreen={fullScreen}
            >
                <DialogContent dividers>
                    <TableContainer component={Paper}>
                        <Table aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight: 'bold'}}>Имя</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}}>Организация</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}}>Добавить организацию</TableCell>
                                    <TableCell style={{fontWeight: 'bold'}}>Заблокировать</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                        <TableCell component="th" scope="row">{row.company}</TableCell>
                                        <TableCell>
                                            <button className={'btn-table'} onClick={() => openAddedOrg(row.id)}>
                                                {row.add_org}</button>
                                        </TableCell>
                                        <TableCell>
                                            <button className={'btn-table'}
                                                    style={row.is_active ? {color: 'black'} : {color: '#dc9a9a'}}
                                                    onClick={() => {
                                                        blockUserAdmin(row.id, row.is_active)
                                                    }}
                                            >
                                                {row.is_active ? 'Заблокировать' : 'Разблокировать'}</button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        setOpenAdminsList(false)
                    }} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={() => {
                setIsOpenAddedOrg(false)
            }} aria-labelledby="customized-dialog-title"
                    open={isOpenAddedOrg} fullScreen={fullScreen}>
                <DialogContent dividers>
                    <div className={'wrapper-added-user'}>
                        <select onChange={onChangeVal}>
                            <option>Выберите организацию</option>
                            {addedComp.map((item: { name: string, idComp: number }, index: number) => {
                                return <option key={index} value={`${item.idComp}`}>{item.name}</option>
                            })}
                        </select>
                    </div>
                    <div>
                        {choiceComp.map((i: { name: string, idComp: number }, index: number) => {
                            return <div key={index} className={'wrap-choiced-comp'}>
                                <span>{i.name}</span>
                                <button className={'btn-table'} style={{background: '#dc9a9a'}}
                                        onClick={() => deletedAddedComp(i.idComp)}>Удалить
                                </button>
                                <button className={'btn-table'} onClick={() => onAddAdminsCompany(i.idComp)}>Добавить
                                </button>
                            </div>
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        setIsOpenAddedOrg(false)
                    }} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
};

const OrdinaryUsersCompany=({ordinaryUserCompany,history}:any)=>{

    useEffect(()=>{
        const axios = new Axios;
        axios.get(`worker/company/${ordinaryUserCompany.id}/`)
            .then(res=>{
                setCompany(res.data.results)
            })
    },[ordinaryUserCompany.id])

    const [openUserEventLog, setOpenUserEventLog] = useState(false)
    const [events, setEvents] = useState([])
    const [countItems, setCountItems] = useState(0)
    const [companyForEventsLog, setCompanyForEventsLog] = useState('')
    const [idCompanyForEvents, setIdCompanyForEvents] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const pageSize = 100
    const [company, setCompany] = useState([] as any)
    const [openModal_, setOpenModal_] = useState(false);
    const [savedMessage_, setSavedMessage_] = useState("");

    const handleOpenEventsLog = (id: number) => {
        setIdCompanyForEvents(id)
        const axios = new Axios;
        axios.get(`worker/eventlog/${id}/?page=1&page_size=100`)
            .then(res => {
                //   let arr=res.data.results.filter((i:any)=>i.user_id===Number(localStorage.getItem('userID')))
                setEvents(res.data.results);
                setCountItems(res.data.count)
            })
        setOpenUserEventLog(true);
        axios.get(`worker/company/${localStorage.getItem('userID')}/${id}/`)
            .then(res => {
                setCompanyForEventsLog(res.data.short_organization_name)
            })
    };
    const closeEventsLog = () => {
        setOpenUserEventLog(false)
    };
    const handleOpenModal = () => {
        setOpenModal_(true);
    };
    const handleCloseModal = () => {
        setOpenModal_(false);
    };
    return (
        <div className={'company-grid-container_'}
        >
            {company.map((item: any, number: number) => {

                return <div className='company-link' key={number + getRandomInt(775296)}>
                                <span
                                    onClick={() => {
                                        localStorage.setItem('company', item.short_organization_name);
                                        localStorage.setItem('id', item.id);
                                        localStorage.setItem('addressCompany', item.address);
                                        history.push('/home');
                                    }}

                                    className='span-company-link'
                                >
                                    {item.short_organization_name}
                                </span>

                    <Tooltip title="Журнал событий" placement="right-start">
                        <img src={event} alt={''} className={'user-eventlog'}
                             onClick={() => {
                                 handleOpenEventsLog(Number(item.id))
                             }}/>
                    </Tooltip>
                </div>
            })}

            <EventsLog openEventLog={openUserEventLog} closeEventsLog={closeEventsLog} events={events}
                       companyForEventsLog={companyForEventsLog} idCompanyForEvents={idCompanyForEvents}
                       currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize}
                       countItems={countItems} handleOpenModal={handleOpenModal} setEvents={setEvents}
                       handleCloseModal={handleCloseModal} setSavedMessage={setSavedMessage_}
            />
        </div>
    )
}