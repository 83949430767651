import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import './Envelopes.css';
import AutocompleteOffice from '../../components/AutocompleteOffice/AutocompleteOffice';
import EnvelopePDF from '../../components/PDFOther/EnvelopePDF'
import Axios from '../../utils/axios';
import {pdf} from "@react-pdf/renderer";

const Envelopes = ({...props}: any) => {

    const company = localStorage.getItem('company');
    const [valueOffice, setValueOffice] = useState('');
    const [address_, setAddress_] = useState({
        address:'',
        area:'',
        index:'',
        settlement:''
    });
    const [valueShortOffice, setValueShortOffice] = useState('');
    const [address, setAddress] = useState({} as any);
    const [logo, setLogo] = useState('');
    const [addressCompany, setAddressCompany] = useState({
        address: '',
        settlement: '', // city
        index: '',
        district: '',
        area: '',
        postal_address: '',
        logotip: ''
    });

    const generatePDFDocument = async (data_pdf: {
        valueShortOffice: any, addressCompany: any, address: any, company: any, logo: any, valueOffice:any,
    address_:object}) => {
        const blobPdf = pdf(EnvelopePDF(data_pdf));
        blobPdf.updateContainer(EnvelopePDF(data_pdf));
        const result = await blobPdf.toBlob();

        saveAs(result, "Envelope.pdf");
    };


    const handleChangeOffice = (event: any) => {
        setValueOffice(event.name as string);
        setValueShortOffice(event.short_name as string);
        //short_name
        // debugger;
        setAddress(event as string);
    };

    useEffect(() => {

        const axios = new Axios();
        axios.get(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}`)
            .then(res => {
                setAddressCompany(res.data as any);
                setLogo(res.data.logotip)
                setAddress_(
                    {
                        address:res.data.postal_address,
                        area: res.data.postal_area,
                        index: res.data.postal_index,
                        settlement: res.data.postal_settlement
                    }
                )

          fetch(res.data.logotip)
              .then(res=>{
               if(res.status===404) {
                   setLogo('')
               }
              })
            })
            .catch(err => {
                console.log(err);
            })
    }, []);



    return (
        <>
            <div>
                <span className='title_letters_'>Создание конверта</span>
                <div className='toolbar'>
                    <div className='btn-wrapper'>
                        <Button variant="contained" onClick={() => props.history.push('/letters')}>
                            Назад
                        </Button>
                    </div>

                    <Button variant="contained" onClick={() => {
                        generatePDFDocument({valueShortOffice, addressCompany, address, company, logo,
                            valueOffice,address_
                            })
                    }}>
                        Сохранить
                    </Button>
                </div>
                <div className='envelope-container'>
                    <div className='envelope-box1'>
                        <section>
                        <div className={'wrapper_address'}>
                            {addressCompany.logotip && logo !=='' && <img alt={''} src={addressCompany.logotip} className={'logo-wrap-img'}/>}

                                <div style={{width: '298px', fontSize: '18px'}}>
                                    {company}
                                    <br/>
                                    {address_.address}
                                    <br/>
                                    {address_.index}, {address_.settlement}
                                    <br/>
                                    {address_.area}
                                </div>
{/*                            }*/}
                        </div>

                        </section>
                        <div className='postageStamp'>

                        </div>
                    </div>
                    <div className='envelope-box2' style={{fontSize:'18px'}}>
                        <span>Военному комиссару</span>
                        <AutocompleteOffice
                            handleChangeOfficeName={handleChangeOffice}
                            setOfficeName={setValueOffice}
                        />
                        <span className=''>
                            {address.letter_street}
                            <br/>
                            {Object.keys(address).length ===0 ? '' : `${address.index}, ${address.letter_locality}`}

                            <br/>
                            {address.letter_district===null ? '' : address.letter_district}
                        </span>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Envelopes;


