import React, { useState, useEffect } from 'react';
import OfficesTable from '../components/OfficesTable/OfficesTable';
import Axios from '../utils/axios';
import Button from '@material-ui/core/Button';
import AutocompleteOffice from '../components/AutocompleteOffice/AutocompleteOffice';
import classes from '../components/Documents/Documents.module.css';
import OfficesToolbar from '../components/OfficesToolbar/OfficesToolbar';
import { connect } from 'react-redux';
import SettingsModalOffices from '../components/TableSettingsModal/SettingsModalOffices';

interface Column {
    id: 'address' | 'name' | 'id' | 'letter' | 'people';
    label: string;
    minWidth?: number;
    align?: 'center';
    isSelected?: boolean,
    width?: number;
}

interface Item {
    id: string;
    name: string;
    address: string;
}

function Offices({ tableSettings, ...props }: any) {
    const [countItems, setCountItems] = useState(0);
    const [items, setItems] = useState<Item[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const [order, setOrder] = React.useState('desc');
    const [orderId, setOrderId] = React.useState('id');

    const [officeName, setOfficeName] = useState('');

    const [isActive, setActive] = useState(false);

    const [openModal, setOpenModal] = useState(false);

    const [columns, setColumns] = useState(null as any);
    const [defaultColumns, setDefaultColumns] = useState([] as any);

    const handleClickOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const [filterCity, setFilterCity] = useState({
        city: '',
        district: '',
        region: ''
    })

    const handleChangeOfficeName = (event: any) => {
        setOfficeName(event.name as string);
    };

    const updateTable = () => {
        let active = null as any;

        if (isActive) {
            active = 1
        } else active = undefined

        // TODO
        let order_ = '';
        if (order === 'asc') {
            order_ = '-'
        }

        let data = {
            order: `${order_}${orderId}`,
            "active": active,
            "worker": localStorage.getItem('id'),
            "name__contains": undefined as any,
            area: filterCity.region.length > 0 ? filterCity.region : undefined,
            city: filterCity.city.length > 0 ? filterCity.city : undefined,
            district: filterCity.district.length > 0 ? filterCity.district : undefined,
        };

        if (officeName.length > 0) {
            data = {
                order: `${order_}${orderId}`,
                "worker": localStorage.getItem('id'),
                "active": active,
                "name__contains": officeName,
                area: filterCity.region.length > 0 ? filterCity.region : undefined,
                city: filterCity.city.length > 0 ? filterCity.city : undefined,
                district: filterCity.district.length > 0 ? filterCity.district : undefined,
            }
        }
        const axios = new Axios();
        axios.post(`worker/office/filter/?page=${page + 1}&page_size=${rowsPerPage}`, data)
            .then(res => {
                setCountItems(res.data.count);
                setItems(res.data.results);
                // setPage(0);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        updateTable();
    }, [officeName, page, rowsPerPage, order, orderId, isActive, filterCity]);

    useEffect(() => {
        const axios = new Axios();
        axios.get(`user/me/`)
            .then(res => {
                const result = Object.keys(res.data.setting['1']);
                setDefaultColumns(result)
            })
    }, []);

    useEffect(() => {
        if (defaultColumns.length > 0) {
            const axios = new Axios();
            axios.get(`worker/field/1`)
                .then(res => {
                    const result = [];
                    for (let i = 0; i < defaultColumns.length; i += 1) {
                        result.push({});
                    }

                    for (const [key, value] of Object.entries(res.data)) {
                        const index = defaultColumns.indexOf(key);
                        if (index !== -1) {
                            result[index] = {
                                id: key,
                                label: value,
                                isSelected: true
                            } as Column;
                        } else {
                            result.push({
                                id: key,
                                label: value,
                                isSelected: false
                            } as Column)
                        }
                    }

                    setColumns(result.filter((i:any)=>i.id !=='area' && i.id !=="district"));
                })
                .catch(err => {
                    console.log(err);
                })
        }

    }, [defaultColumns])

    return (
        <div style={{margin: '110px auto 10px auto', width:'98%'}}>
            <span className={'title_'} style={{margin:'0 auto'}}>Военные комиссариаты Республики Беларусь</span>
            <div className='btn-wrapper'>
                <Button variant="contained" onClick={() => props.history.push('/home')} >
                    Назад
                </Button>
            </div>


            <OfficesToolbar
                isActive={isActive}
                setActive={setActive}
                setFilterCity={setFilterCity}
            />


            <div className={classes.forOffices}>
                <div>
                    <Button variant="outlined" onClick={() => { handleClickOpen(); }} >
                        Вид
                    </Button>
                </div>
                <div className={classes.autocomplete}>
                    <p className={classes.p_autocomplete}>Военкомат: </p>
                    <AutocompleteOffice
                        handleChangeOfficeName={handleChangeOfficeName}
                        updateTable={updateTable}
                        setOfficeName={setOfficeName}
                    />
                </div>
            </div>

            <OfficesTable
                items={items}
                countItems={countItems}
                setItems={setItems}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setOrderId={setOrderId}
                order={order}
                setOrder={setOrder}
                columns={columns}
            />

            <SettingsModalOffices
                open={openModal}
                handleClose={handleClose}
                columns={columns}
                setColumns={setColumns}
                essence={1}
                updateTable={updateTable}
            />
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    tableSettings: state.tableSettings['1'],
});

const mapActionsToProps = {
    // loginUser
};
export default connect(mapStateToProps, mapActionsToProps)(Offices);
// export default Offices;
