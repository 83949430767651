import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Axios from '../../utils/axios';
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Alert from '@material-ui/lab/Alert'

const SuccessfulSaveModal = ({message, open, handleClose}: any) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}

const ForgetPassword = (props: any) => {

    const [email, setEmail] = useState('');
    const [isSuccessSend, setSuccessSend] = useState(false);
    const [isNote, setNote] = useState(false);
    const [gettingId, setGettingId] = useState(0);
    const [code, setCode] = useState('');
    const [pass, setPass] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [mess, setMess] = useState('');
    const [success, setSuccess] = useState(false);

    const handleOpenSuccessModal = () => {
        setSuccessSend(true)
    };
    const handleCloseSuccessModal = () => {
        setSuccessSend(false)
    }
    const handleOpenNoteModal = () => {
        setNote(true)
    }
    const handleCloseNoteModal = () => {
        setNote(false)
    }

    const sendEmail = () => {
        const axios = new Axios();
        axios.post(`password/restore/ `, {"email": email})
            .then(res => {
                props.handleClose();
                handleCloseNoteModal()
                handleOpenSuccessModal();
                setGettingId(res.data.id)
            })
            .catch(err => {
                setSuccess(false)
                handleOpenNoteModal();
                setMess('Пользователь с таким email отсутствует. Попробуйте еще раз')
                setTimeout(handleCloseNoteModal, 4000);
            })
    };

    const sendCode=()=>{
const axios=new Axios();
let data={
    "code":Number(code),
    "password":pass
};
const sendData=()=>{
    axios.post(`password/recovery/${gettingId}`,data)
        .then(res=>{
            setSuccess(true)
            handleCloseSuccessModal()
            handleOpenNoteModal();
            setMess('Пароль успешно изменен! Информация об изменении отправлена Вам на email')
            setTimeout(handleCloseNoteModal, 6000);
        })
        .catch(err=>{
            setSuccess(false)
            handleOpenNoteModal();
            setMess('Что-то пошло нетак... Попробуйте еще раз')
            setTimeout(handleCloseNoteModal, 2000);
        })
}

const sendErrorRepeat=()=>{
    setSuccess(false)
    handleOpenNoteModal();
    setMess('Пароли не совпадают.')
    setTimeout(handleCloseNoteModal, 2000);
        }
        const sendErrorLength=()=>{
            setSuccess(false)
            handleOpenNoteModal();
            setMess('Вы ввели менее 8 символов.')
            setTimeout(handleCloseNoteModal, 2000);
        }
 return pass!==repeatPassword ? sendErrorRepeat() : pass.length<8 ? sendErrorLength() : sendData()

    }

    return (<>
        <Dialog
            open={props.open}
            onClose={props.handleClose}
        >
            {/*<DialogTitle>Для восстановления пароля <a href={'/#contacts'}>свяжитесь с администратором</a></DialogTitle>*/}
            <DialogTitle>
                <div className={'forget-modal'}>
                    <span>Введите email, указанный при регистрации</span>
                    <input value={email} onChange={(e: any) => {
                        setEmail(e.target.value)
                    }}/>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={sendEmail} color="primary" autoFocus>
                    Отправить
                </Button>
                <Button onClick={props.handleClose} color="primary" autoFocus>
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={isSuccessSend}
            onClose={handleCloseSuccessModal}
        >
            <DialogTitle>
                <div className={'forget-modal'}>
                    <span>Введите код для смены пароля с сообщения, отправленного на Ваш email</span>
                    <input value={code} onChange={(e:any)=>{setCode(e.target.value)}}/>
                    <span style={{paddingTop:'10px',paddingBottom:'0px'}}>Введите новый пароль</span>
                    <span style={{fontSize:'13px'}}>(Пароль должен включать буквы и цифры, не менее 8 символов):</span>
                    <input value={pass} onChange={(e:any)=>{setPass(e.target.value)}} placeholder={'Пароль'}
                    style={{width:'300px',marginBottom:'8px'}}/>
                    <input value={repeatPassword} onChange={(e:any)=>{setRepeatPassword(e.target.value)}} placeholder={'Повторите пароль'}
                           style={{width:'300px'}}/>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                </DialogContentText>
            </DialogContent>
            <Button onClick={sendCode} color="primary" autoFocus>
                Ок
            </Button>
        </Dialog>

        <Dialog
            open={isNote}
            onClose={handleCloseNoteModal}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Alert variant="outlined" severity={success ? "success" : "error"} style={success ? {background:"#d6f4b3"}:{background: 'wheat'}}>
                        {mess}
                    </Alert>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    </>)
}

export default SuccessfulSaveModal;
export {ForgetPassword}
