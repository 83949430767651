import React, {useState, useEffect} from 'react';
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {FileUploader} from '../FileUploader/FileUploader'
import classes from './Documents.module.css';
import AutocompleteOffice from '../AutocompleteOffice/AutocompleteOffice'
import Axios from '../../utils/axios';
import AutocompleteDocTypes from './AutocompleteDocTypes';
import {getUserIdAgainLetterAC, onAgainSendLetterAC} from "../../redux/actions/userActions";


const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },

    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function NewNoticeModal(props: any) {

    const month = new Date().getMonth() + 1 <= 9 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1;
    const day = new Date().getDate() <= 9 ? `0${new Date().getDate()}` : new Date().getDate();
    const data = `${new Date().getFullYear()}-${month}-${day}`

    const [valueOffice, setValueOffice] = useState(0);
    const [valueType, setValueType] = React.useState('');
    const [file, setFile] = useState<any>('');
    const [types, setTypes] = useState<any>([{}]);
    const [error, setError] = useState('');
    const [dateRegistr, setDetaRegistr] = useState(data);
    const [answersDate, setAnswersDate] = useState([{name: 'да', checked: false}, {name: 'нет', checked: false}]);
    const [isOpenAnswerDate, setOpenAnswerDate] = useState(false);
    const [ansData, setAnsData] = useState('');


    const handleChangeOffice = (event: any) => {
        setValueOffice(event.id as number);
    };

    const addNewType = () => {
        let r = types.find((type: any) => type.name === valueType);

        if (!r) {
            const axios = new Axios();
            axios.post('worker/type/', {
                name: valueType
            })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const submit = () => {
        if (valueType === '' || file === '' || valueOffice === 0) {
            setError('Заполните все поля и загрузите документ')
        } else {
            addNewType();
            let data = `${dateRegistr}T12:00:00.016954+03:00`
            let body = new FormData();
            body.append("docs", file);
            body.append("office_id", String(valueOffice));
            body.append("type_id", valueType);
            body.append("user", String(localStorage.getItem('id')));
            body.append("register_date", data);
            body.append("entering", props.type==='reports' ? 'false' : 'true');
            body.append("flag_date", ansData === '' ? '' : `${ansData}T12:00:00.016954+03:00`);
            body.append("name", props.respPerson);
            const axios = new Axios();
            axios.post(`worker/report/${localStorage.getItem('id')}/`, body)
                .then(res => {
                    clear();
                    props.handleClose();
                    props.updateTable(new Date().getMilliseconds());
                })
                .catch(err => {
                    console.log(err);
                })
        }

    }
    const clear = () => {
        setValueType('');
        setValueOffice(0);
        setFile('');
        setError('');
    }

    useEffect(() => {
        clear();
    }, [props.open])

    useEffect(() => {
        const fetchData = () => {
            const axios = new Axios();
            axios.get(`worker/type/`)
                .then(res => {
                    setTypes(res.data.results);
                })
                .catch(err => {
                    console.log(err);
                })
        };
        fetchData();
    }, []);

    const dateAnswerOpen = (name: string, checked: boolean) => {
        let arr = answersDate.map((i: any) => {
            if (i.name === name) {
                return {...i, checked: !checked}
            } else return i
        })
        setAnswersDate(arr);

        return !checked && name === 'да' ? setOpenAnswerDate(true) : setOpenAnswerDate(false)
    }

    return (
        <div>
            <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}
                    maxWidth='xl'>
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    Добавить
                </DialogTitle>
                <DialogContent dividers>
                    <p className={classes.error}>{error}</p>
                    <div className={classes.body}>
                        <div className={classes.toolbar}>
                            <div className={classes.toolbar}>
                                <p className={classes.p_autocomplete}>Военкомат*: </p>
                                <AutocompleteOffice
                                    handleChangeOfficeName={handleChangeOffice}
                                    setOfficeName={setValueOffice}
                                />
                            </div>

                            <div className={classes.toolbar}>
                                <p className={classes.p_autocomplete}>Тип документа*: </p>
                                <AutocompleteDocTypes
                                    value={valueType}
                                    setValue={setValueType}
                                    items={types}
                                />
                            </div>
                        </div>
                        <div className={classes.fileUpload}>
                            <FileUploader setFile={setFile}/>
                        </div>

                        <div className={'wrapper-dates-data'}>
                            <div>
                                <span style={{paddingRight: '15px'}}>Дата регистрации</span>
                                <input type={'date'} value={dateRegistr}
                                       onChange={(e) => {
                                           setDetaRegistr(e.target.value)
                                       }}/>
                            </div>
                            <div>
                                <span style={{paddingRight: '15px'}}>Дата ответа</span>
                                {isOpenAnswerDate && <input type={'date'}
                                                            value={ansData} onChange={(e) => {
                                    return isOpenAnswerDate ?
                                        setAnsData(e.target.value) : setAnsData('')
                                }}/>}
                                {answersDate.map((i: any, index: number) => {
                                    return <div key={index}> {i.name} <input type='checkbox'
                                                                             checked={i.checked} onChange={(e) => {
                                        dateAnswerOpen(i.name, i.checked)
                                    }}/></div>
                                })}
                            </div>
                        </div>
                        <div>
                            <div>Ответственный</div>
                            <input style={{padding:'10px',width:'260px'}} type={'text'} value={props.respPerson}
                                   onChange={(e)=>{props.setRespPerson(e.target.value)}}/>
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={submit} variant="contained" color="primary">
                        Добавить
                    </Button>
                    <Button autoFocus onClick={props.handleClose} color="primary">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
