import React, { useEffect } from 'react';
import MaterialTable, { Column } from 'material-table';
import './PersonalCard_v2.css'

export interface Row {
    text: string,
    tableData?: any
}

export interface TableState {
    columns: Array<Column<Row>>;
    data: Row[] | any;
}

const getData = (marks: any) => {
    const result = [] as any;
    marks.map((item: any) => {
        const data = {
            text: item.text,
        }

        result.push(data)
    });
    return result;
}

export default function TableMarks({ marks, setStateTable }: any) {
    const [state, setState] = React.useState<TableState>({
        columns: [
            {
                title: 'Дополнительные отметки',
                field: 'text',
                cellStyle: {
                    width: 500,
                    minWidth: 500,
                    alignContent: 'left'
                },
                headerStyle: {
                    width: 500,
                    minWidth: 500,
                    textAlign: 'center'
                },
            },
        ],
        data: getData(marks),
    });

    useEffect(() => {
        setState((prevState) => {
            let data = [...prevState.data];
            data = getData(marks);
            return { ...prevState, data };
        });
    }, [marks]);

    return (
        <div className='tableVerification-wrp'>
            <div style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline'}}><sup>*</sup> После внесения новой записи в "Дополнительные отметки" или "Отметки о сверке данных",
                прежде,чем скачать Отчёт - нажмите √ внесенной записи, затем кнопку Сохранить</div>
            <MaterialTable
                title=""
                columns={state.columns}
                data={state.data}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                setState((prevState) => {
                                    const data = [...prevState.data];
                                    data.push(newData);
                                    return { ...prevState, data };
                                });
                                setStateTable((prevState: any) => {
                                    const obj = {
                                        text: newData.text,
                                    };
                                    const data = prevState;
                                    data.push(obj);
                                    return data;
                                })
                            }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                if (oldData) {
                                    setState((prevState) => {
                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;
                                        return { ...prevState, data };
                                    });
                                    setStateTable((prevState: any) => {

                                        const obj = {
                                            text: newData.text,
                                        };

                                        const data = prevState;
                                        data[oldData.tableData.id] = obj;
                                        return data;
                                    })
                                }
                            }, 600);

                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            setTimeout(() => {
                                resolve();
                                setState((prevState) => {
                                    const data = [...prevState.data];
                                    data.splice(data.indexOf(oldData), 1);
                                    return { ...prevState, data };
                                });

                                setStateTable((prevState: any) => {
                                    const data = prevState;
                                    data.splice(oldData.tableData.id, 1);
                                    return data;
                                })
                            }, 600);
                        }),

                }}
                options={{
                    search: false,
                    paging: false,
                }}
                localization={{
                    toolbar: {
                        nRowsSelected: '{0} выбрано строк'
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Нет записей',
                        filterRow: {
                            filterTooltip: 'Фильтр'
                        },
                        editRow: {
                            deleteText: 'Вы уверены, что хотите удалить запись?',
                            cancelTooltip: 'Отмена',
                            saveTooltip: 'Сохранить'
                        },
                        addTooltip: 'Добавить',
                        deleteTooltip: 'Удалить',
                        editTooltip: 'Изменить',
                    }
                }}
            />
        </div>
    );
}