import React, {CSSProperties, useEffect, useState} from 'react';
import style from './Voucher.module.css';
import Button from "@material-ui/core/Button";
import Axios from "../../utils/axios";
import {createStyles, Theme, makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Font, pdf, StyleSheet} from "@react-pdf/renderer";
import {
    Page,
    Text,
    View,
    Document,
} from "@react-pdf/renderer";
import font_1 from '../../fonts/Roboto/Roboto-Light.ttf';
import font_2 from '../../fonts/Roboto/Roboto-Bold.ttf';

Font.register({
    family: "Roboto",
    src: font_1,
    format: "truetype",
});
Font.register({
    family: "Roboto",
    src: font_2,
    format: "truetype",
});

export default function Voucher(props: any) {

    let line_1 = ['Л', 'и', 'н', 'и', 'я']
    let line_2 = ['о', 'т', 'р', 'е', 'з', 'а']
    const [number, setNumber] = React.useState('');
    const [took, setTook] = React.useState('');
    const [took_1, setTook_1] = React.useState('');
    const [rank, setRank] = React.useState('')
    const [rankTo, setRankTo] = React.useState('')
    const [fullName, setFullName] = React.useState('')
    const [middleName, setMiddleName] = React.useState('')
    const [fullNameTo, setFullNameTo] = React.useState('')
    const [middleNameTo, setMiddleNameTo] = React.useState('')
    const [birth, setBirth] = React.useState('')
    const [series, setSeries] = React.useState('')
    const [series_1, setSeries_1] = React.useState('')
    const [target, setTarget] = React.useState('')
    const [validateDay, setValidateDay] = React.useState('')
    const [validateMonthDay, setValidateMonthDay] = React.useState('')
    const [validateYear, setValidateYear] = React.useState('')
    const [day, setDay] = React.useState('')
    const [monthDay, setMonthDay] = React.useState('')
    const [year, setYear] = React.useState('')
    const [official, setOfficial] = React.useState('')
    const [file, setFile] = useState<any>('');
    const [monthValidate, setMonthValidate] = useState<any>('');
    const [month, setMonth] = useState<any>('');
    const [openModal, setOpenModal] = useState(false);
    const [savedMessage, setSavedMessage] = useState("");
    const [acceptDay, setAcceptDay] = useState("");
    const [acceptMonth, setAcceptMonth] = useState("");
    const [acceptYear, setAcceptYear] = useState("");
    const [registerMonth, setRegisterMonth] = useState("");
    const [workers, setWorkers] = useState([]);
    const [worker, setWorker] = useState('');
    const [gettingPerson, setGettingPerson] = useState('');

    const [isSavedPdf, setIsSavedPdf] = useState(false);


    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        const axios = new Axios();
        axios.get(`worker/receipt/${localStorage.getItem('id')}/`)
            .then(res => {
            })
        const data = {
            "delete": false
        }
        axios.post(`worker/${localStorage.getItem('id')}/filter/`, data)
            .then(res => {
                setWorkers(res.data.results)

            })
    }, []);

    useEffect(() => {
        if (validateMonthDay.indexOf('январ') !== -1 || validateMonthDay.indexOf('01') !== -1) setMonthValidate('01')
        if (validateMonthDay.indexOf('феврал') !== -1 || validateMonthDay.indexOf('02') !== -1) setMonthValidate('02')
        if (validateMonthDay.indexOf('март') !== -1 || validateMonthDay.indexOf('03') !== -1) setMonthValidate('03')
        if (validateMonthDay.indexOf('апрел') !== -1 || validateMonthDay.indexOf('04') !== -1) setMonthValidate('04')
        if (validateMonthDay.indexOf('мая') !== -1 || validateMonthDay.indexOf('05') !== -1 || validateMonthDay.indexOf('май') !== -1) setMonthValidate('05')
        if (validateMonthDay.indexOf('июн') !== -1 || validateMonthDay.indexOf('06') !== -1) setMonthValidate('06')
        if (validateMonthDay.indexOf('июл') !== -1 || validateMonthDay.indexOf('07') !== -1) setMonthValidate('07')
        if (validateMonthDay.indexOf('август') !== -1 || validateMonthDay.indexOf('08') !== -1) setMonthValidate('08')
        if (validateMonthDay.indexOf('сентябр') !== -1 || validateMonthDay.indexOf('09') !== -1) setMonthValidate('09')
        if (validateMonthDay.indexOf('октябр') !== -1 || validateMonthDay.indexOf('10') !== -1) setMonthValidate('10')
        if (validateMonthDay.indexOf('ноябр') !== -1 || validateMonthDay.indexOf('11') !== -1) setMonthValidate('11')
        if (validateMonthDay.indexOf('декабр') !== -1 || validateMonthDay.indexOf('12') !== -1) setMonthValidate('12')

        if (monthDay.indexOf('январ') !== -1 || monthDay.indexOf('01') !== -1) setMonth('01')
        if (monthDay.indexOf('феврал') !== -1 || monthDay.indexOf('02') !== -1) setMonth('02')
        if (monthDay.indexOf('март') !== -1 || monthDay.indexOf('03') !== -1) setMonth('03')
        if (monthDay.indexOf('апрел') !== -1 || monthDay.indexOf('04') !== -1) setMonth('04')
        if (monthDay.indexOf('мая') !== -1 || monthDay.indexOf('05') !== -1 || monthDay.indexOf('май') !== -1) setMonth('05')
        if (monthDay.indexOf('июн') !== -1 || monthDay.indexOf('06') !== -1) setMonth('06')
        if (monthDay.indexOf('июл') !== -1 || monthDay.indexOf('07') !== -1) setMonth('07')
        if (monthDay.indexOf('август') !== -1 || monthDay.indexOf('08') !== -1) setMonth('08')
        if (monthDay.indexOf('сентябр') !== -1 || monthDay.indexOf('09') !== -1) setMonth('09')
        if (monthDay.indexOf('октябр') !== -1 || monthDay.indexOf('10') !== -1) setMonth('10')
        if (monthDay.indexOf('ноябр') !== -1 || monthDay.indexOf('11') !== -1) setMonth('11')
        if (monthDay.indexOf('декабр') !== -1 || monthDay.indexOf('12') !== -1) setMonth('12')

        if (acceptMonth.indexOf('январ') !== -1 || acceptMonth.indexOf('01') !== -1) setRegisterMonth('01')
        if (acceptMonth.indexOf('феврал') !== -1 || acceptMonth.indexOf('02') !== -1) setRegisterMonth('02')
        if (acceptMonth.indexOf('март') !== -1 || acceptMonth.indexOf('03') !== -1) setRegisterMonth('03')
        if (acceptMonth.indexOf('апрел') !== -1 || acceptMonth.indexOf('04') !== -1) setRegisterMonth('04')
        if (acceptMonth.indexOf('мая') !== -1 || acceptMonth.indexOf('05') !== -1 || acceptMonth.indexOf('май') !== -1) setRegisterMonth('05')
        if (acceptMonth.indexOf('июн') !== -1 || acceptMonth.indexOf('06') !== -1) setRegisterMonth('06')
        if (acceptMonth.indexOf('июл') !== -1 || acceptMonth.indexOf('07') !== -1) setRegisterMonth('07')
        if (acceptMonth.indexOf('август') !== -1 || acceptMonth.indexOf('08') !== -1) setRegisterMonth('08')
        if (acceptMonth.indexOf('сентябр') !== -1 || acceptMonth.indexOf('09') !== -1) setRegisterMonth('09')
        if (acceptMonth.indexOf('октябр') !== -1 || acceptMonth.indexOf('10') !== -1) setRegisterMonth('10')
        if (acceptMonth.indexOf('ноябр') !== -1 || acceptMonth.indexOf('11') !== -1) setRegisterMonth('11')
        if (acceptMonth.indexOf('декабр') !== -1 || acceptMonth.indexOf('12') !== -1) setRegisterMonth('12')
    }, [validateMonthDay, monthDay, acceptMonth])

    const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumber(event.target.value)

    };

    const saveLetter =  () => {
const isPassedToServer= async ()=>{
    setIsSavedPdf(false)
    let body = new FormData();

    let w = workers.filter((i: any) => {
        return i.full_name.indexOf(fullName) !== -1 ? i.id : console.log('err')
    })

    for (let i of w) {
        // @ts-ignore
        setWorker(i.id)
    };
    const data_pdf: any = {
        number, rank,rankTo, fullName, fullNameTo,middleNameTo, middleName,
        birth, series,series_1, line_1, line_2,
        target, validateDay, validateMonthDay,
        validateYear, took, took_1, gettingPerson,
        official, day, monthDay, year, acceptDay,
        acceptMonth, acceptYear
    }
    const blobPdf = pdf(VoucherPdf(data_pdf));
    blobPdf.updateContainer(VoucherPdf(data_pdf));
    const result = blobPdf.toBlob();
    setFile(result);
    let validDate:string =validateYear==='' && monthValidate==='' && validateDay==='' ? '' : `20${validateYear}-${monthValidate}-${validateDay}`
    let acceptDate:string =acceptYear==='' && registerMonth==='' && acceptDay==='' ? '' : `20${acceptYear}-${registerMonth}-${acceptDay}`
    let date:string =year==='' && month==='' && day==='' ? '' : `20${year}-${month}-${day}`

    body.append("full_name_worker", `${fullNameTo} ${middleNameTo}`);
    body.append("target", target);
    body.append("docs", await result);
    body.append("date_valid_by", validDate);
    body.append("full_name_accepted", official);
    body.append("register_date", acceptDate);
    body.append("return_date", date);
    //@ts-ignore
    body.append("company", `${localStorage.getItem('id')}`);

        const axios = new Axios();
        axios.post(`worker/receipt/${localStorage.getItem('id')}/`, body)
            .then(res => {
                handleOpenModal();
                setTimeout(handleCloseModal, 5000);
                setSavedMessage('Данные успешно сохранены')
            })
            .catch(err => {
                handleOpenModal();
                setTimeout(handleCloseModal, 5000);
                // if (w.length === 0 || w.length===workers.length) {
                //     setSavedMessage('Неправильно указаны имя или фамилия работника')
                // } else if(validateDay==='' || validateMonthDay==='' ||
                //     validateYear==='')  setSavedMessage('Не заполнено поле "Расписка действительна по..." ')
                // else if(day==='' || monthDay==='' ||
                //     year==='')  setSavedMessage('Не заполнена дата принятия удостоверения призывника или военного билета')
                // else if(acceptDay==='' || acceptMonth==='' ||
                //     acceptYear==='')  setSavedMessage('Не заполнена дата получения документа обратно')
                 setSavedMessage('Введите корректную дату')
            })
};

const toShowWarn=()=>{
    handleOpenModal();
    setTimeout(handleCloseModal, 5000);
    setSavedMessage('Пожалуйста, сохраните документ сначала в PDF формате')
}
return isSavedPdf ? isPassedToServer() : toShowWarn()
    }

    // @ts-ignore
    // @ts-ignore
    return (<div className={style.voucher_style}>

            <span className='title_'>Расписка в приеме от гражданина
                            личного документа воинского учета</span>
            <div className='btn-wrapper'>
                <Button variant="contained" onClick={() => props.history.push('/forms')}>
                    Назад
                </Button>
            </div>

            <div className={style.wrapper} id={'pdfdiv'}>
                <OrganizationVoucher number={number} setNumber={setNumber} rank={rank} setRank={setRank}
                                     rankTo={rankTo} setRankTo={setRankTo}
                                     fullNameTo={fullNameTo} setFullNameTo={setFullNameTo} middleNameTo={middleNameTo}
                                     setMiddleNameTo={setMiddleNameTo}
                                     birth={birth} setBirth={setBirth} series={series} setSeries={setSeries}
                                     series_1={series_1} setSeries_1={setSeries_1}
                                     target={target} setTarget={setTarget} validateDay={validateDay}
                                     setValidateDay={setValidateDay}
                                     validateMonthDay={validateMonthDay} setValidateMonthDay={setValidateMonthDay}
                                     validateYear={validateYear}
                                     day={day} monthDay={monthDay} setDay={setDay} setValidateYear={setValidateYear}
                                     setMonthDay={setMonthDay} year={year} setYear={setYear} official={official}
                                     setOfficial={setOfficial}
                                     file={file} setFile={setFile} monthValidate={monthValidate}
                                     setMonthValidate={setMonthValidate}
                                     month={month} setMonth={setMonth} handleChangeNumber={handleChangeNumber}
                                     took={took} setTook={setTook} took_1={took_1} setTook_1={setTook_1}
                                     gettingPerson={gettingPerson} setGettingPerson={setGettingPerson}
                                     acceptYear={acceptYear} setAcceptYear={setAcceptYear}
                                     acceptMonth={acceptMonth} setAcceptMonth={setAcceptMonth}
                                     acceptDay={acceptDay} setAcceptDay={setAcceptDay}
                />
                <section className={style.lineSection}>
                    <div>{line_1.map((i, index) => {
                        return <div key={index}>{i}</div>
                    })}</div>
                    <div>{line_2.map((i, index) => {
                        return <div key={index}>{i}</div>
                    })}</div>
                </section>
                <PersonVoucher took={took} setTook={setTook} took_1={took_1} setTook_1={setTook_1}
                               gettingPerson={gettingPerson} setGettingPerson={setGettingPerson}
                               acceptYear={acceptYear} setAcceptYear={setAcceptYear}
                               acceptMonth={acceptMonth} setAcceptMonth={setAcceptMonth}
                               acceptDay={acceptDay} setAcceptDay={setAcceptDay}
                               number={number} setNumber={setNumber} rank={rank} setRank={setRank}
                               rankTo={rankTo} setRankTo={setRankTo}
                               fullName={fullName} setFullName={setFullName} middleName={middleName}
                               setMiddleName={setMiddleName}
                               birth={birth} setBirth={setBirth} series={series} setSeries={setSeries}
                               series_1={series_1} setSeries_1={setSeries_1}
                               target={target} setTarget={setTarget} validateDay={validateDay}
                               setValidateDay={setValidateDay}
                               validateMonthDay={validateMonthDay} setValidateMonthDay={setValidateMonthDay}
                               validateYear={validateYear}
                               day={day} monthDay={monthDay} setDay={setDay} setValidateYear={setValidateYear}
                               setMonthDay={setMonthDay} year={year} setYear={setYear} official={official}
                               setOfficial={setOfficial}
                               file={file} setFile={setFile} monthValidate={monthValidate}
                               setMonthValidate={setMonthValidate}
                               month={month} setMonth={setMonth} handleChangeNumber={handleChangeNumber}/>
            </div>
            <div className='btn-save-wrapper'>
                <button className='btn-save-pdf' onClick={() => {
                    generatePDFDocument({
                        number, rank, rankTo, fullName, middleName, birth, series,series_1,
                        target, validateDay, validateMonthDay, validateYear,
                        official, day, monthDay, year, took, took_1, gettingPerson, line_1, line_2,
                        acceptMonth, acceptYear, acceptDay,fullNameTo, middleNameTo
                    });setIsSavedPdf(true)
                }}>Сохранить в PDF
                </button>
                <button className='btn-save' onClick={saveLetter}>Сохранить</button>
            </div>
            <SaveModal handleCloseModal={handleCloseModal} openModal={openModal} savedMessage={savedMessage}/>
        </div>
    );
};

const generatePDFDocument = async (data_pdf: {
    number: string, rank: string,rankTo: string, fullName: string, middleName: string,
    fullNameTo: string, middleNameTo: string,
    birth: string, series: string,series_1: string, line_1: any[], line_2: any[]
    target: string, validateDay: string, validateMonthDay: string,
    validateYear: string, took: string, took_1: string, gettingPerson: string,
    official: string, day: string, monthDay: string, year: string; acceptDay: string;
    acceptMonth: string; acceptYear: string
}) => {

    const blobPdf = pdf(VoucherPdf(data_pdf));
    blobPdf.updateContainer(VoucherPdf(data_pdf));
    const result = await blobPdf.toBlob();
    saveAs(result, "Voucher.pdf");

};

const styles = StyleSheet.create({
        page: {
            marginRight: 'auto',
            marginLeft: 'auto',
            fontSize: 12,
            fontStyle: 'normal',
            fontFamily: 'Roboto'
        },
        wrapper: {
            marginRight: 'auto',
            marginLeft: 'auto',
            width: 794,
            height: 548,
            marginTop: 10,
            marginBottom: 20,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row'
        },
        text: {
            fontWeight: 300,
            fontStyle: 'normal',
            fontSize: 12,
            textAlign: 'left',
            fontFamily: 'Roboto'
        },
        text_2: {
            fontWeight: 300,
            fontStyle: 'normal',
            fontSize: 12,
            textAlign: 'left',
            fontFamily: 'Roboto',
            paddingTop: 10
        },
    text_4:{
        fontWeight: 300,
        fontStyle: 'normal',
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'Roboto',
        paddingTop: 10
    },
    text_3: {
        fontWeight: 300,
        fontStyle: 'normal',
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'Roboto',
    },
    text_5: {
        fontWeight: 300,
        fontStyle: 'normal',
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'Roboto',
        paddingTop:-5
    },
    text_7: {
        fontWeight: 300,
        fontStyle: 'normal',
        fontSize: 12,
        textAlign: 'right',
        fontFamily: 'Roboto',
        paddingTop:20
    },

        text_: {
            fontWeight: 300,
            fontStyle: 'normal',
            fontSize: 12,
            textAlign: 'left',
            paddingBottom: 10,
            fontFamily: 'Roboto'
        },
    text__: {
        fontWeight: 300,
        fontStyle: 'normal',
        fontSize: 12,
        textAlign: 'left',
        paddingBottom: 10,
        paddingTop:20,
        fontFamily: 'Roboto'
    },
    text_6: {
        fontWeight: 300,
        fontStyle: 'normal',
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'Roboto'
    },
        textCenter: {
            fontWeight: 'bold',
            fontSize: 12,
            textAlign: 'center',
            paddingBottom: 10,
            fontFamily: 'Roboto',
            lineHeight:1
        },
        textLine: {
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontSize: 12,
            fontFamily: 'Roboto'
        },
        person: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: 374,

        },
        organization: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: 374,
        },
        linesSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 300,
        },
        lines: {
            width: 46,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: 120
        },

        helper: {
            fontStyle: 'normal',
            fontSize: 9,
            textAlign: 'center',
            paddingBottom: 10,
            fontFamily: 'Roboto',
        },
        helper_: {
            fontStyle: 'normal',
            fontSize: 9,
            textAlign: 'center',
            paddingBottom: 10,
            fontFamily: 'Roboto',
            width: 190,
            marginRight: 'auto',
            marginLeft: 'auto'
        },
    helper_1: {
        fontStyle: 'normal',
        fontSize: 9,
        textAlign: 'center',
        paddingBottom: 10,
        fontFamily: 'Roboto',
        width: 180,
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop:-41
    },
    helperNum: {
        fontStyle: 'normal',
        fontSize: 9,
        textAlign: 'center',
        paddingBottom: 10,
        fontFamily: 'Roboto',
        width: 180,
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop:-27
    },
        helperFilled: {
            fontStyle: 'normal',
            fontSize: 9,
            textAlign: 'center',
            marginBottom: 10,
            fontFamily: 'Roboto',
            marginTop: -20
        },
    helperFilled_1: {
        fontStyle: 'normal',
        fontSize: 9,
        textAlign: 'center',
        fontFamily: 'Roboto',
        marginTop: -20,
        width: 180,
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom:10
    },
        helperFilled_: {
            fontStyle: 'normal',
            fontSize: 9,
            textAlign: 'center',
            fontFamily: 'Roboto',
            marginTop: -20,
            width: 190,
            marginRight: 'auto',
            marginLeft: 'auto'
        },
        helperLine: {
            textAlign: 'center',
            position: 'relative',
            top: -28,
            fontFamily: 'Roboto'
        },
        helperLineTook: {
            textAlign: 'right',
            position: 'relative',
            top: -17,
            fontFamily: 'Roboto'
        },
        helperLineTook_1: {
            textAlign: 'left',
            position: 'relative',
            top: -17,
            fontFamily: 'Roboto'
        },

    helperLineTook_6: {
        textAlign: 'left',
        position: 'relative',
        top: -27,
        fontFamily: 'Roboto'
    },
    helperLineTook_2: {
        textAlign: 'right',
        position: 'relative',
        top: -17,
        fontFamily: 'Roboto'
    },
    helperLineTook_3: {
        textAlign: 'right',
        position: 'relative',
        top: -38,
        fontFamily: 'Roboto',
        marginBottom:-17
    },
    helperLineTook_4: {
        textAlign: 'right',
        position: 'relative',
        top: -38,
        fontFamily: 'Roboto',
    },
        helperLineDate: {
            textAlign: 'left',
            position: 'relative',
            top: -25,
            fontFamily: 'Roboto'
        },
        textUnd: {
            fontWeight: 'bold',
            fontSize: 12,
            textAlign: 'left',
            paddingBottom: 10,
            fontFamily: 'Roboto'
        },
    textUnd_11: {
        fontWeight: 'bold',
        fontSize: 12,
        textAlign: 'center',
        paddingBottom: 10,
        fontFamily: 'Roboto'
    },



    }
);

const VoucherPdf = (props: any) => {

    let lines_1 = ['Л', 'и', 'н', 'и', 'я', '                  ']
    let lines_2 = ['о', 'т', 'р', 'е', 'з', 'а']
    return (<Document>
            <Page orientation={'landscape'}>
                <View style={styles.wrapper}>
                    <View style={styles.person}>
                        <Text style={styles.textCenter}>
                            Корешок расписки в приеме
                        </Text>
                        <Text style={styles.textCenter}>
                            от гражданина
                            личного документа
                        </Text>
                        <Text style={styles.textCenter}>
                            воинского учета
                        </Text>

                        <Text style={styles.textCenter}>  {props.number ? <Text style={styles.textUnd}>
                            № {props.number}</Text> : <Text>№ ______________</Text>}</Text>
                        <Text style={styles.helperLine}>{props.number ? '_________' : ''}</Text>

                        <Text style={styles.text}>
                            в приеме {props.took ? <Text
                            style={styles.textUnd}>{props.took}</Text> : '______________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook}>{props.took ? '__________________________________________' : ''}</Text>
                        <Text style={props.took ? styles.helperFilled : styles.helper}>(серия, номер
                            удостоверения</Text>

                        <Text style={styles.text}>
                            {props.took_1 ? <Text
                                style={styles.textUnd}>{props.took_1}</Text> : '________________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.took_1 ? '________________________________________________' : ''}</Text>
                        <Text style={props.took_1 ? styles.helperFilled : styles.helper}>призывника или военного
                            билета)</Text>

                        <Text style={styles.text}>
                            от {props.rankTo ? <Text
                            style={styles.textUnd}>{props.rankTo}</Text> : '_____________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook}>{props.rankTo ? '______________________________________________' : ''}</Text>
                        <Text style={props.rankTo ? styles.helperFilled : styles.helper}>(воинское звание (если таковое
                            имеется),</Text>

                        <Text style={styles.text}>
                            {props.fullName ? <Text
                                style={styles.textUnd}>{props.fullName}</Text> : '________________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.fullName ? '________________________________________________' : ''}</Text>
                        <Text style={props.fullName ? styles.helperFilled : styles.helper}>фамилия, собственное
                            имя,</Text>

                        <Text style={styles.text}>
                            {props.middleName ? <Text
                                style={styles.textUnd}>{props.middleName}</Text> : '________________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.middleName ? '________________________________________________' : ''}</Text>
                        <Text style={props.middleName ? styles.helperFilled : styles.helper}>отчество (если таковое
                            имеется)</Text>

                        <Text style={styles.text}>
                            {props.birth ? <Text
                                style={styles.textUnd}>{props.birth}</Text> : '________________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.birth ? '________________________________________________' : ''}</Text>
                        <Text style={props.birth ? styles.helperFilled : styles.helper}>(число, месяц, год
                            рождения)</Text>

                        <Text style={styles.text}>
                            для {props.target ? <Text
                            style={styles.textUnd}>{props.target}</Text> : '___________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook}>{props.target ? '_____________________________________________' : ''}</Text>
                        <Text style={props.target ? styles.helperFilled : styles.helper}>(указать для какой цели)</Text>

                        <Text style={styles.text_}>
                            Расписка действительна по
                        </Text>
                        <Text style={styles.text}>
                            {props.validateDay ? <Text style={styles.textUnd}>{props.validateDay} </Text> : '______ '}
                            {props.validateMonthDay ?
                                <Text style={styles.textUnd}>{props.validateMonthDay} </Text> : '______________ '}
                            {props.validateYear ?
                                <Text style={styles.textUnd}>20{props.validateYear} г.</Text> : '20____ г.'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.validateDay || props.validateMonthDay || props.validateYear ? '______________' : ''}</Text>

                        <Text style={props.official ? styles.text_5 : styles.text_4}>
                            {props.official ? <Text
                                style={styles.textUnd_11}>{props.official}</Text> : '_______________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.official ? '________________________________________________' : ''}</Text>
                        <Text style={props.official ? styles.helperFilled_ : styles.helper_}>(подпись, инициалы,
                            фамилия, должность лица, принявшего
                            удостоверение
                            призывника или военный билет)</Text>
                        <Text style={styles.text}>
                            {props.day ? <Text style={styles.textUnd}>{props.day} </Text> : '______ '}
                            {props.monthDay ? <Text style={styles.textUnd}>{props.monthDay} </Text> : '______________ '}
                            {props.year ? <Text style={styles.textUnd}>20{props.year} г. </Text> : '20____ г.'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.day || props.monthDay || props.year ? '______________' : ''}</Text>

                        <Text style={styles.text__}>
                            Документ обратно получил(а)
                        </Text>

                        <Text style ={props.gettingPerson ? styles.text_3 : styles.text}>
                            {props.gettingPerson ? <Text
                                style={styles.textUnd_11}>{props.gettingPerson}</Text> : '_______________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.gettingPerson ? '________________________________________________' : ''}</Text>
                        <Text style={props.gettingPerson ? styles.helperFilled : styles.helper}>(подпись, инициалы, фамилия гражданина)</Text>

                        <Text style={styles.text_}>
                            {props.acceptDay ? <Text style={styles.textUnd}>{props.acceptDay} </Text> : '______ '}
                            {props.acceptMonth ?
                                <Text style={styles.textUnd}>{props.acceptMonth} </Text> : '______________ '}
                            {props.acceptYear ?
                                <Text style={styles.textUnd}>20{props.acceptYear} г. </Text> : '20____ г.'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_6}>{props.acceptDay || props.acceptMonth || props.acceptYear ? '______________' : ''}</Text>
                    </View>
                    <View style={styles.lines}>
                        {lines_1.map((i: string, index: number) => {
                            return <Text style={styles.textLine} key={index}>{i}</Text>
                        })}
                        {lines_2.map((i: string, index: number) => {
                            return <Text style={styles.textLine} key={index}>{i}</Text>
                        })}
                    </View>
                    <View style={styles.organization}>
                        <Text style={styles.textCenter}>
                            Расписка в приеме
                        </Text>
                        <Text style={styles.textCenter}>
                            от гражданина личного документа
                        </Text>
                        <Text style={styles.textCenter}>
                            воинского учета
                        </Text>
                        <Text style={styles.textCenter}>
                            № {props.number ? <Text style={styles.textUnd}>{props.number}</Text> : '______________'}
                        </Text>
                        <Text style={styles.helperLine}>{props.number ? '_________' : ''}</Text>

                        <Text style={styles.text}>
                            Дана {props.rank ? <Text
                            style={styles.textUnd}>{props.rank}</Text> : '________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook}>{props.rank ? '______________________________________________' : ''}</Text>
                        <Text style={props.rank ? styles.helperFilled : styles.helper}>(воинское звание (если таковое имеется),</Text>

                        <Text style={styles.text}>
                            {props.fullNameTo ? <Text
                                style={styles.textUnd}>{props.fullNameTo}</Text> : '_______________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.fullNameTo ? '________________________________________________' : ''}</Text>
                        <Text style={props.fullNameTo ? styles.helperFilled : styles.helper}>фамилия, собственное имя,</Text>

                        <Text style={styles.text}>
                            {props.middleNameTo ? <Text
                                style={styles.textUnd}>{props.middleNameTo}</Text> : '_______________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.middleNameTo ? '________________________________________________' : ''}</Text>
                        <Text style={props.middleNameTo ? styles.helperFilled : styles.helper}>отчество (если таковое имеется)</Text>
                        <Text style={styles.text}>
                            {props.birth ? <Text
                                style={styles.textUnd}>{props.birth}</Text> : '_______________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.birth ? '________________________________________________' : ''}</Text>
                        <Text style={props.birth ? styles.helperFilled : styles.helper}>(число, месяц, год рождения)</Text>

                        <Text style={styles.text_}>
                            в том, что у него (нее) принят
                            {props.series ? <Text
                                style={styles.textUnd}> {props.series}</Text> :<Text style={styles.text_7}> _______________________________________</Text>}
                        </Text>
                        <Text style={styles.text_6}>
                            {props.series_1 ? <Text
                                style={styles.textUnd}> {props.series_1}</Text> : '_______________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_4}> {props.series ? '___________________________' : ''}</Text>
                        <Text
                            style={styles.helperLineTook_3}> {props.series_1 ? '________________________________________________' : ''}</Text>
                        <Text style={props.series_1 ? styles.helperFilled_1 : styles.helperNum}>(серия, номер удостоверения призывника или военного билета)</Text>

                        <Text style={styles.text}>
                            для {props.target ? <Text
                            style={styles.textUnd}>{props.target}</Text> : '___________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook}>{props.target ? '_____________________________________________' : ''}</Text>
                        <Text style={props.target ? styles.helperFilled : styles.helper}>(указать для какой цели)</Text>

                        <Text style={styles.text_}>
                            Расписка действительна по
                        </Text>

                        <Text style={styles.text}>
                            {props.validateDay ? <Text style={styles.textUnd}>{props.validateDay} </Text> : '______ '}
                            {props.validateMonthDay ?
                                <Text style={styles.textUnd}>{props.validateMonthDay} </Text> : '______________ '}
                            {props.validateYear ?
                                <Text style={styles.textUnd}>20{props.validateYear} г.</Text> : '20____ г.'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.validateDay || props.validateMonthDay || props.validateYear ? '______________' : ''}</Text>


                        <Text style={props.official ? styles.text_5 : styles.text_4}>
                            {props.official ? <Text
                                style={styles.textUnd_11}>{props.official}</Text> : '_______________________________________________________________________'}
                        </Text>
                        <Text
                            style={styles.helperLineTook_1}>{props.official ? '________________________________________________' : ''}</Text>
                        <Text style={props.official ? styles.helperFilled_ : styles.helper_}>(подпись, инициалы, фамилия, должность лица, принявшего
                            удостоверение
                            призывника или военный билет)</Text>
                        <Text style={styles.text}>
                            {props.day ? <Text style={styles.textUnd}>{props.day} </Text> : '______ '}
                            {props.monthDay ? <Text style={styles.textUnd}>{props.monthDay} </Text> : '______________ '}
                            {props.year ? <Text style={styles.textUnd}>20{props.year} г. </Text> : '20____ г.'}
                        </Text>
                        <Text
                        style={styles.helperLineTook_1}>{props.day || props.monthDay || props.year ? '______________' : ''}</Text>
                    </View>
                </View>
            </Page>
        </Document>

    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(0),
            },
        },
    }),
);
const styleNumberForm: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}
const styleDataForm: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
}
const dataInputStyle: CSSProperties = {
    width: '100%'
}
const dataInputStyle_: CSSProperties = {
    width: '31%'
}
const styleHelperText: CSSProperties = {
    margin: '0 auto'
}
const styleHelperTextLong: CSSProperties = {
    textAlign: 'center'
};

const PersonVoucher = (props: any) => {
    const classes = useStyles();
    return (

        <section className={style.container}>
            <div className={style.title}> Корешок расписки в приеме от гражданина
                личного документа воинского учета
            </div>
            <form className={classes.root} noValidate autoComplete="off">
                <InputNumber number={props.number} handleChangeNumber={props.handleChangeNumber}/>
                <div style={{display: 'flex'}}>
                    <div style={{marginRight: '10px', width: '97px'}}>в приеме</div>
                    <FormControl style={dataInputStyle}>
                        <Input style={dataInputStyle}
                               value={props.took}
                               onChange={(e: any) => {
                                   props.setTook(e.target.value)
                               }}
                               id="component-helper"
                               aria-describedby="component-helper-text"
                        />
                        <FormHelperText id="component-helper-text"
                                        style={styleHelperText}
                        >(серия, номер удостоверения</FormHelperText>
                    </FormControl>
                </div>
                <FormControl style={dataInputStyle}>
                    <Input style={dataInputStyle}
                           value={props.took_1}
                           onChange={(e: any) => {
                               props.setTook_1(e.target.value)
                           }}
                           id="component-helper"
                           aria-describedby="component-helper-text"
                    />
                    <FormHelperText id="component-helper-text"
                                    style={styleHelperText}
                    >призывника или военного билета)</FormHelperText>
                </FormControl>
                <PersonData rank={props.rank} setRank={props.setRank} fullName={props.fullName} rankTo={props.rankTo}
                            setRankTo={props.setRankTo}
                            setFullName={props.setFullName} middleName={props.middleName}
                            setMiddleName={props.setMiddleName} birth={props.birth}
                            setBirth={props.setBirth} message={'от'}/>
                <InputDates target={props.target} setTarget={props.setTarget} validateDay={props.validateDay}
                            setValidateDay={props.setValidateDay} validateMonthDay={props.validateMonthDay}
                            setValidateMonthDay={props.setValidateMonthDay} validateYear={props.validateYear}
                            setValidateYear={props.setValidateYear} official={props.official}
                            setOfficial={props.setOfficial} day={props.day} setDay={props.setDay}
                            monthDay={props.monthDay} setMonthDay={props.setMonthDay} year={props.year}
                            setYear={props.setYear}/>
                <div/>
                <div style={{marginRight: '10px',marginTop:'20px'}}> Документ обратно получил(а)</div>
                <FormControl style={dataInputStyle}>
                    <Input style={dataInputStyle}
                           value={props.gettingPerson}
                           onChange={(e: any) => {
                               props.setGettingPerson(e.target.value)
                           }}
                           id="component-helper"
                           aria-describedby="component-helper-text"
                    />
                    <FormHelperText id="component-helper-text"
                                    style={styleHelperText}
                    >(подпись, инициалы, фамилия гражданина)</FormHelperText>
                </FormControl>
                <FormControl style={{display: 'flex', flexDirection: 'row'}}>
                    <Input style={{width: '60px', marginRight: '10px'}}
                           value={props.acceptDay}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setAcceptDay(e.target.value)
                           }}
                           id="component-helper"
                           aria-describedby="component-helper-text"
                    />
                    <Input style={{width: '140px', marginRight: '10px'}}
                           value={props.acceptMonth}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setAcceptMonth(e.target.value)
                           }}
                           id="component-helper"
                           aria-describedby="component-helper-text"
                    />
                    <div className={style.wrapYear}><span>20</span> <Input
                        style={{width: '63px', marginRight: '10px', padding: '0px'}}
                        value={props.acceptYear}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            props.setAcceptYear(e.target.value)
                        }}
                        id="component-helper"
                        aria-describedby="component-helper-text"
                    /><span>г.</span></div>
                </FormControl>
                <FormControl style={dataInputStyle}>
                    <Input style={dataInputStyle}
                           id="component-helper"
                           aria-describedby="component-helper-text"
                    />
                </FormControl>
            </form>
        </section>

    )
};

const OrganizationVoucher = (props: any) => {
    const classes = useStyles();

    return (
        <>
            <section className={style.container}>
                <div className={style.title}>Расписка в приеме от гражданина
                    личного документа воинского учета
                </div>

                <form className={classes.root} noValidate autoComplete="off">
                    <InputNumber number={props.number} handleChangeNumber={props.handleChangeNumber}/>
                    <PersonDataOrganization rank={props.rank} setRank={props.setRank} rankTo={props.rankTo}
                                setRankTo={props.setRankTo}
                                fullNameTo={props.fullNameTo}
                                setFullNameTo={props.setFullNameTo} middleNameTo={props.middleNameTo}
                                setMiddleNameTo={props.setMiddleNameTo} birth={props.birth} setBirth={props.setBirth}
                                message={'Дана'}
                    />
                    <section>
                        <FormControl style={dataInputStyle}>
                         <div style={{display: 'flex'}}>  <div>  в том, что у него(нее) принят</div>
                            <Input style={dataInputStyle_}
                                   id="component-helper"
                                   value={props.series}
                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                       props.setSeries(e.target.value)
                                   }}
                                   aria-describedby="component-helper-text"
                            />
                            </div>
                                <Input style={dataInputStyle}
                                       id="component-helper"
                                       value={props.series_1}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                           props.setSeries_1(e.target.value)
                                       }}
                                       aria-describedby="component-helper-text"
                                />
                            <FormHelperText id="component-helper-text"
                                            style={styleHelperTextLong}
                            >(серия,номер удостоверения призывника или военного билета)</FormHelperText>
                        </FormControl>
                    </section>
                    <InputDates target={props.target} setTarget={props.setTarget} validateDay={props.validateDay}
                                setValidateDay={props.setValidateDay} validateMonthDay={props.validateMonthDay}
                                setValidateMonthDay={props.setValidateMonthDay} validateYear={props.validateYear}
                                setValidateYear={props.setValidateYear} official={props.official}
                                setOfficial={props.setOfficial} day={props.day} setDay={props.setDay}
                                monthDay={props.monthDay} setMonthDay={props.setMonthDay} year={props.year}
                                setYear={props.setYear}/>
                </form>
            </section>
        </>
    )
}

const InputNumber = (props: any) => {

    return (
        <div style={styleNumberForm}>
            <div style={{marginRight: '10px'}}>№</div>
            <FormControl>
                <Input style={{padding: '0px', width: '90px'}}
                       id="component-helper"
                       value={props.number}
                       onChange={props.handleChangeNumber}
                       aria-describedby="component-helper-text"
                />
            </FormControl>
        </div>
    )
}
const PersonData = (props: any) => {
    return (
        <div>
            <section className={style.getRank}>
                <div style={{marginRight: '10px'}}>{props.message}</div>
                <FormControl style={dataInputStyle}>
                    <Input style={dataInputStyle}
                           id="component-helper"
                           value={props.rankTo}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setRankTo(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <FormHelperText id="component-helper-text"
                                    style={styleHelperText}
                    >(воинское звание (если таковое имеется),</FormHelperText>
                </FormControl>
            </section>
            <section className={style.dataSection}>
                <FormControl>
                    <Input style={dataInputStyle}
                           id="component-helper"
                           value={props.fullName}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setFullName(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <FormHelperText id="component-helper-text"
                                    style={styleHelperText}
                    >фамилия, собственное имя,</FormHelperText>
                </FormControl>
                <FormControl>
                    <Input style={dataInputStyle}
                           id="component-helper"
                           value={props.middleName}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setMiddleName(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <FormHelperText id="component-helper-text"
                                    style={styleHelperText}
                    >отчество (если таковое имеется)</FormHelperText>
                </FormControl>
                <FormControl>
                    <Input style={dataInputStyle}
                           id="component-helper"
                           value={props.birth}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setBirth(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <FormHelperText id="component-helper-text"
                                    style={styleHelperText}
                    >(число,месяц,год рождения)</FormHelperText>
                </FormControl>
            </section>
        </div>
    )
}
const PersonDataOrganization = (props: any) => {
    return (
        <div>
            <section className={style.getRank}>
                <div style={{marginRight: '10px'}}>{props.message}</div>
                <FormControl style={dataInputStyle}>
                    <Input style={dataInputStyle}
                           id="component-helper"
                           value={props.rank}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setRank(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <FormHelperText id="component-helper-text"
                                    style={styleHelperText}
                    >(воинское звание (если таковое имеется),</FormHelperText>
                </FormControl>
            </section>
            <section className={style.dataSection}>
                <FormControl>
                    <Input style={dataInputStyle}
                           id="component-helper"
                           value={props.fullNameTo}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setFullNameTo(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <FormHelperText id="component-helper-text"
                                    style={styleHelperText}
                    >фамилия, собственное имя,</FormHelperText>
                </FormControl>
                <FormControl>
                    <Input style={dataInputStyle}
                           id="component-helper"
                           value={props.middleNameTo}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setMiddleNameTo(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <FormHelperText id="component-helper-text"
                                    style={styleHelperText}
                    >отчество (если таковое имеется)</FormHelperText>
                </FormControl>
                <FormControl>
                    <Input style={dataInputStyle}
                           id="component-helper"
                           value={props.birth}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setBirth(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <FormHelperText id="component-helper-text"
                                    style={styleHelperText}
                    >(число,месяц,год рождения)</FormHelperText>
                </FormControl>
            </section>
        </div>
    )
}

const InputDates = (props: any) => {
    return (
        <section style={{alignItems: 'center'}}>
            <div style={styleDataForm}>
                <div style={{marginRight: '10px'}}> для</div>
                <FormControl style={dataInputStyle}>
                    <Input style={dataInputStyle}
                           id="component-helper"
                           value={props.target}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setTarget(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <FormHelperText id="component-helper-text"
                                    style={styleHelperText}
                    >(указать для какой цели)</FormHelperText>
                </FormControl>
            </div>
            <div>
                <div style={{marginRight: '10px'}}> Расписка действительна по</div>
                <FormControl style={{display: 'flex', flexDirection: 'row'}}>
                    <Input style={{width: '60px', marginRight: '10px'}}
                           id="component-helper"
                           value={props.validateDay}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setValidateDay(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <Input style={{width: '140px', marginRight: '10px'}}
                           id="component-helper"
                           value={props.validateMonthDay}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setValidateMonthDay(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <div className={style.wrapYear}><span>20</span> <Input
                        style={{width: '63px', marginRight: '10px', padding: '0px'}}
                        id="component-helper"
                        value={props.validateYear}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            props.setValidateYear(e.target.value)
                        }}
                        aria-describedby="component-helper-text"
                    /><span>г.</span></div>
                </FormControl>
                <div>
                    <FormControl>
                        <Input style={dataInputStyle}
                               id="component-helper"
                               value={props.official}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                   props.setOfficial(e.target.value)
                               }}
                               aria-describedby="component-helper-text"
                        />
                        <FormHelperText id="component-helper-text"
                                        style={styleHelperTextLong}
                        >(подпись, инициалы, фамилия, должность лица, принявшего удостоверение
                            призывника или военный билет)</FormHelperText>
                    </FormControl>
                </div>
                <FormControl style={{display: 'flex', flexDirection: 'row'}}>
                    <Input style={{width: '60px', marginRight: '10px'}}
                           id="component-helper"
                           value={props.day}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setDay(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <Input style={{width: '140px', marginRight: '10px'}}
                           id="component-helper"
                           value={props.monthDay}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               props.setMonthDay(e.target.value)
                           }}
                           aria-describedby="component-helper-text"
                    />
                    <div className={style.wrapYear}><span>20</span> <Input
                        style={{width: '63px', marginRight: '10px', padding: '0px'}}
                        id="component-helper"
                        value={props.year}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            props.setYear(e.target.value)
                        }}
                        aria-describedby="component-helper-text"
                    /><span>г.</span></div>
                </FormControl>
            </div>

        </section>
    )
}

export const SaveModal = (props: any) => {

    return (
        <>
            <Dialog
                open={props.openModal}
                onClose={props.handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.savedMessage}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}
