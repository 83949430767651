import React, {CSSProperties, useEffect, useState} from 'react';
import style from './Acts.module.css';
import Axios from "../../utils/axios";
import {SaveModal} from "../Voucher/Voucher";
import {pdf} from "@react-pdf/renderer";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font
} from "@react-pdf/renderer";
import Button from "@material-ui/core/Button";
import font_4 from '../../fonts/TimesNewRoman/bold_Times New Roman.ttf';
import font_3 from '../../fonts/TimesNewRoman/times_new_roman.ttf';


const inputChairManStyle: CSSProperties = {
    width: '400px',
    marginRight: '10px',
    marginLeft: '10px'
}

Font.register({
    family: "Times New Roman",
    src:font_3,
    format: "truetype",
});
Font.register({
    family: "Times New Roman",
    src:font_4,
    format: "truetype",
});

export default function Acts(props:any) {

    useEffect(() => {
        const axios = new Axios();
        axios.get(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}/`)
            .then(res => {
                setCompany(res.data.short_organization_name);
                setBoss(res.data.full_name_head);
                setCompanyId(res.data.id);
                setHeadCompany(res.data.position_of_head);
                setBossPosition(res.data.position_of_head);
            })
    }, []);
    const [bossPosition, setBossPosition]= useState('')
    const [openModal, setOpenModal] = useState(false);
    const [isSavedPdf, setIsSavedPdf] = useState(false);
    const [savedMessage, setSavedMessage] = useState('');
    const [company, setCompany] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [boss, setBoss] = useState('')
    const [hedCompany, setHeadCompany] = useState('')
    const [whoPassed, setWhoPassed] = useState('');
    const [whoTook, setWhoTook] = useState('');
    const [chairMan, setChairMan] = useState({
        full_name: '', position: ''
    })
    const [newchairMan, setNewChairMan] = useState({
        full_name: '', position: ''
    })
    const [member, setMember] = useState([
        {full_name: '', position: ''},
        {full_name: '', position: ''},
        {full_name: '', position: ''},
    ])
    const [documentsList, setDocumentsList] = useState([{name_doc: '', count: ''}])
    const [day, setDay] = useState('')
    const [month, setMonth] = useState('')
    const [monthDay, setMonthDay] = useState('')
    const [year, setYear] = useState('')
    const [restPerson, setRestPerson] = useState('')
    const [fileDoc, setFileDoc] = useState(null)


    const [day1, setDay1] = useState('')
    const [month1, setMonth1] = useState('')
    const [monthDay1, setMonthDay1] = useState('')
    const [year1, setYear1] = useState('')
    const [day2, setDay2] = useState('')
    const [month2, setMonth2] = useState('')
    const [monthDay2, setMonthDay2] = useState('')
    const [year2, setYear2] = useState('')
    const [documents, setDocuments] = useState('')


    useEffect(() => {

        if (month.indexOf('январ') !== -1 || month.indexOf('01') !== -1) setMonthDay('01')
        if (month.indexOf('феврал') !== -1 || month.indexOf('02') !== -1) setMonthDay('02')
        if (month.indexOf('март') !== -1 || month.indexOf('03') !== -1) setMonthDay('03')
        if (month.indexOf('апрел') !== -1 || month.indexOf('04') !== -1) setMonthDay('04')
        if (month.indexOf('мая') !== -1 || month.indexOf('05') !== -1 || month.indexOf('май') !== -1) setMonthDay('05')
        if (month.indexOf('июн') !== -1 || month.indexOf('06') !== -1) setMonthDay('06')
        if (month.indexOf('июл') !== -1 || month.indexOf('07') !== -1) setMonthDay('07')
        if (month.indexOf('август') !== -1 || month.indexOf('08') !== -1) setMonthDay('08')
        if (month.indexOf('сентябр') !== -1 || month.indexOf('09') !== -1) setMonthDay('09')
        if (month.indexOf('октябр') !== -1 || month.indexOf('10') !== -1) setMonthDay('10')
        if (month.indexOf('ноябр') !== -1 || month.indexOf('11') !== -1) setMonthDay('11')
        if (month.indexOf('декабр') !== -1 || month.indexOf('12') !== -1) setMonthDay('12')
    }, [month]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const onChangeChairManValue = (e: any) => {
        const {name, value} = e.target;
        setChairMan(prevState => ({
            ...prevState,
            [name]: value
        }))
    }
    const onChangeNewChairManValue = (e: any) => {
        const {name, value} = e.target;
        setNewChairMan(prevState => ({
            ...prevState,
            [name]: value
        }))
    }
    const onChangeMemberValue = (e: any, indexItem: number) => {
        let arr = [...member]
        let newArr = arr.map((item, index) => {
            let keys = Object.keys(item)
            if (indexItem === index) {
                if (keys[1] === e.target.name) {
                    return {...item, position: e.target.value}
                }
                if (keys[0] === e.target.name) {
                    return {...item, full_name: e.target.value}
                } else return {...item}
            } else return {...item}
        })
        setMember(newArr)
    }

    const onAddDocument = () => {
        let document = {name_doc: '', count: ''};
        setDocumentsList([...documentsList, document])
    }

    const onChangeDocumentValue = (e: any, indexItem: number) => {
        let arr = [...documentsList]
        let newArr = arr.map((item, index) => {
            let keys = Object.keys(item)
            if (indexItem === index) {
                if (keys[1] === e.target.name) {
                    return {...item, count: e.target.value}
                }
                if (keys[0] === e.target.name) {
                    return {...item, name_doc: e.target.value}
                } else return {...item}
            } else return {...item}
        })
        setDocumentsList(newArr)
    }

    const toSaveAct = () => {

      const  toPassedInServer=()=>{
          setIsSavedPdf(false)
          let comission:any = [...member, chairMan]

          let file:any=fileDoc
          const data= new FormData()
          data.append('who_passed',whoPassed)
          data.append('who_took',whoTook)
          data.append('date',`20${year}-${monthDay}-${day}`)
          data.append('company_id',companyId)
          data.append('act_commision',JSON.stringify(comission))
          data.append('act_info',JSON.stringify(documentsList))
          data.append('docs',file)

          // let data = {
          //     who_passed: whoPassed,
          //     who_took: whoTook,
          //     date: `20${year}-${monthDay}-${day}`,
          //     company_id: companyId,
          //     act_commision: comission,
          //     act_info: documentsList,
          //     docs:null
          // };

          let errorMessage=()=>{
              return whoPassed ==='' ? setSavedMessage('Вы не заполнили поле "Сдал(а)"'):
                  whoTook ==='' ?setSavedMessage('Вы не заполнили поле "Принял(а)"') :
                      year ==='' || monthDay==='' || day==='' ? setSavedMessage('Вы не заполнили дату'):
                          member[0].position==='' || member[0].full_name==='' ? setSavedMessage('Вы не внесли председателя и(или) членов комиссии'):
                              documentsList[0].name_doc==='' || documentsList[0].count===''  ? setSavedMessage('Вы не внесли документы') :
                                  setSavedMessage('Данные не сохранены')
          }
          const axios = new Axios();
          axios.post(`worker/act/${companyId}/`, data)
              .then(res => {
                  handleOpenModal();
                  setTimeout(handleCloseModal, 5000);
                  setSavedMessage('Данные успешно сохранены')
              })
              .catch(error => {
                  handleOpenModal();
                  setTimeout(handleCloseModal, 5000);
                  errorMessage()
              })
        };
   const   toShowWarn=()=>{
          handleOpenModal();
          setTimeout(handleCloseModal, 5000);
          setSavedMessage('Пожалуйста, сохраните документ сначала в PDF формате')
      }
        return isSavedPdf ? toPassedInServer() : toShowWarn()
    }

    return (<>
        <div className='btn-wrapper'>
            <div className='btn-wrapper'>
                <Button variant="contained" onClick={() => props.history.push('/home')}>
                    Назад
                </Button>
            </div>
        </div>
            <div className={style.wrapper}>
                <section className={style.cap}>
                    <span style={{paddingBottom: '10px'}}>УТВЕРЖДАЮ</span>
                    <form noValidate autoComplete="off" className={style.nameCap}>
                        <div>{bossPosition}</div>
                        <div>{boss}</div>
                        <div>{hedCompany}</div>
                        <div>{company}</div>
                        <div className={style.date}>
                            <input value={day} onChange={(e: any) => {
                                setDay(e.target.value)
                            }}
                                   style={{borderBottom: '1px solid gray', width: '33px', marginRight: '10px'}}/>
                            <input value={month} onChange={(e: any) => {
                                setMonth(e.target.value)
                            }}
                                   style={{borderBottom: '1px solid gray', width: '83px', marginRight: '10px'}}/>
                            <span>20</span><input value={year} onChange={(e: any) => {
                            setYear(e.target.value)
                        }}
                                                  style={{
                                                      borderBottom: '1px solid gray',
                                                      width: '23px',
                                                      marginRight: '10px'
                                                  }}/><span>г.</span>
                        </div>
                    </form>
                </section>
                <section className={style.title}>
                    Акт приема-передачи<br/>
                </section>
                <section className={style.text}>
                    В связи с убытием в основной отпуск, 
                    <input style={inputChairManStyle} placeholder='должность ответственного'
                           value={chairMan.position} onChange={onChangeChairManValue}
                           name='position'/>
                    <input style={inputChairManStyle} placeholder='ФИО ответственного'
                           value={chairMan.full_name} onChange={onChangeChairManValue}
                           name='full_name'/>
                    передает документы по ведению воинского учета, а
                            <input style={inputChairManStyle} placeholder='должность нового ответственного'
                                   value={newchairMan.position} onChange={onChangeNewChairManValue}
                                   name='position'/>
                            <input style={inputChairManStyle} placeholder='ФИО нового ответственного'
                                   value={newchairMan.full_name} onChange={onChangeNewChairManValue}
                                   name='full_name'/>             
                    принимает:<br/>
                    1.ДОКУМЕНТЫ по ведению воинского учета.<br/>
                    2.ДЕЛО С ПЕРЕПИСКОЙ по воинскому учету.<br/>
                    3.КАРТОТЕКА личных карточек военнообязанных и призывников 
                    <input style={inputChairManStyle} placeholder='дополнительный текст'
                           value={documents} onChange={(e) => {
                            setDocuments(e.target.value)
                        }}
                           name='documents'/><br/>
                    4.ЖУРНАЛ  ПРОВЕРОК  состояния в/учета<br/>
                </section>
                <div>
                </div>
                <section className={style.members}>
                    <div>
                        <span>Сдал(а):</span><span><input placeholder='ФИО'
                                                          value={whoPassed} onChange={(e) => {
                        setWhoPassed(e.target.value)
                    }}/></span>
                    </div>
                    <div style={{marginBottom: '45px'}}>
                        <span>Приянл(а):</span><span><input placeholder='ФИО'
                                                            value={whoTook} onChange={(e) => {
                        setWhoTook(e.target.value)
                    }}/></span>
                    </div>
                    <div className={style.date}>
                            <input value={day1} onChange={(e: any) => {
                                setDay1(e.target.value)
                            }}
                                   style={{borderBottom: '1px solid gray', width: '33px', marginRight: '10px'}}/>
                            <input value={month1} onChange={(e: any) => {
                                setMonth1(e.target.value)
                            }}
                                   style={{borderBottom: '1px solid gray', width: '83px', marginRight: '10px'}}/>
                            <span>20</span><input value={year1} onChange={(e: any) => {
                            setYear1(e.target.value)
                        }}
                                                  style={{
                                                      borderBottom: '1px solid gray',
                                                      marginRight: '10px'
                                                  }}/><span>г.</span>
                        </div>
                    <div>
                        <span>Сдал(а):</span><span><input placeholder='ФИО'
                                                          value={whoTook} onChange={(e) => {
                        setWhoPassed(e.target.value)
                    }}/></span>
                    </div>
                    <div style={{marginBottom: '45px'}}>
                        <span>Приянл(а):</span><span><input placeholder='ФИО'
                                                            value={whoPassed} onChange={(e) => {
                        setWhoTook(e.target.value)
                    }}/></span></div>
                    <div className={style.date}>
                            <input value={day2} onChange={(e: any) => {
                                setDay2(e.target.value)
                            }}
                                   style={{borderBottom: '1px solid gray', width: '33px', marginRight: '10px'}}/>
                            <input value={month2} onChange={(e: any) => {
                                setMonth2(e.target.value)
                            }}
                                   style={{borderBottom: '1px solid gray', width: '83px', marginRight: '10px'}}/>
                            <span>20</span><input value={year2} onChange={(e: any) => {
                            setYear2(e.target.value)
                        }}
                                                  style={{
                                                      borderBottom: '1px solid gray',
                                                      marginRight: '10px'
                                                  }}/><span>г.</span>
                        </div>
 
                </section>
            </div>
            <div className='btn-save-wrapper' >
                <button  className='btn-save-pdf' onClick={() => {
                    generatePDFDocument({
                        company, boss, day, month, year, chairMan, newchairMan, member,
                        restPerson, documentsList, whoTook, whoPassed,hedCompany,
                        day1, month1, year1, day2, month2, year2, documents, bossPosition
                    },setFileDoc);setIsSavedPdf(true)
                }}>Сохранить в PDF
                </button>
                <button className='btn-save'
                        onClick={toSaveAct}>Сохранить
                </button>
            </div>
            <SaveModal handleCloseModal={handleCloseModal} openModal={openModal} savedMessage={savedMessage}/>
        </>
    )
}
// @ts-ignore
const generatePDFDocument = async (data_pdf: {
    company: string; boss: string; day: string; month: string; year: string;
    chairMan: { full_name: string; position: string; };
    newchairMan: { full_name: string; position: string; };
    member: { full_name: string; position: string; }[];
    restPerson: string; documentsList: { name_doc: string; count: string }[],
    whoTook: string; whoPassed: string; hedCompany:string;
    day1: string; month1: string; year1: string;
    day2: string; month2: string; year2: string;
    documents: string; bossPosition: string;
},setFileDoc:any) => {
    const blobPdf = pdf(ActPdf(data_pdf));

    blobPdf.updateContainer(ActPdf(data_pdf));
    const result = await blobPdf.toBlob();
    setFileDoc(result)
    saveAs(result, "Act.pdf");
};

const styles = StyleSheet.create({
    page: {
        width: '90%',
        marginTop: 40,
        marginBottom: 40,
        marginRight: 'auto',
        marginLeft: 'auto',
        border: '1px solid black',
        fontSize: 13,
    },
    cap: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
        marginRight: 0
    },
    text: {
        fontFamily: "Times New Roman",
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: 13
    },
    text_: {
        fontFamily: "Times New Roman",
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: 13,
        paddingBottom:20,
    },
    text__: {
        fontFamily: "Times New Roman",
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: 13,
        paddingBottom:15,
    },
    text___: {
        fontFamily: "Times New Roman",
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: 13,
    },
    text___2: {
        fontFamily: "Times New Roman",
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: 13,
        paddingBottom:5,
    },
    textCommission: {
        fontFamily: "Times New Roman",
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: 13,
        textIndent: 40,
    },
    textDate: {
        fontFamily: "Times New Roman",
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: 13,
        marginRight: 10
    },
    date: {
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'row'
    },
    title: {
        fontFamily: "Times New Roman",
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: 13,
        textAlign: 'center'
    },
    wrapperTitle: {
        marginTop: 10,
        marginBottom: 20,
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
    },
    descriptionText: {
        width: '85%',
        textAlign: 'left',
        marginTop: 5,
        marginBottom: 40,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    textMembers: {
        fontFamily: "Times New Roman",
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: 13,
        marginLeft: 60,
        paddingRight: 10,
        paddingBottom:7,
    },
    list:{
        display:'flex',
        flexDirection:'row'
    },
    listText: {
        marginTop: 20,
        fontFamily: "Times New Roman",
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: 13,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center'
    },
    wrapperFooter: {
        width: 500,
        marginBottom: 30,
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 30
    },
    text1:{
        marginBottom: 20,
        fontFamily: "Times New Roman",
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: 13,
    },
    text2:{
        marginBottom: 30,
        fontFamily: "Times New Roman",
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: 13,
    },
    membersList_:{
        display:'flex',
        flexDirection:'column',
    },
    membersList:{
        display:'flex',
        flexDirection:'row',
    }


});


const ActPdf = (props: any) => {

    return (<Document>
            <Page style={styles.page}>
                <View style={styles.cap}>
                    <Text style={styles.text__}>Утверждаю</Text>
                    <Text style={styles.text___2}>{props.bossPosition} </Text>
                       <Text style={styles.text___}> {props.boss}</Text>
                    <Text style={styles.text___}>{props.hedCompany}</Text>
                    <Text style={styles.text___}>{props.company}</Text>

                    <View style={styles.date}>
                            <Text style={styles.textDate}>«{props.day ? `${props.day}` : ' ____ '}»
                                {props.month ? `  ${props.month}  ` : ' ______________ '}
                                {props.year ? ` 20${props.year} г. `: ' 20____ г. '}  </Text>
                    </View>
                </View>
                <View style={styles.wrapperTitle}>
                    <Text style={styles.title}>Акт приема-передачи</Text>
                </View>
                <View style={styles.descriptionText}>
                    <Text style={styles.textCommission}>
                    В связи с убытием в основной отпуск, {props.chairMan.position} {props.chairMan.full_name} передает документы по ведению
                     воинского учета, а {props.newchairMan.position} {props.newchairMan.full_name} принимает: 
                     </Text>  
                     <Text style={styles.textCommission}>
                    1.ДОКУМЕНТЫ по ведению воинского учета.</Text>  
                    <Text style={styles.textCommission}>
                    2.ДЕЛО С ПЕРЕПИСКОЙ по воинскому учету.</Text>  
                    <Text style={styles.textCommission}>
                    3.КАРТОТЕКА личных карточек военнообязанных и призывников {props.documents}</Text>  
                    <Text style={styles.textCommission}>
                    4.ЖУРНАЛ  ПРОВЕРОК  состояния в/учета
                    </Text>
                </View>
                <View style={styles.wrapperFooter}>
                    <Text style={styles.text1}>Сдал(а): {props.whoPassed ? `                               _______________________      / ${props.whoPassed} /`
                    : `                               _______________________      /____________________ /`}   </Text>
                    <Text style={styles.text2}>Принял(а):   {props.whoTook ? `                        _______________________      / ${props.whoTook} /`
                        : `                        _______________________      /____________________ /`}</Text>
                    <View style={styles.date}>
                    <Text style={styles.textDate}>«{props.day1 ? `${props.day1}` : ' ____ '}»
                                {props.month1 ? `  ${props.month1}  ` : ' ______________ '}
                                {props.year1 ? ` 20${props.year1} г. `: ' 20____ г. '}  </Text>
                    </View>
                    <Text style={styles.text1}>Сдал(а): {props.whoTook ? `                               _______________________      / ${props.whoTook} /`
                    : `                               _______________________      /____________________ /`}   </Text>
                    <Text style={styles.text2}>Принял(а):   {props.whoPassed ? `                        _______________________      / ${props.whoPassed} /`
                        : `                        _______________________      /____________________ /`}</Text>
                    <View style={styles.date}>
                    <Text style={styles.textDate}>«{props.day2 ? `${props.day2}` : ' ____ '}»
                                {props.month2 ? `  ${props.month2}  ` : ' ______________ '}
                                {props.year2 ? ` 20${props.year2} г. `: ' 20____ г. '}  </Text>
                    </View>
                </View>
            </Page>
        </Document>

    )
}
