import React, { useState, useEffect } from 'react';
import Autocomplete from 'react-autocomplete';
import Axios from '../../utils/axios';
import classes from './Documents.module.css';

const AutocompleteDocTypes = (props : any) => {
  
    const onSelect = (value: string, item: any) => {
        props.setValue(value);
    }

    const onChange = (event: any, value: any) => {
        props.setValue(value);
    }

    function matchStateToTerm(state: any, value: any) {
        return (
            state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
            //state.abbr.toLowerCase().indexOf(value.toLowerCase()) !== -1
        )
    }
    return (
        <Autocomplete
            value={props.value}
            items={props.items}
            getItemValue={(item) => item.name}
            wrapperStyle={{ position: 'relative', display: 'inline-block' }}
            shouldItemRender={matchStateToTerm}
            onChange={onChange}
            onSelect={onSelect}
            renderMenu={children => (
                <div className={classes.menuAutocomplete} key={new Date().getMilliseconds() + 17}>
                    {children}
                </div>
            )}
            renderItem={(item, isHighlighted) => (
                <div
                    className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                    key={item.id + new Date().getMilliseconds() + 50}
                >{item.name}</div>
            )}
            renderInput={props => {
                //return <Input  id="first_name" />;
                return <input {...props} className={classes.inputStyle} type="search" />;
            }}
        />
    )
}

export default AutocompleteDocTypes;