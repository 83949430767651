import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    UnderlineType,
    AlignmentType,
    HeadingLevel,
    //ITableCellMarginOptions, 
    Table,
    WidthType,
    TableCell,
    TableRow
} from "docx";
import { saveAs } from "file-saver";

const formatDate = (date: string) => {
    const d = new Date(date);

    const day = d.getDate();
    const month = d.getMonth() + 1;
    let result = (day < 10 ? '0' + day : day) + '.' + (month < 10 ? '0' + month : month) + '.' + d.getFullYear() + ' г.';

    return result;
}

const WorkPlanDOCX = ({ date1, name1, office, position2, name2, position3, name3, company, date2, name, nameResponsible, city, year }: any) => {
    
    
    const doc = new Document(
        {
            title: "План работ",
            styles: {
                paragraphStyles: [
                    {
                        id: "Heading1",
                        name: "Heading 1",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 24,
                            bold: true,
                        },
                        paragraph: {
                            spacing: {
                                after: 120,
                            },
                        },
                    },
                    {
                        id: "listParagraph",
                        name: "ListParagraph",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 24,
                        },
                        paragraph: {
                            indent: {
                                firstLine: 700,
                            },
                            spacing: {
                                // before: 240,
                                // after: 120,
                            },
                            alignment: AlignmentType.JUSTIFIED,
                        },
                    },
                    {
                        id: "header",
                        name: "header",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 24,
                        },
                        paragraph: {
                            indent: {
                                //left: 5500,
                            },
                            alignment: AlignmentType.LEFT,
                        },
                    },
                    {
                        id: "box1",
                        name: "box1",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 24,
                            bold: true
                        },
                        paragraph: {
                            indent: {
                                //left: 5500,
                            },

                            alignment: AlignmentType.LEFT,
                        },
                    },
                    {
                        id: "title",
                        name: "title",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 28,
                            //bold: true
                        },
                        paragraph: {
                            indent: {
                                //left: 5500,
                            },

                            alignment: AlignmentType.CENTER,
                        },
                    },
                    {
                        id: "footer",
                        name: "footer",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 24,
                            //bold: true
                        },


                    },
                    {
                        id: "longRow",
                        name: "longRow",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 24,
                            bold: true
                        },
                        paragraph: {
                            indent: {
                                left: 70,
                                right: 60,
                            },
                            //alignment: AlignmentType.CENTER,
                        },
                    },
                    {
                        id: "centerColumnHeader",
                        name: "centerColumnHeader",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 24,
                            // bold: true
                        },
                        paragraph: {
                            indent: {
                                left: 70,
                                right: 60,
                            },
                            alignment: AlignmentType.CENTER,
                        },
                    },
                    {
                        id: "centerColumnHeaderBold",
                        name: "centerColumnHeaderBold",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 24,
                            bold: true
                        },
                        paragraph: {
                            indent: {
                                left: 70,
                                right: 60,
                            },
                            alignment: AlignmentType.CENTER,
                        },
                    },
                    {
                        id: "cellAll",
                        name: "cellAll",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 24,
                        },
                        paragraph: {
                            indent: {
                                left: 70,
                                right: 60,
                                
                            },
                            //alignment: AlignmentType.CENTER,
                        },
                    },
                    {
                        id: "secondHeader",
                        name: "secondHeader",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 24,
                        },
                        paragraph: {
                            indent: {
                                left: 5000,
                            },
                            //alignment: AlignmentType.CENTER,
                        },
                    },
                ],
            },
        }
    );


    const table = new Table({

        width: {
            size: 2000,
            type: WidthType.AUTO,
        },
        margins: {
            bottom: 15,
            top: 15,
            left: 15,
            right: 15
        },
        rows: [

            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "№ п/п" })],
                    }),
                    new TableCell({

                        children: [new Paragraph({ style: "centerColumnHeader", text: "Наименование мероприятий" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Срок выполнения" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Ответственный" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Отметка о выполнении" })],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeaderBold", text: "1" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeaderBold", text: "2" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeaderBold", text: "3" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeaderBold", text: "4" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeaderBold", text: "5" })],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "1" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: "Постановка на воинский учет граждан, принятых на работу. Проверка наличия и подлинности документов воинского учета, наличие штампа военкомата о постановке на воинский учет (соответствие регистрации в паспорте и территориальности РВК), заполнение на них личных карточек (форма 2)." })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "В дни приема на работу" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({  
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "2" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: "Снятие с учета граждан, состоящих в запасе и призывников" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "В дни увольнения" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "3" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: "Внесение в личные карточки сведений об изменении семейного положения, состава семьи, уровня основного образования,  должности (специальности), места жительства (регистрации) или места пребывания" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Постоянно" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "4" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: "Уточнение данных военнообязанных и призывников по документам воинского учета организации и посредством личного общения с работником организации" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Ежегодно" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "5" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: "Внесение в личные карточки сведений об изменении семейного положения, состава семьи, уровня основного образования,  должности (специальности), места жительства (регистрации) или места пребывания." })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Ежегодно" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "6" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: "Обновление (замена) личных карточек (форма 2) пришедших в негодность." })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "По мере необходимости" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "7" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: "Подготовка личных карточек на лиц, исключенных с воинского учета по состоянию здоровья (уточнить даты исключения, номера приказов и статей) для сверки с военкоматом." })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "1 раз в полугодие" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "8" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: "Направление в военные комиссариаты сведений о принятых на работу (учебу) и уволенных с работы (отчисленных с учебных заведений), военнообязанных и призывников, а также об изменениях их военно-учетных данных." })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "В течение 1-го месяца" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "9" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: `Проверять состояние картотеки с личными карточками граждан состоящих на воинском учете:` }),
                        new Paragraph({ style: "cellAll", text: "- правильность построения картотеки;" }),
                        new Paragraph({ style: "cellAll", text: "- правильность, полнота и качество заполнения личных карточек." })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Eжеквартально" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "10" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: `Проведение сверки личных карточек граждан, состоящих в запасе и призывников с учетными данными районных  военных комиссариатов ${city}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Eжеквартально" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "11" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: "Направление в военные комиссариаты других районов, городов республики в которых состоят на воинском учете работающие в организации граждане, списков для сверки учетных данных." })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "По запросам военных комиссариатов" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "longRow", text: "Составление отчетности" })],
                        columnSpan: 5,

                    }),

                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "1" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: `Изъятие из картотеки карточек на граждан, состоящих в запасе и подлежащих исключению с воинского учета по возрасту, или по болезни, сделав отметку об исключении в разделе 2 личных карточек (форма 2)`})],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Перед составлением отчета" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "2" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: `Составление отчета по форме № 6 «численности работающих военнообязанных»` })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "До 15 января" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "3" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: `Представление в военный комиссариат района сведений согласно (приложение № 14)` })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "К 15 октября" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "longRow", text: "Другие мероприятия" })],
                        columnSpan: 5,
                    }),

                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "1" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: `Выявлять граждан женского пола, которые в соответствии с перечнем специальностей (приложение №8)  подлежат постановке на воинский учет`  })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Постоянно" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "2" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: `Изучение руководящих документов по воинскому учету и призывников и военнообязанных` })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Постоянно" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "3" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "cellAll", text: `Подготовка проекта приказа и акта на передачу  документов воинского учета )`})],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: "Перед отпуском" })],
                    }),
                    new TableCell({
                        children: [new Paragraph({ style: "centerColumnHeader", text: `${nameResponsible}` })],
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                    }),
                ],
            }),
        ],
    });


    doc.addSection({
        children: [
            new Paragraph({
                style: "header",
                children: [
                    new TextRun({
                        text: "СОГЛАСОВАНО",
                        bold: true,
                        // font: 20
                    }),
                    new TextRun({
                        style: 'secondHeader',
                        text: "                             УТВЕРЖДАЮ",
                        bold: true,
                        // font: 20
                    }),
                ],
            }),

            new Paragraph({
                style: "header",
                children: [
                    new TextRun({
                        text: `Военный комиссар `,
                    }),
                    new TextRun({
                        text: `                   ${position2}`,
                    }),
                ],
            }),
            new Paragraph({
                style: "header",
                children: [
                    new TextRun({
                        text: `${office.slice(20)}                                            ${company}`,
                    }),
                ],
            }),
            new Paragraph({
                style: "header",
                children: [
                    new TextRun({
                        text: `_____________  ${name1}                                              _____________  ${name2}`,
                    }),
                ],
            }),
            
            new Paragraph({
                style: "header",
                text: `${formatDate(date1)}                                               ${formatDate(date2)}`,
            }),
            new Paragraph({
                style: "header",
                text: ``,
            }),
            new Paragraph({
                style: "header",
                text: ``,
            }),


            new Paragraph({
                style: 'title',
                children: [
                    new TextRun({
                        text: "ПЛАН",
                        bold: true,
                        //font: 28
                    }),
                ],
            }),
            new Paragraph({
                style: 'title',
                children: [
                    new TextRun({
                        text: "работы по ведению воинского учета призывников",
                        bold: true,
                        // font: 28
                    }),
                ],
            }),
            new Paragraph({
                style: 'title',
                children: [
                    new TextRun({
                        text: `и военнообязанных в ${year} году`,
                        bold: true,
                        // font: 28
                    }),
                ],
            }),
            new Paragraph({
                style: 'title',
                children: [
                    new TextRun({
                        text: `в `,
                        bold: true,
                        // font: 28
                    }),
                    new TextRun({
                        text: `${company}`,
                        bold: true,
                        underline: {
                            type: UnderlineType.SINGLE,
                        },
                        // font: 28
                    }),
                ],
            }),
            new Paragraph({
                style: 'title',
                children: [
                    new TextRun({
                        text: ``,
                        bold: true,
                        // font: 28
                    }),
                ],
            }),

            table,

            new Paragraph({
                style: "listParagraph",
                text: "",
            }),
            new Paragraph({
                style: "listParagraph",
                text: "",
            }),
            new Paragraph({
                style: 'footer',
                children: [
                    new TextRun({
                        text: `${position3}         `,
                    }),
                    new TextRun({
                        text: "        ___________________________ ",
                    }),
                    new TextRun({
                        text: `   ${name3}`,
                    }),
                ],
            }),
            new Paragraph({
                style: "",
                text: "",
            }),
        ],


    });


    Packer.toBlob(doc).then((blob) => {
        //fs.writeFileSync("Document.docx", buffer);
        saveAs(blob, "План_работ.docx");
    });
}

export default WorkPlanDOCX;