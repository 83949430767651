import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import ChangeLetter from '../../components/Letters/ChangeLetter';
import './NewChangeLetter.css';
import AutocompleteOffice from '../../components/AutocompleteOffice/AutocompleteOffice';
import Axios from '../../utils/axios';
import {connect, useSelector} from 'react-redux';
import SuccessfulSaveModal from '../../components/SuccessfulSaveModal/SuccessfulSaveModal';
import {Link} from 'react-router-dom';
// @ts-ignore
import { DataGrid } from '@material-ui/data-grid';
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import {QuestionModal} from "../../utils/Modals/QuestionModal";

const getDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;

    return today.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
}

const getChangeDate=(value:string)=>{
    const date = new Date(value);
    const month_ = date.toLocaleString('default', { month: 'long' });
    const month = month_[0].toUpperCase() + month_.substring(1);
return `${month},${new Date(date).getDay()}, ${new Date(date).getFullYear()} г. `
}

const NewChangeLetter = ({user, ...props}: any) => {

    const [office, setOffice] = useState('');
    const [date, setDate] = useState(getDate());
    const [number, setNumber] = useState('');
    const [address, setAddress] = useState('');
    const [fullAddress, setFullAddress] = useState({
        area: '',
        city: '',
        district: '',
        index: '',
        address: ''
    });
    const [items, setItems] = useState([]);

    const [file, setFile] = useState<any>('');
    const [isDisabled, setDisabled] = useState(true);

    const name = `${(localStorage.getItem('responsible person') as string).split(' ')[2][0]}.${(localStorage.getItem('responsible person') as string).split(' ')[1][0]}. ${(localStorage.getItem('responsible person') as string).split(' ')[0]} `
    const phone = localStorage.getItem('hr_department_phone');
    const company = localStorage.getItem('company');

    const [openModal, setOpenModal] = useState(false);
    const [idOffice, setIdOffice] = useState(0);
    let office_id_val = useSelector((state: any) => state.lettersReducer.office_id_value);
    const [organizationName, setOrganizationName] = useState('');
    const [organizationRespPerson, setOrganizationRespPerson] = useState('');
    const [organizationPhone, setOrganizationPhone] = useState('');
    const [organizationPositionResp, setOrganizationPositionResp] = useState('');
    const [changes, setChanges] = useState([]);
    const [basisUpdate, setBasisUpdate] = useState([]);
    const [reason, setReason] = useState('');
    const [historyChanges, setHistoryChanges] = useState([]);
    const [shortOffice, setShortOffice] = useState('');
    const [reasonValue, setReasonValue] = useState('');
    const [isSavePdf, setIsSavePdf] = useState(false);
    const [openAcceptModal, setOpenAcceptModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleChangeOffice = (event: any) => {
        setOffice(event.name as string);
        setAddress(event.address as string);
        setShortOffice(event.short_name as string);
        setFullAddress({
            area: event.area,
            city: event.city,
            district: event.district,
            index: event.index,
            address: `${event.letter_street} \n ${event.index}, ${event.letter_locality} \n ${event.letter_district!==null ? event.letter_district : '' }`
        })
    };

    useEffect(()=>{
        const axios = new Axios();
        axios.get(`worker/history/updatelk/${localStorage.getItem('id')}`)
            .then(res=>{
                setHistoryChanges(res.data.results);
            });
    },[office])

    useEffect(() => {
        const axios = new Axios();
        axios.get('worker/office/')
            .then(res => {
                let value_office = res.data.results.filter((i: any) => {
                    return office_id_val === i.name ? i.id : null
                })
                for (let i of value_office) {
                    setIdOffice(i.id)
                }
            })

    }, [office_id_val])
    const postData=() => {
        setOpenAcceptModal(false)
        const axios = new Axios();
        let body = new FormData();
        body.append("register_date", `${date}T12:00:00.016954+03:00`);
        body.append("docs", file, 'Письмо_об_изменении_учётных_данных');
        body.append("office_id", idOffice.toString());
        body.append("type_id", 'Письмо о изменении данных');
        body.append("user", String(localStorage.getItem('id')));
        if (file !== '') {
            axios.post(`worker/report/${localStorage.getItem('id')}/`, body)
                .then(res => {
                    axios.post(`worker/${localStorage.getItem('id')}/report/`, {
                        report: 2,
                        office__name: office
                    })
                        .then(res => {
                            handleOpenModal();
                            setTimeout(handleCloseModal, 5000);
                            setIsSavePdf(false);
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
                .catch(err => {
                    console.log(err);
                })
        }

    };



    useEffect(() => {
        const axios = new Axios();
        const user_id = localStorage.getItem('id');
        axios.post(`worker/${user_id}/filter/`, {
            changes_report: false,
            office__name: office,
            category:3
           //  report:true
        })
            .then(res => {
                setItems(res.data.results);
            })
            .catch(err => {
                console.log(err);
            });
        axios.get(`worker/company/${localStorage.getItem('userID')}/${localStorage.getItem('id')}/`)
            .then(res=>{
                setOrganizationName(res.data.short_organization_name);
                setOrganizationRespPerson(res.data.full_name);
                setOrganizationPhone(res.data.phone);
                setOrganizationPositionResp(res.data.position);
            })
        axios.get(`library/basis/update/`)
            .then(res=>{
                //@ts-ignore
                setBasisUpdate(res.data.results)
            });


    }, [office]);

    useEffect(()=>{
        const axios=new Axios()
        axios.post(`worker/${localStorage.getItem('id')}/filter_date/`,{
                "date_field_name" : "changes_date __gte",
                "count_day" : '',
                "changes_report":false,
                 "report":true
            }
        )
            .then(res=>{
                let arr=res.data.results.filter((i:any)=>{
                    return i.remove_military===null && i.date_remove_military===null && i.remove_military===null
                })
                setChanges(arr)

            });
    },[openModal])
    let  arrItems=items.map((item:any)=>{
              return  {...item,history_changes:
              historyChanges.filter((h:any)=>{
                  return item.id===h.worker
              })
              }
        });

    const save = () => {
        setIsSavePdf(true);
        const data = {
            office,
            date,
            number,
            address,
            items,
            name,
            phone,
            company,
            setFile,
            fullAddress,
            organizationName,
            organizationRespPerson,
            organizationPhone,
            organizationPositionResp,
            reason,
            arrItems,
            shortOffice,
            reasonValue,
            getChangeDate
        }
        ChangeLetter(data);
    }


    useEffect(() => {
        if (number === '' || date === '' || office === '' || !isSavePdf) {
            setDisabled(true)
        } else {
            setDisabled(false);
        }

    }, [number, date, office,isSavePdf])


    return (
        <div style={{margin: '105px auto 10px auto', width: '98%'}}>

            <div style={{marginBottom:'280px'}}>
                {changes.length !==0 && <ChangedPersons changes={changes}/>}
                </div>
            <span className='title_letters'>Письмо об изменении учётных данных</span>
            <div className='toolbar'>
                <div className='btn-wrapper'>
                    <Button variant="contained" onClick={() => props.history.push('/letters')}>
                        Назад
                    </Button>
                </div>
                <div style={{marginBottom: '20px',fontSize: '14px',color:'red'}}><sup>*</sup>Прежде, чем отправить данные в электронный архив -пожалуйста, сохраните документ в PDF формате.</div>
            </div>
            <Button variant="contained" color="primary" startIcon={<ArtTrackIcon/>}
                    style={{marginRight: '20px'}}   onClick={save} >
                Сохранить в PDF
            </Button>

            <Button variant="contained"  disabled={isDisabled} onClick={()=>setOpenAcceptModal(true)}>
                Сохранить
            </Button>
            <div className='letter'>
                <div className='header'>
                    <div></div>
                    <div>
                        <span>Военному комиссару <br/></span>
                        <AutocompleteOffice
                            handleChangeOfficeName={handleChangeOffice}
                            setOfficeName={setOffice}
                        />

                        {fullAddress.address &&
                        <span className='underHeader'>
                            <br/>
                            {fullAddress.address}
                        </span>
                        }
                    </div>
                </div>

                <div className='description'>
                    <div>
                        <div className='description-child'>
                            Исх. №
                            <div>
                                <input type="text" value={number} onChange={(e: any) => setNumber(e.target.value)}
                                       className='number-input'/>
                            </div>
                        </div>
                        <div className='description-child'>
                            от
                            <div>
                                <input type="date" id="start"
                                       value={date}
                                       min="2017-01-01" max="2022-12-31"
                                       onChange={(e: any) => setDate(e.target.value)}
                                       className='number-input'
                               />
                            </div>

                        </div>
                    </div>
                    <div className="description-text">
                        <div>
                            <b>СВЕДЕНИЯ</b> <br/>
                            <b> о приеме на работу (поступлению на учебу) граждан, состоящих или обязанных
                                состоять на воинском учете,</b><br/>
                            <b>об их увольнении с работы (отчислении), <span style={{fontSize:'14px',textDecoration:'underline'}}>о произошедших изменениях</span> данных воинского учета</b>
                        </div>
                        <div className={'org-name-let'}>

                            <div style={{borderBottom:'1px solid gray'}}><b style={{fontSize:'14px'}} >{organizationName}</b></div>
                            <span>(наименование государственного органа, организации, учреждения образования,
                                местного исполнительного и распорядительного органа)</span>
                        </div>
                    </div>
                </div>

                <div className='table'>
                    <table className="tg">
                        <thead>
                        <tr className='table-header'>

                            <th className="tg-1">№ <br /> п/п</th>
                            <th className="tg-1">Основание</th>
                            <th className="tg-1">Воинское звание, номер военно-учетной специальности и код воинской
                                должности
                            </th>
                            <th className="tg-1">Фамилия, собственное имя, отчество(если
                                таковое имеется), идентификационный номер
                            </th>
                            <th className="tg-1">Дата рождения</th>
                            <th className="tg-1">Место жительства</th>
                            <th className="tg-1">Место пребывания</th>
                            <th className="tg-1">Образование</th>
                            <th className="tg-1">Семья</th>
                            <th className="tg-1">Работа(учеба)</th>
                            <th className="tg-1">Номера телефонов</th>
                            <th className="tg-1">Особые отметки</th>
                        </tr>
                        </thead>
                        <tbody>
                        {arrItems.map((item: any, number: number) => {
                            return (<tr key={number + 1}  className={'letter-columns-td'}>
                                <td className="tg-2">{number + 1}</td>
                                <td className="tg-2">{item.letter_base}<br/>
                                    <span>Причина: </span>
                                 {item.history_changes.map((i:any)=>{
                                        return <div>
                                            <span>{i.name} - </span>
                                            <span>{getChangeDate(i.data)}</span>
                                        </div>
                                    })}
                                </td>
                                <td className="tg-2"> {item.accountg_id===4 ? 'Призывник' :
                                    <>{item.grade}<br/><br/>
                                        {item.wyc ? item.wyc : '' }
                                    </>
                                }</td>
                                <td className="tg-2">{item.full_name}<br/><br/>
                                    {item.pass_number}</td>
                                <td className="tg-2">{item.date_of_birth ? item.date_of_birth.split('-').reverse().join('-') : ''}</td>
                                <td className="tg-2">{item.registered_address}</td>
                                <td className="tg-2">{item.address}</td>
                                <td className="tg-2"> {item.education ? item.education : ''}
                                    <br/> {item.education_full ? item.education_full : ''}
                                    <br/> {item.edu_specialization ? item.edu_specialization : ''}
                                    {/*<br/> {item.edu_qualification ? item.edu_qualification : ''}*/}
                                    <br/> {item.edu_dop_full ? item.edu_dop_full : ''}
                                    <br/> {item.edu_dop_specialization ? item.edu_dop_specialization : ''}

                                </td>
                                <td className="tg-2">{item.marital_status ? <span>{item.marital_status}
                                    <br/>
                                    {item.family_half && item.family ? `Состав семьи: \n супруга (супруг): \n ${item.family_half} \n ${item.family}` :
                                        !item.family_half && item.family ? `Состав семьи: \n ${item.family}` :
                                            item.family_half && !item.family ? `Состав семьи: \n супруга (супруг): \n ${item.family_half}` : ''}
                                    </span>:<span></span>}</td>
                                <td style={{whiteSpace: 'pre-line', width:'200px'}}>
                                    {item.position ? `${item.position} \n Дата приёма: ${item.add_military ? item.add_military.split('-').reverse().join('-') : ''}
                                    ${item.order_military ? 'Приказ № ' + item.order_military : ''} 
                                         ${item.date_add_military ? 'от ' + item.date_add_military.split('-').reverse().join('-') : ''} ` :
                                    item.education}  </td>
                                <td className="tg-2">{item.home_phone ? 'дом. ' + item.home_phone:''}<br/><br/>
                                    {item.work_phone ? `рабоч. ${item.work_phone}` : '' }<br/><br/>
                                    {item.phone ? `моб. ${item.phone}` : ''}</td>
                                <td className="tg-2">{item.status ? `Годность к военной службе: ${item.status}` : ''}<br/><br/>
                                    {item.requirement === true ? <><span>Моб. предписание:</span><div>Да</div>
                                            <div>{item.requirement_annotation}</div>
                                        </>
                                        : <><span>Моб. предписание:</span><div>Нет</div></>
                                    }
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>

                <div className='footers_'>
                    <div className={'name_styles'}>
                        {organizationRespPerson}
                        <span style={{fontSize: '10px',width: '243px'}}> (подпись, инициалы, фамилия должностного лица, ответственного за ведение военно-учетной работы)</span>
                    </div>
                    <div style={{fontSize: '14px',marginTop:'10px'}}>
                        Тел. {organizationPhone}
                    </div>
                </div>
            </div>

            <SuccessfulSaveModal
                message={'Письмо об Изменении учётных данных отправлено в '}
                open={openModal}
                handleClose={handleCloseModal}
                office={office}
            />
            <QuestionModal message={'Отправить данные в электронный архив? '} open={openAcceptModal} handleClose={()=>setOpenAcceptModal(false)} handleAccept={postData}/>
        </div>
    )
}
// export default NewLetter;
const mapStateToProps = (state: any) => ({
    user: state.user,
});

const mapActionsToProps = {
    // loginUser
};
export default connect(mapStateToProps, mapActionsToProps)(NewChangeLetter)

const ChangedPersons=(props:any)=>{
    const sorting=(arr:any[])=>{
        arr.sort((a, b) => a.full_name > b.full_name ? 1 : -1);
    };
    sorting(props.changes);

    const columns = [
        { field: 'id', headerName: '№', width: 70 },
        { field: 'full_name', headerName: 'ФИО', width: 270 },
        { field: 'war_name', headerName: 'Военкомат', width: 270 },
    ];
    let rows:any[]=[];
    let nextI=1
    for(let i of props.changes) {
        rows=[...rows,{id:nextI,full_name:i.full_name,war_name:i.office}]
        nextI++
    }

    return (<>
            {props.changes &&
            <div style={{ height: 400, width: '100%' }}>
                <div className={'warnMessage'}><sup>*</sup> Просим проверить, что для выбранных Вами сотрудников уже были составлены и направлены письма
                    в Военный Коммисариат о постановке их на воинский учёт! </div>
                   <div className={'warnMessage'} style={{marginBottom:'20px'}}> Перейти в раздел <Link to={'/newHiringLetter'}>"Письмо о приёме на работу"</Link>
                   </div>
                <div style={{fontWeight:'bold',textAlign:'center',marginBottom:'10px'}}>Список сотрудников, в карточках которых произошли изменения, письма по которым еще не отправлены</div>
                <DataGrid rows={rows} columns={columns} pageSize={5} disableColumnMenu={true}    />
            </div>
            }
        </>
    );

}
