import React, {useState, useEffect} from 'react';
import Header from '../HeaderLandingPage';
import Footer from '../FooterLandingPage';
import Axios from '../../../utils/axios';
import '../LandingPage.css';
import {NavLink} from "react-router-dom";

const ResponsibilityPage = () => {

    const [title, setTitle] = useState('Ответственность за нарушения воинского учёта');
    const [body, setBody] = useState([] as any);

    useEffect(() => {
        const axios = new Axios();
        axios.get(`control/page/4/`)
            .then(res => {
                setBody(res.data.page);
            })
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<>
            <div className='wrapper_header_'>
                <Header/>
            </div>
            <div className='wrapper_responsibility'>
                <div className='provisions_pre_title_1'>
                    <NavLink to='/' style={{textDecoration: 'none', color: 'black'}}><span>Главная</span></NavLink>
                    <span>/</span>
                    <span>Законодательсво</span>
                    <span>/</span>
                    <span style={{color: 'gray'}}>Ответственность за нарушения ВУ</span>
                </div>
                <div className='provisions_title'>{title}</div>
                <div className='provisions_body'>
                    {body.map((item: any, number: number) => {
                        return (
                            <div>
                                <div><strong>{item.name}</strong></div>
                                {item.text.split('\r\n').map((i: any, num: number) => {
                                    return <p key={num + 'num235'}>{i}</p>
                                })}

                            </div>
                        )
                    })}
                </div>
                <div style={{position: 'relative'}}><Footer/></div>
            </div>
        </>
    )
}
export default ResponsibilityPage;
